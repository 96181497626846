export const chartOptions = information => {
  return {
    tooltips: {
      callbacks: {
        title: (tooltipItem, { labels }) => {
          return labels[tooltipItem[0].index];
        },
        label: (tooltipItem, { datasets }) => {
          return `${datasets[0].data[tooltipItem.index]} traslados`;
        },
        afterLabel: ({ index }) => {
          const { percentage } = information[index];
          return percentage ? `(${percentage}%)` : null;
        }
      },
      backgroundColor: '#FFF',
      titleFontSize: 14,
      titleFontColor: '#000',
      bodyFontColor: '#000',
      displayColors: false
    }
  };
};

export const backgroundColors = [
  '#235236',
  '#f4ba27',
  '#d46b0d',
  '#0a435c',
  '#a86210',
  '#0193bd',
  '#fa7268',
  '#bea850',
  '#a74949'
];
