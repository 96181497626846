import * as React from 'react';

function BulletPoint(props) {
  return (
    <svg width={9} height={9} viewBox="0 0 9 9" {...props}>
      <circle data-name="Elipse 298" cx={4.5} cy={4.5} r={4.5} fill="#008363" />
    </svg>
  )
}

export default BulletPoint;
