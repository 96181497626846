import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import {withStyles} from '@material-ui/core/styles'
import {Link} from '@reach/router'


const defaultToolbarStyles = {
  iconButton: {},
}

class CustomToolbar extends React.Component {
  render() {
    const {classes, history, selectedEps} = this.props
    const url = selectedEps ? `/agreements/crear?epsId=${selectedEps}` : `/agreements/crear`;
    return (
      <React.Fragment>
        <Tooltip title={'Crear acuerdo'}>
            <IconButton className={classes.iconButton} onClick={()=> history.push(url)}>
              <AddIcon className={classes.deleteIcon} />
            </IconButton>
        </Tooltip>
      </React.Fragment>
    )
  }
}

export default withStyles(defaultToolbarStyles, {name: 'CustomToolbar'})(CustomToolbar)
