export const columns = [
  {
    name: 'name',
    title: 'Medellín',
    getCellValue: row => {
      if (!row) return undefined;
      return `${row.name || '--'}`;
    }
  },
  {
    name: 'emergency',
    title: 'Urgencias',
    getCellValue: row => {
      if (!row) return undefined;
      return row.emergency || 0;
    }
  },
  {
    name: 'uci',
    title: 'UCI',
    getCellValue: row => {
      if (!row) return undefined;
      return row.uci || 0;
    }
  },
  {
    name: 'uce',
    title: 'UCE',
    getCellValue: row => {
      if (!row) return undefined;
      return row.uce || 0;
    }
  },
  {
    name: 'hospitalization',
    title: 'Hospitalización',
    getCellValue: row => {
      if (!row) return undefined;
      return row.hospitalization || 0;
    }
  },
  {
    name: 'total',
    title: 'Total IPS',
    getCellValue: row => {
      if (!row) return undefined;
      return row.total || 0;
    }
  }
];

export const columnExtensions = [
  {
    columnName: 'name',
    width: 350,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'emergency',
    width: 300,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'uci',
    width: 300,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'uce',
    width: 300,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'hospitalization',
    width: 300,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'total',
    width: 150,
    align: 'center',
    wordWrapEnabled: true
  }
];

export const columnBands = [
  {
    title: 'Urgencias',
    children: [{ columnName: 'emergencyMale' }, { columnName: 'emergencyFemale' }, { columnName: 'emergencyTotal' }]
  },
  {
    title: 'Hospitalización',
    children: [{ columnName: 'hospMale' }, { columnName: 'hospFemale' }, { columnName: 'hospTotal' }]
  },
  {
    title: 'UCI',
    children: [{ columnName: 'uciMale' }, { columnName: 'uciFemale' }, { columnName: 'uciTotal' }]
  },
  {
    title: 'UCE',
    children: [{ columnName: 'uceMale' }, { columnName: 'uceFemale' }, { columnName: 'uceTotal' }]
  }
];

export const data = [];
export default {
  columns,
  columnExtensions,
  columnBands,
  data
};
