import React from 'react';
import PropTypes from 'prop-types';

// core components
import GridItem from 'components/Grid/GridItem';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import NumberInput from '../NumberInput/NumberInput';

const useStyles = makeStyles({
  addIcon: {
    padding: '0 10px',
    marginTop: '20px',
    background: '#05BF95',
    color: 'white',
    '&:hover': {
      background: '#05BF95'
    }
  }
});

const PatientByEps = ({ epsList, value, name, handleChange, placeholder, addPatientByEps }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <InputLabel style={{ fontSize: '14px' }}>{placeholder}</InputLabel>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <GridItem xs={8}>
          <FormControl required fullWidth>
            <InputLabel htmlFor={name} error={!value}>
              EPS
            </InputLabel>
            <Select
              fullWidth
              value={value.id || ''}
              onChange={({ target }) => handleChange(target.name, target.value)}
              inputProps={{
                name: `${name}Eps`,
                id: `${name}Eps`
              }}
            >
              {epsList &&
                epsList.map(eps => (
                  <MenuItem key={eps.id} value={eps.id}>
                    {eps.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={2}>
          <FormControl required fullWidth>
            <NumberInput
              required
              placeHolder="Cantidad"
              handleChange={handleChange}
              name={`${name}Qty`}
              value={`${value.qty}` !== 'undefined' ? `${value.qty}` : ''}
              validation={{
                type: 'min-value',
                number: 0
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={2}>
          <Fab
            size="small"
            variant="extended"
            aria-label="Add"
            className={classes.addIcon}
            onClick={() => addPatientByEps(name)}
            disabled={!value.id || !value.qty}
          >
            <AddIcon />
            Agregar
          </Fab>
        </GridItem>
      </div>
    </React.Fragment>
  );
};

PatientByEps.propTypes = {
  handleChange: PropTypes.func.isRequired,
  addPatientByEps: PropTypes.func.isRequired,
  epsList: PropTypes.array,
  value: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string
};

PatientByEps.defaultProps = {
  epsList: [],
  value: {},
  name: '',
  placeholder: ''
};

export default PatientByEps;
