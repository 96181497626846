import React from 'react';
import PropTypes, { array, string, number, object } from 'prop-types';

// Material-ui components
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';

// core components
import ManIconWhiteBackgroun from 'Icons/ManIconWhiteBackgroun';
import WomanIconWhiteBackground from 'Icons/WomanIconWhiteBackground';
import CloseIcon from 'Icons/CloseIcon';

const useStyles = makeStyles({
  marginOne: {
    margin: '1px'
  },
  closeIcon: {
    height: '10px',
    width: '10px',
    marginLeft: '10px',
    marginRight: '10px'
  },
  tooltip: {
    paddingRight: '20px'
  }
});

const PatientChip = ({
  patient,
  id,
  selectedEps,
  selectedService,
  selectedMunicipality,
  openModal,
  modalKey,
  comorbidities
}) => {
  const classes = useStyles();
  return (
    <Tooltip
      placement="bottom"
      title={
        <React.Fragment>
          <Typography color="inherit" className={classes.tooltip}>
            <ul>
              <li>Edad: {patient.age}</li>
              <li>
                Nombre:
                {patient.firstName && ` ${patient.firstName} `}
                {patient.middleName && `${patient.middleName} `}
                {patient.lastName && `${patient.lastName} `}
                {patient.secondLastName && `${patient.secondLastName}`}
              </li>
              <li>EPS: {patient.epsId && ` ${(selectedEps || {}).name}`}</li>
              {modalKey === 'covid' && (
                <li className={classes.comorbidities}>
                  Comorbilidades:
                  {` `}
                  {comorbidities}
                </li>
              )}
            </ul>
          </Typography>
        </React.Fragment>
      }
    >
      <Chip
        key={id}
        icon={patient.sex === 'M' ? <ManIconWhiteBackgroun /> : <WomanIconWhiteBackground />}
        label={`${selectedService ? selectedService.name : 'Defina un servicio válido'}/
        ${patient.documentType === undefined || patient.documentType === null ? '' : `${patient.documentType} `}
        ${patient.document === undefined || patient.document === null ? '' : `${patient.document}`}
        ${modalKey === 'covid' ? `/ ${selectedMunicipality.name}` : ''}`}
        className={classes.marginOne}
        onDelete={() => openModal(id, modalKey, Boolean(patient.id))}
        deleteIcon={<CloseIcon className={classes.closeIcon} />}
      />
    </Tooltip>
  );
};

PatientChip.propTypes = {
  patient: PropTypes.object.isRequired,
  comorbidities: PropTypes.oneOfType(string, array, object),
  id: PropTypes.oneOfType(string, number),
  selectedEps: PropTypes.object.isRequired,
  selectedService: PropTypes.object.isRequired,
  selectedMunicipality: PropTypes.oneOfType(string, object),
  openModal: PropTypes.func.isRequired,
  modalKey: PropTypes.string.isRequired
};

export default PatientChip;
