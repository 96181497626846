import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CovidCellSex from '../CovidInfected/CovidCellSex';

const getColor = column => {
  switch (column) {
    case 'covid':
      return '#F00000';
    case 'ira':
      return '#005C9A';
    case 'availables':
      return '#00F060';
    default:
      return '#828282';
  }
};

const renderCell = (columnName, value, classes) => {
  if (columnName === 'name') {
    return (
      <GridContainer>
        <GridItem xs={10}>
          <p style={{ color: getColor(columnName) }} className={classes.total}>
            {value}
          </p>
        </GridItem>
        <GridItem xs={2}>
          <Divider orientation="vertical" variant="middle" flexItem />
        </GridItem>
      </GridContainer>
    );
  }
  if (columnName === 'total') {
    return (
      <GridContainer className={classes.center}>
        <GridItem xs={10}>
          <Chip className={classes.totales} label={value} />
        </GridItem>
        <GridItem xs={2}>
          <Divider orientation="vertical" variant="middle" flexItem />
        </GridItem>
      </GridContainer>
    );
  }
  if (
    (columnName === 'ira' || columnName === 'covid') &&
    ((value.manWithCovid === 0 && value.womanWithCovid === 0) || (value.manWithIra === 0 && value.womanWithIra === 0))
  ) {
    return (
      <GridContainer>
        <GridItem xs={10} className={classes.center}>
          <Chip
            key="totalNumber"
            label="0"
            className={classes.totalChip}
            style={{ color: getColor(columnName), backgroundColor: '#FFF' }}
          />
        </GridItem>
        <GridItem xs={2}>
          <Divider orientation="vertical" variant="middle" flexItem />
        </GridItem>
      </GridContainer>
    );
  }
  if (columnName === 'covid') {
    return (
      <GridContainer>
        <GridItem xs={8}>
          <CovidCellSex value={value} classes={classes} type="covid" />
        </GridItem>
        <GridItem xs={2}>
          <Chip
            key="totalNumber"
            label={`= ${value.total}` || 0}
            className={classes.totalChip}
            style={{ color: getColor(columnName), backgroundColor: '#FFF' }}
          />
        </GridItem>
        <GridItem xs={2}>
          <Divider orientation="vertical" variant="middle" flexItem />
        </GridItem>
      </GridContainer>
    );
  }
  if (columnName === 'ira') {
    return (
      <GridContainer>
        <GridItem xs={8}>
          <CovidCellSex value={value} classes={classes} type="ira" />
        </GridItem>
        <GridItem xs={2}>
          <Chip
            key="totalNumber"
            label={`= ${value.total}` || 0}
            className={classes.totalChip}
            style={{ color: getColor(columnName), backgroundColor: '#FFF' }}
          />
        </GridItem>
        <GridItem xs={2}>
          <Divider orientation="vertical" variant="middle" flexItem />
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <GridContainer>
      <GridItem xs={10}>
        <p style={{ color: getColor(columnName) }} className={classes.total}>
          {value}
        </p>
      </GridItem>
      <GridItem xs={2}>
        <Divider orientation="vertical" variant="middle" flexItem />
      </GridItem>
    </GridContainer>
  );
};

export const Cell = props => {
  const { column, classes, value } = props;
  return (
    <Table.Cell className={classes.cell} style={{ padding: '8px 5px', margin: 0 }}>
      {renderCell(column.name, value, classes)}
    </Table.Cell>
  );
};

Cell.propTypes = {
  column: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])
};
