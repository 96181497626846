import React from 'react';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';

import { chartOptions } from './options';

export default function HorizontalChart({ info, color, keyName }) {
  const { information } = info;
  const data = {
    labels: information.map(row => row[keyName].charAt(0) + row[keyName].slice(1).toLowerCase()),
    datasets: [
      {
        backgroundColor: color,
        // borderColor: 'rgba(255,99,132,1)',
        // borderWidth: 1,
        // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        // hoverBorderColor: 'rgba(255,99,132,1)',
        data: information.map(row => row.count),
        barThickness: 22
      }
    ]
  };

  const options = {
    ...chartOptions(information),
    legend: {
      display: false
    }
  };

  return (
    <div>
      <HorizontalBar data={data} options={options} />
    </div>
  );
}

HorizontalChart.propTypes = {
  info: PropTypes.object.isRequired,
  keyName: PropTypes.string.isRequired,
  color: PropTypes.string
};

HorizontalChart.defaultProps = {
  color: '#05BF95'
};
