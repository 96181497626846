import React from 'react';
import PropTypes from 'prop-types';

// Core components
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

const useStyles = makeStyles(theme => ({
  navigationLabel: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.7em !important'
    }
  },
  navigationSelected: {
    color: '#38BCD7',
    fontWeight: 'bold',
    fontSize: '1.1em !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.3em !important'
    }
  },
  navigationRoot: {
    marginBottom: 10,
    minWidth: 370,
    maxWidth: 500,
    margin: '0 auto',
    borderRadius: '0 0 40px 40px',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.14)',
    [theme.breakpoints.down('sm')]: {
      minWidth: 370,
      maxWidth: 500
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 470,
      maxWidth: 700,
      minHeight: 60
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 670,
      maxWidth: 900,
      minHeight: 80
    }
  },
  navigationActionRoot: {
    maxWidth: 'none'
  }
}));

export default function NxBtnNavigation({ onChange, items, value }) {
  const classes = useStyles();

  return (
    <BottomNavigation value={value} onChange={onChange} showLabels className={classes.navigationRoot}>
      {items.map(item => (
        <BottomNavigationAction
          key={item}
          classes={{
            label: classes.navigationLabel,
            selected: classes.navigationSelected,
            root: classes.navigationActionRoot
          }}
          label={item}
        />
      ))}
    </BottomNavigation>
  );
}

NxBtnNavigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};
