// @flow
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import without from 'lodash/without';

// Core components
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

// Others
import { outOfServiceFields, reasonsTranslate } from 'variables/resources';

// Components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import NumberInput from '../../NumberInput/NumberInput';

//  Assets
import styles from '../iosStyleSwitch';

const useStyles = makeStyles({
  ...styles,
  addIcon: {
    padding: '0 10px',
    margin: '20px 0',
    background: '#05BF95',
    color: 'white',
    '&:hover': {
      background: '#05BF95'
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cancelIcon: {
    color: '#f44336',
    '&:hover': {
      color: '#f44336'
    }
  },
  chip: {
    margin: 1
  }
});

const getActiveServices = capacity => {
  const activeServices = [];
  const totalInfo = { ...capacity.internment, ...capacity.urgency };
  Object.keys(totalInfo).forEach(key => {
    if (totalInfo[key] === true) {
      activeServices.push(key);
    }
  });
  return activeServices;
};

const initialReasons = ['isolation', 'humanResource', 'maintenance'];

const OutOfService = props => {
  const { state: currentBeds, onBedsChange, capacity } = props;
  const [state, setState] = useState({});
  const [beds, setBeds] = useState(currentBeds);
  const [reasons, setReasons] = useState(['isolation', 'humanResource', 'maintenance']);

  const classes = useStyles();

  const [serviceNames, setServices] = useState(getActiveServices(capacity));
  const services = outOfServiceFields.filter(service => serviceNames.find(as => service.name === as));

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value
    });
  };

  const getReasons = (reason, actualBeds = beds) => {
    let currentReasons = initialReasons;
    const bedsFiltered = actualBeds.filter(bed => bed.service === reason);
    if (bedsFiltered.length > 0) {
      const a = bedsFiltered.map(bed => bed.reason);
      currentReasons = without(initialReasons, ...a);
    }
    return currentReasons;
  };

  const validateReasons = e => {
    handleChange(e.target.name, e.target.value);
    const newReasons = getReasons(e.target.value);
    setState({
      ...state,
      reason: '',
      [e.target.name]: e.target.value
    });
    setReasons(newReasons);
  };

  const addBeds = () => {
    const newBeds = [...beds.concat(state)];
    setBeds(newBeds);
    const currrentReasons = getReasons(state.service, newBeds);
    if (currrentReasons.length === 0) {
      setServices([...serviceNames.filter(service => service !== state.service)]);
    }
    setState({});
    onBedsChange('bedsOutOfService', newBeds, 'lastOccupation');
  };

  const deleteBeds = index => {
    const newBeds = [...beds];
    newBeds.splice(index, 1);
    if (!serviceNames.includes(beds[index].service)) {
      setServices([...serviceNames.concat(beds[index].service)]);
    }
    const newReasons = getReasons(beds[index].value);
    setReasons(newReasons);
    setBeds(newBeds);
    onBedsChange('bedsOutOfService', newBeds, 'lastOccupation');
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="danger" text>
            <CardText color="danger">
              <h4>Camas fuera de servicio</h4>
            </CardText>
          </CardHeader>
          <CardBody className={classes.cardBodyJustify}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="documentType">Servicio</InputLabel>
                  <Select
                    value={state.service || ''}
                    onChange={validateReasons}
                    inputProps={{
                      name: 'service',
                      id: 'service'
                    }}
                  >
                    <MenuItem disabled value="">
                      {services.length > 0 ? 'Seleccione un servicio' : 'Ya no hay mas servicios'}
                    </MenuItem>
                    {services.map(service => (
                      <MenuItem key={service.id} value={service.name}>
                        {service.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <FormControl fullWidth disabled={!state.service}>
                  <InputLabel htmlFor="reason">Motivo fuera de servicio</InputLabel>
                  <Select
                    value={state.reason || ''}
                    onChange={({ target }) => handleChange(target.name, target.value)}
                    inputProps={{
                      name: 'reason',
                      id: 'reason'
                    }}
                  >
                    <MenuItem disabled value="">
                      {reasons.length > 0 ? 'Seleccione un motivo' : 'Ya definiste todos los motivos'}
                    </MenuItem>
                    {reasons.map(reason => (
                      <MenuItem key={reason} value={reason}>
                        {reasonsTranslate[reason]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <NumberInput
                  required
                  placeHolder="Cantidad"
                  handleChange={handleChange}
                  name="quantity"
                  value={state.quantity || ''}
                  validation={{
                    type: 'min-value',
                    number: 0
                  }}
                />
              </GridItem>
              <GridItem xs={12} className={classes.actions}>
                <Fab
                  size="small"
                  variant="extended"
                  aria-label="Add"
                  className={classes.addIcon}
                  onClick={addBeds}
                  disabled={!state.service || !state.reason || !state.quantity}
                >
                  <AddIcon />
                  Agregar
                </Fab>
              </GridItem>
              <GridItem xs={12}>
                {beds.map((bed, i) => {
                  const { label } = outOfServiceFields.find(field => field.name === bed.service) || {};
                  return (
                    <Chip
                      label={`${label} / ${reasonsTranslate[bed.reason]} / ${bed.quantity}`}
                      className={classes.chip}
                      key={`${label}-${i}`}
                      onDelete={() => deleteBeds(i)}
                      deleteIcon={
                        <Icon className={classes.cancelIcon} color="error">
                          cancel
                        </Icon>
                      }
                    />
                  );
                })}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

OutOfService.propTypes = {
  capacity: PropTypes.object,
  state: PropTypes.array,
  onBedsChange: PropTypes.func
};

OutOfService.defaultProps = {
  capacity: {},
  state: []
};

export default OutOfService;
