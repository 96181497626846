
const columnsStyle = {
  gridDl_dt: {
    borderTop: '2px solid #ccc',
    padding: '0.5em 0 0.5em 0',
    fontWeight: 600
  },
  gridDl_dd: {
    margin: 0,
    padding: '0.5em 0 0.5em 0'
  },
  gridDl_dt_dd: {
    margin: 0,
    padding: '0.5em 0 0.5em 0'
  },

  '@media (min-width: 550px)': {
    gridDl: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      gridTemplateRows: 'auto'
    },
    gridDl_dt: {
      gridColumnStart: 1
    },
    gridDl_dd: {
      gridColumnStart: 2,
      margin: 0
    },
    gridDl_dt_dd: {
      gridColumnStart: 2,
      margin: 0,
      borderTop: '2px solid #ccc'
    }
  }
};

export default columnsStyle;
