import React from 'react';
import MUIDataTable from 'mui-datatables';
import { withRouter } from 'react-router-dom';

import { getColumns, options } from './configurationTable';

const DialogActionAgreement = React.lazy(() => import('./sections/dialogAction'));

function List(props) {
  const [openAdd, setOpenAdd] = React.useState(false);
  const [agreementId, setAgreementId] = React.useState(null);

  const agreements = props.agreements;

  const handleOpen = agreementId => {
    setOpenAdd(true);
    setAgreementId(agreementId);
  };

  const handleClose = (reloadData) => {
    setOpenAdd(false);
    setAgreementId(null);
    if(reloadData) props.getAgreements();
  };

  return (
    <>
      <MUIDataTable
        data={agreements}
        columns={getColumns({ handleOpen, history: props.history })}
        options={options({history: props.history, selectedEps: props.selectedEps})}
      />
      {openAdd && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <DialogActionAgreement onClose={handleClose} agreementId={agreementId} {...props} />
        </React.Suspense>
      )}
    </>
  );
}


export default withRouter(List);