import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NavPills from 'components/NavPills/NavPills';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Paper from '@material-ui/core/Paper';

// import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
const renderNedocs = (nedocs = {}) => {
  let color;
  let message;
  if (nedocs.score <= 50) {
    color = '#008000';
    message = 'Normal';
  } else if (nedocs.score > 50 && nedocs.score <= 100) {
    color = '#ffd700';
    message = 'Ocupado';
  } else if (nedocs.score > 100 && nedocs.score <= 140) {
    color = '#ffa500';
    message = 'Hacinamiento';
  } else if (nedocs.score > 141 && nedocs.score <= 180) {
    color = '#ff0000';
    message = 'Peligroso';
  } else {
    color = '#000000';
    message = 'Desastres';
  }
  return (
    <Paper
      style={{
        background: color,
        padding: '5px',
        borderRadius: '10px',
        margin: 'auto',
        textAlign: 'center',
        color: 'white'
      }}
    >
      <b>{`${nedocs.score}-${message}`}</b>
    </Paper>
  );
};

const renderDiagnosticAidsMessage = diagnosticAid => {
  let message;
  switch (diagnosticAid) {
    case undefined:
      message = 'No se encuentra Ofertado';
      break;
    case true:
      message = 'Disponible';
      break;
    case false:
      message = 'No disponible';
      break;
    default:
      break;
  }
  return message;
};

const IpsResources = props => {
  const { info, resources } = props;
  const { internment, nedocs, supportServices, urgency } = resources;
  return (
    <GridContainer>
      <GridItem style={{ margin: '10px' }}>
        <Card>
          <CardBody>
            <NavPills
              color="info"
              horizontal={{
                tabsGrid: { xs: 12, sm: 5, md: 4 },
                contentGrid: { xs: 12, sm: 7, md: 8 }
              }}
              tabs={
                Object.entries(resources).length !== 0
                  ? [
                      {
                        tabButton: 'Info',
                        tabContent: (
                          <span>
                            <p>
                              <b>Dirección:</b> {info.address}
                            </p>
                            <br />
                            <p>
                              <b>Teléfono:</b> {info.phone}
                            </p>
                            <br />
                            <p>
                              <b>Nit:</b> {info.nit}
                            </p>
                            <br />
                            <p>
                              <b>Último reporte:</b> {format(new Date(resources.createdAt), 'DD-MM-YYYY HH:mm a')}
                            </p>
                            <br />
                            {nedocs && (
                              <p>
                                <b>Nedocs:</b> {renderNedocs(nedocs)}
                              </p>
                            )}
                          </span>
                        )
                      },
                      {
                        tabButton: 'Adultos',
                        tabContent: (
                          <span>
                            <p>
                              <b>Camillas:</b> {urgency.adultGeneralBedsUrg || 0} unidades.
                            </p>
                            <br />
                            <p>
                              <b>Sillas:</b> {urgency.adultSupportBedsUrg || 0} unidades.
                            </p>
                            <br />
                            <p>
                              <b>Sala Reanimación:</b> {urgency.adultReanimationBedsUrg || 0} unidades.
                            </p>
                          </span>
                        )
                      },
                      {
                        tabButton: 'Pediátricos',
                        tabContent: (
                          <span>
                            <p>
                              <b>Camillas:</b> {urgency.pediatricGeneralBeds || 0} unidades.
                            </p>
                            <br />
                            <p>
                              <b>Sillas:</b> {urgency.pediatricSupportBedsUrg || 0} unidades.
                            </p>
                            <br />
                            <p>
                              <b>Sala Reanimación:</b> {urgency.pediatricReanimationBedsUrg || 0} unidades.
                            </p>
                          </span>
                        )
                      },
                      {
                        tabButton: 'UCI',
                        tabContent: (
                          <span>
                            <p>
                              <b>Adultos:</b> {internment.adultUciBedsHosp || 0} unidades.
                            </p>
                            <br />
                            <p>
                              <b>Pediátrica:</b> {internment.pediatricUciBedsHosp || 0} unidades.
                            </p>
                            <br />
                            <p>
                              <b>Neonatal:</b> {internment.neonatalUciBedsHosp || 0} unidades.
                            </p>
                          </span>
                        )
                      },
                      {
                        tabButton: 'Ayudas',
                        tabContent: (
                          <span>
                            <p>
                              <b>Rayos X:</b> {renderDiagnosticAidsMessage(supportServices.xrays)}.
                              {supportServices.xraysReason && <p>{supportServices.xraysReason}</p>}
                            </p>
                            <br />
                            <p>
                              <b>Tomógrafo:</b> {renderDiagnosticAidsMessage(supportServices.tomograph)}.
                              {supportServices.tomographReason && <p>{supportServices.tomographReason}</p>}
                            </p>
                            <br />
                            <p>
                              <b>Angiógrafo:</b> {renderDiagnosticAidsMessage(supportServices.angiograph)}.
                              {supportServices.angiographReason && <p>{supportServices.angiographReason}</p>}
                            </p>
                            <br />
                            <p>
                              <b>Resonador:</b> {renderDiagnosticAidsMessage(supportServices.resonator)}.
                              {supportServices.resonatorReason && <p>{supportServices.resonatorReason}</p>}
                            </p>
                            <br />
                            <p>
                              <b>Ultrasonógrafo:</b> {renderDiagnosticAidsMessage(supportServices.ultrasonograph)}.
                              {supportServices.ultrasonographReason && <p>{supportServices.ultrasonographReason}</p>}
                            </p>
                          </span>
                        )
                      }
                    ]
                  : [
                      {
                        tabButton: 'Info',
                        tabContent: (
                          <span>
                            <p>
                              <b>Dirección:</b> {info.address}
                            </p>
                            <br />
                            <p>
                              <b>Teléfono:</b> {info.phone}
                            </p>
                            <br />
                            <p>
                              <b>Nit:</b> {info.nit}
                            </p>
                            <br />
                            {resources.createdAt && (
                              <React.Fragment>
                                <p>
                                  <b>Último reporte:</b> {format(new Date(resources.createdAt), 'DD-MM-YYYY HH:mm a')}
                                </p>
                                <br />
                              </React.Fragment>
                            )}
                            {nedocs && (
                              <p>
                                <b>Nedocs:</b> {renderNedocs(nedocs)}
                              </p>
                            )}
                          </span>
                        )
                      }
                    ]
              }
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

IpsResources.propTypes = {
  info: PropTypes.object.isRequired,
  resources: PropTypes.object
};

export default IpsResources;
