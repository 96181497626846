import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import { useAlert } from 'react-alert';

// Core components
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';

// Components
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import NxSpinner from 'nxComponents/NxSpinner/NxSpinner';
import NxNavigation from 'nxComponents/NxNavigation/NxNavigation';
import DoughnutChart from 'nxComponents/Charts/DoughnutChart';
import MultiPieChart from 'nxComponents/Charts/MultiPieChart';
import BarChart from 'nxComponents/Charts/BarChart';

// Others

import { useInterval } from 'customHooks/useInterval';
import AuthContainer from 'containers-state/auth';
import Netux from 'assets/img/Netux.png';
import alcaldia from 'assets/img/alcaldia.png';
import {
  styles,
  navigationItems,
  getAllResources,
  getResourcesAsync,
  getData,
  barData,
  customLegend,
  dataCovidBySex,
  dataServices,
  optionsCovidBySex
} from 'utils/covid';

const ModalChart = React.lazy(() => import('nxComponents/ModalChart/ModalChart'));

function Covid(props) {
  const [open, setOpen] = useState(false);
  const [selectedCharts, setSelectedCharts] = useState();
  const [resources, setResources] = useState();
  const [loading, setLoading] = useState(false);
  const { classes, history, roles } = props;
  const alert = useAlert();

  useEffect(() => {
    window.Intercom('update', {
      hide_default_launcher: true
    });
    async function getResources() {
      setLoading(true);
      const listResources = await getAllResources('/ips/covid/resources/patients/all/');
      if (listResources.ok) {
        setResources(listResources.data);
      } else {
        alert.error('Error al obtener la información');
      }
      setLoading(false);
    }

    getResources();
  }, []);

  const handleOpen = charts => {
    setOpen(true);
    setSelectedCharts(charts);
  };

  useInterval(() => {
    getResourcesAsync('/ips/covid/resources/patients/all/', setResources);
  }, 90000);

  if (!roles.includes('CRUE')) {
    history.push('/');
  }

  return (
    <>
      <NxSpinner loading={loading} />
      <div className={classes.covid}>
        <Hidden smDown>
          <GridItem xs={12} sm={3}>
            <img className={classes.netuxImg} src={Netux} alt="logo-Netux" />
          </GridItem>
        </Hidden>
        <GridItem xs={12} sm={9} style={{ marginRight: '25vw', textAlign: 'right' }}>
          <NxNavigation items={navigationItems(history)} value={3} />
        </GridItem>
        <Card className={classes.card}>
          <CardBody className={classes.cardBody}>
            <img className={classes.alcaldiaLogo} alt="Logo-alcaldía" src={alcaldia} />
          </CardBody>
        </Card>
      </div>
      {resources && (
        <GridContainer className={classes.dashboardContainer} justify="center">
          <GridItem xs={5}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <h4 className={classes.halfPieTitle}>UCI adultos disponible</h4>
                <DoughnutChart
                  labels={['Disponibles', 'Usadas']}
                  data={getData({ resources: resources.total, service: 'hospitalization', name: 'uci' })}
                />
                <p className={classes.halfPieNumber}>{resources.total.hospitalization.uci.availables}</p>
                <div className={classes.halfPielabels}>
                  <p>0</p>
                  <Button
                    className={classes.button}
                    onClick={() => handleOpen({ service: 'uci', key: 'availables', name: 'uci-availables' })}
                  >
                    Ver Gráfica
                  </Button>
                  <p>{resources.total.hospitalization.uci.total}</p>
                </div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h4 className={classes.halfPieTitle}>Hospitalización disponible</h4>
                <DoughnutChart
                  labels={['Disponibles', 'Usadas']}
                  data={getData({ resources: resources.total, service: 'hospitalization', name: 'general' })}
                />
                <p className={classes.halfPieNumber}>{resources.total.hospitalization.general.availables}</p>
                <div className={classes.halfPielabels}>
                  <p>0</p>
                  <Button
                    className={classes.button}
                    onClick={() =>
                      handleOpen({
                        service: 'generalHospitalization',
                        key: 'availables',
                        name: 'generalHospitalization-availables'
                      })
                    }
                  >
                    Ver Gráfica
                  </Button>
                  <p>{resources.total.hospitalization.general.total}</p>
                </div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h4 className={classes.halfPieTitle}>UCE adultos disponible</h4>
                <DoughnutChart
                  labels={['Disponibles', 'Usadas']}
                  data={getData({ resources: resources.total, service: 'hospitalization', name: 'uce' })}
                />
                <p className={classes.halfPieNumber}>{resources.total.hospitalization.uce.availables}</p>
                <div className={classes.halfPielabels}>
                  <p>0</p>
                  <Button
                    className={classes.button}
                    onClick={() => handleOpen({ service: 'uce', key: 'availables', name: 'uce-availables' })}
                  >
                    Ver Gráfica
                  </Button>
                  <p>{resources.total.hospitalization.uce.total}</p>
                </div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h4 className={classes.halfPieTitle}>Urgencias</h4>
                <DoughnutChart
                  labels={['Disponibles', 'Usadas']}
                  data={getData({ resources: resources.total, service: 'emergency', name: 'general' })}
                />
                <div>
                  <p className={classes.halfPieNumber}>{resources.total.emergency.general.availables}</p>
                </div>
                <div className={classes.halfPielabels}>
                  <p>0</p>
                  <Button
                    className={classes.button}
                    onClick={() =>
                      handleOpen({ service: 'emergency', key: 'availables', name: 'emergency-availables' })
                    }
                  >
                    Ver Gráfica
                  </Button>
                  <p>{resources.total.emergency.general.total}</p>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={7}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <h4 className={classes.halfPieTitle}>Pacientes COVID e IRA por servicio</h4>
                <GridContainer>
                  <GridItem xs={12} md={7}>
                    <MultiPieChart data={dataServices(resources)} />
                  </GridItem>
                  <GridItem xs={12} md={5} style={{ display: 'flex' }}>
                    <div className={classes.flex}>
                      {customLegend &&
                        customLegend.map(({ color, label }) => (
                          <div key={label} className={classes.flex}>
                            <div className={classes.circle} style={{ backgroundColor: color }} />
                            <p className={classes.legendLabel}>{label}</p>
                          </div>
                        ))}
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} md={6}>
                <GridItem xs={12}>
                  <h4 className={classes.halfPieTitle}>Pacientes COVID por género</h4>
                  <MultiPieChart
                    data={dataCovidBySex(resources, 'covid')}
                    options={optionsCovidBySex}
                    style={classes.canvasDoughnut}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <h4 className={classes.halfPieTitle}>Pacientes IRA por género</h4>
                  <MultiPieChart
                    data={dataCovidBySex(resources, 'ira')}
                    options={optionsCovidBySex}
                    style={classes.canvasDoughnut}
                  />
                </GridItem>
              </GridItem>
              <GridItem xs={12}>
                <BarChart data={barData(resources)} style={classes.canvasBar} />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      )}
      {open && (
        <React.Suspense fallback={<NxSpinner loading />}>
          <ModalChart open={open} handleOpen={bool => setOpen(bool)} selectedChart={selectedCharts} />
        </React.Suspense>
      )}
    </>
  );
}

Covid.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  roles: PropTypes.array
};

const TableComponent = withStyles(styles)(Covid);

export default props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer => <TableComponent {...props} roles={AuthContainer.state.roles} />}
  </Subscribe>
);
