import React from 'react';
import PropTypes from 'prop-types';
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

// Others
import { internmentFields } from 'variables/resources';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import NumberInput from '../../NumberInput/NumberInput';

const useStyles = makeStyles({
  tooltip: {
    fontSize: '1.08em',
    position: 'relative',
    top: '-30px',
    backgroundColor: '#2BC5A3'
  },
  root: {
    '&$checked': {
      color: '#2BC5A3'
    }
  },
  checked: {}
});

const Internment = props => {
  const classes = useStyles();
  const { state, handleChange, capacity, isOccupation } = props;
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Internación</h4>
            </CardText>
          </CardHeader>
          <CardBody className={classes.cardBodyJustify}>
            <GridContainer>
              {internmentFields.map(resource => {
                if (isOccupation && capacity[resource.checkName]) {
                  return (
                    <Tooltip
                      key={resource.id}
                      classes={{
                        tooltip: classes.tooltip
                      }}
                      title={`${capacity[resource.qtyName]} unidades ofertadas`}
                    >
                      <GridItem style={{ display: 'flex' }} key={resource.id} xs={12} sm={6} lg={4}>
                        <NumberInput
                          key={resource.id}
                          required
                          placeHolder={resource.qtyLabelOccupation}
                          handleChange={handleChange}
                          name={resource.qtyName}
                          value={state[resource.qtyName]}
                          validation={{
                            type: 'min-value',
                            number: 0
                          }}
                        />
                      </GridItem>
                    </Tooltip>
                  );
                }
                if (!isOccupation) {
                  return (
                    <GridItem style={{ display: 'flex' }} key={resource.id} xs={12} sm={6} lg={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(state[resource.checkName])}
                            onClick={({ target }) => handleChange(target.name, target.checked)}
                            classes={{
                              root: classes.root,
                              checked: classes.checked
                            }}
                          />
                        }
                        name={resource.checkName}
                        label={!state[resource.checkName] ? resource.checkLabel : null}
                      />
                      {state[resource.checkName] && (
                        <NumberInput
                          key={resource.id}
                          required
                          placeHolder={resource.qtyLabel}
                          handleChange={handleChange}
                          name={resource.qtyName}
                          value={state[resource.qtyName] || ''}
                          validation={{
                            type: 'min-value',
                            number: 0
                          }}
                        />
                      )}
                    </GridItem>
                  );
                }
                return null;
              })}
              {/* {isOccupation && (
                <GridItem xs={12} sm={6} lg={4}>
                  <NumberInput
                    required
                    placeHolder="Camas fuera de servicio"
                    handleChange={handleChange}
                    name="bedsOutOfServiceInt"
                    value={state.bedsOutOfServiceInt}
                    validation={{
                      type: 'min-value',
                      number: 0
                    }}
                  />
                </GridItem>
              )} */}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

Internment.propTypes = {
  capacity: PropTypes.object,
  // classes: PropTypes.object,
  state: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isOccupation: PropTypes.bool
};

Internment.defaultProps = {
  capacity: {},
  // classes: {},
  state: {},
  isOccupation: false
};

export default Internment;
