export const hospitalizationFields = [
  {
    id: 1,
    label: 'Pacientes adultos hospitalizados',
    name: 'adultsHospitalization'
  },
  {
    id: 2,
    label: 'Pacientes pediátricos hospitalizados',
    name: 'pediatricsHospitalization'
  },
  {
    id: 3,
    label: 'Pacientes obstétricos hospitalizados',
    name: 'obstetricsHospitalization'
  },
  {
    id: 4,
    label: 'Pacientes quemados en hospitalización',
    name: 'burnPatientHospitalization'
  }
];

export const urgencyFields = [
  {
    id: 1,
    label: 'Pacientes adultos en camillas',
    name: 'adultsInBedUrgency'
  },
  {
    id: 2,
    label: 'Pacientes adultos en sillas',
    name: 'adultsInChairUrgency'
  },
  {
    id: 3,
    label: 'Pacientes pediátricos en camillas',
    name: 'pediatricsInBedUrgency'
  },
  {
    id: 4,
    label: 'Pacientes pediátricos en sillas',
    name: 'pediatricsInChairUrgency'
  },
  {
    id: 5,
    label: 'Pacientes adultos en reanimación',
    name: 'adultsInReanimationRoomUrgency'
  },
  {
    id: 6,
    label: 'Pacientes en reanimación pediátrica',
    name: 'pediatricsInReanimationRoomUrgency'
  }
];

export const diagnosticFields = [
  {
    id: 1,
    name: 'tomograph',
    label: 'Tomógrafo',
    reasonLabel: '¿Por qué no cuenta con Tomógrafo?'
  },
  {
    id: 2,
    name: 'resonator',
    label: 'Resonador',
    reasonLabel: '¿Por qué no cuenta con Resonador?'
  },
  {
    id: 3,
    name: 'angiograph',
    label: 'Angiógrafo',
    reasonLabel: '¿Por qué no cuenta con Angiógrafo?'
  },
  {
    id: 4,
    name: 'xrays',
    label: 'Rayos X',
    reasonLabel: '¿Por qué no cuenta con rayos X?'
  },
  {
    id: 5,
    name: 'ultrasonograph',
    label: 'Ultrasonógrafo',
    reasonLabel: '¿Por qué no cuenta con ultrasonógrafo?'
  }
];

export const comorbidities = [
  { id: 'cancer', name: 'Cáncer' },
  { id: 'diabetes', name: 'Diabetes' },
  { id: 'hypertension', name: 'Hipertensión' },
  { id: 'obesity', name: 'Obesidad' },
  { id: 'dyslipidemia', name: 'Dislipidemia' },
  { id: 'hiv', name: 'VIH' },
  { id: 'malnutrition', name: 'Desnutrición' },
  { id: 'kidneyFailure', name: 'Insuficiencia Renal' },
  { id: 'heartFailure', name: 'Falla Cardíaca' },
  { id: 'autoimmuneDisease', name: 'Enfermedad Autoinmune' },
  { id: 'smoker', name: 'Fumador' },
  { id: 'alcoholism', name: 'Alcoholismo' },
  { id: 'epoc', name: 'EPOC' },
  { id: 'alzheimer', name: 'Alzheimer' },
  { id: 'mentalIllness', name: 'Enfermedad Mental' },
  { id: 'pregnant', name: 'Embarazada' },
  { id: 'none', name: 'Ninguna' }
];
