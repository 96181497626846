import * as React from 'react';
import PropTypes from 'prop-types';

function ListIcon(props) {
  const { color, height, width, ...rest } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 40.273 21.003" {...rest}>
      <defs>
        <style>{`.list_icon{fill:none;stroke:${color};stroke-linecap:round;stroke-width:3px}`}</style>
      </defs>
      <path
        className="list_icon"
        d="M8.156 1.5h11.98M8.156 7.501h11.98M8.156 13.502h11.98M8.156 19.503h11.98M1.5 1.5h1.331M1.5 7.501h1.331M1.5 13.502h1.331M1.5 19.503h1.331M25.461 1.5h13.312M25.461 7.501h13.312M25.461 13.502h13.312M25.461 19.503h13.312"
      />
    </svg>
  );
}

ListIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

ListIcon.defaultProps = {
  color: '#858585',
  height: 15,
  width: 35
};

export default ListIcon;
