// @flow

import React from 'react';
import { Subscribe } from 'unstated';

import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  IntegratedFiltering,
  IntegratedSummary,
  DataTypeProvider,
  SummaryState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Table,
  Toolbar,
  SearchPanel,
  TableBandHeader,
  TableColumnVisibility,
  ColumnChooser,
  TableSummaryRow,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';

import apiClient from 'config/apiClient';
import { urlApi } from 'config/app';

import AuthContainer from 'containers-state/auth';

import { TableComponent } from './TableComponentBase';
import { TableRow } from './RowComponent';
import { BandCellBase } from './BandCellHeader';
import { Cell } from './CellComponent';
import {
  defaultColumns,
  defaultColumnExtensions,
  defaultColumnBands,
  defaultTotalSummaryItems,
  defaultCurrencyColumns,
  data
} from './intialConfig';

const CurrencyFormatter = ({ value }) => `${value.toFixed(2)}%`;

const CurrencyTypeProvider = props => <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />;

const getRowId = row => row.hospitalaryUsageId;

const initialState = {
  listResources: data,
  columns: defaultColumns,
  hiddenColumnNames: [],
  tableColumnExtensions: defaultColumnExtensions,
  totalSummaryItems: defaultTotalSummaryItems,
  currencyColumns: defaultCurrencyColumns,
  searchValue: ''
};

// const urlSergio = 'http://192.168.1.129:2323/api';

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;

// const HeaderCompo = ({ children, tableRow, tableColumn, column, ...restProps }) => {
//   return (
//     <TableHeaderRow.Cell {...restProps} column={column} getMessage={() => column.title}>
//       <strong>{children}</strong>
//     </TableHeaderRow.Cell>
//   );
// };

class TransfersResources extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.changeSearchValue = value => this.setState({ searchValue: value });
    this.hiddenColumnNamesChange = hiddenColumnNames => {
      this.setState({ hiddenColumnNames });
    };
  }

  componentDidMount() {
    this.getAllResources();
  }

  getAllResources = async () => {
    try {
      const response = await apiClient.SEM.get(`${urlApi}/ips/resources/summary/all/`);
      this.setState({ listResources: response.data.data });
    } catch (error) {
      throw error;
    }
  };

  render() {
    const {
      columns,
      searchValue,
      tableColumnExtensions,
      listResources,
      hiddenColumnNames,
      totalSummaryItems,
      currencyColumns
    } = this.state;
    return (
      <Paper style={{ height: 'calc(90vh - 80px)' }}>
        <Grid rows={listResources} columns={columns} getRowId={getRowId} rootComponent={Root}>
          <VirtualTable height="auto" />
          <SearchState value={searchValue} onValueChange={this.changeSearchValue} />
          <IntegratedFiltering />
          <CurrencyTypeProvider for={currencyColumns} />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSummary />

          <Table
            rowComponent={TableRow}
            columnExtensions={tableColumnExtensions}
            cellComponent={Cell}
            tableComponent={TableComponent}
          />

          <TableHeaderRow />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
          />
          <Toolbar />
          <SearchPanel />
          <ColumnChooser />
          <TableSummaryRow />
          <TableBandHeader columnBands={defaultColumnBands} cellComponent={BandCellBase} />
        </Grid>
      </Paper>
    );
  }
}

export default props => <Subscribe to={[AuthContainer]}>{() => <TransfersResources {...props} />}</Subscribe>;
