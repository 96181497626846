import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import 'chartjs-plugin-datalabels';

import { makeStyles } from '@material-ui/core/styles';
import { buildName, backgroundColors } from 'utils/covid';

const useStyles = makeStyles(() => ({
  canvas: {
    height: '35vh'
  }
}));

const defaultOptions = {
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: true,
      anchor: 'end',
      clamp: true,
      font: {
        size: '15'
      }
    }
  },
  legend: {
    position: 'bottom',
    labels: {
      fontSize: 14,
      boxWidth: 7,
      usePointStyle: true
    }
  }
};

export default function MixedChart({ labels, selectedCharts, resources, options }) {
  const classes = useStyles();
  const data = {
    labels,
    datasets: selectedCharts.map(({ name, key, service }) => ({
      label: buildName(name),
      fill: false,
      data: resources[service] && resources[service].map(info => info[key]),
      backgroundColor: backgroundColors[name],
      borderColor: backgroundColors[name]
    }))
  };

  return (
    <div className={classes.canvas}>
      <Line data={data} options={{ ...defaultOptions, ...options }} />
    </div>
  );
}

MixedChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  selectedCharts: PropTypes.arrayOf(PropTypes.object),
  resources: PropTypes.object,
  options: PropTypes.object
};
