const axios = require('axios');

class API {
  constructor({ url }) {
    this.url = url;
    this.endpoints = {};
    // this.SEM = axios.create({
    //   headers: { authorization: `Bearer ${token}` }
    // });
    this.SEM = axios.create({
      timeout: 30000
    });
  }

  /**
   * Create and store a single entity's endpoints
   * @param {A entity Object} entity
   */
  createEntity(entity) {
    this.endpoints[entity.name] = this.createBasicCRUDEndpoints(entity);
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this));
  }

  /**
   * Create the basic endpoints handlers for CRUD operations
   * @param {A entity Object} entity
   */
  createBasicCRUDEndpoints({ name }) {
    const endpoints = {};

    const resourceURL = `${this.url}/${name}`;

    endpoints.getAll = () => this.SEM.get(resourceURL);

    endpoints.getOne = ({ id }) => this.SEM.get(`${resourceURL}/${id}`);

    endpoints.create = toCreate => this.SEM.post(resourceURL, toCreate);

    endpoints.update = toUpdate => this.SEM.put(`${resourceURL}/${toUpdate.id}`, toUpdate);

    endpoints.patch = ({ id }, toPatch) => this.SEM.patch(`${resourceURL}/${id}`, toPatch);

    endpoints.delete = ({ id }) => this.SEM.delete(`${resourceURL}/${id}`);

    return endpoints;
  }

  getAxios() {
    return this.instance;
  }

  setToken(token) {
    this.SEM.defaults.headers.common.authorization = `bearer ${token}`;
  }

  handleErrors() {
    this.SEM.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        const {
          config,
          response: { status }
        } = error;
        const originalRequest = config;

        if (status === 401) {
          // if (!isRefreshing) {
          // isRefreshing = true;
          // refreshAccessToken().then(newToken => {
          //   isRefreshing = false;
          //   onRrefreshed(newToken);
          // });
          // }

          const retryOrigReq = new Promise((resolve, reject) => {
            // replace the token and send again the request
            // subscribeTokenRefresh(token => {
            // replace the expired token and retry
            // originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
            // });
          });
          return retryOrigReq;
        }
        return Promise.reject(error);
      }
    );
  }
}

export default API;
