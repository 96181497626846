import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import GridItem from 'components/Grid/GridItem';
import CircularProgress from '@material-ui/core/CircularProgress';

// Styles
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import columnsStyle from 'assets/jss/material-dashboard-pro-react/columnsStyle';

import { redBloodFields, plasmaFields } from '../../variables/resources';

const useStyles = makeStyles({
  ...validationFormsStyle,
  root: {
    width: '100%'
  },
  progress: {
    color: '#05BF95',
    zIndex: 1
  },
  ...columnsStyle,
  ...buttonStyle
});

function BloodBankList({ bloodResources }) {
  const classes = useStyles();
  const hasInfo = Object.keys(bloodResources).length > 0;

  if (!hasInfo) {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress size={55} className={classes.progress} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <GridItem xs={12} sm={6}>
          <h4>
            <strong>Globulos rojos</strong>
          </h4>
          {redBloodFields.map(field => (
            <dl key={field.id} className={classes.gridDl}>
              <dt className={classes.gridDl_dt}>{field.label}:</dt>
              <dd className={classes.gridDl_dt_dd}>{bloodResources[field.name]} unidades.</dd>
            </dl>
          ))}
        </GridItem>
        <GridItem xs={12} sm={6}>
          <h4>
            <strong>Plasma</strong>
          </h4>
          {plasmaFields.map(field => (
            <dl key={field.id} className={classes.gridDl}>
              <dt className={classes.gridDl_dt}>{field.label}:</dt>
              <dd className={classes.gridDl_dt_dd}>{bloodResources[field.name]} unidades.</dd>
            </dl>
          ))}
        </GridItem>
      </div>
      {bloodResources.observations !== '' ? (
        <GridItem xs={12} sm={12}>
          <br />
          <h4>
            <strong>Observaciones:</strong>
          </h4>
          <hr style={{ marginBottom: '7px' }} />
          <p>{bloodResources.observations}</p>
        </GridItem>
      ) : null}
    </React.Fragment>
  );
}

BloodBankList.propTypes = {
  bloodResources: PropTypes.object.isRequired
};

export default BloodBankList;
