import React from 'react';
import { Pie } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import 'chartjs-plugin-datalabels';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  canvas: {
    height: '30vh'
  }
}));

const defaultOptions = {
  maintainAspectRatio: false,
  circumference: 2 * Math.PI,
  rotation: 2 * Math.PI,
  cutoutPercentage: 30,
  plugins: {
    datalabels: {
      display: true,
      color: 'white',
      font: {
        size: '15'
      }
    }
  },
  legend: {
    display: false
  }
};

export default function MultiPieChart({ data, options, style }) {
  const classes = useStyles();
  return (
    <div className={`${style} ${classes.canvas}`}>
      <Pie data={data} options={{ ...defaultOptions, ...options }} />
    </div>
  );
}

MultiPieChart.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  style: PropTypes.string
};
MultiPieChart.defaultProps = {
  data: [],
  options: {}
};
