import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
// @material-ui/core
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Hospital from 'Icons/Hospital';

const useStyles = makeStyles(theme => ({
  card: {
    height: 'auto',
    width: '25vw',
    borderRadius: '13px',
    margin: 'auto 0 auto auto',
    [theme.breakpoints.down('sm')]: {
      width: '30vw !important'
    }
  },
  expandedCard: {
    backgroundColor: '#C5F8EB'
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    height: 'auto'
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '10%',
    transform: 'translateY(-50%)',
    height: '50px',
    width: '50px',
    [theme.breakpoints.up('md')]: {
      height: '35px',
      width: '35px'
    },
    [theme.breakpoints.up('lg')]: {
      height: '40px',
      width: '40px'
    },
    [theme.breakpoints.up('xl')]: {
      height: '50px',
      width: '50px'
    }
  },
  expandIcon: {
    fill: '#00B589 !important'
  },
  visibility: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  expand: {
    transform: 'rotate(270deg)',
    marginLeft: 'auto',
    color: '#3F3F41',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(90deg)',
    color: '#008363'
  },
  paddingCero: {
    padding: '0px !important'
  },
  title: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '15px'
    }
  }
}));

const EpsCard = props => {
  const classes = useStyles();

  const { expanded, handleExpandClick, name, id } = props;

  return (
    <Card className={classNames(classes.card, { [classes.expandedCard]: expanded })}>
      <CardContent>
        <GridContainer className={classes.cardContent}>
          <GridItem md={2} sm={2} className={classes.visibility}>
            <Hospital
              color={expanded ? '#00B589' : '#c1c1c1'}
              className={classNames(classes.icon, { [classes.expandIcon]: expanded })}
            />
          </GridItem>
          <GridItem xs={9} sm={9} md={7} className={classes.title}>
            <Typography component="p" variant="body2">
              {name}
            </Typography>
          </GridItem>
          <GridItem xs={3} sm={3} md={2} className={classes.paddingCero}>
            <IconButton
              className={classNames(classes.expand, { [classes.expandOpen]: expanded })}
              onClick={() => handleExpandClick(id)}
              aria-expanded={expanded}
            >
              <ExpandMoreIcon />
            </IconButton>
          </GridItem>
        </GridContainer>
      </CardContent>
    </Card>
  );
};

EpsCard.propTypes = {
  expanded: PropTypes.bool.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};

export default EpsCard;
