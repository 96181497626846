import format from 'date-fns/format';
import round from 'lodash/round';
import { outOfServiceFields, typeTraslate } from 'variables/resources';

export const scheduleOptions = [
  { id: 1, value: '1', label: '24 horas' },
  { id: 2, value: '2', label: 'Al llamado' },
  { id: 3, value: '3', label: 'Día presencial' },
  { id: 4, value: '4', label: 'Día al llamado' },
  { id: 5, value: '5', label: 'Noche presencial' },
  { id: 6, value: '6', label: 'Noche al llamado' },
  { id: 7, value: '7', label: 'Semana presencial día' },
  { id: 8, value: '8', label: 'Semana al llamado dia' },
  { id: 9, value: '9', label: 'Semana presencial noche' },
  { id: 10, value: '10', label: 'Semana al llamado noche' },
  { id: 11, value: '11', label: 'Fin de semana al llamado' },
  { id: 12, value: '12', label: 'Fin de semana presencial' }
];

const builScheduleTypes = scheduleTypes => {
  if (scheduleTypes) {
    return scheduleTypes.map(type => scheduleOptions.find(option => option.value === type));
  }
  return null;
};

export const buildBedsOutOfService = beds => {
  const response = {};
  let bedsEmergency = 0;
  let bedsHospitalization = 0;
  beds.forEach(bed => {
    const { category, type, service } = outOfServiceFields.find(field => field.name === bed.service);
    if (category === 'emergency') {
      bedsEmergency += bed.quantity;
    } else {
      bedsHospitalization += bed.quantity;
    }
    response[`${category}.${type}.${service}.${bed.reason}`] = bed.quantity;
  });
  return [response, bedsEmergency, bedsHospitalization];
};

export const getLastBedsOutOfService = beds => {
  if (!beds) return [];
  const response = Object.keys(beds).map(key => {
    const array = key.split('.');
    return {
      quantity: beds[key],
      reason: array[3],
      service: `${typeTraslate[array[1]]}${typeTraslate[array[2]]}${typeTraslate[array[0]]}`
    };
  });
  return response;
};

export const buildCapacities = response => {
  const { specialities, services, units } = response;
  const { hospitalization, emergency } = units;
  return {
    humanResource: {
      generalSurgery: specialities.generalSurgery.enable,
      generalSurgerySchedule:
        specialities.generalSurgery.scheduleType && builScheduleTypes(specialities.generalSurgery.scheduleType),
      orthopaedic: specialities.orthopaedic.enable,
      orthopaedicSchedule:
        specialities.orthopaedic.scheduleType && builScheduleTypes(specialities.orthopaedic.scheduleType),
      neurosurgery: specialities.neurosurgery.enable,
      neurosurgerySchedule:
        specialities.neurosurgery.scheduleType && builScheduleTypes(specialities.neurosurgery.scheduleType),
      radiology: specialities.radiology.enable,
      radiologySchedule: specialities.radiology.scheduleType && builScheduleTypes(specialities.radiology.scheduleType),
      plasticSurgery: specialities.plasticSurgery.enable,
      plasticSurgerySchedule:
        specialities.plasticSurgery.scheduleType && builScheduleTypes(specialities.plasticSurgery.scheduleType),
      oralSurgery: specialities.oralSurgery.enable,
      oralSurgerySchedule:
        specialities.oralSurgery.scheduleType && builScheduleTypes(specialities.oralSurgery.scheduleType),
      cardiovascularSurgery: specialities.cardiovascularSurgery.enable,
      cardiovascularSurgerySchedule:
        specialities.cardiovascularSurgery.scheduleType &&
        builScheduleTypes(specialities.cardiovascularSurgery.scheduleType),
      peripheralVascularSurgery: specialities.peripheralVascularSurgery.enable,
      peripheralVascularSurgerySchedule:
        specialities.peripheralVascularSurgery.scheduleType &&
        builScheduleTypes(specialities.peripheralVascularSurgery.scheduleType),
      headSurgery: specialities.headSurgery.enable,
      headSurgerySchedule:
        specialities.headSurgery.scheduleType && builScheduleTypes(specialities.headSurgery.scheduleType),
      microvascularSurgery: specialities.microvascularSurgery.enable,
      microvascularSurgerySchedule:
        specialities.microvascularSurgery.scheduleType &&
        builScheduleTypes(specialities.microvascularSurgery.scheduleType),
      ocularSurgery: specialities.ocularSurgery.enable,
      ocularSurgerySchedule:
        specialities.ocularSurgery.scheduleType && builScheduleTypes(specialities.ocularSurgery.scheduleType),
      otorhinolaryngology: specialities.otorhinolaryngology.enable,
      otorhinolaryngologySchedule:
        specialities.otorhinolaryngology.scheduleType &&
        builScheduleTypes(specialities.otorhinolaryngology.scheduleType),
      thoracicSurgery: specialities.thoracicSurgery.enable,
      thoracicSurgerySchedule:
        specialities.thoracicSurgery.scheduleType && builScheduleTypes(specialities.thoracicSurgery.scheduleType),
      pediatricSurgery: specialities.pediatricSurgery.enable,
      pediatricSurgerySchedule:
        specialities.pediatricSurgery.scheduleType && builScheduleTypes(specialities.pediatricSurgery.scheduleType),
      urology: specialities.urology.enable,
      urologySchedule: specialities.urology.scheduleType && builScheduleTypes(specialities.urology.scheduleType),
      obstetricSurgery: specialities.obstetricSurgery.enable,
      obstetricSurgerySchedule:
        specialities.obstetricSurgery.scheduleType && builScheduleTypes(specialities.obstetricSurgery.scheduleType)
    },
    nonSurgicalSupport: {
      internalMedicine: specialities.internalMedicine && specialities.internalMedicine.enable,
      internalMedicineSchedule:
        specialities.internalMedicine.scheduleType && builScheduleTypes(specialities.internalMedicine.scheduleType),
      pediatric: specialities.pediatric && specialities.pediatric.enable,
      pediatricSchedule: specialities.pediatric.scheduleType && builScheduleTypes(specialities.pediatric.scheduleType),
      nephrology: specialities.nephrology && specialities.nephrology.enable,
      nephrologySchedule: specialities.nephrology && builScheduleTypes(specialities.nephrology.scheduleType),
      psychiatry: specialities.psychiatry && specialities.psychiatry.enable,
      psychiatrySchedule: specialities.psychiatry && builScheduleTypes(specialities.psychiatry.scheduleType),
      neuroRadiology: specialities.neuroRadiology && specialities.neuroRadiology.enable,
      neuroRadiologySchedule:
        specialities.neuroRadiology && builScheduleTypes(specialities.neuroRadiology.scheduleType),
      hemodynamics: specialities.hemodynamics && specialities.hemodynamics.enable,
      hemodynamicsSchedule: specialities.hemodynamics && builScheduleTypes(specialities.hemodynamics.scheduleType),
      emergencySpecialist: specialities.emergencySpecialist && specialities.emergencySpecialist.enable,
      emergencySpecialistSchedule:
        specialities.emergencySpecialist && builScheduleTypes(specialities.emergencySpecialist.scheduleType)
    },
    supportServices: {
      dialysisService: services.dialysis.enable,
      dialysisServiceQty: services.dialysis.qty,
      operatingRoom: services.surgery.enable,
      operatingRoomQty: services.surgery.qty,
      clinicalLab: services.clinicalLaboratory.enable,
      clinicalLabQty: services.clinicalLaboratory.qty,
      bloodBank: services.bloodBank.enable,
      bloodBankQty: services.bloodBank.qty
    },
    diagnosticAids: {
      xrays: services.xrays.enable,
      xraysQty: services.xrays.qty,
      tomograph: services.tomograph.enable,
      tomographQty: services.tomograph.qty,
      angiograph: services.angiograph.enable,
      angiographQty: services.angiograph.qty,
      resonator: services.magneticResonance.enable,
      resonatorQty: services.magneticResonance.qty,
      ultrasonograph: services.ultrasonograph.enable,
      ultrasonographQty: services.ultrasonograph.qty
    },
    internment: {
      neonatalUciHosp: hospitalization.neonatal.uci.enable,
      neonatalUciBedsHosp: hospitalization.neonatal.uci.beds,
      neonatalUceHosp: hospitalization.neonatal.uce.enable,
      neonatalUceBedsHosp: hospitalization.neonatal.uce.beds,
      pediatricUciHosp: hospitalization.pediatric.uci.enable,
      pediatricUciBedsHosp: hospitalization.pediatric.uci.beds,
      pediatricUceHosp: hospitalization.pediatric.uce.enable,
      pediatricUceBedsHosp: hospitalization.pediatric.uce.beds,
      pediatricBurnHosp: (hospitalization.pediatric.burn && hospitalization.pediatric.burn.enable) || false,
      pediatricBurnBedsHosp: (hospitalization.pediatric.burn && hospitalization.pediatric.burn.beds) || 0,
      pediatricGeneralHosp: hospitalization.pediatric.general.enable,
      pediatricGeneralBedsHosp: hospitalization.pediatric.general.beds,
      adultUciHosp: hospitalization.adult.uci.enable,
      adultUciBedsHosp: hospitalization.adult.uci.beds,
      adultUceHosp: hospitalization.adult.uce.enable,
      adultUceBedsHosp: hospitalization.adult.uce.beds,
      adultObstetricHosp: hospitalization.adult.obstetric.enable,
      adultObstetricBedsHosp: hospitalization.adult.obstetric.beds,
      adultBurnHosp: hospitalization.adult.burn.enable,
      adultBurnBedsHosp: hospitalization.adult.burn.beds,
      adultGeneralHosp: hospitalization.adult.general.enable,
      adultGeneralBedsHosp: hospitalization.adult.general.beds
    },
    urgency: {
      pediatricGeneralUrg: emergency.pediatric.general.enable,
      pediatricGeneralBeds: emergency.pediatric.general.beds,
      pediatricSupportUrg: emergency.pediatric.therapeuticSupport.enable,
      pediatricSupportBedsUrg: emergency.pediatric.therapeuticSupport.chairs,
      pediatricReanimationUrg: emergency.pediatric.reanimation.enable,
      pediatricReanimationBedsUrg: emergency.pediatric.reanimation.beds,
      adultGeneralUrg: emergency.adult.general.enable,
      adultGeneralBedsUrg: emergency.adult.general.beds,
      adultSupportUrg: emergency.adult.therapeuticSupport.enable,
      adultSupportBedsUrg: emergency.adult.therapeuticSupport.chairs,
      adultReanimationUrg: emergency.adult.reanimation.enable,
      adultReanimationBedsUrg: emergency.adult.reanimation.beds,
      expansionChairsUrg: 0, // emergency.chairsExpansion,
      expansionBedsUrg: 0 // emergency.bedsExpansion
    }
  };
};

export const getDisabledServices = bedsOutOfService => {
  if (!bedsOutOfService) return null;
  const disabledServices = {};
  Object.keys(bedsOutOfService).forEach(key => {
    const str = key
      .split('.')
      .splice(0, 3)
      .join('.');

    outOfServiceFields.forEach(obj => {
      const { category, type, service, label } = obj;
      const string = `${category}.${type}.${service}`;
      if (string === str) {
        const newObj = {
          reason: key.split('.')[3],
          label,
          quantity: bedsOutOfService[key]
        };
        if (disabledServices[string]) {
          disabledServices[string].push(newObj);
        } else {
          disabledServices[string] = [newObj];
        }
      }
    });
  });
  return disabledServices;
};

export const buildResourceDetails = response => {
  const { specialities, services, units } = response;
  const { hospitalization, emergency } = units;
  return {
    ipsName: response.ipsName,
    createdBy: response.createdBy,
    createdAt: response.createdAt,
    bloodResources: response.bloodResources,
    bedsOutOfService: getDisabledServices(response.bedsOutOfService),
    humanResource: {
      generalSurgery: specialities.generalSurgery && specialities.generalSurgery.available,
      generalSurgeryReason: specialities.generalSurgery && specialities.generalSurgery.reason,
      orthopaedic: specialities.orthopaedic && specialities.orthopaedic.available,
      orthopaedicReason: specialities.orthopaedic && specialities.orthopaedic.reason,
      neurosurgery: specialities.neurosurgery && specialities.neurosurgery.available,
      neurosurgeryReason: specialities.neurosurgery && specialities.neurosurgery.reason,
      radiology: specialities.radiology && specialities.radiology.available,
      radiologyReason: specialities.radiology && specialities.radiology.reason,
      plasticSurgery: specialities.plasticSurgery && specialities.plasticSurgery.available,
      plasticSurgeryReason: specialities.plasticSurgery && specialities.plasticSurgery.reason,
      oralSurgery: specialities.oralSurgery && specialities.oralSurgery.available,
      oralSurgeryReason: specialities.oralSurgery && specialities.oralSurgery.reason,
      cardiovascularSurgery: specialities.cardiovascularSurgery && specialities.cardiovascularSurgery.available,
      cardiovascularSurgeryReason: specialities.cardiovascularSurgery && specialities.cardiovascularSurgery.reason,
      microvascularSurgery: specialities.microvascularSurgery && specialities.microvascularSurgery.available,
      microvascularSurgeryReason: specialities.microvascularSurgery && specialities.microvascularSurgery.reason,
      peripheralVascularSurgery:
        specialities.peripheralVascularSurgery && specialities.peripheralVascularSurgery.available,
      peripheralVascularSurgeryReason:
        specialities.peripheralVascularSurgery && specialities.peripheralVascularSurgery.reason,
      headSurgery: specialities.headSurgery && specialities.headSurgery.available,
      headSurgeryReason: specialities.headSurgery && specialities.headSurgery.reason,
      ocularSurgery: specialities.ocularSurgery && specialities.ocularSurgery.available,
      ocularSurgeryReason: specialities.ocularSurgery && specialities.ocularSurgery.reason,
      otorhinolaryngology: specialities.otorhinolaryngology && specialities.otorhinolaryngology.available,
      otorhinolaryngologyReason: specialities.otorhinolaryngology && specialities.otorhinolaryngology.reason,
      thoracicSurgery: specialities.thoracicSurgery && specialities.thoracicSurgery.available,
      thoracicSurgeryReason: specialities.thoracicSurgery && specialities.thoracicSurgery.reason,
      pediatricSurgery: specialities.pediatricSurgery && specialities.pediatricSurgery.available,
      pediatricSurgeryReason: specialities.pediatricSurgery && specialities.pediatricSurgery.reason,
      urology: specialities.urology && specialities.urology.available,
      urologyReason: specialities.urology && specialities.urology.reason,
      obstetricSurgery: specialities.obstetricSurgery && specialities.obstetricSurgery.available,
      obstetricSurgeryReason: specialities.obstetricSurgery && specialities.obstetricSurgery.reason
    },
    nonSurgicalSupport: {
      internalMedicine: specialities.internalMedicine && specialities.internalMedicine.available,
      internalMedicineReason: specialities.internalMedicine && specialities.internalMedicine.reason,
      pediatric: specialities.pediatric && specialities.pediatric.available,
      pediatricReason: specialities.pediatric && specialities.pediatric.reason,
      nephrology: specialities.nephrology && specialities.nephrology.available,
      nephrologyReason: specialities.nephrology && specialities.nephrology.reason,
      psychiatry: specialities.psychiatry && specialities.psychiatry.available,
      psychiatryReason: specialities.psychiatry && specialities.psychiatry.reason,
      neuroRadiology: specialities.neuroRadiology && specialities.neuroRadiology.available,
      neuroRadiologyReason: specialities.neuroRadiology && specialities.neuroRadiology.reason,
      hemodynamics: specialities.hemodynamics && specialities.hemodynamics.available,
      hemodynamicsReason: specialities.hemodynamics && specialities.hemodynamics.reason,
      emergencySpecialist: specialities.emergencySpecialist && specialities.emergencySpecialist.available,
      emergencySpecialistReason: specialities.emergencySpecialist && specialities.emergencySpecialist.reason
    },
    supportServices: {
      dialysisService: services.dialysis && services.dialysis.available,
      dialysisServiceQty: services.dialysis && services.dialysis.availableQty,
      dialysisServiceReason: services.dialysis && services.dialysis.reason,
      operatingRoom: services.surgery && services.surgery.available,
      operatingRoomQty: services.surgery && services.surgery.availableQty,
      operatingRoomReason: services.surgery && services.surgery.reason,
      clinicalLab: services.clinicalLaboratory && services.clinicalLaboratory.available,
      clinicalLabQty: services.clinicalLaboratory && services.clinicalLaboratory.availableQty,
      clinicalLabReason: services.clinicalLaboratory && services.clinicalLaboratory.reason,
      bloodBank: services.bloodBank && services.bloodBank.available,
      bloodBankQty: services.bloodBank && services.bloodBank.availableQty,
      bloodBankReason: services.bloodBank && services.bloodBank.reason,
      xrays: services.xrays && services.xrays.available,
      xraysQty: services.xrays && services.xrays.availableQty,
      xraysReason: services.xrays && services.xrays.reason,
      tomograph: services.tomograph && services.tomograph.available,
      tomographQty: services.tomograph && services.tomograph.availableQty,
      tomographReason: services.tomograph && services.tomograph.reason,
      angiograph: services.angiograph && services.angiograph.available,
      angiographQty: services.angiograph && services.angiograph.availableQty,
      angiographReason: services.angiograph && services.angiograph.reason,
      resonator: services.magneticResonance && services.magneticResonance.available,
      resonatorQty: services.magneticResonance && services.magneticResonance.availableQty,
      resonatorReason: services.magneticResonance && services.magneticResonance.reason,
      ultrasonograph: services.ultrasonograph && services.ultrasonograph.available,
      ultrasonographQty: services.ultrasonograph && services.ultrasonograph.availableQty,
      ultrasonographReason: services.ultrasonograph && services.ultrasonograph.reason
    },
    internment: {
      neonatalUciBedsHosp: hospitalization.neonatal.uci && hospitalization.neonatal.uci.patients,
      neonatalUciBedsPercentage: hospitalization.neonatal.uci && hospitalization.neonatal.uci.occupationPercentage,
      neonatalUceBedsHosp: hospitalization.neonatal.uce && hospitalization.neonatal.uce.patients,
      neonatalUceBedsPercentage: hospitalization.neonatal.uce && hospitalization.neonatal.uce.occupationPercentage,
      pediatricUciBedsHosp: hospitalization.pediatric.uci && hospitalization.pediatric.uci.patients,
      pediatricUciBedsPercentage: hospitalization.pediatric.uci && hospitalization.pediatric.uci.occupationPercentage,
      pediatricUceBedsHosp: hospitalization.pediatric.uce && hospitalization.pediatric.uce.patients,
      pediatricUceBedsPercentage: hospitalization.pediatric.uce && hospitalization.pediatric.uce.occupationPercentage,
      pediatricBurnBedsHosp: hospitalization.pediatric.burn && hospitalization.pediatric.burn.patients,
      pediatricBurnBedsPercentage:
        hospitalization.pediatric.burn && hospitalization.pediatric.burn.occupationPercentage,
      pediatricGeneralBedsHosp: hospitalization.pediatric.general && hospitalization.pediatric.general.patients,
      pediatricGeneralBedsPercentage:
        hospitalization.pediatric.general && hospitalization.pediatric.general.occupationPercentage,
      adultUciBedsHosp: hospitalization.adult.uci && hospitalization.adult.uci.patients,
      adultUciBedsPercentage: hospitalization.adult.uci && hospitalization.adult.uci.occupationPercentage,
      adultUceBedsHosp: hospitalization.adult.uce && hospitalization.adult.uce.patients,
      adultUceBedsPercentage: hospitalization.adult.uce && hospitalization.adult.uce.occupationPercentage,
      adultObstetricBedsHosp: hospitalization.adult.obstetric && hospitalization.adult.obstetric.patients,
      adultObstetricBedsPercentage:
        hospitalization.adult.obstetric && hospitalization.adult.obstetric.occupationPercentage,
      adultBurnBedsHosp: hospitalization.adult.burn && hospitalization.adult.burn.patients,
      adultBurnBedsPercentage: hospitalization.adult.burn && hospitalization.adult.burn.occupationPercentage,
      adultGeneralBedsHosp: hospitalization.adult.general && hospitalization.adult.general.patients,
      adultGeneralBedsPercentage: hospitalization.adult.general && hospitalization.adult.general.occupationPercentage,
      bedsOutOfServiceInt: hospitalization.bedsOutOfService
    },
    urgency: {
      pediatricGeneralBeds: emergency.pediatric.general && emergency.pediatric.general.patients,
      pediatricGeneralBedsPercentage: emergency.pediatric.general && emergency.pediatric.general.occupationPercentage,
      pediatricSupportBedsUrg:
        emergency.pediatric.therapeuticSupport && emergency.pediatric.therapeuticSupport.patients,
      pediatricSupportBedsPercentage:
        emergency.pediatric.therapeuticSupport && emergency.pediatric.therapeuticSupport.occupationPercentage,
      pediatricReanimationBedsUrg: emergency.pediatric.reanimation && emergency.pediatric.reanimation.patients,
      pediatricReanimationBedsPercentage:
        emergency.pediatric.reanimation && emergency.pediatric.reanimation.occupationPercentage,
      adultGeneralBedsUrg: emergency.adult.general && emergency.adult.general.patients,
      adultGeneralBedsPercentage: emergency.adult.general && emergency.adult.general.occupationPercentage,
      adultSupportBedsUrg: emergency.adult.therapeuticSupport && emergency.adult.therapeuticSupport.patients,
      adultSupportBedsPercentage:
        emergency.adult.therapeuticSupport && emergency.adult.therapeuticSupport.occupationPercentage,
      adultReanimationBedsUrg: emergency.adult.reanimation && emergency.adult.reanimation.patients,
      adultReanimationBedsPercentage: emergency.adult.reanimation && emergency.adult.reanimation.occupationPercentage,
      nedocs: {
        score: emergency.nedocs.score,
        level: emergency.nedocs.level
      },
      bedsOutOfServiceUrg: emergency.bedsOutOfService,
      maxTimeAtEmergencyToHospitalization: emergency.maxTimeAtEmergencyToHospitalization,
      maxTimeToBeAttended: emergency.maxTimeToBeAttended,
      patientsPendingHospitalization: emergency.patientsPendingHospitalization,
      patientsReferredToOtherHospital: emergency.patientsReferredToOtherHospital,
      patientsWaitingReferralTransport: emergency.patientsWaitingReferralTransport
    }
  };
};

const reasons = [
  'Terminación de contrato',
  'Incapacidad o novedad temporal del recurso humano',
  'Cierre del servicio',
  'El servicio desborda la capacidad de atención',
  'Otro',
  'Daño',
  'Mantenimiento',
  'Falta de materiales, medicamentos o insumos',
  'Falta de recurso humano'
];

const getReason = speciality => {
  if (speciality === undefined) {
    return null;
  }
  if (reasons.includes(speciality.reason)) {
    return speciality.reason;
  }
  return 'Otro';
};

const getOtherReason = speciality => {
  if (!reasons.includes(speciality.reason)) {
    return speciality.reason;
  }
  return undefined;
};

export const getLastOccupation = response => {
  const bedsOutOfService = getLastBedsOutOfService(response.bedsOutOfService);
  const { specialities, services, units } = response;
  const { hospitalization, emergency, covid, ira } = units;
  return {
    ipsName: response.ipsName,
    createdBy: response.createdBy,
    createdAt: response.createdAt,
    humanResource: {
      generalSurgery: specialities.generalSurgery && specialities.generalSurgery.available,
      generalSurgeryReason: specialities.generalSurgery && getReason(specialities.generalSurgery),
      generalSurgeryOtherReason: specialities.generalSurgery && getOtherReason(specialities.generalSurgery),
      orthopaedic: specialities.orthopaedic && specialities.orthopaedic.available,
      orthopaedicReason: specialities.orthopaedic && getReason(specialities.orthopaedic),
      orthopaedicOtherReason: specialities.orthopaedic && getOtherReason(specialities.orthopaedic),
      neurosurgery: specialities.neurosurgery && specialities.neurosurgery.available,
      neurosurgeryReason: specialities.neurosurgery && getReason(specialities.neurosurgery),
      neurosurgeryOtherReason: specialities.neurosurgery && getOtherReason(specialities.neurosurgery),
      radiology: specialities.radiology && specialities.radiology.available,
      radiologyReason: specialities.radiology && getReason(specialities.radiology),
      radiologyOtherReason: specialities.radiology && getOtherReason(specialities.radiology),
      plasticSurgery: specialities.plasticSurgery && specialities.plasticSurgery.available,
      plasticSurgeryReason: specialities.plasticSurgery && getReason(specialities.plasticSurgery),
      plasticSurgeryOtherReason: specialities.plasticSurgery && getOtherReason(specialities.plasticSurgery),
      oralSurgery: specialities.oralSurgery && specialities.oralSurgery.available,
      oralSurgeryReason: specialities.oralSurgery && getReason(specialities.oralSurgery),
      oralSurgeryOtherReason: specialities.oralSurgery && getOtherReason(specialities.oralSurgery),
      cardiovascularSurgery: specialities.cardiovascularSurgery && specialities.cardiovascularSurgery.available,
      cardiovascularSurgeryReason: specialities.cardiovascularSurgery && getReason(specialities.cardiovascularSurgery),
      cardiovascularSurgeryOtherReason:
        specialities.cardiovascularSurgery && getOtherReason(specialities.cardiovascularSurgery),
      microvascularSurgery: specialities.microvascularSurgery && specialities.microvascularSurgery.available,
      microvascularSurgeryReason: specialities.microvascularSurgery && getReason(specialities.microvascularSurgery),
      microvascularSurgeryOtherReason:
        specialities.microvascularSurgery && getOtherReason(specialities.microvascularSurgery),
      peripheralVascularSurgery:
        specialities.peripheralVascularSurgery && specialities.peripheralVascularSurgery.available,
      peripheralVascularSurgeryReason:
        specialities.peripheralVascularSurgery && getReason(specialities.peripheralVascularSurgery),
      peripheralVascularSurgeryOtherReason:
        specialities.peripheralVascularSurgery && getOtherReason(specialities.peripheralVascularSurgery),
      headSurgery: specialities.headSurgery && specialities.headSurgery.available,
      headSurgeryReason: specialities.headSurgery && getReason(specialities.headSurgery),
      headSurgeryOtherReason: specialities.headSurgery && getOtherReason(specialities.headSurgery),
      ocularSurgery: specialities.ocularSurgery && specialities.ocularSurgery.available,
      ocularSurgeryReason: specialities.ocularSurgery && getReason(specialities.ocularSurgery),
      ocularSurgeryOtherReason: specialities.ocularSurgery && getOtherReason(specialities.ocularSurgery),
      otorhinolaryngology: specialities.otorhinolaryngology && specialities.otorhinolaryngology.available,
      otorhinolaryngologyReason: specialities.otorhinolaryngology && getReason(specialities.otorhinolaryngology),
      otorhinolaryngologyOtherReason:
        specialities.otorhinolaryngology && getOtherReason(specialities.otorhinolaryngology),
      thoracicSurgery: specialities.thoracicSurgery && specialities.thoracicSurgery.available,
      thoracicSurgeryReason: specialities.thoracicSurgery && getReason(specialities.thoracicSurgery),
      thoracicSurgeryOtherReason: specialities.thoracicSurgery && getOtherReason(specialities.thoracicSurgery),
      pediatricSurgery: specialities.pediatricSurgery && specialities.pediatricSurgery.available,
      pediatricSurgeryReason: specialities.pediatricSurgery && getReason(specialities.pediatricSurgery),
      pediatricSurgeryOtherReason: specialities.pediatricSurgery && getOtherReason(specialities.pediatricSurgery),
      urology: specialities.urology && specialities.urology.available,
      urologyReason: specialities.urology && getReason(specialities.urology),
      urologyOtherReason: specialities.urology && getOtherReason(specialities.urology),
      obstetricSurgery: specialities.obstetricSurgery && specialities.obstetricSurgery.available,
      obstetricSurgeryReason: specialities.obstetricSurgery && getReason(specialities.obstetricSurgery),
      obstetricSurgeryOtherReason: specialities.obstetricSurgery && getOtherReason(specialities.obstetricSurgery)
    },
    nonSurgicalSupport: {
      internalMedicine: specialities.internalMedicine && specialities.internalMedicine.available,
      internalMedicineReason: specialities.internalMedicine && getReason(specialities.internalMedicine),
      internalMedicineOtherReason: specialities.internalMedicine && getOtherReason(specialities.internalMedicine),
      pediatric: specialities.pediatric && specialities.pediatric.available,
      pediatricReason: specialities.pediatric && getReason(specialities.pediatric),
      pediatricOtherReason: specialities.pediatric && getOtherReason(specialities.pediatric),
      nephrology: specialities.nephrology && specialities.nephrology.available,
      nephrologyReason: specialities.nephrology && getReason(specialities.nephrology),
      nephrologyOtherReason: specialities.nephrology && getOtherReason(specialities.nephrology),
      psychiatry: specialities.psychiatry && specialities.psychiatry.available,
      psychiatryReason: specialities.psychiatry && getReason(specialities.psychiatry),
      psychiatryOtherReason: specialities.psychiatry && getOtherReason(specialities.psychiatry),
      neuroRadiology: specialities.neuroRadiology && specialities.neuroRadiology.available,
      neuroRadiologyReason: specialities.neuroRadiology && getReason(specialities.neuroRadiology),
      neuroRadiologyOtherReason: specialities.neuroRadiology && getOtherReason(specialities.neuroRadiology),
      hemodynamics: specialities.hemodynamics && specialities.hemodynamics.available,
      hemodynamicsReason: specialities.hemodynamics && getReason(specialities.hemodynamics),
      hemodynamicsOtherReason: specialities.hemodynamics && getOtherReason(specialities.hemodynamics),
      emergencySpecialist: specialities.emergencySpecialist && specialities.emergencySpecialist.available,
      emergencySpecialistReason: specialities.emergencySpecialist && getReason(specialities.emergencySpecialist),
      emergencySpecialistOtherReason:
        specialities.emergencySpecialist && getOtherReason(specialities.emergencySpecialist)
    },
    supportServices: {
      dialysisService: services.dialysis && services.dialysis.available,
      dialysisServiceQty: services.dialysis && services.dialysis.availableQty,
      dialysisServiceReason: services.dialysis && getReason(services.dialysis),
      dialysisServiceOtherReason: services.dialysis && getOtherReason(services.dialysis),
      operatingRoom: services.surgery && services.surgery.available,
      operatingRoomQty: services.surgery && services.surgery.availableQty,
      operatingRoomReason: services.surgery && getReason(services.surgery),
      operatingRoomOtherReason: services.surgery && getOtherReason(services.surgery),
      clinicalLab: services.clinicalLaboratory && services.clinicalLaboratory.available,
      clinicalLabQty: services.clinicalLaboratory && services.clinicalLaboratory.availableQty,
      clinicalLabReason: services.clinicalLaboratory && getReason(services.clinicalLaboratory),
      clinicalLabOtherReason: services.clinicalLaboratory && getOtherReason(services.clinicalLaboratory),
      bloodBank: services.bloodBank && services.bloodBank.available,
      bloodBankQty: services.bloodBank && services.bloodBank.availableQty,
      bloodBankReason: services.bloodBank && getReason(services.bloodBank),
      bloodBankOtherReason: services.bloodBank && getOtherReason(services.bloodBank),
      xrays: services.xrays && services.xrays.available,
      xraysQty: services.xrays && services.xrays.availableQty,
      xraysReason: services.xrays && getReason(services.xrays),
      xraysOtherReason: services.xrays && getOtherReason(services.xrays),
      tomograph: services.tomograph && services.tomograph.available,
      tomographQty: services.tomograph && services.tomograph.availableQty,
      tomographReason: services.tomograph && getReason(services.tomograph),
      tomographOtherReason: services.tomograph && getOtherReason(services.tomograph),
      angiograph: services.angiograph && services.angiograph.available,
      angiographQty: services.angiograph && services.angiograph.availableQty,
      angiographReason: services.angiograph && getReason(services.angiograph),
      angiographOtherReason: services.angiograph && getOtherReason(services.angiograph),
      resonator: services.magneticResonance && services.magneticResonance.available,
      resonatorQty: services.magneticResonance && services.magneticResonance.availableQty,
      resonatorReason: services.magneticResonance && getReason(services.magneticResonance),
      resonatorOtherReason: services.magneticResonance && getOtherReason(services.magneticResonance),
      ultrasonograph: services.ultrasonograph && services.ultrasonograph.available,
      ultrasonographQty: services.ultrasonograph && services.ultrasonograph.availableQty,
      ultrasonographReason: services.ultrasonograph && getReason(services.ultrasonograph),
      ultrasonographOtherReason: services.ultrasonograph && getOtherReason(services.ultrasonograph)
    },
    internment: {
      neonatalUciBedsHosp: hospitalization.neonatal.uci && hospitalization.neonatal.uci.patients,
      neonatalUciBedsPercentage: hospitalization.neonatal.uci && hospitalization.neonatal.uci.occupationPercentage,
      neonatalUceBedsHosp: hospitalization.neonatal.uce && hospitalization.neonatal.uce.patients,
      neonatalUceBedsPercentage: hospitalization.neonatal.uce && hospitalization.neonatal.uce.occupationPercentage,
      pediatricUciBedsHosp: hospitalization.pediatric.uci && hospitalization.pediatric.uci.patients,
      pediatricUciBedsPercentage: hospitalization.pediatric.uci && hospitalization.pediatric.uci.occupationPercentage,
      pediatricUceBedsHosp: hospitalization.pediatric.uce && hospitalization.pediatric.uce.patients,
      pediatricUceBedsPercentage: hospitalization.pediatric.uce && hospitalization.pediatric.uce.occupationPercentage,
      pediatricBurnBedsHosp:
        hospitalization.pediatric && hospitalization.pediatric.burn && hospitalization.pediatric.burn.patients,
      pediatricBurnBedsPercentage:
        hospitalization.pediatric &&
        hospitalization.pediatric.burn &&
        hospitalization.pediatric.burn.occupationPercentage,
      pediatricGeneralBedsHosp: hospitalization.pediatric.general && hospitalization.pediatric.general.patients,
      pediatricGeneralBedsPercentage:
        hospitalization.pediatric.general && hospitalization.pediatric.general.occupationPercentage,
      adultUciBedsHosp: hospitalization.adult.uci && hospitalization.adult.uci.patients,
      adultUciBedsPercentage: hospitalization.adult.uci && hospitalization.adult.uci.occupationPercentage,
      adultObstetricBedsHosp: hospitalization.adult.obstetric && hospitalization.adult.obstetric.patients,
      adultUceBedsHosp: hospitalization.adult.uce && hospitalization.adult.uce.patients,
      adultUceBedsPercentaje: hospitalization.adult.uce && hospitalization.adult.uce.occupationPercentage,
      adultObstetricBedsPercentage:
        hospitalization.adult.obstetric && hospitalization.adult.obstetric.occupationPercentage,
      adultBurnBedsHosp: hospitalization.adult.burn && hospitalization.adult.burn.patients,
      adultBurnBedsPercentage: hospitalization.adult.burn && hospitalization.adult.burn.occupationPercentage,
      adultGeneralBedsHosp: hospitalization.adult.general && hospitalization.adult.general.patients,
      adultGeneralBedsPercentage: hospitalization.adult.general && hospitalization.adult.general.occupationPercentage,
      bedsOutOfService: hospitalization.bedsOutOfService
    },
    urgency: {
      pediatricGeneralBeds: emergency.pediatric.general && emergency.pediatric.general.patients,
      pediatricGeneralBedsPercentage: emergency.pediatric.general && emergency.pediatric.general.occupationPercentage,
      pediatricSupportBedsUrg:
        emergency.pediatric.therapeuticSupport && emergency.pediatric.therapeuticSupport.patients,
      pediatricSupportBedsPercentage:
        emergency.pediatric.therapeuticSupport && emergency.pediatric.therapeuticSupport.occupationPercentage,
      pediatricReanimationBedsUrg: emergency.pediatric.reanimation && emergency.pediatric.reanimation.patients,
      pediatricReanimationBedsPercentage:
        emergency.pediatric.reanimation && emergency.pediatric.reanimation.occupationPercentage,
      adultGeneralBedsUrg: emergency.adult.general && emergency.adult.general.patients,
      adultGeneralBedsPercentage: emergency.adult.general && emergency.adult.general.occupationPercentage,
      adultSupportBedsUrg: emergency.adult.therapeuticSupport && emergency.adult.therapeuticSupport.patients,
      adultSupportBedsPercentage:
        emergency.adult.therapeuticSupport && emergency.adult.therapeuticSupport.occupationPercentage,
      adultReanimationBedsUrg: emergency.adult.reanimation && emergency.adult.reanimation.patients,
      adultReanimationBedsPercentage: emergency.adult.reanimation && emergency.adult.reanimation.occupationPercentage,
      nedocs: {
        score: emergency.nedocs.score,
        level: emergency.nedocs.level
      },
      bedsOutOfServiceUrg: emergency.bedsOutOfService,
      maxTimeAtEmergencyToHospitalization: emergency.maxTimeAtEmergencyToHospitalization,
      maxTimeToBeAttended: emergency.maxTimeToBeAttended,
      patientsPendingHospitalization: emergency.patientsPendingHospitalization,
      patientsReferredToOtherHospital: emergency.patientsReferredToOtherHospital,
      patientsWaitingReferralTransport: emergency.patientsWaitingReferralTransport
    },
    bedsOutOfService,
    covidPatientsInfo: covid && covid.patientsInfo,
    iraPatientsInfo: ira && ira.patientsInfo,
    intensivist: {
      intensivistNumber: covid && covid.hospitalization.adult.intensivist.profesionals
    }
  };
};

export const buildShortAvailabitity = response => {
  const { services, units, nedocs = {} } = response;
  const { hospitalization, emergency } = units;
  return {
    supportServices: {
      bloodBank: services.bloodBank ? services.bloodBank.available : undefined,
      bloodBankQty: services.bloodBank ? services.bloodBank.availableQty : undefined,
      bloodBankReason: services.bloodBank ? services.bloodBank.reason : undefined,
      xrays: services.xrays ? services.xrays.available : undefined,
      xraysQty: services.xrays ? services.xrays.availableQty : undefined,
      xraysReason: services.xrays ? services.xrays.reason : undefined,
      tomograph: services.tomograph ? services.tomograph.available : undefined,
      tomographQty: services.tomograph ? services.tomograph.availableQty : undefined,
      tomographReason: services.tomograph ? services.tomograph.reason : undefined,
      angiograph: services.angiograph ? services.angiograph.available : undefined,
      angiographQty: services.angiograph ? services.angiograph.availableQty : undefined,
      angiographReason: services.angiograph ? services.angiograph.reason : undefined,
      resonator: services.magneticResonance ? services.magneticResonance.available : undefined,
      resonatorQty: services.magneticResonance ? services.magneticResonance.availableQty : undefined,
      resonatorReason: services.magneticResonance ? services.magneticResonance.reason : undefined,
      ultrasonograph: services.ultrasonograph ? services.ultrasonograph.available : undefined,
      ultrasonographQty: services.ultrasonograph ? services.ultrasonograph.availableQty : undefined,
      ultrasonographReason: services.ultrasonograph ? services.ultrasonograph.reason : undefined
    },
    internment: {
      neonatalUciBedsHosp: hospitalization.neonatal.uci && hospitalization.neonatal.uci.beds,
      neonatalUciPercentage: hospitalization.neonatal.uci && hospitalization.neonatal.uci.availabilityPercentage,
      neonatalUceBedsHosp: hospitalization.neonatal.uce && hospitalization.neonatal.uce.beds,
      neonatalUcePercentage: hospitalization.neonatal.uce && hospitalization.neonatal.uce.availabilityPercentage,
      pediatricUciBedsHosp: hospitalization.pediatric.uci && hospitalization.pediatric.uci.beds,
      pediatricUciBedsPercentage: hospitalization.pediatric.uci && hospitalization.pediatric.uci.availabilityPercentage,
      pediatricUceBedsHosp: hospitalization.pediatric.uce && hospitalization.pediatric.uce.beds,
      pediatricUceBedsPercentage: hospitalization.pediatric.uce && hospitalization.pediatric.uce.availabilityPercentage,
      // pediatricBurnBedsHosp: hospitalization.pediatric.burn.beds,
      // pediatricBurnBedsPercentage: hospitalization.pediatric.burn.availabilityPercentage,
      pediatricGeneralBedsHosp: hospitalization.pediatric.general && hospitalization.pediatric.general.beds,
      pediatricGeneralBedsPercentage:
        hospitalization.pediatric.general && hospitalization.pediatric.general.availabilityPercentage,
      adultUciBedsHosp: hospitalization.adult.uci && hospitalization.adult.uci.beds,
      adultUciBedsPercentage: hospitalization.adult.uci && hospitalization.adult.uci.availabilityPercentage,
      adultObstetricBedsHosp: hospitalization.adult.obstetric && hospitalization.adult.obstetric.beds,
      adultObstetricBedsPercentage:
        hospitalization.adult.obstetric && hospitalization.adult.obstetric.availabilityPercentage,
      adultBurnBedsHosp: hospitalization.adult.burn && hospitalization.adult.burn.beds,
      adultBurnBedsPercentage: hospitalization.adult.burn && hospitalization.adult.burn.availabilityPercentage,
      adultGeneralBedsHosp: hospitalization.adult.general && hospitalization.adult.general.beds,
      adultGeneralBedsPercentage: hospitalization.adult.general && hospitalization.adult.general.availabilityPercentage
    },
    urgency: {
      pediatricGeneralBeds: emergency.pediatric.general && emergency.pediatric.general.beds,
      pediatricGeneralBedsPercentage: emergency.pediatric.general && emergency.pediatric.general.availabilityPercentage,
      pediatricSupportBedsUrg: emergency.pediatric.therapeuticSupport && emergency.pediatric.therapeuticSupport.chairs,
      pediatricSupportBedsPercentage:
        emergency.pediatric.therapeuticSupport && emergency.pediatric.therapeuticSupport.availabilityPercentage,
      pediatricReanimationBedsUrg: emergency.pediatric.reanimation && emergency.pediatric.reanimation.beds,
      pediatricReanimationBedsPercentage:
        emergency.pediatric.reanimation && emergency.pediatric.reanimation.availabilityPercentage,
      adultGeneralBedsUrg: emergency.adult.general && emergency.adult.general.beds,
      adultGeneralBedsPercentage: emergency.adult.general && emergency.adult.general.availabilityPercentage,
      adultSupportBedsUrg: emergency.adult.therapeuticSupport && emergency.adult.therapeuticSupport.chairs,
      adultSupportBedsPercentage:
        emergency.adult.therapeuticSupport && emergency.adult.therapeuticSupport.availabilityPercentage,
      adultReanimationBedsUrg: emergency.adult.reanimation && emergency.adult.reanimation.beds,
      adultReanimationBedsPergcentage: emergency.adult.reanimation && emergency.adult.reanimation.availabilityPercentage
    },
    nedocs: {
      score: nedocs.score,
      level: nedocs.level
    },
    createdAt: response.createdAt,
    createdBy: response.createdBy
  };
};

const getScheduleTypes = scheduleTypes => {
  return scheduleTypes.map(type => type.value);
};

export const buildResource = state => {
  const {
    humanResource = {},
    nonSurgicalSupport = {},
    supportServices = {},
    diagnosticAids = {},
    internment = {},
    urgency = {}
  } = state;
  return {
    specialities: {
      generalSurgery: {
        enable: humanResource.generalSurgery || false,
        scheduleType: humanResource.generalSurgery ? getScheduleTypes(humanResource.generalSurgerySchedule) : undefined
      },
      orthopaedic: {
        enable: humanResource.orthopaedic || false,
        scheduleType: humanResource.orthopaedic ? getScheduleTypes(humanResource.orthopaedicSchedule) : undefined
      },
      neurosurgery: {
        enable: humanResource.neurosurgery || false,
        scheduleType: humanResource.neurosurgery ? getScheduleTypes(humanResource.neurosurgerySchedule) : undefined
      },
      radiology: {
        enable: humanResource.radiology || false,
        scheduleType: humanResource.radiology ? getScheduleTypes(humanResource.radiologySchedule) : undefined
      },
      plasticSurgery: {
        enable: humanResource.plasticSurgery || false,
        scheduleType: humanResource.plasticSurgery ? getScheduleTypes(humanResource.plasticSurgerySchedule) : undefined
      },
      oralSurgery: {
        enable: humanResource.oralSurgery || false,
        scheduleType: humanResource.oralSurgery ? getScheduleTypes(humanResource.oralSurgerySchedule) : undefined
      },
      cardiovascularSurgery: {
        enable: humanResource.cardiovascularSurgery || false,
        scheduleType: humanResource.cardiovascularSurgery
          ? getScheduleTypes(humanResource.cardiovascularSurgerySchedule)
          : undefined
      },
      peripheralVascularSurgery: {
        enable: humanResource.peripheralVascularSurgery || false,
        scheduleType: humanResource.peripheralVascularSurgery
          ? getScheduleTypes(humanResource.peripheralVascularSurgerySchedule)
          : undefined
      },
      microvascularSurgery: {
        enable: humanResource.microvascularSurgery || false,
        scheduleType: humanResource.microvascularSurgery
          ? getScheduleTypes(humanResource.microvascularSurgerySchedule)
          : undefined
      },
      headSurgery: {
        enable: humanResource.headSurgery || false,
        scheduleType: humanResource.headSurgery ? getScheduleTypes(humanResource.headSurgerySchedule) : undefined
      },
      ocularSurgery: {
        enable: humanResource.ocularSurgery || false,
        scheduleType: humanResource.ocularSurgery ? getScheduleTypes(humanResource.ocularSurgerySchedule) : undefined
      },
      otorhinolaryngology: {
        enable: humanResource.otorhinolaryngology || false,
        scheduleType: humanResource.otorhinolaryngology
          ? getScheduleTypes(humanResource.otorhinolaryngologySchedule)
          : undefined
      },
      thoracicSurgery: {
        enable: humanResource.thoracicSurgery || false,
        scheduleType: humanResource.thoracicSurgery
          ? getScheduleTypes(humanResource.thoracicSurgerySchedule)
          : undefined
      },
      pediatricSurgery: {
        enable: humanResource.pediatricSurgery || false,
        scheduleType: humanResource.pediatricSurgery
          ? getScheduleTypes(humanResource.pediatricSurgerySchedule)
          : undefined
      },
      urology: {
        enable: humanResource.urology || false,
        scheduleType: humanResource.urology ? getScheduleTypes(humanResource.urologySchedule) : undefined
      },
      obstetricSurgery: {
        enable: humanResource.obstetricSurgery || false,
        scheduleType: humanResource.obstetricSurgery
          ? getScheduleTypes(humanResource.obstetricSurgerySchedule)
          : undefined
      },
      internalMedicine: {
        enable: nonSurgicalSupport.internalMedicine || false,
        scheduleType: nonSurgicalSupport.internalMedicine
          ? getScheduleTypes(nonSurgicalSupport.internalMedicineSchedule)
          : undefined
      },
      pediatric: {
        enable: nonSurgicalSupport.pediatric || false,
        scheduleType: nonSurgicalSupport.pediatric ? getScheduleTypes(nonSurgicalSupport.pediatricSchedule) : undefined
      },
      nephrology: {
        enable: nonSurgicalSupport.nephrology || false,
        scheduleType: nonSurgicalSupport.nephrology
          ? getScheduleTypes(nonSurgicalSupport.nephrologySchedule)
          : undefined
      },
      psychiatry: {
        enable: nonSurgicalSupport.psychiatry || false,
        scheduleType: nonSurgicalSupport.psychiatry
          ? getScheduleTypes(nonSurgicalSupport.psychiatrySchedule)
          : undefined
      },
      neuroRadiology: {
        enable: nonSurgicalSupport.neuroRadiology || false,
        scheduleType: nonSurgicalSupport.neuroRadiology
          ? getScheduleTypes(nonSurgicalSupport.neuroRadiologySchedule)
          : undefined
      },
      hemodynamics: {
        enable: nonSurgicalSupport.hemodynamics || false,
        scheduleType: nonSurgicalSupport.hemodynamics
          ? getScheduleTypes(nonSurgicalSupport.hemodynamicsSchedule)
          : undefined
      },
      emergencySpecialist: {
        enable: nonSurgicalSupport.emergencySpecialist || false,
        scheduleType: nonSurgicalSupport.emergencySpecialist
          ? getScheduleTypes(nonSurgicalSupport.emergencySpecialistSchedule)
          : undefined
      }
    },
    // Volver a poner los servicios supportServicesQty cuando se requiera ingresar la cantidad
    // Se está mandando 2 porque el el back se requiere que la cantidad sea mayor a la ocupación
    services: {
      dialysis: {
        enable: supportServices.dialysisService || false,
        // schedule: [],
        qty: supportServices.dialysisService ? 1 : 0
      },
      surgery: {
        enable: supportServices.operatingRoom || false,
        // schedule: [],
        qty: supportServices.operatingRoom ? 1 : 0
      },
      clinicalLaboratory: {
        enable: supportServices.clinicalLab || false,
        // schedule: [],
        qty: supportServices.clinicalLab ? 1 : 0
      },
      bloodBank: {
        enable: supportServices.bloodBank || false,
        // schedule: [],
        qty: supportServices.bloodBank ? 1 : 0
      },
      xrays: {
        enable: diagnosticAids.xrays || false,
        // schedule: [],
        qty: diagnosticAids.xrays ? 1 : 0
      },
      tomograph: {
        enable: diagnosticAids.tomograph || false,
        // schedule: [],
        qty: diagnosticAids.tomograph ? 1 : 0
      },
      angiograph: {
        enable: diagnosticAids.angiograph || false,
        // schedule: [],
        qty: diagnosticAids.angiograph ? 1 : 0
      },
      magneticResonance: {
        enable: diagnosticAids.resonator || false,
        // schedule: [],
        qty: diagnosticAids.resonator ? 1 : 0
      },
      ultrasonograph: {
        enable: diagnosticAids.ultrasonograph || false,
        // schedule: [],
        qty: diagnosticAids.ultrasonograph ? 1 : 0
      }
    },
    units: {
      hospitalization: {
        neonatal: {
          uci: {
            enable: internment.neonatalUciHosp || false,
            beds: internment.neonatalUciHosp ? internment.neonatalUciBedsHosp : 0
          },
          uce: {
            enable: internment.neonatalUceHosp || false,
            beds: internment.neonatalUceHosp ? internment.neonatalUceBedsHosp : 0
          }
        },
        pediatric: {
          uci: {
            enable: internment.pediatricUciHosp || false,
            beds: internment.pediatricUciHosp ? internment.pediatricUciBedsHosp : 0
          },
          uce: {
            enable: internment.pediatricUceHosp || false,
            beds: internment.pediatricUceHosp ? internment.pediatricUceBedsHosp : 0
          },
          burn: {
            enable: internment.pediatricBurnHosp || false,
            beds: internment.pediatricBurnHosp ? internment.pediatricBurnBedsHosp : 0
          },
          general: {
            enable: internment.pediatricGeneralHosp || false,
            beds: internment.pediatricGeneralHosp ? internment.pediatricGeneralBedsHosp : 0
          }
        },
        adult: {
          uci: {
            enable: internment.adultUciHosp || false,
            beds: internment.adultUciHosp ? internment.adultUciBedsHosp : 0
          },
          uce: {
            enable: internment.adultUceHosp || false,
            beds: internment.adultUceHosp ? internment.adultUceBedsHosp : 0
          },
          obstetric: {
            enable: internment.adultObstetricHosp || false,
            beds: internment.adultObstetricHosp ? internment.adultObstetricBedsHosp : 0
          },
          burn: {
            enable: internment.adultBurnHosp || false,
            beds: internment.adultBurnHosp ? internment.adultBurnBedsHosp : 0
          },
          general: {
            enable: internment.adultGeneralHosp || false,
            beds: internment.adultGeneralHosp ? internment.adultGeneralBedsHosp : 0
          }
        }
      },
      emergency: {
        pediatric: {
          general: {
            enable: urgency.pediatricGeneralUrg || false,
            beds: urgency.pediatricGeneralUrg ? urgency.pediatricGeneralBeds : 0
          },
          therapeuticSupport: {
            enable: urgency.pediatricSupportUrg || false,
            chairs: urgency.pediatricSupportUrg ? urgency.pediatricSupportBedsUrg : 0
          },
          reanimation: {
            enable: urgency.pediatricReanimationUrg || false,
            beds: urgency.pediatricReanimationUrg ? urgency.pediatricReanimationBedsUrg : 0
          }
        },
        adult: {
          general: {
            enable: urgency.adultGeneralUrg || false,
            beds: urgency.adultGeneralUrg ? urgency.adultGeneralBedsUrg : 0
          },
          therapeuticSupport: {
            enable: urgency.adultSupportUrg || false,
            chairs: urgency.adultSupportUrg ? urgency.adultSupportBedsUrg : 0
          },
          reanimation: {
            enable: urgency.adultReanimationUrg || false,
            beds: urgency.adultReanimationUrg ? urgency.adultReanimationBedsUrg : 0
          }
        },
        chairsExpansion: 0, // urgency.expansionChairsUrg,
        bedsExpansion: 0 // urgency.expansionBedsUrg
      }
    }
  };
};

const getTimeInHours = (days = 0, hoursDate) => {
  const dateTime = format(new Date(hoursDate), 'H:mm');
  const hours = parseInt(dateTime.split(':')[0], 10);
  const min = parseInt(dateTime.split(':')[1], 10);
  const totalHours = hours + min / 60;
  return round(days * 24 + totalHours, 2);
};

export const buildCovidAndIraData = (covid, ira) => {
  const covidCount = covid.reduce((count, co) => {
    if (co.outcome === null || !co.outcome) {
      count[co.serviceType] = (count[co.serviceType] || 0) + 1;
    }
    return count;
  }, {});
  const iraCount = ira.reduce((count, ir) => {
    if (ir.outcome === null || !ir.outcome) {
      count[ir.serviceType] = (count[ir.serviceType] || 0) + 1;
    }
    return count;
  }, {});
  const data = {
    coPatientsInfo: covid,
    irPatientsInfo: ira,
    covidAdultsPatientUrgency: covidCount.EMERGENCY || 0,
    covidAdultsPatientUci: covidCount.UCI || 0,
    covidAdultsPatientUce: covidCount.UCE || 0,
    covidAdultsPatientGeneralHosp: covidCount.GENERAL_HOSPITALIZATION || 0,
    iraAdultsPatientUrgency: iraCount.EMERGENCY || 0,
    iraAdultsPatientUci: iraCount.UCI || 0,
    iraAdultsPatientUce: iraCount.UCE || 0,
    iraAdultsPatientGeneralHosp: iraCount.GENERAL_HOSPITALIZATION || 0
  };
  return data;
};

export const buildOccupation = state => {
  const {
    humanResource = {},
    nonSurgicalSupport = {},
    supportServices = {},
    diagnosticAids = {},
    internment = {},
    urgency = {},
    intensivist = {},
    lastOccupation = {},
    covidPatientsInfo = [],
    iraPatientsInfo = []
  } = state;
  const [bedsOutOfService, bedsEmergency, bedsHospitalization] = buildBedsOutOfService(lastOccupation.bedsOutOfService);

  const iraAndCovidData = buildCovidAndIraData(covidPatientsInfo, iraPatientsInfo);
  const {
    covidAdultsPatientGeneralHosp,
    covidAdultsPatientUce,
    covidAdultsPatientUci,
    covidAdultsPatientUrgency,
    iraAdultsPatientGeneralHosp,
    iraAdultsPatientUce,
    iraAdultsPatientUci,
    iraAdultsPatientUrgency,
    coPatientsInfo,
    irPatientsInfo
  } = iraAndCovidData;
  return {
    specialities: {
      generalSurgery: {
        available: humanResource.generalSurgery || false,
        reason:
          humanResource.generalSurgeryReason === 'Otro'
            ? humanResource.generalSurgeryOtherReason
            : humanResource.generalSurgeryReason
      },
      orthopaedic: {
        available: humanResource.orthopaedic || false,
        reason:
          humanResource.orthopaedicReason === 'Otro'
            ? humanResource.orthopaedicOtherReason
            : humanResource.orthopaedicReason
      },
      neurosurgery: {
        available: humanResource.neurosurgery || false,
        reason:
          humanResource.neurosurgeryReason === 'Otro'
            ? humanResource.neurosurgeryOtherReason
            : humanResource.neurosurgeryReason
      },
      radiology: {
        available: humanResource.radiology || false,
        reason:
          humanResource.radiologyReason === 'Otro' ? humanResource.radiologyOtherReason : humanResource.radiologyReason
      },
      plasticSurgery: {
        available: humanResource.plasticSurgery || false,
        reason:
          humanResource.plasticSurgeryReason === 'Otro'
            ? humanResource.plasticSurgeryOtherReason
            : humanResource.plasticSurgeryReason
      },
      oralSurgery: {
        available: humanResource.oralSurgery || false,
        reason:
          humanResource.oralSurgeryReason === 'Otro'
            ? humanResource.oralSurgeryOtherReason
            : humanResource.oralSurgeryReason
      },
      cardiovascularSurgery: {
        available: humanResource.cardiovascularSurgery || false,
        reason:
          humanResource.cardiovascularSurgeryReason === 'Otro'
            ? humanResource.cardiovascularSurgeryOtherReason
            : humanResource.cardiovascularSurgeryReason
      },
      peripheralVascularSurgery: {
        available: humanResource.peripheralVascularSurgery || false,
        reason:
          humanResource.peripheralVascularSurgeryReason === 'Otro'
            ? humanResource.peripheralVascularSurgeryOtherReason
            : humanResource.peripheralVascularSurgeryReason
      },
      microvascularSurgery: {
        available: humanResource.microvascularSurgery || false,
        reason:
          humanResource.microvascularSurgeryReason === 'Otro'
            ? humanResource.microvascularSurgeryOtherReason
            : humanResource.microvascularSurgeryReason
      },
      headSurgery: {
        available: humanResource.headSurgery || false,
        reason:
          humanResource.headSurgeryReason === 'Otro'
            ? humanResource.headSurgeryOtherReason
            : humanResource.headSurgeryReason
      },
      ocularSurgery: {
        available: humanResource.ocularSurgery || false,
        reason:
          humanResource.ocularSurgeryReason === 'Otro'
            ? humanResource.ocularSurgeryOtherReason
            : humanResource.ocularSurgeryReason
      },
      otorhinolaryngology: {
        available: humanResource.otorhinolaryngology || false,
        reason:
          humanResource.otorhinolaryngologyReason === 'Otro'
            ? humanResource.otorhinolaryngologyOtherReason
            : humanResource.otorhinolaryngologyReason
      },
      thoracicSurgery: {
        available: humanResource.thoracicSurgery || false,
        reason:
          humanResource.thoracicSurgeryReason === 'Otro'
            ? humanResource.thoracicSurgeryOtherReason
            : humanResource.thoracicSurgeryReason
      },
      pediatricSurgery: {
        available: humanResource.pediatricSurgery || false,
        reason:
          humanResource.pediatricSurgeryReason === 'Otro'
            ? humanResource.pediatricSurgeryOtherReason
            : humanResource.pediatricSurgeryReason
      },
      urology: {
        available: humanResource.urology || false,
        reason: humanResource.urologyReason === 'Otro' ? humanResource.urologyOtherReason : humanResource.urologyReason
      },
      obstetricSurgery: {
        available: humanResource.obstetricSurgery || false,
        reason:
          humanResource.obstetricSurgeryReason === 'Otro'
            ? humanResource.obstetricSurgeryOtherReason
            : humanResource.obstetricSurgeryReason
      },
      internalMedicine: {
        available: nonSurgicalSupport.internalMedicine || false,
        reason:
          nonSurgicalSupport.internalMedicineReason === 'Otro'
            ? nonSurgicalSupport.internalMedicineOtherReason
            : nonSurgicalSupport.internalMedicineReason
      },
      pediatric: {
        available: nonSurgicalSupport.pediatric || false,
        reason:
          nonSurgicalSupport.pediatricReason === 'Otro'
            ? nonSurgicalSupport.pediatricOtherReason
            : nonSurgicalSupport.pediatricReason
      },
      nephrology: {
        available: nonSurgicalSupport.nephrology || false,
        reason:
          nonSurgicalSupport.nephrologyReason === 'Otro'
            ? nonSurgicalSupport.nephrologyOtherReason
            : nonSurgicalSupport.nephrologyReason
      },
      psychiatry: {
        available: nonSurgicalSupport.psychiatry || false,
        reason:
          nonSurgicalSupport.psychiatryReason === 'Otro'
            ? nonSurgicalSupport.psychiatryOtherReason
            : nonSurgicalSupport.psychiatryReason
      },
      neuroRadiology: {
        available: nonSurgicalSupport.neuroRadiology || false,
        reason:
          nonSurgicalSupport.neuroRadiologyReason === 'Otro'
            ? nonSurgicalSupport.neuroRadiologyOtherReason
            : nonSurgicalSupport.neuroRadiologyReason
      },
      hemodynamics: {
        available: nonSurgicalSupport.hemodynamics || false,
        reason:
          nonSurgicalSupport.hemodynamicsReason === 'Otro'
            ? nonSurgicalSupport.hemodynamicsOtherReason
            : nonSurgicalSupport.hemodynamicsReason
      },
      emergencySpecialist: {
        available: nonSurgicalSupport.emergencySpecialist || false,
        reason:
          nonSurgicalSupport.emergencySpecialistReason === 'Otro'
            ? nonSurgicalSupport.emergencySpecialistOtherReason
            : nonSurgicalSupport.emergencySpecialistReason
      }
    },
    // When the quantity is required, change availableQty by supportServices.clinicalLabQty. Currenty, qty is being sent as 1 by default
    services: {
      dialysis: {
        available: supportServices.dialysisService || false,
        availableQty: 1,
        reason:
          supportServices.dialysisServiceReason === 'Otro'
            ? supportServices.dialysisServiceOtherReason
            : supportServices.dialysisServiceReason
      },
      surgery: {
        available: supportServices.operatingRoom || false,
        availableQty: 1,
        reason:
          supportServices.operatingRoomReason === 'Otro'
            ? supportServices.operatingRoomOtherReason
            : supportServices.operatingRoomReason
      },
      clinicalLaboratory: {
        available: supportServices.clinicalLab || false,
        availableQty: 1,
        reason:
          supportServices.clinicalLabReason === 'Otro'
            ? supportServices.clinicalLabOtherReason
            : supportServices.clinicalLabReason
      },
      bloodBank: {
        available: supportServices.bloodBank || false,
        availableQty: 1,
        reason:
          supportServices.bloodBankReason === 'Otro'
            ? supportServices.bloodBankOtherReason
            : supportServices.bloodBankReason
      },
      xrays: {
        available: diagnosticAids.xrays || false,
        availableQty: 1,
        reason: diagnosticAids.xraysReason === 'Otro' ? diagnosticAids.xraysOtherReason : diagnosticAids.xraysReason
      },
      tomograph: {
        available: diagnosticAids.tomograph || false,
        availableQty: 1,
        reason:
          diagnosticAids.tomographReason === 'Otro'
            ? diagnosticAids.tomographOtherReason
            : diagnosticAids.tomographReason
      },
      angiograph: {
        available: diagnosticAids.angiograph || false,
        availableQty: 1,
        reason:
          diagnosticAids.angiographReason === 'Otro'
            ? diagnosticAids.angiographOtherReason
            : diagnosticAids.angiographReason
      },
      magneticResonance: {
        available: diagnosticAids.resonator || false,
        availableQty: 1,
        reason:
          diagnosticAids.resonatorReason === 'Otro'
            ? diagnosticAids.resonatorOtherReason
            : diagnosticAids.resonatorReason
      },
      ultrasonograph: {
        available: diagnosticAids.ultrasonograph || false,
        availableQty: 1,
        reason:
          diagnosticAids.ultrasonographReason === 'Otro'
            ? diagnosticAids.ultrasonographOtherReason
            : diagnosticAids.ultrasonographReason
      }
    },
    units: {
      hospitalization: {
        neonatal: {
          uci: {
            patients: internment.neonatalUciBedsHosp
          },
          uce: {
            patients: internment.neonatalUceBedsHosp
          }
        },
        pediatric: {
          uci: {
            patients: internment.pediatricUciBedsHosp
          },
          uce: {
            patients: internment.pediatricUceBedsHosp
          },
          burn: {
            patients: internment.pediatricBurnBedsHosp
          },
          general: {
            patients: internment.pediatricGeneralBedsHosp
          }
        },
        adult: {
          uci: {
            patients: internment.adultUciBedsHosp
          },
          uce: {
            patients: internment.adultUceBedsHosp
          },
          obstetric: {
            patients: internment.adultObstetricBedsHosp
          },
          burn: {
            patients: internment.adultBurnBedsHosp
          },
          general: {
            patients: internment.adultGeneralBedsHosp
          }
        },
        bedsOutOfService: bedsHospitalization
      },
      emergency: {
        pediatric: {
          general: {
            patients: urgency.pediatricGeneralBeds
          },
          therapeuticSupport: {
            patients: urgency.pediatricSupportBedsUrg // Cambiar a patients
          },
          reanimation: {
            patients: urgency.pediatricReanimationBedsUrg
          }
        },
        adult: {
          general: {
            patients: urgency.adultGeneralBedsUrg
          },
          therapeuticSupport: {
            patients: urgency.adultSupportBedsUrg // Cambiar a patients
          },
          reanimation: {
            patients: urgency.adultReanimationBedsUrg
          }
        },
        bedsOutOfService: bedsEmergency,
        patientsPendingHospitalization: urgency.patientsPendingHospitalization,
        patientsReferredToOtherHospital: urgency.patientsReferredToOtherHospital,
        patientsWaitingReferralTransport: urgency.patientsWaitingReferralTransport,
        maxTimeToBeAttended: getTimeInHours(undefined, urgency.maxTimeToBeAttendedTime),
        maxTimeAtEmergencyToHospitalization: getTimeInHours(
          urgency.maxTimeAtEmergencyToHospitalizationDays,
          urgency.maxTimeAtEmergencyToHospitalizationTime
        )
      },
      covid: {
        hospitalization: {
          adult: {
            general: {
              patients: covidAdultsPatientGeneralHosp
            },
            uci: {
              patients: covidAdultsPatientUci
            },
            uce: {
              patients: covidAdultsPatientUce
            },
            intensivist: {
              profesionals: intensivist.intensivistNumber
            }
          }
        },
        emergency: {
          adult: {
            general: {
              patients: covidAdultsPatientUrgency
            }
          }
        },
        patientsInfo: coPatientsInfo
      },
      ira: {
        hospitalization: {
          adult: {
            general: {
              patients: iraAdultsPatientGeneralHosp
            },
            uci: {
              patients: iraAdultsPatientUci
            },
            uce: {
              patients: iraAdultsPatientUce
            }
          }
        },
        emergency: {
          adult: {
            general: {
              patients: iraAdultsPatientUrgency
            }
          }
        },
        patientsInfo: irPatientsInfo
      }
    },
    bedsOutOfService
  };
};

export const buildServiceList = data => {
  const serviceList = [];
  const { units } = data;
  if (units.emergency.adult.general.enable) {
    serviceList.push({
      name: 'Urgencias',
      id: 'EMERGENCY'
    });
  }
  if (units.hospitalization.adult.general.enable) {
    serviceList.push({
      name: 'Hospitalización general',
      id: 'GENERAL_HOSPITALIZATION'
    });
  }
  if (units.hospitalization.adult.uce.enable) {
    serviceList.push({
      name: 'UCE',
      id: 'UCE'
    });
  }
  if (units.hospitalization.adult.uci.enable) {
    serviceList.push({
      name: 'UCI',
      id: 'UCI'
    });
  }
  if (units.hospitalization.neonatal.uci.enable) {
    serviceList.push({
      name: 'UCI Neonatal',
      id: 'UCI_NEONATAL'
    });
  }
  if (units.hospitalization.pediatric.uci.enable) {
    serviceList.push({
      name: 'UCI Pediatrica',
      id: 'UCI_PEDIATRIC'
    });
  }
  return serviceList;
};

export const outcomeList = [
  {
    name: 'Recuperado',
    id: 'RECOVERED'
  },
  {
    name: 'Recuperación en casa',
    id: 'HOME_RECOVERY'
  },
  {
    name: 'Fallecido',
    id: 'DECEASED'
  },
  {
    name: 'Remitido',
    id: 'REFERRED'
  }
];

export const validateNamesInput = inputValue => {
  const regExp = /^[a-zA-Z\s]*$/;
  const letter = inputValue.toUpperCase();
  const letterArray = letter.split('');
  const letterRegExpr = letterArray.filter(w => w.match(regExp)).join('');
  return letterRegExpr;
};
