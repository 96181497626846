import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select/lib/index';

// Components
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import styles from './nxSelectStyles';

function NoOptionsMessage(props) {
  /* eslint-disable react/prop-types */
  const { selectProps, children, innerProps } = props;
  return (
    <Typography
      variant="subtitle1"
      gutterBottom
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const { selectProps, innerRef, children, innerProps } = props;
  return (
    <>
      <TextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            ...innerProps,
            className: selectProps.classes.input,
            inputRef: innerRef,
            children
          }
        }}
        {...selectProps.textFieldProps}
      />
      <p className={selectProps.classes.helpText}>{selectProps.placeholder}</p>
    </>
  );
}

function Option(props) {
  const { innerRef, innerProps, isFocused, isSelected, children } = props;
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const { children, innerProps, selectProps } = props;
  return (
    <Typography
      gutterBottom
      color="textSecondary"
      className={selectProps.required ? selectProps.classes.placeholderError : selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography gutterBottom className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
}

function ValueContainer(props) {
  const { selectProps, children } = props;
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

function MultiValue(props) {
  const { selectProps, isFocused, removeProps, children } = props;
  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
}

function Menu(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

/**
//  * @param {Object} [classes = {}]
//  * @param {Object} [theme = {}]
//  * @param {Array} options
//  * @param {string} placeholder
//  * @param {string} name
//  * @param handleChange
//  * @param {(Array|Object)} value
//  * @param {boolean} isMulti
//  * @param {boolean} required
 */

export function NxSelect(props) {
  const { classes = {}, theme = {}, options, placeholder, name, handleChange, value, isMulti, required } = props;
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  };

  return (
    <div className={classes.root}>
      <Select
        required={required}
        classes={classes}
        styles={selectStyles}
        textFieldProps={{
          InputLabelProps: {
            shrink: true
          }
        }}
        options={options}
        components={components}
        value={value}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        noOptionsMessage={() => 'No hay más opciones'}
        isMulti={isMulti}
        isClearable
      />
    </div>
  );
}

NxSelect.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  required: PropTypes.bool
};

NxSelect.defaultProps = {
  placeholder: 'Selecciona una opción',
  value: null,
  isMulti: false,
  required: false,
  classes: {}
};

export default withStyles(styles, { withTheme: true })(NxSelect);
