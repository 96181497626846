import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

// Others
import { urgencyFields, otherOccupationUrgencyFields } from 'variables/resources';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import Icon from '@material-ui/core/Icon';
import NumberInput from '../../NumberInput/NumberInput';
import PatientByEps from '../PatientByEps';

const useStyles = makeStyles({
  tooltip: {
    fontSize: '1.08em',
    position: 'relative',
    top: '-30px',
    backgroundColor: '#2BC5A3'
  },
  root: {
    '&$checked': {
      color: '#2BC5A3'
    }
  },
  checked: {},
  cancelIcon: {
    color: '#f44336',
    '&:hover': {
      color: '#f44336'
    }
  }
});

const Urgency = props => {
  const classes = useStyles();
  const { state, handleChange, capacity, isOccupation, urgencyDateChange, addPatientByEps, deletePatientByEps } = props;
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Urgencias</h4>
            </CardText>
          </CardHeader>
          <CardBody className={classes.cardBodyJustify}>
            <GridContainer>
              {urgencyFields.map(resource => {
                if (!isOccupation) {
                  return (
                    <GridItem style={{ display: 'flex' }} key={resource.id} xs={12} sm={6} lg={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(state[resource.checkName])}
                            onClick={({ target }) => handleChange(target.name, target.checked)}
                            // checkedIcon={<Check className={classes.checkedIcon} />}
                            // icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              root: classes.root,
                              checked: classes.checked
                            }}
                          />
                        }
                        name={resource.checkName}
                        label={!state[resource.checkName] ? resource.checkLabel : null}
                      />
                      {state[resource.checkName] && (
                        <NumberInput
                          key={resource.id}
                          required
                          placeHolder={resource.qtyLabel}
                          handleChange={handleChange}
                          name={resource.qtyName}
                          value={state[resource.qtyName] || ''}
                          validation={{
                            type: 'min-value',
                            number: 0
                          }}
                        />
                      )}
                    </GridItem>
                  );
                }
                if (isOccupation && capacity[resource.checkName]) {
                  return (
                    <Tooltip
                      key={resource.id}
                      classes={{
                        tooltip: classes.tooltip
                      }}
                      title={`${capacity[resource.qtyName]} unidades ofertadas`}
                    >
                      <GridItem style={{ display: 'flex' }} key={resource.id} xs={12} sm={6} lg={4}>
                        {state[resource.checkName] && (
                          <NumberInput
                            key={resource.id}
                            placeHolder={resource.qtyLabel}
                            handleChange={handleChange}
                            name={resource.qtyName}
                            value={state[resource.qtyName]}
                            validation={{
                              type: 'min-value',
                              number: 0
                            }}
                          />
                        )}
                        <NumberInput
                          key={resource.id}
                          required
                          placeHolder={resource.qtyLabelOccupation}
                          handleChange={handleChange}
                          name={resource.qtyName}
                          value={state[resource.qtyName]}
                          validation={{
                            type: 'min-value',
                            number: 0
                          }}
                        />
                      </GridItem>
                    </Tooltip>
                  );
                }
                return null;
              })}

              {isOccupation ? (
                <React.Fragment>
                  {otherOccupationUrgencyFields.map(resource => (
                    <GridItem key={resource.id} xs={12} sm={6} lg={4}>
                      <NumberInput
                        key={resource.id}
                        required
                        placeHolder={resource.label}
                        handleChange={handleChange}
                        name={resource.name}
                        value={state[resource.name]}
                        validation={{
                          type: 'min-value',
                          number: 0
                        }}
                      />
                    </GridItem>
                  ))}
                  <GridItem xs={12} sm={6} md={4}>
                    <InputLabel error={!state.maxTimeToBeAttendedTime} style={{ fontSize: '14px' }}>
                      Tiempo máximo en sala de espera antes de pasar a urgencias
                    </InputLabel>
                    <FormControl required className={classes.selectFormControl} fullWidth>
                      <Datetime
                        viewMode="time"
                        dateFormat={false}
                        timeFormat="H:mm"
                        onChange={date => urgencyDateChange('maxTimeToBeAttendedTime', date)}
                        value={state.maxTimeToBeAttendedTime}
                        closeOnSelect
                        inputProps={{
                          placeholder: 'Ingresa el tiempo en horas',
                          required: true,
                          readOnly: true
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <InputLabel error={!state.maxTimeAtEmergencyToHospitalizationTime} style={{ fontSize: '14px' }}>
                      Tiempo del paciente más antiguo en urgencias
                    </InputLabel>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <GridItem xs={6}>
                        <NumberInput
                          required
                          placeHolder="días"
                          handleChange={handleChange}
                          name="maxTimeAtEmergencyToHospitalizationDays"
                          value={
                            `${state.maxTimeAtEmergencyToHospitalizationDays}` !== 'undefined'
                              ? `${state.maxTimeAtEmergencyToHospitalizationDays}`
                              : ''
                          }
                          validation={{
                            type: 'min-value',
                            number: 0
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6}>
                        <div style={{ paddingTop: '17px' }}>
                          <Datetime
                            viewMode="time"
                            dateFormat={false}
                            timeFormat="H:mm"
                            onChange={date => urgencyDateChange('maxTimeAtEmergencyToHospitalizationTime', date)}
                            value={state.maxTimeAtEmergencyToHospitalizationTime}
                            closeOnSelect
                            inputProps={{
                              placeholder: 'horas',
                              required: true,
                              readOnly: true
                            }}
                          />
                        </div>
                      </GridItem>
                    </div>
                  </GridItem>
                  <GridItem xs={12}>
                    <PatientByEps
                      placeholder="Pacientes con orden de remisión"
                      value={{
                        id: state.patientsReferredToOtherHospitalEps,
                        qty: state.patientsReferredToOtherHospitalQty
                      }}
                      name="patientsReferredToOtherHospital"
                      handleChange={handleChange}
                      epsList={state.epsList}
                      addPatientByEps={addPatientByEps}
                    />
                    {state.patientsReferredToOtherHospital &&
                      state.patientsReferredToOtherHospital.byEps.map(patient => {
                        const selectedEps = state.epsList.find(eps => patient.id === eps.id);
                        return (
                          <Chip
                            key={selectedEps.id}
                            avatar={<Avatar>{patient.qty}</Avatar>}
                            label={selectedEps.name}
                            className={classes.chip}
                            style={{ margin: '1px' }}
                            onDelete={() => deletePatientByEps(patient.id, 'patientsReferredToOtherHospital')}
                            deleteIcon={
                              <Icon className={classes.cancelIcon} color="error">
                                cancel
                              </Icon>
                            }
                          />
                        );
                      })}
                  </GridItem>
                  <GridItem xs={12}>
                    <PatientByEps
                      placeholder="Pacientes esperando traslado"
                      value={{
                        id: state.patientsWaitingReferralTransportEps,
                        qty: state.patientsWaitingReferralTransportQty
                      }}
                      name="patientsWaitingReferralTransport"
                      handleChange={handleChange}
                      epsList={state.epsList}
                      addPatientByEps={addPatientByEps}
                    />
                    {state.patientsWaitingReferralTransport &&
                      state.patientsWaitingReferralTransport.byEps.map(patient => {
                        const selectedEps = state.epsList.find(eps => patient.id === eps.id);
                        return (
                          <Chip
                            key={selectedEps.id}
                            avatar={<Avatar>{patient.qty}</Avatar>}
                            label={selectedEps.name}
                            className={classes.chip}
                            style={{ margin: '1px' }}
                            onDelete={() => deletePatientByEps(patient.id, 'patientsWaitingReferralTransport')}
                            deleteIcon={
                              <Icon className={classes.cancelIcon} color="error">
                                cancel
                              </Icon>
                            }
                          />
                        );
                      })}
                  </GridItem>
                </React.Fragment>
              ) : (
                <></>
                // <React.Fragment>
                //   <GridItem xs={12} sm={6} md={4}>
                //     <NumberInput
                //       required
                //       placeHolder="Capacidad de expansión camillas"
                //       handleChange={handleChange}
                //       name="expansionBedsUrg"
                //       value={`${state.expansionBedsUrg}` !== 'undefined' ? `${state.expansionBedsUrg}` : ''}
                //       validation={{
                //         type: 'min-value',
                //         number: 0
                //       }}
                //     />
                //   </GridItem>
                //   <GridItem xs={12} sm={6} md={4}>
                //     <NumberInput
                //       required
                //       placeHolder="Capacidad de expansión sillas"
                //       handleChange={handleChange}
                //       name="expansionChairsUrg"
                //       value={`${state.expansionChairsUrg}` !== 'undefined' ? `${state.expansionChairsUrg}` : ''}
                //       validation={{
                //         type: 'min-value',
                //         number: 0
                //       }}
                //     />
                //   </GridItem>
                // </React.Fragment>
              )}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

Urgency.propTypes = {
  capacity: PropTypes.object,
  // classes: PropTypes.object,
  state: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  addPatientByEps: PropTypes.func,
  deletePatientByEps: PropTypes.func,
  urgencyDateChange: PropTypes.func,
  isOccupation: PropTypes.bool
};

Urgency.defaultProps = {
  capacity: {},
  // classes: {},
  state: {},
  isOccupation: false
};

export default Urgency;
