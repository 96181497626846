import React from 'react';
import { Route } from 'react-router-dom';
import { Subscribe } from 'unstated';
import AuthContainer from 'containers-state/auth';

const PrivateRoute = props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer =>
      AuthContainer.state.authenticated ? (
        <Route path={props.path} component={props.component} key={props.path} />
      ) : null
    }
  </Subscribe>
);

export default PrivateRoute;
