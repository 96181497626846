import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import apiClient from 'config/apiClient';
import AuthContainer from 'containers-state/auth';
import { Subscribe } from 'unstated';
import queryString from 'query-string';

// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';

// Components
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';

// Assets
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import { urlApi } from '../../config/app';

class DownloadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloaded: false
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.getFile(location.search);
  }

  getFile = qString => {
    const qS = queryString.parse(qString);
    apiClient.SEM.get(`${urlApi}/file/${qS['filename']}?referralId=${qS.referralId}`, {
      timeout: 30000
    })
      .then(response => {
        window.open(response.data.data.url);
      })
      .then(() => {
        this.setState({
          downloaded: true
        });
      })
      .catch(error => {
        alert.show(`Se ha producido un error al descargar el archivo.`, {
          type: 'error',
          timeout: 5000
        });
        console.error(`Se ha producido un error al descargar el archivo. ${error}`);
      });
  };

  render() {
    const { classes, location } = this.props;
    const { downloaded } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>Descarga de archivos</h3>
            </CardHeader>
            {downloaded ? (
              <CardBody>
                <GridItem xs={12}>
                  <h5>Se ha descargado el archivo, si desea descargarlo de nuevo presione el botón</h5>
                </GridItem>
                <GridItem container justify="center">
                  <Button style={{ marginTop: '20px' }} color="info" onClick={() => this.getFile(location.search)}>
                    Descargar de nuevo
                  </Button>
                </GridItem>
              </CardBody>
            ) : (
              <CardBody>
                <GridItem xs={12}>
                  <h5>Si el archivo no se decarga inmediatamente presione el boton.</h5>
                </GridItem>
                <GridItem container justify="center">
                  <Button style={{ marginTop: '20px' }} color="info" onClick={() => this.getFile(location.search)}>
                    Descargar
                  </Button>
                </GridItem>
              </CardBody>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

DownloadFile.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.string.isRequired
};

const DownloadFileComponent = withAlert()(withStyles(dashboardStyle)(DownloadFile));

export default props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer => <DownloadFileComponent {...props} roles={AuthContainer.state.roles} />}
  </Subscribe>
);
