import format from 'date-fns/format';

export const defaultColumns = [
  {
    name: 'createdBy',
    title: 'IPS',
    getCellValue: row => {
      if (!row) return undefined;
      return `${((row.hospitalaryCapacity || {}).ips || {}).name || 'sin nombre'}|${
        (row.hospitalaryCapacity || {}).ipsId
      }`;
    }
  },
  {
    name: 'updatedAt',
    title: 'Recursos',
    getCellValue: row => {
      if (!row) return undefined;
      return row.updatedAt ? format(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm:ss') : ' NO Asignada';
    }
  },
  {
    name: 'createdAtBlood',
    title: 'Banco de sangre',
    getCellValue: row => {
      const { hospitalaryCapacity, bloodResources = {} } = row;
      if (!row) return undefined;
      if (hospitalaryCapacity.services.bloodBank.enable && bloodResources.createdAt) {
        return format(new Date(bloodResources.createdAt), 'YYYY-MM-DD HH:mm:ss');
      }
      return 'NA';
    }
  },
  {
    name: 'camillasAdult',
    title: 'Camillas',
    getCellValue: row => ((((row.units || {}).emergency || {}).adult || {}).general || {}).beds || 0
  },
  {
    name: 'sillasAdult',
    title: 'Sillas',
    getCellValue: row => ((((row.units || {}).emergency || {}).adult || {}).therapeuticSupport || {}).chairs || 0
  },
  {
    name: 'salaAnimAdult',
    title: 'Sala Reanimación',
    getCellValue: row => ((((row.units || {}).emergency || {}).adult || {}).reanimation || {}).beds || 0
  },
  {
    name: 'camillasPediatricas',
    title: 'Camillas',
    getCellValue: row => ((((row.units || {}).emergency || {}).pediatric || {}).general || {}).beds || 0
  },
  {
    name: 'sillasPediatricas',
    title: 'Sillas',
    getCellValue: row => ((((row.units || {}).emergency || {}).pediatric || {}).therapeuticSupport || {}).chairs || 0
  },
  {
    name: 'salaAnimPediatricas',
    title: 'Sala Reanimación',
    getCellValue: row => ((((row.units || {}).emergency || {}).pediatric || {}).reanimation || {}).beds || 0
  },
  {
    name: 'porcSaturacion',
    title: '% de Saturación de Urgencias (NEDOCS)',
    getCellValue: row => ((row || {}).nedocs || {}).score || 0
  },
  {
    name: 'uciAdultos',
    title: 'Adultos',
    getCellValue: row => ((((row.units || {}).hospitalization || {}).adult || {}).uci || {}).beds || 0
  },
  {
    name: 'uciPediatrica',
    title: 'Pediátrica',
    getCellValue: row => ((((row.units || {}).hospitalization || {}).pediatric || {}).uci || {}).beds || 0
  },
  {
    name: 'uciNeonatal',
    title: 'Neonatal',
    getCellValue: row => ((((row.units || {}).hospitalization || {}).neonatal || {}).uci || {}).beds || 0
  },
  {
    name: 'uceAdultos',
    title: 'Adultos',
    getCellValue: row => ((((row.units || {}).hospitalization || {}).adult || {}).uce || {}).beds || 0
  },
  {
    name: 'ucePediatrica',
    title: 'Pediátrica',
    getCellValue: row => ((((row.units || {}).hospitalization || {}).pediatric || {}).uce || {}).beds || 0
  },
  {
    name: 'uceNeonatal',
    title: 'Neonatal',
    getCellValue: row => ((((row.units || {}).hospitalization || {}).neonatal || {}).uce || {}).beds || 0
  },
  {
    name: 'pediatricGeneralHospBeds',
    title: 'Pediatría',
    getCellValue: row => ((((row.units || {}).hospitalization || {}).pediatric || {}).general || {}).beds || 0
  },
  {
    name: 'GeneralHospBeds',
    title: 'Adultos',
    getCellValue: row => ((((row.units || {}).hospitalization || {}).adult || {}).general || {}).beds || 0
  },
  {
    name: 'obstetricHospBeds',
    title: 'Obstetricia',
    getCellValue: row => ((((row.units || {}).hospitalization || {}).adult || {}).obstetric || {}).beds || 0
  },
  {
    name: 'rayosx',
    title: 'Rayos X',
    getCellValue: row => (((row.services || {}).xrays || {}).available ? 'Si' : 'No')
  },
  {
    name: 'tac',
    title: 'TAC',
    getCellValue: row => (((row.services || {}).tomograph || {}).available ? 'Si' : 'No')
  },
  {
    name: 'angiografia',
    title: 'Angiografia',
    getCellValue: row => (((row.services || {}).angiograph || {}).available ? 'Si' : 'No')
  },
  {
    name: 'rmn',
    title: 'RMN',
    getCellValue: row => (((row.services || {}).magneticResonance || {}).available ? 'Si' : 'No')
  }
];

export const defaultColumnExtensions = [
  {
    columnName: 'createdBy',
    width: 250,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'updatedAt',
    width: 90,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'createdAtBlood',
    width: 90,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'camillasAdult',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'sillasAdult',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'salaAnimAdult',
    width: 90,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'camillasPediatricas',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'sillasPediatricas',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'salaAnimPediatricas',
    width: 90,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'porcSaturacion',
    width: 150,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'uciAdultos',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'uciPediatrica',
    width: 80,
    align: 'center'
  },
  {
    columnName: 'uciNeonatal',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'uceAdultos',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'ucePediatrica',
    width: 80,
    align: 'center'
  },
  {
    columnName: 'uceNeonatal',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'pediatricGeneralHospBeds',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'GeneralHospBeds',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'obstetricHospBeds',
    width: 80,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'rayosx',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'tac',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'angiografia',
    width: 80,
    align: 'center'
  },
  {
    columnName: 'rmn',
    width: 70,
    align: 'center'
  }
];

export const defaultColumnBands = [
  {
    title: 'Urgencias Adultos',
    children: [{ columnName: 'camillasAdult' }, { columnName: 'sillasAdult' }, { columnName: 'salaAnimAdult' }]
  },
  {
    title: 'Última actualización',
    children: [{ columnName: 'updatedAt' }, { columnName: 'createdAtBlood' }]
  },
  {
    title: 'Urgencias Pediátricas',
    children: [
      { columnName: 'camillasPediatricas' },
      { columnName: 'sillasPediatricas' },
      { columnName: 'salaAnimPediatricas' }
    ]
  },
  {
    title: 'Hospitalización',
    children: [
      { columnName: 'pediatricGeneralHospBeds' },
      { columnName: 'GeneralHospBeds' },
      { columnName: 'obstetricHospBeds' }
    ]
  },
  {
    title: 'UCI',
    children: [{ columnName: 'uciAdultos' }, { columnName: 'uciPediatrica' }, { columnName: 'uciNeonatal' }]
  },
  {
    title: 'UCE',
    children: [{ columnName: 'uceAdultos' }, { columnName: 'ucePediatrica' }, { columnName: 'uceNeonatal' }]
  }
];

export const defaultTotalSummaryItems = [
  { columnName: 'camillasAdult', type: 'sum' },
  { columnName: 'sillasAdult', type: 'sum' },
  { columnName: 'salaAnimAdult', type: 'sum' },
  { columnName: 'camillasPediatricas', type: 'sum' },
  { columnName: 'sillasPediatricas', type: 'sum' },
  { columnName: 'salaAnimPediatricas', type: 'sum' },
  { columnName: 'uciAdultos', type: 'sum' },
  { columnName: 'uciPediatrica', type: 'sum' },
  { columnName: 'uciNeonatal', type: 'sum' },
  { columnName: 'pediatricGeneralHospBeds', type: 'sum' },
  { columnName: 'uceAdultos', type: 'sum' },
  { columnName: 'ucePediatrica', type: 'sum' },
  { columnName: 'uceNeonatal', type: 'sum' },
  { columnName: 'GeneralHospBeds', type: 'sum' },
  { columnName: 'obstetricHospBeds', type: 'sum' },
  { columnName: 'porcSaturacion', type: 'avg' }
];

export const defaultCurrencyColumns = ['porcSaturacion'];

export const data = [];
