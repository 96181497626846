import React, { Component } from 'react';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  headButtons: {
    padding: 0
  },
  modalFloat: {
    position: 'absolute',
    zIndex: '99',
    // width: '80%',
    maxWidth: '500px',
    top: '100px',
    right: '60px;'
  },
  modalHead: {
    background: '#2bc5a3',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    padding: '0.3em',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: 44
  },
  modalTitle: {
    paddingTop: 10
  },
  modalHeadButtons: {
    position: 'absolute',
    top: 'inherit',
    right: '10px',
    zIndex: '9999',
    paddingTop: 5
  },
  modalBody: {
    maxHeight: '400px',
    overflow: 'auto',
    background: '#eee'
  },
  showBody: {
    position: 'relative',
    zIndex: '999'
  },
  hidebody: {
    display: 'none'
  }
});
class WrapperFloat extends Component {
  state = {
    showBody: true
  };

  handleDesplegar = () => {
    this.setState(prevState => ({ showBody: !prevState.showBody }));
  };

  render() {
    const { title, children, onDeleteModal, classes } = this.props;
    const { showBody } = this.state;
    return (
      <Draggable cancel="div.div-cancel" enableUserSelectHack={false}>
        <div id="modal-float" className={classes.modalFloat}>
          <div id="info_panel_head" className={classes.modalHead}>
            <strong className={classes.modalTitle}>{`${title}`}</strong>

            <div className={classes.modalHeadButtons}>
              <IconButton className={classes.headButtons} aria-label="Delete" onClick={onDeleteModal}>
                <Clear fontSize="small" />
              </IconButton>
            </div>
          </div>

          <div className={`div-cancell ${showBody ? classes.showBody : classes.hidebody} ${classes.modalBody}`}>
            <div id="info_panel_body" className="info_panel_body no-cursor">
              {children}
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

WrapperFloat.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  onDeleteModal: PropTypes.func,
  classes: PropTypes.object
};
WrapperFloat.defaultProps = { title: 'Información' };

export default withStyles(styles)(WrapperFloat);
