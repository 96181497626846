import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Subscribe } from 'unstated';
import PropTypes from 'prop-types';

import LeftBackground from 'assets/img/Covid/LeftSidebarCovid.png';
import Divider from '@material-ui/core/Divider';

// Components
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import NxSpinner from 'nxComponents/NxSpinner/NxSpinner';
import Map from 'nxComponents/CovidMap/CovidMap';
import NxNavigation from 'nxComponents/NxNavigation/NxNavigation';

// Assets
import { covidBlueColor } from 'assets/jss/material-dashboard-pro-react';
import netux from 'assets/img/Covid/Netux.png';
import redPin from 'assets/img/Covid/red-pin.png';
import grayPin from 'assets/img/Covid/gray-pin.png';
import BackgroundCovid from 'assets/img/BackgroundCovid.png';

// Others
import AuthContainer from 'containers-state/auth';
import apiClient from 'config/apiClient';
import { urlApi } from 'config/app';
import VirusIcon from 'Icons/Virus2';
import { useInterval } from 'customHooks/useInterval';
import { navigationItems } from 'utils/covid';

const useStyles = makeStyles(theme => ({
  covid: {
    fontFamily: "'Lato', sans-serif",
    width: '100vw',
    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.81)),url(${BackgroundCovid}) no-repeat center top`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3vh 20vw 3vh 3vh'
  },
  cardLogo: {
    width: '20vw',
    height: '25vh',
    position: 'absolute',
    top: -30,
    borderRadius: '0 0 0 60px',
    right: 0
  },
  cardBodyLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  alcaldiaLogo: {
    maxWidth: '13vw',
    height: 'auto'
  },
  leftContainer: {
    padding: '0 !important',
    textAlign: 'center',
    height: '100vh',
    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url(${LeftBackground}) no-repeat center top`,
    backgroundSize: 'cover',
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  rightContainer: {
    position: 'absolute',
    right: 0,
    paddingTop: '30vh !important',
    textAlign: 'center',
    height: '100vh',
    backgroundSize: 'cover',
    color: 'white',
    fontWeight: 'bold'
  },
  ipsContainer: {
    maxHeight: '53vh',
    background: 'rgba(0, 0, 0, 0.48)',
    overflowY: 'scroll',
    padding: '0 20px'
  },
  leftTitle: {
    color: covidBlueColor,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2.4em !important'
    }
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5em !important'
    }
  },
  leftSubitle: {
    margin: 0,
    padding: '10px 5px',
    background: 'rgba(0, 0, 0, 0.9)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.8em !important'
    }
  },
  confirmed: {
    padding: '0 10px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: '#F1F1F1',
    color: '#F00000',
    borderRadius: 40,
    width: '60%',
    margin: '15px auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.6em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2.4em !important',
      height: 60
    }
  },
  confirmedText: {
    margin: 0,
    color: '#F00000',
    padding: 6,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5em !important'
    }
  },
  confirmedTotal: {
    margin: 0,
    color: '#F00000',
    padding: 6,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1em !important'
    }
  },
  roundItem: {
    margin: '5px 0',
    display: 'flex',
    alignItems: 'center'
  },
  roundItemPointer: {
    cursor: 'pointer',
    margin: '5px 0',
    display: 'flex',
    alignItems: 'center'
  },
  activeIps: {
    background: '#ffffff1f',
    borderRadius: 40
  },
  point: {
    width: '30%'
  },
  roundLeft: {
    flex: '1 0 0',
    color: '#F00000',
    padding: '5px 15px',
    display: 'flex',
    alignItems: 'center'
  },
  roundRight: {
    textAlign: 'start',
    flex: '4 0 0'
  },
  rightText: {
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5em !important'
    }
  },
  ipsText: {
    color: '#808080',
    margin: 0,
    fontSize: '0.8em',
    padding: '0 10px',
    whiteSpace: 'pre-line',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.3em !important',
      padding: 15
    }
  },
  numberText: {
    margin: 0,
    color: 'white',
    paddingLeft: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.3em !important',
      padding: 15
    }
  },
  virusCard: {
    borderRadius: '40px 40px 0 40px',
    margin: '10px 0',
    [theme.breakpoints.up('xl')]: {
      margin: '20px 0'
    }
  },
  virusCardBody: {
    padding: '5px 20px',
    [theme.breakpoints.up('xl')]: {
      padding: '25px 40px'
    }
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  noPadding: {
    padding: '0 !important'
  },
  netuxImage: {
    width: '100px',
    margin: 15,
    height: 'auto',
    [theme.breakpoints.up('xl')]: {
      width: '180px'
    }
  },
  fab: {
    zIndex: 100,
    marginTop: 30,
    marginLeft: 30,
    position: 'absolute',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'black'
    },
    [theme.breakpoints.up('xl')]: {
      height: 80,
      width: 80,
      marginTop: 40
    }
  }
}));

const setClassName = (classes, ips, selectedIps) => {
  const className = [];
  className.push(ips.cases.covid > 0 ? classes.roundItemPointer : classes.roundItem);
  if (ips.ips === selectedIps.ips) {
    className.push(classes.activeIps);
  }
  return className.join(' ');
};

const orderResponse = response => {
  return {
    ips: response.data.data.ips.sort((a, b) => b.cases.covid - a.cases.covid),
    total: response.data.data.total
  };
};

const getAllResources = async () => {
  try {
    const response = await apiClient.SEM.get(`${urlApi}/ips/covid/resources/map/all`);
    return orderResponse(response);
  } catch (error) {
    return error;
  }
};

const getResourcesAsync = async cb => {
  const ListResources = await getAllResources();
  cb(ListResources);
};

function CovidMap(props) {
  const [resources, setResources] = useState({ ips: [] });
  const [zoom, setZoom] = useState(13);
  const [ips, setIps] = useState({});
  const [center, setCenter] = useState({
    lat: 6.235925,
    lng: -75.57513
  });
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const { history, roles } = props;

  useEffect(() => {
    window.Intercom('update', {
      hide_default_launcher: true
    });
    async function getResources() {
      const ListResources = await getAllResources();
      setResources(ListResources);
      setLoading(false);
    }
    getResources();
  }, []);

  useInterval(() => {
    getResourcesAsync(setResources);
  }, 90000);

  const handleClick = selectedIps => {
    const { lat, lng, cases } = selectedIps;
    const isCurrentIps = ips.ips === selectedIps.ips;
    if (cases.covid > 0) {
      setIps(isCurrentIps ? {} : selectedIps);
      setZoom(isCurrentIps ? 13 : 15);
      setCenter({ lat, lng });
    }
  };

  if (!roles.includes('CRUE')) {
    history.push('/');
  }

  return (
    <>
      <NxSpinner loading={loading} />
      <GridContainer>
        <GridItem xs={3} className={classes.leftContainer}>
          {resources.total && (
            <div>
              <h3 className={classes.leftTitle}>COVID-19</h3>
              <p className={classes.subtitle}>Total de casos confirmados en IPS</p>
              <div className={classes.confirmed}>
                <VirusIcon className={classes.confirmedText} color="#F00000" size={45} />|
                <p className={classes.confirmedTotal}>{ips.cases ? ips.cases.covid : resources.total.cases.covid}</p>
              </div>
            </div>
          )}
          <div>
            <p className={classes.leftSubitle}>Casos confirmados por IPS</p>
            <div className={classes.ipsContainer}>
              {resources.ips &&
                resources.ips.map((rips, i) => (
                  <>
                    <div
                      role="presentation"
                      key={`${rips.ips} ${i}`}
                      className={setClassName(classes, rips, ips)}
                      onClick={() => handleClick(rips)}
                    >
                      <div className={classes.roundLeft}>
                        <img className={classes.point} alt="point" src={ips.ips === rips.ips ? redPin : grayPin} />
                        <p className={classes.numberText}>{rips.cases.covid}</p>
                      </div>
                      <div className={classes.roundRight}>
                        <p className={classes.ipsText}>{rips.ips.toLowerCase()}</p>
                      </div>
                    </div>
                    <hr style={{ borderTop: '1px solid #707070', margin: 2 }} />
                  </>
                ))}
            </div>
          </div>
          <div>
            <img alt="netux" src={netux} className={classes.netuxImage} />
          </div>
        </GridItem>
        <GridItem xs={9} className={classes.noPadding}>
          <div className={classes.covid}>
            <GridItem xs={12} sm={9} style={{ marginRight: '25vw', textAlign: 'right' }}>
              <NxNavigation items={navigationItems(history)} value={1} />
            </GridItem>
          </div>
          <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAHG3exlL7Wc_VCl8w4RBRlyfcHt_09ylY&v=3.exp&&libraries=places,visualization"
            center={center}
            zoom={zoom}
            ipsList={resources.ips}
            selectedIps={ips}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `85vh`, overflow: 'auto' }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </GridItem>
        {resources.total && (
          <div className={classes.rightContainer}>
            <Card className={classes.virusCard}>
              <CardBody className={classes.virusCardBody}>
                <p className={classes.rightText}>Casos UCI</p>
                <div className={classes.flex}>
                  <VirusIcon className={classes.confirmedText} color="#F00000" size={39.1} />
                  <Divider orientation="vertical" flexItem />
                  <p className={classes.confirmedText}>
                    {ips.hospitalization ? ips.hospitalization.uci.covid : resources.total.hospitalization.uci.covid}
                  </p>
                </div>
              </CardBody>
            </Card>
            <Card className={classes.virusCard}>
              <CardBody className={classes.virusCardBody}>
                <p className={classes.rightText}>Casos UCE</p>
                <div className={classes.flex}>
                  <VirusIcon className={classes.confirmedText} color="#F00000" size={39.1} />
                  <Divider orientation="vertical" flexItem />
                  <p className={classes.confirmedText}>
                    {ips.hospitalization ? ips.hospitalization.uce.covid : resources.total.hospitalization.uce.covid}
                  </p>
                </div>
              </CardBody>
            </Card>
            <Card className={classes.virusCard}>
              <CardBody className={classes.virusCardBody}>
                <p className={classes.rightText}>Casos en Hospitalización</p>
                <div className={classes.flex}>
                  <VirusIcon className={classes.confirmedText} color="#F00000" size={39.1} />
                  <Divider orientation="vertical" flexItem />
                  <p className={classes.confirmedText}>
                    {ips.hospitalization
                      ? ips.hospitalization.general.covid
                      : resources.total.hospitalization.general.covid}
                  </p>
                </div>
              </CardBody>
            </Card>
            <Card className={classes.virusCard}>
              <CardBody className={classes.virusCardBody}>
                <p className={classes.rightText}>Casos en Urgencias</p>
                <div className={classes.flex}>
                  <VirusIcon className={classes.confirmedText} color="#F00000" size={39.1} />
                  <Divider orientation="vertical" flexItem />
                  <p className={classes.confirmedText}>
                    {ips.emergency ? ips.emergency.general.covid : resources.total.emergency.general.covid}
                  </p>
                </div>
              </CardBody>
            </Card>
          </div>
        )}
        <Card className={classes.cardLogo}>
          <CardBody className={classes.cardBodyLogo}>
            <img
              className={classes.alcaldiaLogo}
              alt="Logo-alcaldía"
              src="https://www.medellin.gov.co/irj/go/km/docs/etc/PortalMedellin/images/medellin.png"
            />
          </CardBody>
        </Card>
      </GridContainer>
    </>
  );
}

CovidMap.propTypes = {
  roles: PropTypes.array,
  history: PropTypes.object
};

export default props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer => <CovidMap {...props} roles={AuthContainer.state.roles} />}
  </Subscribe>
);
