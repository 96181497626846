import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';

// @material-ui/core
import FormControl from '@material-ui/core/FormControl';

// Components
import GridItem from 'components/Grid/GridItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const OccupationReport = ({
  initialDate,
  finalDate,
  ipsList,
  ips,
  handleDateChange,
  roles,
  valid,
  handleChange,
  validFinalDate,
  classes
}) => {
  const showAvailableIps = ipsL => {
    return ipsL.map(a => (
      <MenuItem key={a.id} value={a.id}>
        {a.name}
      </MenuItem>
    ));
  };

  return (
    <div>
      <div style={{ display: 'flex', marginTop: '15px' }}>
        <GridItem xs={12} sm={6}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel} error={!initialDate}>
            Fecha inicial *
          </InputLabel>
          <FormControl fullWidth>
            <Datetime
              viewMode="years"
              dateFormat="YYYY-MMM-DD"
              timeFormat={false}
              value={initialDate}
              isValidDate={idate => valid(idate)}
              closeOnSelect
              inputProps={{
                placeholder: 'Fecha inicial',
                required: true,
                readOnly: true
              }}
              onChange={date => handleDateChange(date, 'initialDate')}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel} error={!finalDate}>
            Fecha final *
          </InputLabel>
          <FormControl fullWidth disabled={!initialDate}>
            <Datetime
              viewMode="years"
              dateFormat="YYYY-MMM-DD"
              timeFormat={false}
              value={finalDate}
              isValidDate={date => validFinalDate(date, initialDate)}
              closeOnSelect
              inputProps={{
                placeholder: 'Fecha Final',
                required: true,
                readOnly: true,
                disabled: !initialDate
              }}
              onChange={date => handleDateChange(date, 'finalDate')}
            />
          </FormControl>
        </GridItem>
      </div>
      <div style={{ display: 'flex' }}>
        <GridItem xs={12} sm={6}>
          <FormControl
            disabled={!ipsList}
            fullWidth
            className={classes.selectFormControl}
            style={{ marginTop: '10px' }}
          >
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              IPS
            </InputLabel>
            <Select
              disabled={roles.includes('IPS')}
              classes={{ select: classes.select }}
              value={ips || ''}
              onChange={event => handleChange(event)}
              fullWidth
              inputProps={{
                name: 'ips',
                id: 'ips'
              }}
            >
              {showAvailableIps(ipsList, roles)}
            </Select>
          </FormControl>
        </GridItem>
      </div>
    </div>
  );
};

OccupationReport.propTypes = {
  initialDate: PropTypes.instanceOf(Date),
  finalDate: PropTypes.instanceOf(Date),
  ipsList: PropTypes.array.isRequired,
  ips: PropTypes.number,
  handleDateChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  valid: PropTypes.func.isRequired,
  validFinalDate: PropTypes.func.isRequired,
  classes: PropTypes.object,
  roles: PropTypes.array
};

export default OccupationReport;

// const OccupationReportComponent = withStyles(dashboardStyle)(OccupationReport);

// export default OccupationReportComponent;
