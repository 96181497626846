export function isCrue(roles) {
  return ['CRUE', 'CRUE_REFERRAL_MANAGER', 'CRUE_RESOURCES_AUDITOR'].some(r => roles.includes(r));
}

export function isEps(roles) {
  return ['EPS', 'EPS_REFERRAL_MANAGER'].some(r => roles.includes(r));
}

export function isIps(roles) {
  return ['IPS_BLOOD_RESOURCES', 'IPS_HOSPITALARY_RESOURCES', 'IPS_REFERRAL_REPORTER'].some(r => roles.includes(r));
}

export function isAmbulanceEntity(roles) {
  return ['TRANSPORT_REFERRAL_MANAGER'].some(r => roles.includes(r));
}
