import React from 'react';
import PropTypes from 'prop-types';
import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui';

export const BandCellBase = props => {
  const { classes, children } = props;
  return (
    <TableBandHeader.Cell {...props} className={classes.cell}>
      <strong>{children}</strong>
    </TableBandHeader.Cell>
  );
};

BandCellBase.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any
};

// const BandCell = withStyles(cellStyles, { name: 'BandCell' })(BandCellBase);
