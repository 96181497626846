export const addressRoadTypes = [
  { id: 1, name: 'Autopista', code: 'AUT' },
  { id: 2, name: 'Avenida', code: 'AV' },
  { id: 3, name: 'Avenida Calle', code: 'AV-CLL' },
  { id: 4, name: 'Avenida Carrera', code: 'AV-CRR' },
  { id: 5, name: 'Bulevar', code: 'BV' },
  { id: 6, name: 'Calle', code: 'CLL' },
  { id: 7, name: 'Carrera', code: 'CRR' },
  { id: 8, name: 'Circular', code: 'CIR' },
  { id: 9, name: 'Trasversal', code: 'TRAS' },
  { id: 10, name: 'Trasversal Superior', code: 'TRAS-SUP' },
  { id: 11, name: 'Trasversal Inferior', code: 'TRAS-INF' },
  { id: 12, name: 'Diagonal', code: 'DIAG' },
  { id: 13, name: 'Circunvalar', code: 'CIR' },
  { id: 14, name: 'Kilómetro', code: 'KM' }
];

export const adressOrientations = [
  { id: 1, name: 'Sur', code: 1 },
  { id: 2, name: 'Norte', code: 2 },
  { id: 3, name: 'Oriente', code: 3 },
  { id: 4, name: 'Occidente', code: 4 }
];
