import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

// Others
import { diagnosticAids, diagnosticAidsReason } from 'variables/resources';

// core components
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CustomInput from 'components/CustomInput/CustomInput';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';

//  Assets
import styles from '../iosStyleSwitch';

const DiagnosticAids = props => {
  const { classes, state, handleChange, capacity, isOccupation } = props;
  const switchLabel = isOccupation ? 'Disponible' : 'Ofertado';
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Ayudas diagnósticas</h4>
            </CardText>
          </CardHeader>
          <CardBody className={classes.cardBodyJustify}>
            <GridContainer>
              {diagnosticAids.map(resource => {
                if (isOccupation) {
                  if (capacity[resource.checkName]) {
                    return (
                      <GridItem key={resource.id} xs={12} sm={6} md={4} lg={3}>
                        <FormControl component="fieldset">
                          <FormLabel>{resource.checkLabel}</FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  classes={{
                                    switchBase: classes.iOSSwitchBase,
                                    bar: classes.iOSBar,
                                    icon: classes.iOSIcon,
                                    // iconChecked: classes.iOSIconChecked,
                                    checked: classes.iOSChecked
                                  }}
                                  checked={Boolean(state[resource.checkName])}
                                  onClick={({ target }) => handleChange(target.name, target.checked, 'diagnosticAids')}
                                  name={resource.checkName}
                                  color="primary"
                                />
                              }
                              label={state[resource.checkName] ? `${switchLabel}` : `No ${switchLabel}`}
                            />
                          </FormGroup>
                        </FormControl>
                      </GridItem>
                    );
                  }
                } else {
                  return (
                    <GridItem key={resource.id} xs={12} sm={6} md={4} lg={3}>
                      <FormControl component="fieldset">
                        <FormLabel>{resource.checkLabel}</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                classes={{
                                  switchBase: classes.iOSSwitchBase,
                                  bar: classes.iOSBar,
                                  icon: classes.iOSIcon,
                                  // iconChecked: classes.iOSIconChecked,
                                  checked: classes.iOSChecked
                                }}
                                checked={Boolean(state[resource.checkName])}
                                onClick={({ target }) => handleChange(target.name, target.checked, 'diagnosticAids')}
                                name={resource.checkName}
                                color="primary"
                              />
                            }
                            label={state[resource.checkName] ? `${switchLabel}` : `No ${switchLabel}`}
                          />
                        </FormGroup>
                      </FormControl>
                    </GridItem>
                  );
                }
                return null;
              })}

              {/* <GridContainer style={{ padding: '0 12px' }}>
                {diagnosticAids.map(resource => {
                  if (state[resource.checkName] && resource.qtyName) {
                    return (
                      <GridItem key={resource.id} xs={12} sm={6} md={4} lg={3}>
                        <NumberInput
                          placeHolder={resource.qtyLabel}
                          handleChange={qtyHandleChange}
                          name={resource.qtyName}
                          value={state[resource.qtyName]}
                          validation={{
                            type: 'min-value',
                            number: 0
                          }}
                        />
                      </GridItem>
                    );
                  }
                  return null;
                })}
              </GridContainer> */}

              <GridContainer style={{ padding: '0 12px' }}>
                {diagnosticAids.map(resource => {
                  if (capacity[resource.checkName] && !state[resource.checkName]) {
                    return (
                      <GridItem key={resource.id} xs={12} sm={6}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                          <InputLabel error={!state[resource.reasonName]} htmlFor="documentType">
                            {resource.placeholder}
                          </InputLabel>
                          <Select
                            value={state[resource.reasonName] || ''}
                            onChange={({ target }) => handleChange(target.name, target.value, 'diagnosticAids')}
                            inputProps={{
                              name: resource.reasonName,
                              id: resource.reasonName
                            }}
                          >
                            {diagnosticAidsReason.map(reason => (
                              <MenuItem key={reason.id} value={reason.name}>
                                {reason.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {state[resource.reasonName] === 'Otro' && (
                          <CustomInput
                            error={!state[`${resource.checkName}OtherReason`]}
                            labelText="Explique la razón"
                            id={`${resource.checkName}OtherReason`}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: ({ target }) => handleChange(target.name, target.value, 'diagnosticAids'),
                              type: 'text',
                              name: `${resource.checkName}OtherReason`,
                              multiline: true,
                              rowsMax: 2,
                              value: state[`${resource.checkName}OtherReason`]
                            }}
                          />
                        )}
                      </GridItem>
                    );
                  }
                  return null;
                })}
              </GridContainer>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

DiagnosticAids.propTypes = {
  capacity: PropTypes.object,
  classes: PropTypes.object,
  state: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  // qtyHandleChange: PropTypes.func.isRequired,
  isOccupation: PropTypes.bool
};

DiagnosticAids.defaultProps = {
  capacity: {},
  classes: {},
  state: {},
  isOccupation: false
};

export default withStyles(styles)(DiagnosticAids);
