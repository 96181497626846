import React from 'react';
import TableResources from 'nxComponents/Resources/Table/TableResources';

export function Resources() {
  return (
    <div>
      <TableResources />
    </div>
  );
}

export default Resources;
