import React from 'react';
import PropTypes from 'prop-types';

//  Assets
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// material ui icons
import Check from '@material-ui/icons/Check';

// Components
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Checkbox from '@material-ui/core/Checkbox';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberInput from '../NumberInput/NumberInput';

function SearchPerson(props) {
  const { classes, handleChange, handleSearchPerson, document, hasDocument } = props;
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={10} md={8}>
        <Card>
          <CardHeader color="primary">
            <h3 className={classes.cardTitleWhite}>Consultar información de la persona en la BDUA</h3>
          </CardHeader>
          <CardBody>
            <GridItem xs={12}>
              <h5>
                La Base de Datos Única de Afiliados o BDUA te permite realizar una consulta previa de la información de
                la persona. Es importante que una vez se haya consultado la información en la BDUA, se relice una
                comprobación de la información en la central regulación y se hagan las actualizaciones correspondientes.
              </h5>
            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasDocument}
                    tabIndex={-1}
                    onClick={({ target }) => handleChange(target.name, target.checked)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                name="hasDocument"
                label="¿Cuenta con el documento?"
              />
            </GridItem>
            {hasDocument && (
              <GridItem xs={12}>
                <NumberInput
                  handleChange={handleChange}
                  name="document"
                  value={document || ''}
                  placeHolder="Ingresa el documento"
                  styles={classes.formControlFlex}
                  validation={{
                    type: 'min-value',
                    number: 0
                  }}
                />
              </GridItem>
            )}
            <GridItem xs={12}>
              <Button color="info" disabled={!document && hasDocument} onClick={() => handleSearchPerson(document)}>
                {hasDocument ? 'Buscar en la BDUA' : 'Continuar sin consultar'}
              </Button>
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

SearchPerson.propTypes = {
  classes: PropTypes.object,
  hasDocument: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSearchPerson: PropTypes.func.isRequired,
  document: PropTypes.number
};

export default withStyles(validationFormsStyle)(SearchPerson);
