
export const keysToValidate = [
  'firstName',
  'lastName',
  'documentType',
  'document',
  'sex',
  'birthdate',
  'phone',
  'eps',
  'regimeType',
  'careForDiagnosis'
];
