import format from 'date-fns/format';

export const columns = [
  {
    name: 'ips',
    title: 'Medellín',
    getCellValue: row => {
      if (!row) return undefined;
      return `${row.ips || 'sin nombre'}`;
    }
  },
  {
    name: 'updatedAt',
    title: 'Fecha',
    getCellValue: row => {
      if (!row) return undefined;
      return row.updatedAt ? format(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm:ss') : ' NO Asignada';
    }
  },
  {
    name: 'emergencyTotal',
    title: 'Total',
    getCellValue: row => ((row.emergency || {}).general || {}).total || 0
  },
  {
    name: 'emergencyCovid',
    title: 'Covid',
    getCellValue: row => ((row.emergency || {}).general || {}).covid || 0
  },
  {
    name: 'emergencyIra',
    title: 'IRA',
    getCellValue: row => ((row.emergency || {}).general || {}).ira || 0
  },
  {
    name: 'emergencyAvailable',
    title: 'Libre',
    getCellValue: row => ((row.emergency || {}).general || {}).availables || 0
  },
  {
    name: 'uciTotal',
    title: 'Total',
    getCellValue: row => ((row.hospitalization || {}).uci || {}).total || 0
  },
  {
    name: 'uciCovid',
    title: 'Covid',
    getCellValue: row => ((row.hospitalization || {}).uci || {}).covid || 0
  },
  {
    name: 'uciIra',
    title: 'IRA',
    getCellValue: row => ((row.hospitalization || {}).uci || {}).ira || 0
  },
  {
    name: 'uciAvailable',
    title: 'Libre',
    getCellValue: row => ((row.hospitalization || {}).uci || {}).availables || 0
  },
  {
    name: 'uceTotal',
    title: 'Total',
    getCellValue: row => ((row.hospitalization || {}).uce || {}).total || 0
  },
  {
    name: 'uceCovid',
    title: 'Covid',
    getCellValue: row => ((row.hospitalization || {}).uce || {}).covid || 0
  },
  {
    name: 'uceIra',
    title: 'IRA',
    getCellValue: row => ((row.hospitalization || {}).uce || {}).ira || 0
  },
  {
    name: 'uceAvailable',
    title: 'Libre',
    getCellValue: row => ((row.hospitalization || {}).uce || {}).availables || 0
  },
  {
    name: 'hospTotal',
    title: 'Total',
    getCellValue: row => ((row.hospitalization || {}).general || {}).total || 0
  },
  {
    name: 'hospCovid',
    title: 'Covid',
    getCellValue: row => ((row.hospitalization || {}).general || {}).covid || 0
  },
  {
    name: 'hospIra',
    title: 'IRA',
    getCellValue: row => ((row.hospitalization || {}).general || {}).ira || 0
  },
  {
    name: 'hospAvailable',
    title: 'Libre',
    getCellValue: row => ((row.hospitalization || {}).general || {}).availables || 0
  },
  {
    name: 'covidTotal',
    title: 'Covid',
    getCellValue: row => (row.total || {}).covid || 0
  },
  {
    name: 'iraTotal',
    title: 'IRA',
    getCellValue: row => (row.total || {}).ira || 0
  }
];

export const columnExtensions = [
  {
    columnName: 'createdBy',
    width: 300,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'updatedAt',
    width: 120,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'emergencyTotal',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'emergencyCovid',
    width: 75,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'emergencyIra',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'emergencyAvailable',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'uciTotal',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'uciCovid',
    width: 75,
    align: 'center'
  },
  {
    columnName: 'uciIra',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'uciAvailable',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'uceTotal',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'uceCovid',
    width: 75,
    align: 'center'
  },
  {
    columnName: 'uceIra',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'uceAvailable',
    width: 70,
    align: 'center'
  },
  {
    columnName: 'hospTotal',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'hospCovid',
    width: 75,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'hospIra',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'hospAvailable',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'covidTotal',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'iraTotal',
    width: 70,
    align: 'center',
    wordWrapEnabled: true
  }
];

export const columnBands = [
  {
    title: 'Urgencias',
    children: [
      { columnName: 'emergencyTotal' },
      { columnName: 'emergencyCovid' },
      { columnName: 'emergencyIra' },
      { columnName: 'emergencyAvailable' }
    ]
  },
  {
    title: 'Hospitalización',
    children: [
      { columnName: 'hospTotal' },
      { columnName: 'hospCovid' },
      { columnName: 'hospIra' },
      { columnName: 'hospAvailable' }
    ]
  },
  {
    title: 'UCI Adultos',
    children: [
      { columnName: 'uciTotal' },
      { columnName: 'uciCovid' },
      { columnName: 'uciIra' },
      { columnName: 'uciAvailable' }
    ]
  },
  {
    title: 'UCE Adultos',
    children: [
      { columnName: 'uceTotal' },
      { columnName: 'uceCovid' },
      { columnName: 'uceIra' },
      { columnName: 'uceAvailable' }
    ]
  },
  {
    title: 'Total',
    children: [{ columnName: 'covidTotal' }, { columnName: 'iraTotal' }]
  }
];

export const data = [];
export default {
  columns,
  columnExtensions,
  columnBands,
  data
};
