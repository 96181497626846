import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

const getDeviceConfig = width => {
  if (width < 600) {
    return 'xs';
  }
  if (width >= 600 && width < 960) {
    return 'sm';
  }
  if (width >= 960 && width < 1280) {
    return 'md';
  }
  if (width >= 1280 && width < 1920) {
    return 'lg';
  }
  return 'xl';
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
}
export default useBreakpoint;
