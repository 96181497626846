import {useState} from 'react'

const useHandleForm = (callback, Fnvalidate = () => ({}), initialState = {}) => {
  const [inputs, setInputs] = useState(initialState)
  const [errors, setErrors] = useState({})

  const handleSubmit = event => {
    if (event) {
      event.preventDefault()
    }
    const errorsResult = Fnvalidate(inputs)
    if (Object.keys(errorsResult).length === 0) {
      let possiblePromise = callback(inputs)
      let isPromise = possiblePromise instanceof Promise
      if (isPromise) {
        possiblePromise.then(
          response => {
            setInputs(initialState)
            setErrors({})
          },
          error => {},
        )
      } else {
        setInputs(initialState)
        setErrors({})
      }
    } else {
      setErrors(errorsResult)
    }
  }

  const handleInputChange = event => {
    if (event.persist) {
      event.persist()
    }
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}))
  }

  const handleManualChange = (key, value) => {
    setInputs(inputs => ({...inputs, [key]: value}))
  }

  const setInitialState = state => setInputs(state)

  return {
    handleSubmit,
    handleInputChange,
    handleManualChange,
    setInitialState,
    inputs,
    setInputs,
    errors,
  }
}
export default useHandleForm
