import * as React from 'react';

function WomanIconWhiteBackground(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 72 72" {...props}>
      <defs>
        <filter id="prefix__a" x={0} y={0} width={72} height={72} filterUnits="userSpaceOnUse">
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur" />
          <feFlood floodOpacity={0.161} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Grupo 1090">
        <g data-name="Grupo 1071">
          <g filter="url(#prefix__a)">
            <circle data-name="Elipse 222" cx={27} cy={27} r={27} transform="translate(9 6)" fill="#fff" />
          </g>
          <path
            data-name="Trazado 1011"
            d="M47.393 44.971l-1.359-7.023a1.906 1.906 0 00-1.142-1.393l-2.84-1.176a.884.884 0 00-.408-.057c-.013 0-.023-.007-.036-.007a2.22 2.22 0 01-1.12-.305l-.62-.551H43.1a1.856 1.856 0 001.854-1.853v-6.752a8.739 8.739 0 00-17.477 0v6.681a1.856 1.856 0 001.854 1.854h3.834a2.2 2.2 0 01-.358.391l-.128.1a2.25 2.25 0 01-1.386.433c-.029 0-.057.007-.088.01a.6.6 0 00-.243.052c-.009 0-.018 0-.026.005l-2.837 1.175a1.907 1.907 0 00-1.145 1.4l-1.359 7.018a1.987 1.987 0 00-.034.364 1.911 1.911 0 001.907 1.909h18.055a1.91 1.91 0 001.907-1.909 1.889 1.889 0 00-.037-.366z"
            fill="#ff6b7d"
          />
        </g>
      </g>
    </svg>
  );
}

export default WomanIconWhiteBackground;
