// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import apiClient from 'config/apiClient';
import round from 'lodash/round';
import { Subscribe } from 'unstated';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
// core components
import AuthContainer from 'containers-state/auth';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Paper from '@material-ui/core/Paper';
import Button from 'components/CustomButtons/Button';
import NxSpinner from '../NxSpinner/NxSpinner';
import HumanResources from './CapacitySection/HumanResource';
import OutOfService from './CapacitySection/OutOfService';
import NonSurgicalSupport from './CapacitySection/NonSurgicalSupport';
import SupportServices from './CapacitySection/SupportServices';
import DiagnosticAids from './CapacitySection/DiagnosticAids';
import Urgency from './CapacitySection/Urgency';
import Internment from './CapacitySection/Internment';
import CovidForm from './CapacitySection/CovidForm';
import Intensivist from './CapacitySection/Intensivist';

// Others
import { urlApi } from '../../config/app';
import { buildCapacities, buildOccupation, getLastOccupation, buildServiceList } from '../../utils/resources';
import { occupationNedocsKeys, otherOccupationUrgencyFields } from '../../variables/resources';

const DeletePatientModal = React.lazy(() => import('./CapacitySection/DeletePatientModal'), 'default');
const CovidConfirmModal = React.lazy(() => import('./CapacitySection/CovidConfirmModal'), 'delete');

// style for this view
const styles = {
  cardBodyJustify: {
    display: 'flex',
    justifyContentCenter: 'space-between'
  },
  labels: {
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.5',
    fontWeight: 'bold',
    paddingTop: '39px',
    marginRight: '0'
  },
  paperInfo: {
    padding: '15px 8px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  addIcon: {
    padding: '0 10px',
    marginTop: '20px',
    background: '#05BF95',
    color: 'white',
    '&:hover': {
      background: '#05BF95'
    }
  },
  ...validationFormsStyle
};

const comorbiditiesTypes = {
  cancer: {
    enable: false
  },
  diabetes: {
    enable: false
  },
  hypertension: {
    enable: false
  },
  obesity: {
    enable: false
  },
  dyslipidemia: {
    enable: false
  },
  hiv: {
    enable: false
  },
  malnutrition: {
    enable: false
  },
  kidneyFailure: {
    enable: false
  },
  heartFailure: {
    enable: false
  },
  autoimmuneDisease: {
    enable: false
  },
  smoker: {
    enable: false
  },
  alcoholism: {
    enable: false
  },
  epoc: {
    enable: false
  },
  alzheimer: {
    enable: false
  },
  mentalIllness: {
    enable: false
  },
  pregnant: {
    enable: false
  },
  none: {
    enable: false
  }
};

const patientInformationTypes = {
  eps: { name: '' },
  ips: { name: '' },
  department: { name: '' },
  originCity: { name: '' },
  createdBy: { name: '' }
};

const initialState = {
  urgency: {
    patientsReferredToOtherHospital: {
      qty: 0,
      byEps: []
    },
    patientsWaitingReferralTransport: {
      qty: 0,
      byEps: []
    }
  },
  covidPatientInfo: {
    firstName: null,
    middleName: null,
    lastName: null,
    secondLastName: null,
    documentType: null,
    document: null,
    epsId: null,
    patientInformation: {
      patientInformationTypes: { ...patientInformationTypes }
    },
    comorbidities: [],
    originCityId: null,
    originCityObj: {},
    originDepartmentId: null,
    originDepartment: {},
    sex: null,
    age: undefined,
    serviceType: null,
    isCovid: true,
    isIra: false
  },
  iraPatientInfo: {
    firstName: null,
    middleName: null,
    lastName: null,
    secondLastName: null,
    documentType: null,
    document: null,
    epsId: null,
    patientInformation: {
      patientInformationTypes: { ...patientInformationTypes }
    },
    comorbidities: {
      comorbiditiesTypes: {}
    },
    originCityId: null,
    originDepartmentId: null,
    sex: null,
    age: undefined,
    serviceType: null,
    isCovid: false,
    isIra: true
  },
  openModal: false,
  modalKey: undefined,
  modalType: undefined,
  openCovidConfirmModal: false,
  modalId: false,
  modalDocument: null,
  modalDocumentType: null
};

export class Ocupation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentWillMount() {
    this.getLastOccupation();
    this.getAllEps();
    this.getMunicipalities();
  }

  getAllEps = () => {
    apiClient.SEM.get(`${urlApi}/eps`)
      .then(({ data }) => {
        this.setState({
          epsList: data.data.rows
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getMunicipalities = () => {
    apiClient.SEM.get(`${urlApi}/municipality/`)
      .then(({ data }) => {
        this.setState({
          municipalityList: data.data.rows
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getLastCapacity = () => {
    const { alert } = this.props;
    this.setState({ loading: true });
    apiClient.SEM.get(`${urlApi}/ips/resources/capacity`)
      .then(response => {
        if (response.data) {
          const servicesList = buildServiceList(response.data.data);
          const lastCapacity = buildCapacities(response.data.data);
          const ocupationDefault = this.ocupationDefault(lastCapacity);
          this.setState({
            servicesList,
            lastCapacity,
            ...ocupationDefault
          });
        }
      })
      .catch(() => {
        alert.show('Ocurrió un error al traer el último reporte de capacidad', {
          type: 'error',
          timeout: 4000
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  getLastOccupation = () => {
    const { alert } = this.props;
    this.setState({ loading: true });
    apiClient.SEM.get(`${urlApi}/ips/resources`)
      .then(response => {
        const lastOccupation = getLastOccupation(response.data.data);
        this.setState({ lastOccupation }, () => this.getLastCapacity());
      })
      .catch(error => {
        this.getLastCapacity();
        const { message } = error.response ? error.response.data.data : '';
        console.error(error);
        if (message !== 'CAPACITY_AVAILABLE_NOT_FOUND') {
          alert.show('Ocurrió un error al traer el último reporte de ocupación', {
            type: 'error',
            timeout: 4000
          });
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  ocupationDefault = capacity => {
    const humanResourceDefault = this.humanResourceDefault(capacity);
    const nonSurgicalSupportDefault = this.nonSurgicalSupportDefault(capacity);
    const supportServicesDefault = this.supportServicesDefault(capacity);
    const diagnosticAidsDefault = this.diagnosticAidsDefault(capacity);
    const urgencyDefault = this.urgencyDefault(capacity);
    const internmentDefault = this.internmentDefault(capacity);
    const covidPatientsInfoDefault = this.covidPatientsInfoDefault();
    const iraPatientsInfoDefault = this.iraPatientsInfoDefault();
    const intensivistDefault = this.intensivistDefault();
    return {
      humanResource: humanResourceDefault,
      nonSurgicalSupport: nonSurgicalSupportDefault,
      supportServices: supportServicesDefault,
      diagnosticAids: diagnosticAidsDefault,
      urgency: urgencyDefault,
      internment: internmentDefault,
      intensivist: intensivistDefault,
      covidPatientsInfo: covidPatientsInfoDefault,
      iraPatientsInfo: iraPatientsInfoDefault
    };
  };

  humanResourceDefault = capacity => {
    const { humanResource } = capacity;
    const { lastOccupation } = this.state;
    const humanResourceKeys = Object.keys(humanResource);
    const humanResourceDefault = {};
    humanResourceKeys.forEach(key => {
      if (lastOccupation) {
        if (humanResource[key] === true) {
          humanResourceDefault[key] = lastOccupation.humanResource[key];
        }
        if (lastOccupation.humanResource[`${key}Reason`]) {
          humanResourceDefault[`${key}Reason`] = lastOccupation.humanResource[`${key}Reason`];
        }
        if (lastOccupation.humanResource[`${key}OtherReason`]) {
          humanResourceDefault[`${key}OtherReason`] = lastOccupation.humanResource[`${key}OtherReason`];
        }
      }

      if (!lastOccupation) {
        if (humanResource[key] === true) {
          humanResourceDefault[key] = true;
        }
      }
    });
    return humanResourceDefault;
  };

  nonSurgicalSupportDefault = capacity => {
    const { nonSurgicalSupport } = capacity;
    const { lastOccupation } = this.state;
    const nonSurgicalSupportKeys = Object.keys(nonSurgicalSupport);
    const nonSurgicalSupportDefault = {};
    nonSurgicalSupportKeys.forEach(key => {
      if (lastOccupation) {
        if (nonSurgicalSupport[key] === true) {
          nonSurgicalSupportDefault[key] = lastOccupation.nonSurgicalSupport[key];
        }
        if (lastOccupation.nonSurgicalSupport[`${key}Reason`]) {
          nonSurgicalSupportDefault[`${key}Reason`] = lastOccupation.nonSurgicalSupport[`${key}Reason`];
        }
        if (lastOccupation.nonSurgicalSupport[`${key}OtherReason`]) {
          nonSurgicalSupportDefault[`${key}OtherReason`] = lastOccupation.nonSurgicalSupport[`${key}OtherReason`];
        }
      }

      if (!lastOccupation) {
        if (nonSurgicalSupport[key] === true) {
          nonSurgicalSupportDefault[key] = true;
        }
      }
    });
    return nonSurgicalSupportDefault;
  };

  supportServicesDefault = capacity => {
    const { supportServices } = capacity;
    const { lastOccupation } = this.state;
    const supportServicesKeys = Object.keys(supportServices);
    const supportServicesDefault = {};
    supportServicesKeys.forEach(key => {
      if (lastOccupation) {
        if (supportServices[key] === true) {
          supportServicesDefault[key] = lastOccupation.supportServices[key];
        }
        if (lastOccupation.supportServices[`${key}Reason`]) {
          supportServicesDefault[`${key}Reason`] = lastOccupation.supportServices[`${key}Reason`];
        }
        if (lastOccupation.supportServices[`${key}OtherReason`]) {
          supportServicesDefault[`${key}OtherReason`] = lastOccupation.supportServices[`${key}OtherReason`];
        }
      }

      if (!lastOccupation) {
        if (supportServices[key] === true) {
          supportServicesDefault[key] = true;
        }
      }
    });
    return supportServicesDefault;
  };

  diagnosticAidsDefault = capacity => {
    const { diagnosticAids } = capacity;
    const { lastOccupation } = this.state;
    const diagnosticAidsKeys = Object.keys(diagnosticAids);
    const diagnosticAidsDefault = {};
    diagnosticAidsKeys.forEach(key => {
      if (lastOccupation) {
        if (diagnosticAids[key] === true) {
          diagnosticAidsDefault[key] = lastOccupation.supportServices[key];
        }
        if (lastOccupation.supportServices[`${key}Reason`]) {
          diagnosticAidsDefault[`${key}Reason`] = lastOccupation.supportServices[`${key}Reason`];
        }
        if (lastOccupation.supportServices[`${key}OtherReason`]) {
          diagnosticAidsDefault[`${key}OtherReason`] = lastOccupation.supportServices[`${key}OtherReason`];
        }
      }

      if (!lastOccupation) {
        if (diagnosticAids[key] === true) {
          diagnosticAidsDefault[key] = true;
        }
      }
    });
    return diagnosticAidsDefault;
  };

  urgencyDefault = capacity => {
    const { urgency } = capacity;
    const { lastOccupation } = this.state;
    const urgencyKeys = Object.keys(urgency);
    const urgencyDefault = {};
    if (lastOccupation) {
      urgencyKeys.forEach(key => {
        if (urgency[key] || urgency[key] === 0) {
          urgencyDefault[key] = lastOccupation.urgency[key];
        }
        otherOccupationUrgencyFields.forEach(field => {
          urgencyDefault[field.name] = lastOccupation.urgency[field.name];
        });
      });
      const maxTimeAtEmergencyToHospitalization = this.hoursToTime(
        lastOccupation.urgency.maxTimeAtEmergencyToHospitalization
      );
      const maxTimeToBeAttended = this.hoursToTime(lastOccupation.urgency.maxTimeToBeAttended);
      urgencyDefault.patientsReferredToOtherHospital = lastOccupation.urgency.patientsReferredToOtherHospital;
      urgencyDefault.patientsWaitingReferralTransport = lastOccupation.urgency.patientsWaitingReferralTransport;
      urgencyDefault.maxTimeAtEmergencyToHospitalizationTime = maxTimeAtEmergencyToHospitalization;
      urgencyDefault.maxTimeToBeAttendedTime = maxTimeToBeAttended;
      urgencyDefault.maxTimeAtEmergencyToHospitalization = lastOccupation.urgency.maxTimeAtEmergencyToHospitalization;
      urgencyDefault.maxTimeToBeAttended = lastOccupation.urgency.maxTimeToBeAttended;
      urgencyDefault.maxTimeAtEmergencyToHospitalizationDays = Math.floor(
        lastOccupation.urgency.maxTimeAtEmergencyToHospitalization / 24
      );
    }
    if (!lastOccupation) {
      urgencyKeys.forEach(key => {
        if (urgency[key]) {
          urgencyDefault[key] = undefined;
        }
        otherOccupationUrgencyFields.forEach(field => {
          urgencyDefault[field.name] = undefined;
        });
      });
      const patientsReferredToOtherHospital = {
        qty: 0,
        byEps: []
      };
      const patientsWaitingReferralTransport = {
        qty: 0,
        byEps: []
      };
      urgencyDefault.patientsReferredToOtherHospital = patientsReferredToOtherHospital;
      urgencyDefault.patientsWaitingReferralTransport = patientsWaitingReferralTransport;
      urgencyDefault.maxTimeAtEmergencyToHospitalization = 0;
      urgencyDefault.maxTimeToBeAttended = 0;
      urgencyDefault.maxTimeAtEmergencyToHospitalizationTime = undefined;
      urgencyDefault.maxTimeToBeAttendedTime = undefined;
    }
    return urgencyDefault;
  };

  hoursToTime = hours => {
    const hoursArray = `${hours}`.split('.');
    const minArray = hoursArray[1] ? `0.${hoursArray[1]}` : '0';
    const min = `${(minArray || 0) * 60}`;
    return new Date(2019, 2, 14, hours, round(min), 0);
  };

  internmentDefault = capacity => {
    const { internment } = capacity;
    const { lastOccupation } = this.state;
    const internmentKeys = Object.keys(internment);
    const internmentDefault = {};
    internmentKeys.forEach(key => {
      if (lastOccupation) {
        if (internment[key] || internment[key] === 0) {
          internmentDefault[key] = lastOccupation.internment[key];
        }
      }
      if (!lastOccupation) {
        if (internment[key]) {
          internmentDefault[key] = undefined;
        }
      }
    });
    internmentDefault.bedsOutOfServiceInt = lastOccupation ? lastOccupation.internment.bedsOutOfService : undefined;
    return internmentDefault;
  };

  intensivistDefault = () => {
    const intensivistKeys = ['intensivistNumber'];
    const intensivistDefault = {};
    const { state } = this;
    if (state.lastOccupation) {
      const { lastOccupation } = state;
      const { intensivist } = lastOccupation;
      intensivistKeys.forEach(key => {
        if (intensivist[key] || intensivist[key] === 0) {
          intensivistDefault[key] = lastOccupation.intensivist[key];
        }
        if (!intensivist[key] && intensivist[key] !== 0) {
          intensivistDefault[key] = undefined;
        }
      });
    }
    if (!state.lastOccupation) {
      intensivistKeys.forEach(key => {
        intensivistDefault[key] = undefined;
      });
    }
    return intensivistDefault;
  };

  covidPatientsInfoDefault = () => {
    const { state } = this;
    if (state.lastOccupation) {
      const { lastOccupation } = state;
      return lastOccupation.covidPatientsInfo;
    }
    return [];
  };

  iraPatientsInfoDefault = () => {
    const { state } = this;
    if (state.lastOccupation) {
      const { lastOccupation } = state;
      return lastOccupation.iraPatientsInfo;
    }
    return [];
  };

  handleChangeSwitch = (name, value, type) => {
    this.setState(prevState => ({
      [type]: {
        ...prevState[type],
        [name]: value,
        [`${name}Reason`]: undefined
      }
    }));
  };

  handleMunicipalityChange = inputValue => {
    this.setState(prevState => ({
      covidPatientInfo: {
        ...prevState.covidPatientInfo,
        originCityId: inputValue ? inputValue.value : null,
        originCityObj: inputValue || null,
        patientInformation: inputValue
          ? {
              ...prevState.covidPatientInfo.patientInformation,
              patientInformationTypes: {
                ...prevState.covidPatientInfo.patientInformation.patientInformationTypes,
                originCity: { name: inputValue.label }
              }
            }
          : {
              ...prevState.covidPatientInfo.patientInformation,
              patientInformationTypes: {
                ...prevState.covidPatientInfo.patientInformation.patientInformationTypes,
                originCity: {
                  name: prevState.covidPatientInfo.patientInformation.patientInformationTypes.originCity.name
                }
              }
            }
      }
    }));
  };

  handleDepartmentChange = inputValue => {
    this.setState(prevState => ({
      covidPatientInfo: {
        ...prevState.covidPatientInfo,
        originCityId: null,
        originCityObj: {},
        originDepartmentId: inputValue ? inputValue.value : null,
        originDepartment: inputValue || null,
        patientInformation: inputValue
          ? {
              ...prevState.covidPatientInfo.patientInformation,
              patientInformationTypes: {
                ...prevState.covidPatientInfo.patientInformation.patientInformationTypes,
                department: { name: inputValue.label }
              }
            }
          : {
              ...prevState.covidPatientInfo.patientInformation,
              patientInformationTypes: {
                ...prevState.covidPatientInfo.patientInformation.patientInformationTypes,
                department: {
                  name:
                    prevState.patientInformation.covidPatientInfo.patientInformation.patientInformationTypes.department
                      .name
                }
              }
            }
      }
    }));
  };

  intensivistHandleChange = (name, value) => {
    this.setState(prevState => ({
      intensivist: {
        ...prevState.intensivist,
        [name]: value
      }
    }));
  };

  supportServicesHandleChange = (name, value) => {
    this.setState(prevState => ({
      supportServices: {
        ...prevState.supportServices,
        [name]: value
      }
    }));
  };

  handleChange = (name, value, form) => {
    this.setState(prevState => ({
      [form]: {
        ...prevState[form],
        [name]: value
      }
    }));
  };

  diagnosticAidsHandleChange = (name, value) => {
    this.setState(prevState => ({
      diagnosticAids: {
        ...prevState.diagnosticAids,
        [name]: value
      }
    }));
  };

  urgencyHandleChange = (name, value) => {
    this.setState(prevState => ({
      urgency: {
        ...prevState.urgency,
        [name]: value
      }
    }));
  };

  covidPatientInfoHandleChange = (name, value, nativeEvent) => {
    const { userInfo, entity } = this.props;
    this.setState(prevState => ({
      covidPatientInfo: {
        ...prevState.covidPatientInfo,
        [name]: value,
        patientInformation:
          name === 'epsId'
            ? {
                ...prevState.covidPatientInfo.patientInformation,
                patientInformationTypes: {
                  ...prevState.covidPatientInfo.patientInformation.patientInformationTypes,
                  eps: { name: nativeEvent.target.innerText },
                  ips: { name: entity ? entity.name : 'hospital' },
                  createdBy: { name: userInfo ? userInfo.username : 'usuario' }
                }
              }
            : {
                ...prevState.covidPatientInfo.patientInformation,
                patientInformationTypes: {
                  ...prevState.covidPatientInfo.patientInformation.patientInformationTypes,
                  eps: { name: prevState.covidPatientInfo.patientInformation.patientInformationTypes.eps.name },
                  ips: { name: entity ? entity.name : 'hospital' },
                  createdBy: { name: userInfo ? userInfo.username : 'usuario' }
                }
              }
      }
    }));
  };

  iraPatientInfoHandleChange = (name, value, nativeEvent) => {
    const { userInfo, entity } = this.props;
    this.setState(prevState => ({
      iraPatientInfo: {
        ...prevState.iraPatientInfo,
        [name]: value,
        patientInformation:
          name === 'epsId'
            ? {
                ...prevState.iraPatientInfo.patientInformation,
                patientInformationTypes: {
                  ...prevState.iraPatientInfo.patientInformation.patientInformationTypes,
                  eps: { name: nativeEvent.target.innerText },
                  ips: { name: entity ? entity.name : 'hospital' },
                  createdBy: { name: userInfo ? userInfo.username : 'usuario' }
                }
              }
            : {
                ...prevState.iraPatientInfo.patientInformation,
                patientInformationTypes: {
                  ...prevState.iraPatientInfo.patientInformation.patientInformationTypes,
                  eps: { name: prevState.iraPatientInfo.patientInformation.patientInformationTypes.eps.name },
                  ips: { name: entity ? entity.name : 'hospital' },
                  createdBy: { name: userInfo ? userInfo.username : 'usuario' }
                }
              }
      }
    }));
  };

  comorbiditiesHandleChange = (value, formName) => {
    if (value.some(val => val === 'none')) {
      this.setState(prevState => ({
        [formName]: {
          ...prevState[formName],
          comorbidities: ['none']
        }
      }));
    } else {
      this.setState(prevState => ({
        [formName]: {
          ...prevState[formName],
          comorbidities: value
        }
      }));
    }
  };

  addPatientByEps = name => {
    const { urgency } = this.state;
    const value = {
      id: urgency[`${name}Eps`],
      qty: urgency[`${name}Qty`]
    };
    const byEps = urgency[name].byEps.concat(value);
    let qty = 0;
    byEps.forEach(eps => {
      qty += eps.qty;
    });
    this.setState(prevState => ({
      urgency: {
        ...prevState.urgency,
        [`${name}Eps`]: undefined,
        [`${name}Qty`]: undefined,
        [name]: {
          qty,
          byEps
        }
      }
    }));
  };

  deletePatientByEps = (id, name) => {
    const { urgency } = this.state;
    const byEps = urgency[name].byEps.filter(element => element.id !== id);
    let qty = 0;
    byEps.forEach(eps => {
      qty += eps.qty;
    });
    this.setState(prevState => ({
      urgency: {
        ...prevState.urgency,
        [name]: {
          qty,
          byEps
        }
      }
    }));
  };

  addPatientsToCovidOrIra = formName => {
    const { covidPatientInfo, covidPatientsInfo, iraPatientInfo, iraPatientsInfo } = this.state;
    const {
      firstName,
      middleName,
      lastName,
      secondLastName,
      documentType,
      document,
      epsId,
      comorbidities,
      originCityId,
      originDepartmentId,
      sex,
      age,
      serviceType,
      isCovid,
      isIra,
      patientInformation
    } = formName === 'covidPatientInfo' ? covidPatientInfo : iraPatientInfo;
    const comorbiditiesObject = Array.isArray(comorbidities)
      ? comorbidities.reduce(
          (acc, current) => ({
            ...acc,
            [current]: { enable: true }
          }),
          comorbiditiesTypes
        )
      : comorbiditiesTypes;
    const patientInfo = {
      firstName,
      middleName,
      lastName,
      secondLastName,
      documentType,
      document,
      epsId,
      comorbidities: { comorbiditiesTypes: comorbiditiesObject },
      originCityId,
      originDepartmentId,
      sex,
      age,
      serviceType,
      isCovid,
      isIra,
      patientInformation
    };
    const array = formName === 'covidPatientInfo' ? [...covidPatientsInfo] : [...iraPatientsInfo];
    const patientList = formName === 'covidPatientInfo' ? 'covidPatientsInfo' : 'iraPatientsInfo';
    array.push(patientInfo);
    this.setState(prevState => ({
      ...prevState,
      [patientList]: array,
      [formName]: {
        ...[formName],
        firstName: null,
        middleName: null,
        lastName: null,
        secondLastName: null,
        documentType: null,
        document: null,
        epsId: null,
        comorbidities: [],
        originCityId: null,
        originDepartmentId: null,
        sex: null,
        age: undefined,
        serviceType: null,
        originCityObj: {},
        originDepartment: {},
        isCovid: formName === 'covidPatientInfo',
        isIra: formName === 'iraPatientInfo',
        patientInformation: {
          patientInformationTypes: { ...patientInformationTypes }
        }
      }
    }));
  };

  deletePatientsToCovid = key => {
    const { covidPatientsInfo } = this.state;
    const array = [...covidPatientsInfo];
    array.splice(key, 1);
    this.setState(
      prevState => ({
        ...prevState,
        covidPatientsInfo: array
      }),
      () => this.closeModal()
    );
  };

  deletePatientsToIra = key => {
    const { iraPatientsInfo } = this.state;
    const array = [...iraPatientsInfo];
    array.splice(key, 1);
    this.setState(
      prevState => ({
        ...prevState,
        iraPatientsInfo: array
      }),
      () => this.closeModal()
    );
  };

  outcomePatients = async outcome => {
    const { alert } = this.props;
    const { modalType, modalKey, covidPatientsInfo, iraPatientsInfo } = this.state;
    const patientsInfo = modalType === 'covid' ? covidPatientsInfo : iraPatientsInfo;
    const array = [...patientsInfo];
    array[modalKey].outcome = outcome;
    this.setState(
      prevState => ({
        ...prevState,
        covidPatientsInfo: modalType === 'covid' ? array : covidPatientsInfo,
        iraPatientsInfo: modalType === 'ira' ? array : iraPatientsInfo
      }),
      () => {
        alert.show(
          'La información del paciente fue modificada exitosamente, debe enviar el formulario para confirmar el cambio',
          {
            timeout: 6000
          }
        );
        this.closeModal();
      }
    );
  };

  changeServicePatients = async (
    documentType,
    document,
    service,
    isCovid,
    originCityId,
    originDepartmentId,
    comorbidities,
    originCity,
    department
  ) => {
    const comorbiditiesObject = comorbidities.reduce(
      (acc, current) => ({
        ...acc,
        [current]: { enable: true }
      }),
      comorbiditiesTypes
    );
    const { alert } = this.props;
    const { modalType, modalKey } = this.state;
    const { iraPatientsInfo, covidPatientsInfo } = this.state;
    const arrayCovid = [...covidPatientsInfo];
    const arrayIra = [...iraPatientsInfo];
    if (arrayCovid[modalKey] && modalType === 'covid') {
      arrayCovid[modalKey].serviceType = service && modalType === 'covid' ? service : arrayCovid[modalKey].serviceType;
    }
    if (arrayIra[modalKey] && modalType === 'ira') {
      arrayIra[modalKey].serviceType = service && modalType === 'ira' ? service : arrayIra[modalKey].serviceType;
      arrayIra[modalKey].isCovid = isCovid !== undefined ? isCovid : arrayIra[modalKey].isCovid;
      arrayIra[modalKey].documentType = documentType !== undefined ? documentType : undefined;
      arrayIra[modalKey].document = document !== undefined ? document : undefined;
      arrayIra[modalKey].comorbidities = { comorbiditiesTypes: [] };
      arrayIra[modalKey].comorbidities.comorbiditiesTypes = comorbiditiesObject;
      arrayIra[modalKey].patientInformation.patientInformationTypes = {
        ...arrayIra[modalKey].patientInformation.patientInformationTypes,
        originCity: { name: originCity },
        department: { name: department }
      };
    }
    const patient = arrayIra[modalKey];
    if (isCovid) {
      patient.originCityId = originCityId;
      patient.originDepartmentId = originDepartmentId;
      arrayIra.splice(modalKey, 1);
      arrayCovid.push(patient);
    }
    this.setState(
      prevState => ({
        ...prevState,
        iraPatientsInfo: arrayIra,
        covidPatientsInfo: arrayCovid
      }),
      () => {
        alert.show(
          'La información del paciente fue modificada exitosamente, debe enviar el formulario para confirmar el cambio',
          {
            timeout: 6000
          }
        );
        this.closeModal();
      }
    );
  };

  openModal = (key, type, id) => {
    const { iraPatientsInfo, covidPatientsInfo } = this.state;
    const { document, documentType } = type === 'ira' ? iraPatientsInfo[key] : covidPatientsInfo[key];
    this.setState(prevState => ({
      ...prevState,
      openModal: true,
      modalKey: key,
      modalType: type,
      idModal: id,
      modalDocument: document,
      modalDocumentType: documentType
    }));
  };

  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      openModal: false,
      modalKey: undefined,
      modalType: undefined,
      modalId: false,
      modalDocument: null,
      modalDocumentType: null
    }));
  };

  handleOpenCovidConfirmModal = action => {
    this.setState(prevState => ({
      ...prevState,
      openCovidConfirmModal: action === 'open' ? true : action === 'close' && false
    }));
  };

  urgencyDateChange = (name, value) => {
    this.setState(prevState => ({
      urgency: {
        ...prevState.urgency,
        [name]: value
      }
    }));
  };

  internmentHandleChange = (name, value) => {
    this.setState(prevState => ({
      internment: {
        ...prevState.internment,
        [name]: value
      }
    }));
  };

  buttonSaveResourcesAction = () => {
    const { covidPatientsInfo, iraPatientsInfo } = this.state;
    if (covidPatientsInfo.length < 1 || iraPatientsInfo.length < 1) {
      return this.handleOpenCovidConfirmModal('open');
    }
    return this.saveResources();
  };

  validateDocumentsDuplication = () => {
    const { covidPatientsInfo, iraPatientsInfo } = this.state;
    const documentsArray = []
      .concat(covidPatientsInfo, iraPatientsInfo)
      .reduce(
        (acc, current) =>
          !current.outcome || current.outcome === null
            ? [...acc, `${current.documentType} ${current.document}`]
            : [...acc],
        []
      );
    const duplicatedDocuments = [];
    let temporal = [];
    documentsArray.forEach((value, index) => {
      temporal = [...documentsArray];
      temporal.splice(index, 1);
      if (temporal.indexOf(value) !== -1 && duplicatedDocuments.indexOf(value) === -1) duplicatedDocuments.push(value);
    });
    return duplicatedDocuments;
  };

  saveResources = () => {
    const { alert } = this.props;
    this.setState({ loading: true });
    const validation = this.validateDocumentsDuplication();
    if (validation.length === 0) {
      const data = buildOccupation(this.state);
      apiClient.SEM.post(`${urlApi}/ips/resources`, data)
        .then(() => {
          alert.show('Se ha guardado la información de la ocupación y disponibilidad correctamente', {
            type: 'info',
            timeout: 6000
          });
          this.getLastOccupation();
          this.handleOpenCovidConfirmModal('close');
        })
        .catch(() => {
          alert.show('No se pudo guardar el reporte de capacidad, inténtalo más tarde.', {
            type: 'error',
            timeout: 6000
          });
        })
        .finally(() => this.setState({ loading: false }));
    } else {
      alert.show(
        `${validation.length > 1 ? 'Los documentos' : 'El documento'} ${validation} ${
          validation.length > 1 ? 'estan duplicados' : 'está duplicado'
        } en los formularios de COVID y/o IRA, por favor verifique la información`,
        {
          type: 'error',
          timeout: 10000
        }
      );
      this.setState({ loading: false });
    }
  };

  buttonState = () => {
    const reasonsToValidate = [];
    const qtyservices = [];
    const numbersToValidate = [];
    const { lastCapacity } = this.state;
    const stateClone = { ...this.state };
    const globalState = {
      ...stateClone.humanResource,
      ...stateClone.internment,
      ...stateClone.nonSurgicalSupport,
      ...stateClone.supportServices,
      ...stateClone.diagnosticAids,
      ...stateClone.urgency,
      ...stateClone.intensivist
    };

    if (stateClone) {
      const qtyfields = {
        ...stateClone.intensivist
      };
      const qtyKeys = Object.keys(qtyfields);
      qtyKeys.forEach(key => {
        if (key.match(/intensivist/)) {
          numbersToValidate.push(key);
        }
      });
    }

    if (lastCapacity) {
      const switchKeys = {
        ...lastCapacity.humanResource,
        ...lastCapacity.nonSurgicalSupport,
        ...lastCapacity.supportServices,
        ...lastCapacity.diagnosticAids
      };
      const qtyfields = {
        ...lastCapacity.internment,
        ...lastCapacity.urgency
      };
      const reasonKeys = Object.keys(switchKeys);
      const qtyKeys = Object.keys(qtyfields);
      const serviceKeys = Object.keys(lastCapacity.supportServices);
      reasonKeys.forEach(key => {
        if (switchKeys[key] === true) {
          reasonsToValidate.push(key);
        }
      });
      qtyKeys.forEach(key => {
        if (qtyfields[key] && key.match(/Beds/)) {
          numbersToValidate.push(key);
        }
      });
      serviceKeys.forEach(key => {
        if (lastCapacity.supportServices[key] === true) {
          qtyservices.push(key);
        }
      });
    }
    const invalidReasons = reasonsToValidate.some(key => !globalState[key] && !globalState[`${key}Reason`]);
    const otherReason = reasonsToValidate.some(
      key => !globalState[key] && globalState[`${key}Reason`] === 'Otro' && !globalState[`${key}OtherReason`]
    );
    const invalidNumbers = numbersToValidate.some(
      key => `${globalState[key]}` === 'undefined' || `${globalState[key]}` === ''
    );
    // Uncomment when we want to send the quantity of any service
    // const invalidServices = qtyservices.some(key => globalState[key] && !globalState[`${key}Qty`]);
    // return invalidReasons || invalidNumbers || invalidServices || otherReason;
    const invalidNedocsKeys = occupationNedocsKeys.some(
      key => `${globalState[key]}` === 'undefined' || `${globalState[key]}` === ''
    );
    return invalidReasons || invalidNumbers || otherReason || invalidNedocsKeys;
  };

  render() {
    const { classes = {}, history } = this.props;
    const stateClone = { ...this.state };
    const {
      humanResource,
      supportServices,
      diagnosticAids,
      nonSurgicalSupport,
      urgency,
      covidPatientInfo,
      covidPatientsInfo,
      iraPatientInfo,
      iraPatientsInfo,
      internment,
      lastCapacity = null,
      epsList,
      municipalityList,
      intensivist,
      openModal,
      modalType,
      modalKey,
      idModal,
      modalDocument,
      modalDocumentType,
      openCovidConfirmModal,
      servicesList,
      lastOccupation = {}
    } = this.state;
    const disableButton = this.buttonState();
    return (
      <GridContainer>
        <NxSpinner loading={stateClone.loading} />
        {lastCapacity ? (
          <div>
            <GridItem xs={12}>
              <i>
                Los siguientes campos están relacionados con el número de pacientes ocupando los diferentes recursos de
                la entidad.
              </i>
            </GridItem>
            {Object.values(lastCapacity.humanResource).some(key => key) && (
              <HumanResources
                handleChange={this.handleChangeSwitch}
                state={humanResource}
                isOccupation
                capacity={lastCapacity.humanResource}
              />
            )}
            {Object.values(lastCapacity.nonSurgicalSupport).some(key => key) && (
              <NonSurgicalSupport
                handleChange={this.handleChangeSwitch}
                state={nonSurgicalSupport}
                isOccupation
                capacity={lastCapacity.nonSurgicalSupport}
              />
            )}
            {Object.values(lastCapacity.supportServices).some(key => key) && (
              <SupportServices
                handleChange={this.handleChangeSwitch}
                state={supportServices}
                qtyHandleChange={this.supportServicesHandleChange}
                isOccupation
                capacity={lastCapacity.supportServices}
              />
            )}
            {Object.values(lastCapacity.diagnosticAids).some(key => key) && (
              <DiagnosticAids
                handleChange={this.handleChangeSwitch}
                state={diagnosticAids}
                qtyHandleChange={this.handleChange}
                isOccupation
                capacity={lastCapacity.diagnosticAids}
              />
            )}
            <Urgency
              handleChange={this.urgencyHandleChange}
              handleChangeCheckbox={this.handleChangeCheckbox}
              state={{
                ...urgency,
                epsList
              }}
              isOccupation
              capacity={lastCapacity.urgency}
              urgencyDateChange={this.urgencyDateChange}
              addPatientByEps={this.addPatientByEps}
              deletePatientByEps={this.deletePatientByEps}
            />
            {Object.values(lastCapacity.internment).some(key => key) && (
              <Internment
                handleChange={this.internmentHandleChange}
                state={internment}
                isOccupation
                capacity={lastCapacity.internment}
              />
            )}
            <OutOfService
              onBedsChange={this.handleChange}
              capacity={lastCapacity}
              state={lastOccupation.bedsOutOfService}
            />
            <CovidForm
              state={{
                patientInfo: covidPatientInfo,
                municipalityList,
                patientsInfo: covidPatientsInfo,
                epsList
              }}
              servicesList={servicesList}
              handleChange={this.covidPatientInfoHandleChange}
              comorbiditiesHandleChange={this.comorbiditiesHandleChange}
              addPatient={this.addPatientsToCovidOrIra}
              openModal={this.openModal}
              formName="covidPatientInfo"
              label="Covid"
              originDepartmentId={covidPatientInfo.originDepartmentId}
              originCity={covidPatientInfo.originCityObj}
              originDepartment={covidPatientInfo.originDepartment}
              handleMunicipalityChange={this.handleMunicipalityChange}
              handleDepartmentChange={this.handleDepartmentChange}
            />
            <CovidForm
              state={{
                patientInfo: iraPatientInfo,
                municipalityList,
                patientsInfo: iraPatientsInfo,
                epsList
              }}
              servicesList={servicesList}
              handleChange={this.iraPatientInfoHandleChange}
              comorbiditiesHandleChange={this.comorbiditiesHandleChange}
              addPatient={this.addPatientsToCovidOrIra}
              openModal={this.openModal}
              formName="iraPatientInfo"
              label="IRA no Covid"
            />
            <Intensivist state={{ ...intensivist }} handleChange={this.intensivistHandleChange} />
            <React.Suspense fallback={<div>Loading...</div>}>
              <DeletePatientModal
                openModal={openModal}
                modalKey={modalKey}
                closeModal={this.closeModal}
                modalType={modalType}
                outcomePatients={this.outcomePatients}
                changeServicePatients={this.changeServicePatients}
                idModal={idModal}
                deletePatientsToIra={this.deletePatientsToIra}
                deletePatientsToCovid={this.deletePatientsToCovid}
                municipalityList={municipalityList}
                handleMunicipalityChange={this.handleMunicipalityChange}
                handleDepartmentChange={this.handleDepartmentChange}
                servicesList={servicesList}
                modalDocument={modalDocument}
                modalDocumentType={modalDocumentType}
              />
            </React.Suspense>
            <React.Suspense fallback={<div>Loading...</div>}>
              <CovidConfirmModal
                openCovidConfirmModal={openCovidConfirmModal}
                handleCloseCovidConfirmModal={this.handleOpenCovidConfirmModal}
                saveResources={this.saveResources}
              />
            </React.Suspense>
            <GridItem container justify="center">
              <div>
                <Button color="info" onClick={this.buttonSaveResourcesAction} disabled={disableButton}>
                  Reportar ocupación y disponibilidad
                </Button>
              </div>
            </GridItem>
          </div>
        ) : (
          <GridItem xs={12}>
            <Paper className={classes.paperInfo}>
              <h4> Debes reportar la capacidad antes de reportar ocupación y disponibilidad.</h4>
              <Button color="info" onClick={() => history.push('/resources/capacity')}>
                Reportar capacidad
              </Button>
            </Paper>
          </GridItem>
        )}
      </GridContainer>
    );
  }
}

Ocupation.propTypes = {
  classes: PropTypes.object,
  alert: PropTypes.object,
  history: PropTypes.object,
  userInfo: PropTypes.object,
  entity: PropTypes.object
};

Ocupation.defaultProps = {
  classes: {},
  history: {}
};

const ComponentOcupation = withAlert()(withStyles(styles)(Ocupation));

export default props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer => (
      <ComponentOcupation
        {...props}
        logout={AuthContainer.logout}
        userInfo={AuthContainer.state.userInfo}
        roles={AuthContainer.state.roles}
        entity={AuthContainer.state.entity}
      />
    )}
  </Subscribe>
);
