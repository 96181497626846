import React from 'react';
import ListNotifications from '../../nxComponents/Notifications/ListNotifications';

export function ViewNotifications(props) {
  return (
    <div>
      <ListNotifications {...props} />
    </div>
  );
}

// export default Transfer;

export default ViewNotifications;
