import { Container } from 'unstated';
import Keycloak from 'keycloak-js';
import io from 'socket.io-client';
import socketSEM from 'config/ws';
import socketCHAT from 'config/wsChat';
import apiClient from 'config/apiClient';
import { urlApi, socketUbidots } from 'config/app';
import { askForPermissioToReceiveNotifications } from '../push-notification';

let socket;
class AuthContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      keycloak: null,
      authenticated: false,
      isRtConnected: false,
      isChatConnected: false
    };
    this.initialLogin();
  }

  getEntityData = async (roles, attributes) => {
    try {
      let endpoint;
      if (roles.includes('IPS')) {
        endpoint = `${urlApi}/ips/${attributes.entityId[0]}`;
      } else if (roles.includes('EPS')) {
        endpoint = `${urlApi}/eps/${attributes.entityId[0]}`;
      }

      if (endpoint) {
        const response = await apiClient.SEM.get(endpoint);
        this.setState({
          entity: response.data.data
        });
        return response;
      }
      const data = { name: 'Crue' };
      this.setState({
        entity: data
      });
      return data;
    } catch (error) {
      throw error;
    }
  };

  initialLogin = () => {
    const keycloak = Keycloak('/keycloak.json');
    keycloak
      .init({ onLoad: 'login-required', checkLoginIframe: false })
      .success(authenticatedLocal => {
        keycloak.loadUserProfile().success(response => {
          const rolesUser = keycloak.tokenParsed.realm_access.roles.concat(
            (keycloak.tokenParsed.resource_access['sem-web'] || {}).roles
          );
          this.getEntityData(rolesUser, response.attributes);

          this.setState({
            keycloak,
            userInfo: response,
            authenticated: authenticatedLocal,
            roles: rolesUser,
            logout: keycloak.logout
          });

          window.Intercom('shutdown');
          window.Intercom('boot', {
            app_id: 'avbsci24',
            user_id: `SEM_${response.username}`,
            project: 'SEM',
            name: response.username
          });

          askForPermissioToReceiveNotifications()
            .then(tokenFCM => {
              this.setState({ tokenFCM });
              socketSEM.io.opts.query = { tokenAuth: response.username, tokenFCM };
            })
            .catch(() => {
              // Todo: si entra aca es porque no tiene permiso de notificacion de escritorio, colocar un aviso al usuario
              socketSEM.io.opts.query = { tokenAuth: response.username };
            })
            .finally(() => socketSEM.open());

          socketCHAT.io.opts.query = { token: keycloak.token };
          socketCHAT.open();
        });

        apiClient.setToken(keycloak.token);
        socket = io(socketUbidots, {
          path: '/notifications',
          transports: ['websocket'],
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          reconnectionAttempts: 5
        });

        socketSEM.on('connect', () => {
          this.setState({ isRtConnected: true });
        });

        socketCHAT.on('connect', () => {
          this.setState({ isChatConnected: true });
        });

        socketSEM.on('disconnect', () => {
          this.setState({ isRtConnected: false });
        });

        socketCHAT.on('disconnect', () => {
          this.setState({ isChatConnected: false });
        });
      })
      .error(() => {
        console.error('failed to initialize');
      });

    keycloak.onAuthSuccess = () => {
      console.log('authenticated');
    };

    keycloak.onTokenExpired = () => {
      console.warn('token expired');
      keycloak
        .updateToken(60)
        .success(refreshed => {
          if (refreshed) {
            this.setState({ keycloak });
            console.log('Token was successfully refreshed');
            apiClient.setToken(keycloak.token);
          } else {
            console.log('Token is still valid');
          }
        })
        .error(() => {
          console.error('Failed to refresh the token, or the session has expired');
        });
    };
  };

  logout = async () => {
    const { logout, userInfo, tokenFCM } = this.state;
    socketSEM.emit('unsubscribefcm', {
      topic: userInfo.username,
      tokenFCM
    });
    window.Intercom('shutdown');
    window.Intercom('boot', {
      app_id: 'avbsci24',
      project: 'SEM'
    });

    logout();
    this.setState({ isAuth: false }, () => {});
  };
}

export const UbidotsSocket = () => socket;
export default AuthContainer;
