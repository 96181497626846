// @flow

import React from 'react';
import PropTypes from 'prop-types';

// import queryString from 'query-string';
import { Table } from '@devexpress/dx-react-grid-material-ui';
// import { fade } from '@material-ui/core/styles/colorManipulator';

import isWithinRange from 'date-fns/is_within_range';
import differenceInHours from 'date-fns/difference_in_hours';
import setHours from 'date-fns/set_hours';
import startOfYesterday from 'date-fns/start_of_yesterday';
import startOfTomorrow from 'date-fns/start_of_tomorrow';
import startOfToday from 'date-fns/start_of_today';
import addHours from 'date-fns/add_hours';
import subMinutes from 'date-fns/sub_minutes';

const currentDate = new Date();
const todayAm = setHours(startOfToday(), 8);
const todayPm = setHours(startOfToday(), 20);
const tomorrowAm = setHours(startOfTomorrow(), 8);
const yerterdayPm = setHours(startOfYesterday(), 20);

function getValidRange() {
  if (isWithinRange(currentDate, todayAm, todayPm)) {
    return {
      start: subMinutes(todayAm, 180),
      end: todayPm
    };
  }
  if (isWithinRange(currentDate, todayPm, tomorrowAm)) {
    return {
      start: subMinutes(todayPm, 180),
      end: tomorrowAm
    };
  }
  // if (isWithinRange(currentDate, yerterdayPm, todayAm)) {
  return {
    start: subMinutes(yerterdayPm, 180),
    end: todayAm
  };
  // }
}

const range = getValidRange();
const limitToReport = addHours(range.start, 4);

function resourcesAreOnTime(reportDate, bloodResources, services) {
  const bloodBankCondition = services.bloodBank.enable
    ? isWithinRange(bloodResources.createdAt, range.start, range.end)
    : true;
  return isWithinRange(reportDate, range.start, range.end) && bloodBankCondition;
}

function resourcesAreInTheLimit(reportDate, bloodResources, services) {
  const bloodBankCondition = services.bloodBank.enable
    ? isWithinRange(currentDate, range.start, limitToReport) &&
      differenceInHours(currentDate, bloodResources.createdAt) < 15
    : false;
  return (
    (isWithinRange(currentDate, range.start, limitToReport) && differenceInHours(currentDate, reportDate) < 15) ||
    bloodBankCondition
  );
}

export const TableRow = ({
  row: { updatedAt: reportDate, bloodResources = {}, hospitalaryCapacity = {} },
  ...restProps
}) => {
  const { services = {} } = hospitalaryCapacity;
  let color = '#ff000017'; // red
  if (resourcesAreOnTime(reportDate, bloodResources, services)) {
    color = '#17a5202b'; // green
  } else if (resourcesAreInTheLimit(reportDate, bloodResources, services)) {
    color = '#ff980047'; // orange
  }
  return (
    <Table.Row
      {...restProps}
      style={{
        background: color
      }}
    />
  );
};

TableRow.propTypes = {
  row: PropTypes.object
};
