import React from 'react';
import PropTypes from 'prop-types';

// Material-ui components
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/styles';
import ManIconWhiteBackgroun from 'Icons/ManIconWhiteBackgroun';
import WomanIconWhiteBackground from 'Icons/WomanIconWhiteBackground';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const useStyles = makeStyles({
  femaleTotal: {
    margin: '1px',
    backgroundColor: '#ff6b7d',
    color: '#FFF',
    fontWeight: 'bold'
  },
  maleTotal: {
    margin: '1px',
    backgroundColor: '#00a5c2',
    color: '#FFF',
    fontWeight: 'bold'
  },
  total: {
    margin: '1px',
    fontWeight: 'bold',
    backgroundColor: '#FFF'
  },
  marginZero: {
    margin: 0
  },
  pstyle: {
    margin: '10px 0 0 0'
  },
  spanstyle: {
    color: '#ff9800',
    fontWeight: 'bold',
    fontSize: '1.5rem'
  }
});

const CounterChips = ({ sexCount }) => {
  const classes = useStyles();
  return (
    <GridContainer className={classes.marginTop}>
      <GridItem xs={2} md={5} lg={8} />
      <GridItem xs={3} md={2} lg={1}>
        <Chip
          key="femaleNumber"
          icon={<WomanIconWhiteBackground className={classes.marginZero} />}
          label={sexCount.F || 0}
          className={classes.femaleTotal}
        />
      </GridItem>
      <GridItem xs={3} md={2} lg={1}>
        <Chip
          key="maleNumber"
          icon={<ManIconWhiteBackgroun className={classes.marginZero} />}
          label={sexCount.M || 0}
          className={classes.maleTotal}
        />
      </GridItem>
      <GridItem xs={3} md={2} lg={1}>
        <p className={classes.pstyle}>
          Total:
          <span className={classes.spanstyle}>{` ${(sexCount.M || 0) + (sexCount.F || 0)}`}</span>
        </p>
      </GridItem>
      <GridItem xs={1} />
    </GridContainer>
  );
};

CounterChips.propTypes = {
  sexCount: PropTypes.object.isRequired
};

export default CounterChips;
