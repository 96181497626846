import React from 'react';
import TableTransfers from '../../nxComponents/Transfer/Table/TableTransfers';

export function TransferDetail(props) {
  return (
    <div>
      <TableTransfers {...props} />
    </div>
  );
}

// export default Transfer;

export default TransferDetail;
