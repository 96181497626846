import * as React from 'react';
import PropTypes from 'prop-types';

function Dashboard(props) {
  const { color, height, width, ...rest } = props;

  return (
    <svg
      id="prefix__Capa_1"
      width={width}
      height={height}
      x={0}
      y={0}
      viewBox="0 0 39.4 34.9"
      xmlSpace="preserve"
      {...rest}
    >
      <style>{`.prefix__st0{fill:${color}}`}</style>
      <path
        className="prefix__st0"
        d="M19.1 18.4l8.6-11.5c.4-.5.3-1.3-.2-1.7C20.8-.3 10.9.8 5.4 7.6c-2.1 2.6-3.3 5.8-3.4 9.2v.7c0 8.7 7 15.7 15.7 15.8 3.5 0 6.9-1.2 9.6-3.3.3-.2.4-.5.5-.9 0-.3-.1-.7-.3-.9l-8.4-9.8zM4.6 15.7c.3-2.4 1.3-4.7 2.8-6.6 4.3-5.3 12-6.5 17.6-2.7l-8 10.7-12.4-1.4zm13.1 15c-7.1 0-12.8-5.5-13.2-12.5L17 19.6l7.8 9.1c-2.2 1.3-4.6 2-7.1 2v.3-.3z"
      />
      <path
        className="prefix__st0"
        d="M32.5 5.2c-.3-.2-.6-.3-.9-.3-.3 0-.6.2-.8.5l-9.2 12.3c-.3.5-.3 1.1.1 1.6l9 10.5c.2.3.6.4 1 .4.3 0 .5-.1.8-.3 3.8-3 6.1-7.6 6.1-12.5-.2-4.8-2.3-9.2-6.1-12.2zm3.4 12.2c0 3.7-1.6 7.2-4.3 9.7l-7.5-8.8L31.9 8c2.6 2.5 4 5.8 4 9.4z"
      />
    </svg>
  );
}

Dashboard.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

Dashboard.defaultProps = {
  color: '#858585',
  height: 26,
  width: 38
};

export default Dashboard;
