import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import apiClient from 'config/apiClient';

// @material-ui/core
import { makeStyles } from '@material-ui/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

// Assets
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import { capitalizeString, selectEpsToShow } from 'utils/attentionNetworks';

// Components
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from 'components/CustomButtons/Button';
// import { isCrue, isIps } from 'utils/authFuntions';

import { urlApi } from '../../config/app';

const Telecare = props => {
  const { classes } = props;
  const [ambulances, setAmbulances] = useState([]);
  const [selectedAmbulance, setSelectedAmbulance] = useState();

  const startTelecare = () => {
    const ambulance = ambulances.find(amb => (amb.id === selectedAmbulance));
    window.open(ambulance.hostRoomUrl);
  };

  useEffect(() => {
    apiClient.SEM.get(`${urlApi}/ambulance/legacyTelecare`)
      .then(({ data }) => {
        setAmbulances(data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const hasRoom = useCallback(
    () => {
      const ambulance = ambulances.find(amb => amb.id == selectedAmbulance) || {};
      return ambulance.guestRoomUrl;
    },
    [selectedAmbulance]
  );
  return (
    <GridContainer justify="center">
      <GridItem xs={8}>
        <Card>
          <CardHeader color="primary">
            <h3>Control teleorientación</h3>
          </CardHeader>
          <CardBody>
            <GridItem container justify="center">
              <GridItem xs={12} sm={6}>
                <FormControl fullWidth style={{ marginTop: '10px' }}>
                  <InputLabel htmlFor="simple-select" error={!selectedAmbulance}>
                    Ambulancia *
                  </InputLabel>
                  <Select
                    classes={{ select: classes.select }}
                    value={selectedAmbulance || ''}
                    onChange={e => {
                      setSelectedAmbulance(e.target.value);
                    }}
                    fullWidth
                    inputProps={{
                      name: 'ambulance',
                      id: 'ambulance'
                    }}
                  >
                    {ambulances.map(amb => (
                      <MenuItem key={amb.id} value={amb.id}>
                        {amb.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {selectedAmbulance && !hasRoom() && (
                    <FormHelperText error>La ambulancia no cuenta con una sala definida</FormHelperText>
                  )}
                </FormControl>
              </GridItem>
            </GridItem>
            <GridItem container justify="center">
              <Button
                onClick={startTelecare}
                style={{ marginTop: '20px' }}
                color="info"
                disabled={!selectedAmbulance || !hasRoom()}
              >
                Iniciar teleorientación
              </Button>
            </GridItem>
          </CardBody>
        </Card>
        <GridContainer />
      </GridItem>
    </GridContainer>
  );
};

Telecare.propTypes = {
  classes: PropTypes.object.isRequired
};

const TelecareComponent = withStyles(dashboardStyle)(Telecare);

export default TelecareComponent;
