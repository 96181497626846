import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import { Subscribe } from 'unstated';
import { compose } from 'recompose';

import { withAlert } from 'react-alert';
// import { webNotification } from 'nxComponents/WebNotification/WebNotification';
import socketSEM from 'config/ws';
// import socketCHAT from 'config/wsChat';

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Header from 'components/Header/Header';
// import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import AuthContainer from 'containers-state/auth';

import PrivateRoute from 'nxComponents/PrivateRoute/PrivateRoute';

import dashboardRoutes from 'routes/dashboard';

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle';

import imageSidebar from 'assets/img/sidebar-2.jpg';
/* import logoWhite from 'assets/img/logo-white.svg';
import logoBlue from 'assets/img/logo.svg'; */
import logoWhite from 'assets/img/logo.png';
import logoBlue from 'assets/img/logo.png';
import { getnewSolicitud } from '../models/ambMed';

const switchRoutes = roles => {
  const rutas = (
    <Switch>
      {dashboardRoutes.map((prop, key) => {
        if (!roles || (prop.roles && roles && !roles.some(r => prop.roles.indexOf(r) >= 0))) {
          return null;
        }
        if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
        if (prop.collapse)
          return prop.views.map((prop, key) => {
            if (!roles || (prop.roles && roles && !roles.some(r => prop.roles.indexOf(r) >= 0))) {
              return null;
            }
            return <PrivateRoute path={prop.path} component={prop.component} key={key} />;
          });

        return <PrivateRoute path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  );
  return rutas;
};

let ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: imageSidebar,
      color: 'blue',
      bgColor: 'black',
      fixedClasses: 'dropdown show'
    };
    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleColorClick = this.handleColorClick.bind(this);
    this.handleBgColorClick = this.handleBgColorClick.bind(this);
    this.handleFixedClick = this.handleFixedClick.bind(this);
    this.resizeFunction = this.resizeFunction.bind(this);
    if ((props.roles || []).includes('CRUE') && process.env.REACT_APP_AMB_MED) {
      getnewSolicitud(() => {
        const { alert } = this.props;
        alert.show('Ha recibido una nueva solicitud de Ambulancia');
      });
    }

    socketSEM.on('notification', data => {
      const { alert } = this.props;
      console.log(data);
      /* let body = 'Nueva Notification';
      if (data.action && data.action === 'CREATE_REFERRAL' && data.payload) {
        body = 'Nuevo traslado creado';
      } */

      alert.show(data.title, {
        type: 'info'
      });
    });

    window.addEventListener('unhandledrejection', event => {
      const { alert } = this.props;

      alert.show(((event || {}).reason || {}).message || 'Ocurrio un error', {
        type: 'error'
      });
      console.log(event.reason);
    });

    // socketCHAT.on('message', data => {
    //   console.log(data);
    //   const { msg, username, msgType, referralId } = data;
    //   const { desktopNotification } = this.props;
    //   // if (msgType === 'ADM') {
    //   //   desktopNotification.show(
    //   //     `${username} en el traslado ${referralId}`,
    //   //     msg
    //   //   );
    //   // }
    // });
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);
  }

  //  componentDidUpdate(e) {
  //  este codigo del template evalua si la vista esta en diseño movil y si se hace presionado para ir a otra pagina para proceder a cerrar el sidebar
  //   debugger
  // if (e.history.location.pathname !== e.location.pathname) {
  //   const { mobileOpen } = this.state;
  //   this.refs.mainPanel.scrollTop = 0;
  //   if (mobileOpen) {
  //     this.setState({ mobileOpen: false });
  //   }
  // }
  // }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }

  getRoute() {
    const { location } = this.props;
    return location.pathname !== '/maps/full-screen-maps';
  }

  handleDrawerToggle = () => {
    this.setState(state => ({
      mobileOpen: !state.mobileOpen
    }));
  };

  sidebarMinimize = () => {
    this.setState(state => ({
      miniActive: !state.miniActive
    }));
  };

  handleFixedClick() {
    const { fixedClasses } = this.state;
    this.setState({
      fixedClasses: fixedClasses === 'dropdown' ? 'dropdown show' : 'dropdown'
    });
  }

  handleImageClick(image) {
    this.setState({ image });
  }

  handleColorClick(color) {
    this.setState({ color });
  }

  handleBgColorClick(bgColor) {
    this.setState({ bgColor });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  render() {
    const { classes, roles, ...rest } = this.props;
    const { bgColor, image, color, mobileOpen, miniActive } = this.state;
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1
    })}`;
    const isCrueDashboard = roles && roles.includes('CRUE') && rest.location.pathname === '/dashboard';
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={dashboardRoutes}
          logoText="Dashboard"
          logo={bgColor === 'white' ? logoBlue : logoWhite}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          {...rest}
        />
        <div id="scrollComponent" className={mainPanel} ref="mainPanel">
          <Header
            sidebarMinimize={this.sidebarMinimize}
            miniActive={miniActive}
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content} style={isCrueDashboard ? { padding: '0 5px' } : {}}>
              <div className={classes.container} style={isCrueDashboard ? { padding: '0' } : {}}>
                {switchRoutes(roles)}
              </div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes(roles)}</div>
          )}
          {/* this.getRoute() ? <Footer fluid /> : null */}
        </div>
      </div>
    );
  }
}
// ToDo verificar el tipo de dato de los propTypes
Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  alert: PropTypes.object,
  desktopNotification: PropTypes.object,
  location: PropTypes.object,
  roles: PropTypes.array
};

// const LayoutDashboard = webNotification(
//   withAlert(withStyles(appStyle)(Dashboard))
// );

// const LayoutDashboard = webNotification(withStyles(appStyle)(Dashboard));

const LayoutDashboard = compose(
  withStyles(appStyle),
  withAlert()
  // webNotification
)(Dashboard);

export default props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer => <LayoutDashboard {...props} roles={AuthContainer.state.roles} />}
  </Subscribe>
);
