// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import apiClient from 'config/apiClient';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import NxSpinner from '../NxSpinner/NxSpinner';
import HumanResources from './CapacitySection/HumanResource';
import NonSurgicalSupport from './CapacitySection/NonSurgicalSupport';
import SupportServices from './CapacitySection/SupportServices';
import DiagnosticAids from './CapacitySection/DiagnosticAids';
import Urgency from './CapacitySection/Urgency';
import Internment from './CapacitySection/Internment';

// Others
import { urlApi } from '../../config/app';
import {
  humanResourcesFields,
  internmentFields,
  urgencyFields,
  nonSurgicalSupportFields
} from '../../variables/resources';
import { buildResource, buildCapacities } from '../../utils/resources';

// style for this view
const styles = {
  cardBodyJustify: {
    display: 'flex',
    justifyContentCenter: 'space-between'
  },
  labels: {
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.5',
    fontWeight: 'bold',
    paddingTop: '39px',
    marginRight: '0'
  },
  ...validationFormsStyle
};

const initialState = {};

export class Capacity extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { alert } = this.props;
    this.setState({ loading: true });
    apiClient.SEM.get(`${urlApi}/ips/resources/capacity`)
      .then(response => {
        if (response.data) {
          const capacity = buildCapacities(response.data.data);
          this.setState({ ...capacity });
          alert.show('Se han cargado los últimos recursos reportados', {
            type: 'info',
            timeout: 4000
          });
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  handleChangeSwitch = (name, value, type) => {
    this.setState(prevState => ({
      [type]: {
        ...prevState[type],
        [name]: value
      }
    }));
  };

  supportServicesHandleChange = (name, value) => {
    this.setState(prevState => ({
      supportServices: {
        ...prevState.supportServices,
        [name]: value
      }
    }));
  };

  diagnosticAidsHandleChange = (name, value) => {
    this.setState(prevState => ({
      diagnosticAids: {
        ...prevState.diagnosticAids,
        [name]: value
      }
    }));
  };

  urgencyHandleChange = (name, value) => {
    this.setState(prevState => ({
      urgency: {
        ...prevState.urgency,
        [name]: value
      }
    }));
  };

  internmentHandleChange = (name, value) => {
    this.setState(prevState => ({
      internment: {
        ...prevState.internment,
        [name]: value
      }
    }));
  };

  saveResources = () => {
    const { alert } = this.props;
    this.setState({ loading: true });
    const data = buildResource(this.state);
    apiClient.SEM.post(`${urlApi}/ips/resources/capacity`, data)
      .then(() => {
        alert.show('Se ha guardado el reporte de capacidad exitosamente.', {
          type: 'info',
          timeout: 4000
        });
      })
      .catch(() => {
        alert.show('No se pudo guardar el reporte de capacidad, inténtalo más tarde.', {
          type: 'error',
          timeout: 4000
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  buttonState = () => {
    const stateClone = { ...this.state };
    const globalState = {
      ...stateClone.humanResource,
      ...stateClone.internment,
      ...stateClone.nonSurgicalSupport,
      ...stateClone.supportServices,
      ...stateClone.urgency
    };
    const keysToValidate = [];
    const scheduleToValidate = [];
    // const ceroKeys = ['expansionBedsUrg', 'expansionChairsUrg'];

    // const fieldsToValidate = [...supportServiceFields, ...internmentFields, ...urgencyFields];
    const fieldsToValidate = [...internmentFields, ...urgencyFields];

    fieldsToValidate.forEach(field => {
      if (globalState[field.checkName]) {
        keysToValidate.push(field.qtyName);
      }
    });
    humanResourcesFields.forEach(field => {
      if (globalState[field.name]) {
        scheduleToValidate.push(field.scheduleName);
      }
    });
    nonSurgicalSupportFields.forEach(field => {
      if (globalState[field.name]) {
        scheduleToValidate.push(field.scheduleName);
      }
    });

    const invalidKeys = keysToValidate.some(key => !globalState[key]);
    const scheduleKeys = scheduleToValidate.some(key => (globalState[key] || []).length < 1);
    // const invalidCeroKeys = ceroKeys.some(key => `${globalState[key]}` === 'undefined' || `${globalState[key]}` === '');
    return invalidKeys || scheduleKeys; // || invalidCeroKeys;
  };

  render() {
    const { classes = {} } = this.props;
    const stateClone = { ...this.state };
    const { humanResource, supportServices, diagnosticAids, nonSurgicalSupport, urgency, internment } = this.state;
    const disableButton = this.buttonState();
    return (
      <GridContainer>
        <NxSpinner loading={stateClone.loading} />
        <GridItem xs={12}>
          <i>Los siguientes campos están relacionados con la capacidad que tiene la entidad en las diferentes áreas.</i>
        </GridItem>
        <HumanResources handleChange={this.handleChangeSwitch} state={humanResource} />
        <NonSurgicalSupport /* classes={classes} */ handleChange={this.handleChangeSwitch} state={nonSurgicalSupport} />
        <SupportServices
          handleChange={this.handleChangeSwitch}
          state={supportServices}
          qtyHandleChange={this.supportServicesHandleChange}
        />
        <DiagnosticAids
          handleChange={this.handleChangeSwitch}
          state={diagnosticAids}
          qtyHandleChange={this.diagnosticAidsHandleChange}
        />
        <Urgency
          handleChange={this.urgencyHandleChange}
          handleChangeCheckbox={this.handleChangeCheckbox}
          state={urgency}
        />
        <Internment classes={classes} handleChange={this.internmentHandleChange} state={internment} />
        <GridItem container justify="center">
          <div>
            <Button color="info" onClick={this.saveResources} disabled={disableButton}>
              Reportar capacidad
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

Capacity.propTypes = {
  classes: PropTypes.object,
  alert: PropTypes.object
};

Capacity.defaultProps = {
  classes: {}
};

export default withAlert()(withStyles(styles)(Capacity));
