import React, { useState, useCallback } from 'react';
import apiClient from 'config/apiClient';
import { useAlert } from 'react-alert';
import CustomInput from 'components/CustomInput/CustomInput';

// @material-ui/core
import FormControl from '@material-ui/core/FormControl';

// Components
import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem';

import { urlApi } from '../../config/app';

const FileDownload = require('js-file-download');

const ChatReport = () => {
  const [inputs, setInputs] = useState({});
  const alert = useAlert();
  const handleChange = useCallback((name, value) => {
    setInputs({ ...inputs, [name]: value });
  }, []);
  const download = () => {
    const { initialDate, endDate, referralId } = inputs;
    const params = {
      referralId
    };
    handleChange('loading', true);
    apiClient.SEM.get(`${urlApi}/reports/referralChat`, { responseType: 'blob', params })
      .then(async response => {
        const regexp = new RegExp('zip');
        if (regexp.test(response.headers['content-type'])) {
          FileDownload(response.data, `reporte_chat_traslado_${referralId}.zip`);
        } else {
          const error = "No se encontró información para el traslado indicado";
          alert.show(`${error}`, { type: 'error', timeout: 5000 });
        }
      })
      .catch(error => {
        alert.show(`Se ha producido un error al descargar el archivo.`, { type: 'error', timeout: 5000 });
        console.error(`Se ha producido un error al descargar el archivo: ${error}`);
      })
      .finally(() => handleChange('loading', false));
  };

  return (
    <div>
      <div style={{ display: 'flex', marginTop: '15px' }}>
        <GridItem xs={12} sm={4}>
          <CustomInput
            labelText="Buscar por ID deltraslado"
            formControlProps={{ fullWidth: true }}
            id="referralId"
            inputProps={{
              onChange: event => setInputs({ ...inputs, referralId: event.target.value }),
              name: 'referralId',
              value: inputs.referralId || '',
              type: 'number'
            }}
          />
        </GridItem>
      </div>
      <GridItem container justify="center">
        <Button onClick={download} style={{ marginTop: '20px' }} color="info" disabled={inputs.loading}>
          {inputs.loading ? 'Descargando...' : 'Descargar'}
        </Button>
      </GridItem>
    </div>
  );
};

export default ChatReport;

