import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import redPin from 'assets/img/Covid/red-pin.png';
import grayPin from 'assets/img/Covid/gray-pin.png';

const mapOptions = {
  scrollwheel: true,
  zoomControl: false,
  fullscreenControl: false,
  mapTypeId: 'roadmap',
  styles: [
    {
      featureType: 'water',
      stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#808080' }, { lightness: 54 }]
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ece2d9' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ccdca1' }]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#767676' }]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#ffffff' }]
    },
    { featureType: 'poi', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [{ visibility: 'on' }, { color: '#b8cb93' }]
    },
    { featureType: 'poi.park', stylers: [{ visibility: 'on' }] }
  ]
};

const pinUrl = (selectedIps, ips) => {
  if (!selectedIps.ips) {
    return redPin;
  }
  return selectedIps.ips === ips.ips ? redPin : grayPin;
};

const MyMapComponent = React.memo(
  withScriptjs(
    withGoogleMap(props => {
      const { selectedIps, ipsList, zoom, center } = props;
      return (
        <GoogleMap
          zoom={zoom}
          defaultCenter={{
            lat: 6.235925,
            lng: -75.57513
          }}
          ref={map => map && map.panTo(center)}
          // center={center}
          defaultOptions={{
            ...mapOptions,
            mapTypeControl: false,
            streetViewControl: false
          }}
        >
          {ipsList.map(ips => {
            if (ips.cases.covid > 0) {
              return (
                <Marker
                  icon={{
                    url: pinUrl(selectedIps, ips),
                    scaledSize: new window.google.maps.Size(40, 40)
                  }}
                  position={{ lat: ips.lat, lng: ips.lng }}
                  animation={selectedIps.ips === ips.ips && window.google.maps.Animation.DROP}
                />
              );
            }
            return null;
          })}
        </GoogleMap>
      );
    })
  )
);

export default MyMapComponent;
