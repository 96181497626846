import React from 'react';
import { withRouter } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

const HighlightedCell = ({ value, style, ...restProps }) => {
  let background;
  let message = 'Sin información';

  if (value <= 50) {
    background = '#008000';
    message = 'Normal';
  } else if (value <= 100) {
    background = '#ffd700';
    message = 'Ocupado';
  } else if (value <= 140) {
    background = '#ffa500';
    message = 'Hacinamiento';
  } else if (value <= 180) {
    background = '#ff0000';
    message = 'Peligroso';
  } else if (value > 180) {
    background = '#000';
    message = 'Desastres';
  }
  return (
    <Table.Cell
      {...restProps}
      style={{
        ...style
      }}
    >
      <Tooltip placement="left" title={message}>
        <Chip
          label={value}
          style={{ backgroundColor: background, color: 'white', width: '100%', fontWeight: 'bold' }}
        />
      </Tooltip>
    </Table.Cell>
  );
};

export const Cell = withRouter(props => {
  const { column } = props;
  if (column.name === 'porcSaturacion' && props.value && props.value !== 'undefined') {
    return <HighlightedCell {...props} />;
  }
  if (column.name === 'createdBy') {
    return (
      <Table.Cell>
        <Button
          style={{ textTransform: 'capitalize' }}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => props.history.push(`/recursos/${props.value.split('|')[1]}`)}
        >
          {props.value.split('|')[0].toLowerCase()}
        </Button>
      </Table.Cell>
    );
  }
  return <Table.Cell {...props} />;
});
