import * as React from 'react';
import PropTypes from 'prop-types';

function ChartIcon(props) {
  const { color, height, width, ...rest } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 41.91 28.779" {...rest}>
      <defs>
        <style>
          {`.prefix__b,.prefix__d{fill:none}.prefix__b{stroke:${color};stroke-linecap:round;stroke-width:2px;stroke-linejoin:round}.prefix__c{stroke:none}`}
        </style>
      </defs>
      <path stroke={color} strokeLinecap="round" strokeWidth={2} fill="none" d="M1 27.779h39.91" />
      <g className="prefix__b">
        <path className="prefix__c" d="M5.455 14h9v14h-9z" />
        <path className="prefix__d" d="M6.455 15h7v12h-7z" />
      </g>
      <g className="prefix__b">
        <path className="prefix__c" d="M17.455 6h8v22h-8z" />
        <path className="prefix__d" d="M18.455 7h6v20h-6z" />
      </g>
      <g className="prefix__b">
        <path className="prefix__c" d="M28.455 0h9v28h-9z" />
        <path className="prefix__d" d="M29.455 1h7v26h-7z" />
      </g>
    </svg>
  );
}

ChartIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

ChartIcon.defaultProps = {
  color: '#858585',
  height: 20,
  width: 40
};

export default ChartIcon;
