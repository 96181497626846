import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CustomToolbar from './sections/customToolbar'
import {tableLabels} from 'utils/Table/lang'

export function getColumns({handleOpen,history}) {
  return [
    {
      name: 'agreementId',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue, ...rest) => {
          return (
            <>
              <IconButton aria-label="Editar Agreement" onClick={() => history.push(`/agreements/${value}`)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="Eliminar Agreement" onClick={() => handleOpen(value)}>
                <DeleteIcon />
              </IconButton>
            </>
        )},
      },
    },
    {
      name: 'ipsName',
      label: 'IPS',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'network',
      label: 'Red',
      options: {
        filter: true,
        sort: false,
      },
    },
  ]
}

export const options = (props) => {
  return {
  print: false,
  download: false,
  viewColumns: false,
  selectableRows: false,
  customToolbar: () => <CustomToolbar {...props}/>,
  textLabels: tableLabels,
  }
}
