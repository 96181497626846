import * as React from 'react';
import PropTypes from 'prop-types';

function Hospital(props) {
  const { color } = props;
  return (
    <svg
      id="prefix__Capa_1"
      x={0}
      y={0}
      viewBox="0 0 40.7 33.3"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      fill={color}
      {...props}
    >
      <path
        className="prefix__st0"
        d="M39 30.7h-2.1v-16c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v-12h.1c.6 0 1-.4 1-1s-.4-1-1-1H6c-.6 0-1 .4-1 1s.4 1 1 1h.8v28H1.7c-.6 0-1 .4-1 1s.4 1 1 1H39c.6 0 1-.4 1-1s-.5-1-1-1zm-8.1-15.1h4v14h-4v-14zm-2 15.1c-.1 0 0 0 0 0zm-20-1.1v-26h18v26h-5v-8c0-.6-.4-1-1-1h-8c-.6 0-1 .4-1 1v8h-3zm5-7h6v7h-6v-7z"
      />
      <path
        className="prefix__st0"
        d="M18.9 18.4h2.9c.6 0 1-.4 1-1s-.4-1-1-1h-2.9c-.6 0-1 .4-1 1 0 .5.5 1 1 1zM18.9 14.1h2.9c.6 0 1-.4 1-1s-.4-1-1-1h-2.9c-.6 0-1 .4-1 1s.5 1 1 1zM18.9 9.8h2.9c.6 0 1-.4 1-1s-.4-1-1-1h-2.9c-.6 0-1 .4-1 1s.5 1 1 1zM13.2 18.4h2.9c.6 0 1-.4 1-1s-.4-1-1-1h-2.9c-.6 0-1 .4-1 1 0 .5.4 1 1 1zM13.2 14.1h2.9c.6 0 1-.4 1-1s-.4-1-1-1h-2.9c-.6 0-1 .4-1 1s.4 1 1 1zM13.2 9.8h2.9c.6 0 1-.4 1-1s-.4-1-1-1h-2.9c-.6 0-1 .4-1 1s.4 1 1 1z"
      />
    </svg>
  );
}

Hospital.propTypes = {
  color: PropTypes.string
};

Hospital.defaultProps = {
  color: '#c1c1c1'
};

export default Hospital;
