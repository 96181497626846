export const tableLabels = {
  body: {
    noMatch: 'Lo sentimos, no hay registros encontrados',
    toolTip: 'Ordenar',
  },
  pagination: {
    next: 'Pagina siguiente',
    previous: 'Pagina previa',
    rowsPerPage: 'Filas por pagina:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Descargar CSV',
    print: 'Imprimir',
    viewColumns: 'Ver Columnas',
    filterTable: 'Filtrar Tabla',
  },
  filter: {
    all: 'Todo',
    title: 'Filtros',
    reset: 'Reiniciar',
  },
  viewColumns: {
    title: 'Mostrar Columnas',
    titleAria: 'Mostrar/Ocultar Columnas de la tabla',
  },
  selectedRows: {
    text: 'Fila seleccionada',
    delete: 'Eliminar',
    deleteAria: 'Eliminar fila seleccionada',
  },
}
