export const reasonConsultation = [
  { value: 'burns', name: 'Quemaduras' },
  { value: 'cardiorespiratoryArrest', name: 'Paro cardiorespiratorio' }
];

export const referralReason = [
  { value: '1', name: 'No se cuenta con el recurso tecnológico' },
  { value: '2', name: 'No tiene habilitado el servicio' },
  { value: '3', name: 'Requiere un grado de complejidad superior' },
  { value: '4', name: 'No cuenta con el recurso humano' },
  { value: '5', name: 'Otro ¿Cuál?' }
];

export const keysToValidate = [
  'annex9',
  'ambulanceType',
  'attentionNetwork',
  'birthdate',
  'cie-10',
  'document',
  'documentType',
  'eps',
  'firstName',
  'regimeType',
  'lastName',
  'sex',
  'referralReason',
  'triage',
  'reqIsolation',
  'careForDiagnosis'
];
