import React from 'react';
import PropTypes from 'prop-types';
// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
// import Slide from "@material-ui/core/Slide";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from 'components/CustomButtons/Button';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';

function Modal(props) {
  const {
    classes,
    actionsModal,
    titleModal,
    // contentModal,
    handleClose,
    showModal,
    children
  } = props;

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={showModal}
        keepMounted
        onClose={() => handleClose()}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => handleClose()}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{titleModal}</h4>
        </DialogTitle>
        <DialogContent id="modal-slide-description" className={classes.modalBody}>
          {/* <h5>Are you sure you want to do this?</h5> */}
          {/* contentModal() */}
          {children}
        </DialogContent>
        <DialogActions className={`${classes.modalFooter} ${classes.modalFooterCenter}`}>
          {/* <Button
              onClick={() => this.props.handleClose("modal")}
              color="simple">
              Never Mind
            </Button>
            <Button
              onClick={() => this.props.handleClose("modal")}
              color="successNoBackground">
              Yes
            </Button> */}
          {actionsModal()}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(modalStyle)(Modal);

Modal.propTypes = {
  classes: PropTypes.object,
  actionsModal: PropTypes.any,
  // contentModal: PropTypes.any,
  children: PropTypes.element,
  titleModal: PropTypes.any,
  handleClose: PropTypes.any,
  showModal: PropTypes.bool
  // color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};
