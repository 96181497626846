// @flow

import React from 'react';
// import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
// import queryString from 'query-string';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { fade } from '@material-ui/core/styles/colorManipulator';

const tableStyles = theme => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.15)
    }
  }
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(tableStyles, { name: 'TableComponent' })(TableComponentBase);
