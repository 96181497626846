import React from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import axios from 'axios';

// Components
import AmbulanceRequestForm from '../../nxComponents/Ambulances/AmbulanceRequestForm';
import SearchPerson from '../../nxComponents/Transfer/SearchPerson';
import NxSpinner from '../../nxComponents/NxSpinner/NxSpinner';

// Others
import { urlApi } from '../../config/app';

export class Ambulance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      loading: false,
      hasDocument: true
    };
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleSearchPerson = document => {
    const { alert } = this.props;
    const { hasDocument } = this.state;
    if (hasDocument) {
      this.setState({ loading: true });
      axios
        .get(`${urlApi}/legacy/person?document=${document}`)
        .then(({ data }) => {
          this.setState({
            showForm: true,
            person: {
              ...data.data.P
            }
          });
          alert.show('Se ha cargado la información de la persona correctamente', {
            type: 'info',
            timeout: 4000
          });
        })
        .catch(() => {
          this.setState({
            showForm: true
          });
          alert.show('No se pudo traer la información de la persona', {
            type: 'error',
            timeout: 0
          });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({
        showForm: true
      });
    }
  };

  render() {
    const { document, hasDocument, loading, person, showForm } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <NxSpinner loading={loading} />
        {showForm ? (
          <AmbulanceRequestForm {...this.props} person={{ ...person, document }} />
        ) : (
          <SearchPerson
            classes={classes}
            document={document}
            handleChange={this.handleChange}
            handleSearchPerson={this.handleSearchPerson}
            hasDocument={hasDocument}
          />
        )}
      </div>
    );
  }
}

Ambulance.propTypes = {
  classes: PropTypes.object,
  alert: PropTypes.object
};

Ambulance.defaultProps = {
  classes: {}
};

export default withAlert()(Ambulance);
