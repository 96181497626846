import * as React from 'react';

function ManIconWhiteBackgroun(props) {
  return (
    <svg {...props} width="30" height="30" viewBox="0 0 72 72">
      <defs>
        <filter id="prefix__a" x={0} y={0} width={72} height={72} filterUnits="userSpaceOnUse">
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur" />
          <feFlood floodOpacity={0.161} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Grupo 1091">
        <g filter="url(#prefix__a)">
          <circle data-name="Elipse 222" cx={27} cy={27} r={27} transform="translate(9 6)" fill="#fff" />
        </g>
        <path
          data-name="Trazado 1015"
          d="M48.013 45.111l-1.39-7.173a1.957 1.957 0 00-1.215-1.443l-4.205-1.37-1.089-.752a.17.17 0 01-.073-.141v-.482a6.218 6.218 0 003.053-5.348v-.024a1.687 1.687 0 00.933-2.1.685.685 0 00.115-.361V21.55a3.587 3.587 0 00-3.583-3.583h-1.1a3.5 3.5 0 00-2.8-1.423h-.653a6.292 6.292 0 00-6.284 6.285v2.973a.676.676 0 00.074.3 1.7 1.7 0 00.86 2.273v.027a6.218 6.218 0 003.062 5.349v.477a.172.172 0 01-.074.141l-1.089.752-4.256 1.389a1.937 1.937 0 00-1.163 1.425l-1.388 7.166a1.918 1.918 0 00-.037.375 1.946 1.946 0 001.945 1.944h18.452a1.947 1.947 0 001.908-2.314z"
          fill="#00a5c2"
        />
      </g>
    </svg>
  );
}

export default ManIconWhiteBackgroun;
