// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';

import { TableComponent } from '../CovidTotal/TableComponentBase';
import { Cell } from './CellComponent';
import { HeaderCellBase } from '../CovidTotal/HeaderCellBase';

const getRowId = row => row.name;

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;

const styles = theme => ({
  center: {
    textAlign: 'center'
  },
  marginZero: {
    margin: 0
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.4em'
    }
  },
  totalChip: {
    margin: '1px',
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.7em'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.9em'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '0.8em'
    }
  },
  icon: {
    margin: '1px',
    [theme.breakpoints.down('sm')]: {
      width: '2em',
      height: '2em'
    },
    [theme.breakpoints.up('md')]: {
      width: '2em',
      height: '2em'
    },
    [theme.breakpoints.up('lg')]: {
      width: '1.5em',
      height: '1.5em'
    },
    [theme.breakpoints.up('xl')]: {
      width: '1.4em',
      height: '1.4em'
    }
  },
  total: {
    fontWeight: 'bold',
    color: '#828282',
    margin: 0,
    textAlign: 'center'
  },
  covid: {
    fontWeight: 'bold',
    color: '#F00000'
  },
  ira: {
    fontWeight: 'bold',
    color: '#005C9A'
  }
});

function TableIpsInfected(props) {
  const { dataTable = [], config, classes } = props;
  return (
    <Paper className={classes.paper}>
      <Grid rows={dataTable} columns={config.columns} rootComponent={Root}>
        <VirtualTable
          estimatedRowHeight={85}
          columnExtensions={config.columnExtensions}
          cellComponent={prop => <Cell {...prop} classes={classes} />}
          tableComponent={TableComponent}
        />
        <TableHeaderRow cellComponent={prop => <HeaderCellBase {...prop} classes={classes} />} />
      </Grid>
    </Paper>
  );
}

TableIpsInfected.propTypes = {
  config: PropTypes.object.isRequired,
  dataTable: PropTypes.array,
  classes: PropTypes.object
};

export default withStyles(styles, { name: 'TransfersResources' })(TableIpsInfected);
