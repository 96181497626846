import React from 'react';
import PropTypes from 'prop-types';

// Components
import CustomInput from 'components/CustomInput/CustomInput';

// Core components
import Close from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';

// Styles
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import { changeValidator } from '../../utils/Validator';

/**
 * @param {Object} validation
 * @param {Object} classes
 * @param {string} styles
 * @param {string} placeHolder
 * @param {string} name
 * @param {number} value
 * @param {boolean} disabled
 * @param {boolean} required
 */

export class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSimple = (event, validationType, number, maxValue) => {
    const { handleChange } = this.props;
    let { value } = event.target;
    const { name } = event.target;
    const regExp = /^[0-9]*$/g;
    const valueArr = value.split('');
    value = valueArr.filter(word => word.match(regExp)).join('');
    const valToNum = value ? parseInt(value, 10) : value;
    let error;
    handleChange(name, valToNum);
    if (validationType === 'min-value') {
      error = value ? 'success' : 'error';
    } else {
      error = changeValidator(event, validationType, number, maxValue);
    }
    this.setState({ error });
  };

  render() {
    const { classes, styles, placeHolder, name, validation, value, disabled, required, id } = this.props;
    const { error } = this.state;
    const { type, number, maxValue } = validation;
    return (
      <CustomInput
        // success={error === 'success'}
        error={(required && `${value}` === 'undefined') || error === 'error'}
        id={id ? id : name}
        labelText={placeHolder}
        formControlProps={{
          fullWidth: true,
          className: styles
        }}
        inputProps={{
          onChange: event => this.handleSimple(event, type, number, maxValue),
          required: true,
          autoComplete: 'off',
          disabled,
          name,
          value,
          endAdornment:
            error === 'error' ? (
              <InputAdornment position="end">
                <Close className={classes ? classes.danger : null} />
              </InputAdornment>
            ) : (
              undefined
            )
        }}
      />
    );
  }
}

NumberInput.propTypes = {
  styles: PropTypes.string,
  classes: PropTypes.object,
  placeHolder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

NumberInput.defaultProps = {
  styles: '',
  classes: {},
  placeHolder: '',
  value: undefined,
  disabled: false,
  required: false
};

export default withStyles(validationFormsStyle)(NumberInput);
