import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';

// Styles
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

// core components
import FormControl from '@material-ui/core/FormControl';
import GridItem from 'components/Grid/GridItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import NumberInput from '../NumberInput/NumberInput';

// Others
import { addressRoadTypes, adressOrientations } from '../../variables/address';

/**
 * @param state
 * @param handleChange
 * @param classes
 * @param handleChangeNumber
 */

export const Address = props => {
  const { handleChange, state, classes, handleChangeNumber, required } = props;

  function hasError() {
    const {addressRoad, letterNumber, addressRoad2, number} = state;
    const values = [addressRoad, letterNumber, letterNumber, addressRoad2, number]
    return values.some(value => !value)
  }
  return (
    <FormGroup>
      <GridItem xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel style={{ paddingTop: '20px' }} error={required && hasError()}>Dirección:</FormLabel>
        <GridItem>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              value={state.addressRoad || ''}
              onChange={event => handleChange(event)}
              inputProps={{
                name: 'addressRoad',
                id: 'addressRoad'
              }}
            >
              <MenuItem value="">Selecciona un opción</MenuItem>
              {addressRoadTypes.map(road => (
                <MenuItem key={road.id} value={road.code}>
                  {road.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem>
          <NumberInput
            handleChange={handleChangeNumber}
            name="letterNumber"
            placeHolder="#"
            value={state.letterNumber || ''}
            styles={classes.formControlFlex}
            validation={{
              type: 'min-value',
              number: 0
            }}
          />
        </GridItem>

        <GridItem>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              value={state.addressLetter || ''}
              onChange={event => handleChange(event)}
              inputProps={{
                name: 'addressLetter',
                id: 'addressLetter'
              }}
            >
              <MenuItem value="">Selecciona un opción</MenuItem>
              {state.addressLetters.map(letter => (
                <MenuItem key={letter.id} value={letter.name}>
                  {letter.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              value={state.orientation || ''}
              onChange={event => handleChange(event)}
              inputProps={{
                name: 'orientation',
                id: 'orientation'
              }}
            >
              <MenuItem value="">Selecciona un opción</MenuItem>
              {adressOrientations.map(letter => (
                <MenuItem key={letter.id} value={letter.name}>
                  {letter.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem xs={6} sm={2}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              value={state.addressRoad2 || ''}
              onChange={event => handleChange(event)}
              inputProps={{
                name: 'addressRoad2',
                id: 'addressRoad2'
              }}
            >
              <MenuItem value="">Selecciona un opción</MenuItem>
              {addressRoadTypes.map(road => (
                <MenuItem key={road.id} value={road.code}>
                  {road.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem>
          <NumberInput
            handleChange={handleChangeNumber}
            name="street"
            placeHolder="#"
            value={state.street || ''}
            styles={classes.formControlFlex}
            validation={{
              type: 'min-value',
              number: 0
            }}
          />
        </GridItem>
        <GridItem>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              value={state.addressLetter2 || ''}
              onChange={event => handleChange(event)}
              inputProps={{
                name: 'addressLetter2',
                id: 'addressLetter2'
              }}
            >
              <MenuItem value="">Selecciona un opción</MenuItem>
              {state.addressLetters.map(letter => (
                <MenuItem key={letter.id} value={letter.name}>
                  {letter.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              value={state.orientation2 || ''}
              onChange={event => handleChange(event)}
              inputProps={{
                name: 'orientation2',
                id: 'orientation2'
              }}
            >
              <MenuItem value="">Selecciona un opción</MenuItem>
              {adressOrientations.map(letter => (
                <MenuItem key={letter.id} value={letter.name}>
                  {letter.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem>
          <NumberInput
            handleChange={handleChangeNumber}
            name="number"
            placeHolder="#"
            value={state.number || ''}
            styles={classes.formControlFlex}
            validation={{
              type: 'min-value',
              number: 0
            }}
          />
        </GridItem>
      </GridItem>
    </FormGroup>
  );
};

Address.propTypes = {
  classes: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleChangeNumber: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  required: PropTypes.bool
};

Address.defaultProps = {
  classes: {},
  required: false
};

export default withStyles(validationFormsStyle)(Address);
