import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiClient from 'config/apiClient';

// Material-ui components
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/styles';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';

import { urlApi } from '../../config/app';

const useStyles = makeStyles({
  marginCero: {
    margin: 0
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '20px',
    marginBottom: '25px'
  },
  lineContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0px'
  },
  line: {
    borderColor: '#f50057'
  },
  bodyContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '20px',
    marginBottom: '25px',
    marginTop: '0px',
    paddingTop: '0px',
    paddingBottom: '10px'
  },
  renderCases: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    height: '45vh',
    marginTop: '10px'
  },
  body: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '25px'
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const AmbulanceDuplicateForm = ({ handleSelect, submitToFirebase, formId, buttonState }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    latestEvents: []
  });

  function getLatestEvents() {
    apiClient.SEM.get(`${urlApi}/legacy/latestEvents`).then(({ data }) => {
      setState(prevState => {
        return {
          ...prevState,
          latestEvents: data.data.events
        };
      });
    });
  }

  useEffect(() => {
    if (state.latestEvents.length < 1) {
      getLatestEvents();
    }
  }, []);

  const handleSelectEvent = event => {
    handleSelect(event);
  };

  function renderEvents(latestEvents) {
    return latestEvents.map(eve => {
      return (
        <ListItem button divider dense key={eve.formId} onClick={() => handleSelectEvent(eve.formId)}>
          <GridContainer className={classes.spaceBetween}>
            <GridItem xs={3}>
              <ListItemText primary={eve.formId} />
            </GridItem>
            <GridItem xs={3}>
              <ListItemText primary={eve.address} />
            </GridItem>
            <GridItem xs={3}>
              <ListItemText primary={eve.cellphoneNumber} />
            </GridItem>
          </GridContainer>
        </ListItem>
      );
    });
  }

  return (
    <div>
      <CardActions className={classes.marginCero}>
        <GridContainer className={classes.container}>
          <GridItem>
            <h3>Duplicar incidente</h3>
          </GridItem>
        </GridContainer>
      </CardActions>
      <GridContainer className={classes.lineContainer}>
        <GridItem xs={10}>
          <hr className={classes.line} />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.bodyContainer}>
        <GridItem xs={12}>
          <CardBody>
            <List component="nav">
              <ListItem>
                <GridContainer className={classes.spaceBetween}>
                  <GridItem xs={3}>
                    <ListItemText primary="Id del formulario" />
                  </GridItem>
                  <GridItem xs={3}>
                    <ListItemText primary="Dirección" />
                  </GridItem>
                  <GridItem xs={3}>
                    <ListItemText primary="Teléfono" />
                  </GridItem>
                </GridContainer>
              </ListItem>
            </List>
          </CardBody>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.body}>
        <GridItem>
          <br />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.renderCases}>
        <GridItem xs={12}>
          <CardBody>
            {state.latestEvents.length >= 1 ? (
              <List component="nav">{renderEvents(state.latestEvents)}</List>
            ) : (
              <h3>Cargando...</h3>
            )}
          </CardBody>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.lineContainer}>
        <GridItem xs={10}>
          <hr className={classes.line} />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.buttonContainer}>
        <GridItem className={classes.lineContainer}>
          <div>
            <Button disabled={!formId || buttonState} color="secondary" variant="outlined" onClick={submitToFirebase}>
              Duplicar incidente
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

AmbulanceDuplicateForm.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  submitToFirebase: PropTypes.func.isRequired,
  formId: PropTypes.number,
  buttonState: PropTypes.bool
};

export default AmbulanceDuplicateForm;
