// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import { withAlert } from 'react-alert';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import queryString from 'query-string';
import socketSEM from 'config/ws';

import Paper from '@material-ui/core/Paper';
import { SearchState, PagingState, CustomPaging, IntegratedFiltering } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
  PagingPanel,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core';

// Core components
import AuthContainer from 'containers-state/auth';
import TransfersContainer from 'containers-state/transferState';
// import { withRouter } from 'react-router-dom';
import FilterComponent from './FilterComponent';
import { defaultColumns, defaultTableColumnExtensions } from './initialConfig';
import './tableTransfers.css';

const initialState = {
  columns: defaultColumns,
  tableColumnExtensions: defaultTableColumnExtensions,
  searchValue: ''
};

const getRowId = row => row.id;
const hours = [
  'creationCaseDate',
  'validationCaseDate',
  'ambulanceDispatchDate',
  'eventArrivalDate',
  'transportDate',
  'ipsArrivalDate',
  'ipsProfessionalSignatureDate',
  'patientCareEndDate',
  'availableDate'
];

function actionRequired(
  {
    validationCaseDate,
    ambulanceDispatchDate,
    eventArrivalDate,
    transportDate,
    ipsArrivalDate,
    ipsProfessionalSignatureDate,
    patientCareEndDate,
    availableDate
  },
  ambAssignedBy,
  referralState
) {
  if (referralState === 'REJECTED' || referralState === 'REFERRAL_ENDED') return false;
  if (ambulanceDispatchDate && ambAssignedBy === 'EPS' && !patientCareEndDate) return true;
  if (ambulanceDispatchDate && (ambAssignedBy === 'CRUE' || ambAssignedBy === 'CRUE_DEP') && !eventArrivalDate)
    return true;
  if (transportDate && !ipsArrivalDate && (ambAssignedBy === 'CRUE' || ambAssignedBy === 'CRUE_DEP')) return true;
  if (ipsProfessionalSignatureDate && !patientCareEndDate && (ambAssignedBy === 'CRUE' || ambAssignedBy === 'CRUE_DEP'))
    return true;
  if (patientCareEndDate && !availableDate && (ambAssignedBy === 'CRUE' || ambAssignedBy === 'CRUE_DEP')) return true;
  return false;
}

const TableRow = ({ row, ...restProps }) => (
  <Table.Row
    {...restProps}
    className={actionRequired(row.attentionHours, row.ambAssignedBy, row.referralState) ? 'action-requiried' : null}
  />
);

TableRow.propTypes = {
  row: PropTypes.object
};

// const pluginDependencies = [{ name: 'Toolbar' }];

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;

class TransfersInProgress extends React.PureComponent {
  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search);
    const pageSize = values.pageSize ? parseInt(values.pageSize, 10) : 20;
    const currentPage = values.currentPage ? parseInt(values.currentPage, 10) - 1 : 0;
    this.statusFilter = values.state ? values.state : 'pending';
    const { history } = this.props;

    this.state = {
      ...initialState,
      totalCount: 0,
      pageSizes: [20],
      pageSize,
      currentPage
      // filterState
    };

    this.cell = this.cell.bind(this);
    this.changeSearchValue = value => this.setState({ searchValue: value });

    this.changeCurrentPage = newCurrentPage => {
      // const { pageSize } = this.state;
      this.setState({ currentPage: newCurrentPage });
      // history.replace({
      //   search: `?currentPage=${newCurrentPage + 1}&pageSize=${this.state.pageSize}`
      // });
    };

    this.changePageSize = newPageSize => {
      // const { currentPage } = this.state;
      this.setState({ pageSize: newPageSize });
      // history.replace({
      //   search: `?currentPage=${this.state.currentPage + 1}&pageSize=${newPageSize}`
      // });
    };

    // this.changeFilterState = newState => {
    //   this.setState({ filterState: newState });
    // };

    history.replace({
      search: `?currentPage=${currentPage + 1}&pageSize=${pageSize}&state=${this.statusFilter}`
    });
  }

  componentDidMount() {
    // this.loadData();
    socketSEM.on('notification', async data => {
      // ToDo, preguntar por el action_type para saber que querystring se debe enviar para recargar la pagina
      const currentQuery = this.getCurrentQuery();
      const { getTransfers } = this.props;
      if (this.statusFilter === 'pending') {
        const response = await getTransfers(currentQuery);
        const { pageSize } = this.state;
        this.setState({
          totalCount: pageSize * (((response || {}).data || {}).data || {}).totalPages
        });
      }
    });
  }

  componentDidUpdate() {
    this.loadData();
  }

  getCurrentQuery = filterValue => {
    const { pageSize, currentPage } = this.state;
    if (filterValue) this.statusFilter = filterValue.status;
    return `?limit=${pageSize}&page=${currentPage + 1}&state=${this.statusFilter}`;
  };

  loadData = async filterValue => {
    const currentQuery = this.getCurrentQuery(filterValue);
    if (currentQuery === this.lastQuery) {
      // this.setState({ loading: false });
      return;
    }
    this.lastQuery = currentQuery;

    const { getTransfers, history } = this.props;
    const response = await getTransfers(currentQuery);
    const { pageSize, currentPage } = this.state;
    history.replace({
      search: `?currentPage=${currentPage + 1}&pageSize=${pageSize}&state=${this.statusFilter}`
    });
    this.setState({
      totalCount: pageSize * (((response || {}).data || {}).data || {}).totalPages
    });
  };

  // / aca va el putHour

  cell(props) {
    const { history } = this.props;
    if (props.column.name === 'transferNumber') {
      return (
        <Table.Cell>
          <Button variant="contained" color="primary" onClick={() => history.push(`/traslados/${props.value}`)}>
            {props.value}
            <ArrowForward />
          </Button>
        </Table.Cell>
      );
    }
    if (hours.includes(props.column.name)) {
      if (props.column.getCellValue(props.row))
        return <Table.Cell {...props} style={{ background: '#5fce5f', color: '#fff' }} />;
    }
    return <Table.Cell {...props} />;
  }

  render() {
    const { columns, searchValue, tableColumnExtensions, pageSize, currentPage, totalCount, pageSizes } = this.state;
    const { rows, roles } = this.props;
    return (
      <Paper style={{ height: 'calc(90vh - 80px)' }}>
        {/*  <FilterComponent getFilteredList={getTransfers} roles={roles} /> */}
        <Grid rows={rows} columns={columns} getRowId={getRowId} rootComponent={Root}>
          <VirtualTable height="auto" /* columnExtensions={tableColumnExtensions} */ />
          <SearchState value={searchValue} onValueChange={this.changeSearchValue} />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <IntegratedFiltering />
          <CustomPaging totalCount={totalCount} />
          <Table rowComponent={TableRow} columnExtensions={tableColumnExtensions} cellComponent={this.cell} />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel />
          <Plugin>
            <Template name="toolbarContent">
              <TemplatePlaceholder />
              <FilterComponent getFilteredList={this.loadData} roles={roles} />
            </Template>
          </Plugin>
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      </Paper>
    );
  }
}

TransfersInProgress.propTypes = {
  roles: PropTypes.array,
  history: PropTypes.object,
  alert: PropTypes.object
};

const ComponentTransfersInProgress = withAlert()(TransfersInProgress);

export default props => (
  <Subscribe to={[AuthContainer, TransfersContainer]}>
    {(authData, transferData) => (
      <ComponentTransfersInProgress
        {...props}
        roles={authData.state.roles}
        rows={transferData.state.transfers}
        getTransfers={transferData.getTransfers}
      />
    )}
  </Subscribe>
);
