import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ImageIcon from '@material-ui/icons/Image';
import { Subscribe } from 'unstated';
import NotificationsContainer from 'containers-state/notificationState';
import format from 'date-fns/format';
// import { notifications } from '../../__fixtures__/notifications';

const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    height: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  }
});

const AlignItemsList = React.memo(props => {
  const { classes, notifications } = props;
  if (notifications.length === 0) {
    return <h3>No hay Notificaciones para mostrar</h3>;
  }

  return notifications.map(notification => (
    <List className={classes.root}>
      <ListItem
        alignItems="flex-start"
        button
        onClick={() => props.history.push(`/traslados/${((notification.metadata || {}).referral || {}).id || ''}`)}
      >
        <ListItemAvatar>
          <ImageIcon />
        </ListItemAvatar>
        <ListItemText
          primary={notification.title}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="subtitle1"
                gutterBottom
                className={classes.inline}
                color="textPrimary"
              >
                {notification.shortTitle}
              </Typography>
              {` — ${format(new Date(notification.timestamp), 'DD/MM/YYYY HH:mm')}`}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  ));
});

AlignItemsList.propTypes = {
  classes: PropTypes.object.isRequired
};

const AlignItemsListwithStyle = withStyles(styles)(AlignItemsList);

export default props => (
  <Subscribe to={[NotificationsContainer]}>
    {({ state }) => <AlignItemsListwithStyle {...props} notifications={state.notifications} />}
  </Subscribe>
);
