// function that returns true if value is email, false otherwise
export function verifyEmail(value) {
  /* eslint max-len: ["error", { "ignoreRegExpLiterals": true }] */
  const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}

// function that verifies if a string has a given length or not
export function verifyLength(value, length) {
  if (value.length >= length) {
    return true;
  }
  return false;
}

export function verifyMinValue(value, maxValue) {
  if (value < maxValue) {
    return true;
  }
  return false;
}

export function verifyExactLength(value, length) {
  if (value.length === length) {
    return true;
  }
  return false;
}

export function betweenLength(value, min, max) {
  if (value.length >= min && value.length <= max) {
    return true;
  }
  return false;
}

export function verify2lengths(value, length1, length2) {
  if (value.length === length1 || value.length === length2) {
    return true;
  }
  return false;
}

// function that verifies if two strings are equal
export function compare(string1, string2) {
  if (string1 === string2) {
    return true;
  }
  return false;
}

// function that verifies if value contains only numbers
export function verifyNumber(value) {
  const numberRex = new RegExp('^[0-9]+$');
  if (numberRex.test(value)) {
    return true;
  }
  return false;
}

// verifies if value is a valid URL
export function verifyUrl(value) {
  try {
    new URL(value); // eslint-disable-line no-new
    return true;
  } catch (_) {
    return false;
  }
}

export function changeValidator(event, type, stateNameEqualTo, maxValue) {
  let res;
  switch (type) {
    case 'email':
      if (verifyEmail(event.target.value)) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'password':
      if (verifyLength(event.target.value, 1)) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'equalTo':
      if (compare(event.target.value, stateNameEqualTo)) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'checkbox':
      if (event.target.checked) {
        res = '';
      } else {
        res = 'error';
      }
      return res;

    case 'number':
      if (verifyNumber(event.target.value)) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'length':
      if (verifyLength(event.target.value, stateNameEqualTo)) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'smallerThan':
      if (verifyMinValue(event.target.value, stateNameEqualTo)) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'cellphone':
      if (
        verifyExactLength(event.target.value, 10) &&
        verifyNumber(event.target.value)
      ) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;
    case 'phone':
      if (
        verifyExactLength(event.target.value, 7) &&
        verifyNumber(event.target.value)
      ) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'otherPhone':
      if (
        verify2lengths(event.target.value, 7, 10) &&
        verifyNumber(event.target.value)
      ) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'max-length':
      if (!verifyLength(event.target.value, stateNameEqualTo + 1)) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'url':
      if (verifyUrl(event.target.value)) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'min-value':
      if (
        verifyNumber(event.target.value) &&
        event.target.value >= stateNameEqualTo
      ) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'max-value':
      if (
        verifyNumber(event.target.value) &&
        event.target.value <= stateNameEqualTo
      ) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;

    case 'range':
      if (
        verifyNumber(event.target.value) &&
        event.target.value >= stateNameEqualTo &&
        event.target.value <= maxValue
      ) {
        res = 'success';
      } else {
        res = 'error';
      }
      return res;
    default:
      break;
  }
}
