import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

// Assets
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

// Components
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import Table from 'components/Table/Table';

const useStyles = makeStyles({
  ...dashboardStyle
});

function AgreementsInfo({ agreements }) {
  const classes = useStyles();

  const renderAgreements = type => {
    return agreements[type].map(data => {
      return [
        data.network,
        data.names.length > 0 ? data.names.map(name => <p>- {name}</p>) : 'No tiene acuerdos para esta red.'
      ];
    });
  };

  const entityAgreements = () => {
    if (!agreements.ambulancesEntity) {
      return <p>No tienes acuerdos con entidades de ambulancias.</p>;
    }
    return (
      <GridContainer>
        {agreements.ambulancesEntity.map(entity => {
          return (
            <GridItem xs={12} sm={6}>
              - {entity}
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  const epsAgreements = () => {
    if (!agreements.epsNames) {
      return <p>No tienes acuerdos con EPS.</p>;
    }
    return (
      <GridContainer>
        {agreements.epsNames.map(entity => {
          return (
            <GridItem xs={12} sm={6}>
              - {entity}
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  return (
    <React.Fragment>
      {agreements.ips && (
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info">
              <h3 className={classes.cardTitleWhite}>Acuerdos con las IPS</h3>
            </CardHeader>
            <CardBody>
              <Table tableData={renderAgreements('ips')} />
            </CardBody>
          </Card>
        </GridItem>
      )}
      {agreements.eps && (
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info">
              <h3 className={classes.cardTitleWhite}>Acuerdos con las EPS</h3>
            </CardHeader>
            <CardBody>
              <Table tableData={renderAgreements('eps')} />
            </CardBody>
          </Card>
        </GridItem>
      )}
      {agreements.epsNames && (
        <GridItem xs={12} sm={8}>
          <Card>
            <CardHeader color="info">
              <h3 className={classes.cardTitleWhite}>Acuerdos con las EPS</h3>
            </CardHeader>
            <CardBody>{epsAgreements()}</CardBody>
          </Card>
        </GridItem>
      )}
      {agreements.ambulancesEntity && (
        <GridItem xs={12} sm={8}>
          <Card>
            <CardHeader color="info">
              <h3 className={classes.cardTitleWhite}>Acuerdos con las entidades de ambulancias</h3>
            </CardHeader>
            <CardBody>{entityAgreements()}</CardBody>
          </Card>
        </GridItem>
      )}
    </React.Fragment>
  );
}

AgreementsInfo.propTypes = {
  agreements: PropTypes.object.isRequired
};

export default AgreementsInfo;
