import React from 'react';
import PropTypes from 'prop-types';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import CustomInput from 'components/CustomInput/CustomInput';
import NumberInput from '../NumberInput/NumberInput';
import NxSelectAsync from '../NxSelect/NxSelectAsync';

import { typeDocuments, typeGenders } from '../../variables/person';
import { comorbidities } from '../../variables/ocupation';
import { municipalityCall, departmentCall } from '../../utils/Transfer';
import { validateNamesInput } from '../../utils/resources';

let timeOutId;

function apiCall(inputValue, field, param) {
  switch (field) {
    case 'department':
      return departmentCall(inputValue);
    case 'municipality':
      return municipalityCall(inputValue, param);
    default:
      break;
  }
  return null;
}

const getOptions = (inputValue, field, param) => {
  if (timeOutId) {
    clearInterval(timeOutId);
  }
  if (inputValue.length > 2) {
    return new Promise(resolve => {
      timeOutId = setTimeout(() => {
        resolve(apiCall(inputValue, field, param));
      }, 400);
    });
  }
  return null;
};

const useStyles = makeStyles({
  addIcon: {
    padding: '0 10px',
    marginTop: '20px',
    background: '#05BF95',
    color: 'white',
    '&:hover': {
      background: '#05BF95'
    }
  },
  documents: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  chip: {
    margin: 2
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  tooltip: {
    fontSize: '12px'
  }
});

const PatientByPathology = ({
  value,
  formName,
  addPatient,
  handleChange,
  comorbiditiesHandleChange,
  placeholder,
  handleMunicipalityChange,
  handleDepartmentChange,
  servicesList,
  originDepartmentId,
  originCity,
  originDepartment,
  epsList
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <InputLabel style={{ fontSize: '14px' }}>{placeholder}</InputLabel>
      <div className={classes.documents}>
        <GridContainer>
          <GridItem xs={12} md={6} lg={3}>
            <CustomInput
              labelText="Primer Nombre*"
              formControlProps={{ fullWidth: true }}
              id="firstName"
              inputProps={{
                onChange: ({ target }) => handleChange(target.name, validateNamesInput(target.value)),
                autoComplete: 'off',
                name: 'firstName',
                value: value.firstName || '',
                type: 'text'
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} lg={3}>
            <CustomInput
              labelText="Segundo Nombre"
              formControlProps={{ fullWidth: true }}
              id="middleName"
              inputProps={{
                onChange: ({ target }) => handleChange(target.name, validateNamesInput(target.value)),
                autoComplete: 'off',
                name: 'middleName',
                value: value.middleName || '',
                type: 'text'
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} lg={3}>
            <CustomInput
              labelText="Primer Apellido*"
              formControlProps={{ fullWidth: true }}
              id="lastName"
              inputProps={{
                onChange: ({ target }) => handleChange(target.name, validateNamesInput(target.value)),
                autoComplete: 'off',
                name: 'lastName',
                value: value.lastName || '',
                type: 'text'
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} lg={3}>
            <CustomInput
              labelText="Segundo Apellido"
              formControlProps={{ fullWidth: true }}
              id="secondLastName*"
              inputProps={{
                onChange: ({ target }) => handleChange(target.name, validateNamesInput(target.value)),
                autoComplete: 'off',
                name: 'secondLastName',
                value: value.secondLastName || '',
                type: 'text'
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6} lg={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="documentType"> Tipo de documento* </InputLabel>
              <Select
                value={value.documentType || ''}
                onChange={({ target }) => handleChange(target.name, target.value, formName)}
                inputProps={{
                  name: 'documentType',
                  id: 'documentType'
                }}
              >
                {typeDocuments.map((typeDocument, key) => (
                  <MenuItem key={key} value={typeDocument.value}>
                    {typeDocument.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} md={6} lg={3}>
            <FormControl required fullWidth>
              <NumberInput
                required
                placeHolder={
                  value.document && value.document !== null && value.document.toString().length > 15
                    ? 'Máximo 15 dígitos'
                    : 'Numero de documento*'
                }
                handleChange={handleChange}
                id="document"
                name="document"
                value={value.document || ''}
                validation={{
                  type: 'range',
                  number: 0,
                  maxValue: 999999999999999
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} md={6} lg={3}>
            <FormControl required fullWidth>
              <InputLabel htmlFor={formName} error={!value}>
                EPS
              </InputLabel>
              <Select
                fullWidth
                value={value.epsId || ''}
                onChange={({ target, nativeEvent }) => handleChange(target.name, target.value, nativeEvent)}
                inputProps={{
                  name: 'epsId',
                  id: 'epsId'
                }}
              >
                {epsList &&
                  epsList.map(eps => (
                    <MenuItem key={eps.id} value={eps.id}>
                      {eps.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} md={6} lg={3}>
            <FormControl required fullWidth>
              <InputLabel htmlFor={formName} error={!value}>
                Sexo
              </InputLabel>
              <Select
                fullWidth
                value={value.sex || ''}
                onChange={({ target }) => handleChange(target.name, target.value)}
                inputProps={{
                  name: `sex`,
                  id: `sex`
                }}
              >
                {typeGenders &&
                  typeGenders.map(sex => (
                    <MenuItem key={sex.value} value={sex.value}>
                      {sex.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} md={6} lg={3}>
            <FormControl required fullWidth>
              <NumberInput
                required
                placeHolder={`${value.age > 150 ? 'ingrese valores entre 1 y 150' : 'Edad (años)*'}`}
                handleChange={handleChange}
                id={`${formName}age`}
                name="age"
                value={value.age || ''}
                validation={{
                  type: 'range',
                  number: 0,
                  maxValue: 150
                }}
              />
            </FormControl>
          </GridItem>
          {formName === 'covidPatientInfo' && (
            <GridItem xs={12} md={6} lg={6}>
              <Tooltip
                className={classes.tooltip}
                title={
                  <>
                    <Typography color="inherit" className={classes.tooltip}>
                      Seleccione las comorbilidades que sean necesarias. Para salir de la lista, haga clic afuera.
                    </Typography>
                  </>
                }
                placement="right-end"
              >
                <FormControl required fullWidth>
                  <InputLabel htmlFor={formName} error={!value}>
                    Comorbilidades
                  </InputLabel>
                  <Select
                    fullWidth
                    multiple
                    value={value.comorbidities || ''}
                    onChange={({ target }) => comorbiditiesHandleChange(target.value, formName)}
                    inputProps={{
                      name: `comorbidities`,
                      id: `comorbidities`
                    }}
                    renderValue={selected => (
                      <div className={classes.chips}>
                        {selected.map(val => {
                          const element = comorbidities.find(e => e.id === val);
                          return <Chip key={val} label={element.name} className={classes.chip} />;
                        })}
                      </div>
                    )}
                  >
                    {comorbidities &&
                      comorbidities.map(condition => (
                        <MenuItem
                          disabled={condition.id !== 'none' && value.comorbidities.some(val => val === 'none')}
                          key={condition.id}
                          value={condition.id}
                        >
                          <Checkbox checked={value.comorbidities.indexOf(condition.id) > -1} />
                          {condition.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </GridItem>
          )}
          <GridItem xs={12} md={6} lg={3}>
            <FormControl required fullWidth>
              <InputLabel htmlFor={formName} error={!value}>
                Servicio
              </InputLabel>
              <Select
                fullWidth
                value={value.serviceType || ''}
                onChange={({ target }) => handleChange(target.name, target.value)}
                inputProps={{
                  name: `serviceType`,
                  id: `serviceType`
                }}
              >
                {servicesList &&
                  servicesList.map(service => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          {formName === 'covidPatientInfo' && (
            <>
              <GridItem xs={12} md={6} lg={5}>
                <NxSelectAsync
                  name="originDepartmentId"
                  placeholder="Departamento de procedencia"
                  handleLoadOptions={e => getOptions(e, 'department')}
                  handleChange={handleDepartmentChange}
                  value={originDepartment}
                />
              </GridItem>
              <GridItem xs={12} md={6} lg={5}>
                <NxSelectAsync
                  name="originCityId"
                  placeholder="Municipio de procedencia"
                  handleLoadOptions={e => getOptions(e, 'municipality', originDepartmentId)}
                  handleChange={handleMunicipalityChange}
                  value={originCity}
                  isDisabled={originDepartmentId === null || originDepartmentId === undefined}
                />
              </GridItem>
            </>
          )}
          <GridItem xs={12} md={6} lg={2}>
            <GridItem container justify="center">
              <Fab
                size="medium"
                variant="extended"
                aria-label="Add"
                className={classes.addIcon}
                onClick={() => addPatient(formName)}
                disabled={
                  !value.firstName ||
                  !value.lastName ||
                  !value.documentType ||
                  !value.document ||
                  value.document > 999999999999999 ||
                  !value.epsId ||
                  !value.serviceType ||
                  !value.sex ||
                  (formName === 'covidPatientInfo' && (!value.originCityId || !value.originDepartmentId)) ||
                  !value.age ||
                  value.age > 150
                }
              >
                <AddIcon />
                Agregar
              </Fab>
            </GridItem>
          </GridItem>
          <hr />
        </GridContainer>
      </div>
    </React.Fragment>
  );
};

PatientByPathology.propTypes = {
  handleChange: PropTypes.func.isRequired,
  comorbiditiesHandleChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  formName: PropTypes.string,
  placeholder: PropTypes.string,
  addPatient: PropTypes.func.isRequired,
  servicesList: PropTypes.array,
  handleMunicipalityChange: PropTypes.func,
  handleDepartmentChange: PropTypes.func,
  originDepartmentId: PropTypes.number,
  originCity: PropTypes.object,
  originDepartment: PropTypes.object,
  epsList: PropTypes.array
};

PatientByPathology.defaultProps = {
  value: {},
  formName: '',
  placeholder: '',
  servicesList: [],
  originCity: {},
  originDepartment: {},
  epsList: []
};

export default PatientByPathology;
