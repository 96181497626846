import format from 'date-fns/format';

function getReferralState(state) {
  if (state === 'EPS_PENDING_IPS') return 'Pendiente de asignación por parte de la EPS';
  if (state === 'CRUE_PENDING_IPS') return 'Pendiente de asignación  por parte del CRUE';
  if (state === 'AMBULANCE_TRANSPORT') return 'Ambulancia en transporte';
  if (state === 'AMBULANCE_MISSING_DATA') return 'Faltan datos por parte de la Ambulancia';
  if (state === 'REJECTED') return 'Traslado rechazado';
  if (state === 'REFERRAL_ENDED') return 'Traslado terminado';
  return 'sin estado';
}
export const defaultColumns = [
  {
    name: 'transferNumber',
    title: 'Traslado',
    getCellValue: row => (row ? row.id : undefined)
  },
  {
    name: 'transferState',
    title: 'Estado',
    getCellValue: row => (row ? getReferralState(row.referralState) : undefined)
  },
  {
    name: 'ambAssigned',
    title: 'Ambulancia',
    getCellValue: row => {
      if (!row) return undefined;
      return row.ambAssignedBy ? 'Asignada' : ' NO Asignada';
    }
  },
  {
    name: 'ipsReq',
    title: 'IPS remisora',
    getCellValue: row => (row ? row.ipsReq.name : 'Sin Información')
  },
  {
    name: 'ipsRecv',
    title: 'IPS receptora',
    getCellValue: row => {
      if (!row) return undefined;
      return row.ipsRecv ? row.ipsRecv.name : ' No Asignada';
    }
  },
  {
    name: 'creationCaseDate',
    title: 'Creado',
    getCellValue: row =>
      (row.attentionHours || {}).creationCaseDate
        ? format(new Date(row.attentionHours.creationCaseDate), 'YYYY-MM-DD HH:mm:ss')
        : undefined
  },
  {
    name: 'validationCaseDate',
    title: 'Aceptado',
    getCellValue: row =>
      row.attentionHours.validationCaseDate
        ? format(new Date(row.attentionHours.validationCaseDate), 'YYYY-MM-DD HH:mm:ss')
        : undefined
  },
  {
    name: 'ambulanceDispatchDate',
    title: 'Despacho',
    getCellValue: row =>
      row.attentionHours.ambulanceDispatchDate
        ? format(new Date(row.attentionHours.ambulanceDispatchDate), 'YYYY-MM-DD HH:mm:ss')
        : undefined
  },

  {
    name: 'eventArrivalDate',
    title: 'Llegada IPS Remite',
    getCellValue: row =>
      row.attentionHours.eventArrivalDate
        ? format(new Date(row.attentionHours.eventArrivalDate), 'YYYY-MM-DD HH:mm:ss')
        : undefined
  },
  {
    name: 'transportDate',
    title: 'Firma IPS Remite',
    getCellValue: row =>
      row.attentionHours.transportDate
        ? format(new Date(row.attentionHours.transportDate), 'YYYY-MM-DD HH:mm:ss')
        : undefined
  },
  {
    name: 'ipsArrivalDate',
    title: 'Llegada IPS destino',
    getCellValue: row =>
      row.attentionHours.ipsArrivalDate
        ? format(new Date(row.attentionHours.ipsArrivalDate), 'YYYY-MM-DD HH:mm:ss')
        : undefined
  },
  {
    name: 'ipsProfessionalSignatureDate',
    title: 'Firma IPS destino',
    getCellValue: row =>
      row.attentionHours.ipsProfessionalSignatureDate
        ? format(new Date(row.attentionHours.ipsProfessionalSignatureDate), 'YYYY-MM-DD HH:mm:ss')
        : undefined
  },
  {
    name: 'patientCareEndDate',
    title: 'Paciente Recibido',
    getCellValue: row =>
      row.attentionHours.patientCareEndDate
        ? format(new Date(row.attentionHours.patientCareEndDate), 'YYYY-MM-DD HH:mm:ss')
        : undefined
  },
  {
    name: 'availableDate',
    title: 'Disponible',
    getCellValue: row =>
      row.attentionHours.availableDate
        ? format(new Date(row.attentionHours.availableDate), 'YYYY-MM-DD HH:mm:ss')
        : undefined
  }
];

export const defaultTableColumnExtensions = [
  {
    columnName: 'transferNumber',
    width: 120,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'transferState',
    wordWrapEnabled: true
  },
  {
    columnName: 'ambAssigned',
    width: 120,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'ipsReq',
    width: 190,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'ipsRecv',
    width: 190,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'creationCaseDate',
    width: 150,
    align: 'center'
  },
  {
    columnName: 'validationCaseDate',
    width: 150,
    align: 'center'
  },
  {
    columnName: 'ambulanceDispatchDate',
    width: 150,
    align: 'center'
  },
  {
    columnName: 'eventArrivalDate',
    width: 150,
    align: 'center'
  },
  {
    columnName: 'transportDate',
    width: 150,
    align: 'center'
  },
  {
    columnName: 'ipsArrivalDate',
    width: 150,
    align: 'center'
  },
  {
    columnName: 'ipsProfessionalSignatureDate',
    width: 150,
    align: 'center'
  },
  {
    columnName: 'patientCareEndDate',
    width: 150,
    align: 'center'
  },
  {
    columnName: 'availableDate',
    width: 150,
    align: 'center'
  }
];
