import { firebaseDb } from 'config/firebase';

// User API

const refRequest = firebaseDb.ref('req_ambulances');
export const doCreateUser = (id, username, email, rol = 'pendiente') =>
  firebaseDb.ref(`users/${id}`).set({ username, email, rol });

export const onceGetUsers = () => firebaseDb.ref('users').once('value');

export const getUsersByEmail = email =>
  firebaseDb
    .ref(`users`)
    .orderByChild('email')
    .equalTo(`${email}`)
    .once('value');

export const getUsersAdmin = () =>
  firebaseDb
    .ref(`users`)
    .orderByChild('rol')
    .equalTo(`admin`)
    .once('value');

export const onceGetUserId = id => firebaseDb.ref(`users/${id}`).once('value');

export const doUpdateUser = (id, data) =>
  firebaseDb.ref(`users/${id}`).update({
    ...data
  });

export const deleteUser = id => firebaseDb.ref(`users/${id}`).remove();
// Other firebaseDb APIs ...

// RequetsTechnician API

export const doCreateRequest = data => {
  return firebaseDb.ref(`requests/`).push({
    ...data,
    createAt: Date.now()
  });
};
export const onceGetRequest = () => firebaseDb.ref('requests').once('value');

export const onceGetRequestId = id => firebaseDb.ref(`requests/${id}`).once('value');

export const onceGetRequestbyHospital = userId =>
  firebaseDb
    .ref(`requests`)
    .orderByChild('person/userId')
    .equalTo(`${userId}`)
    .once('value');

export const updateRequest = (id, data) => {
  return firebaseDb.ref(`requests/${id}`).update({
    ...data
  });
};

export const getDataUser = async userId => {
  const user = await firebaseDb.ref(`users/${userId}`).once('value');
  return user.val();
};

export const getnewSolicitud = async saveNewRequest =>
  refRequest
    .orderByChild('timestamp')
    .startAt(Date.now())
    .on('child_added', snapshot => {
      const ambMed = snapshot.val();
      ambMed.id = snapshot.key;
      firebaseDb
        .ref(`users/${ambMed.userId}`)
        .once('value')
        .then(snapshotUser => {
          ambMed.user = snapshotUser.val();
          if (saveNewRequest) saveNewRequest(ambMed);
        });
      return ambMed;
    });

export const updateChangedRequest = async updateRequestAmb =>
  refRequest.on('child_changed', snapshot => {
    const ambMed = snapshot.val();
    ambMed.id = snapshot.key;
    firebaseDb
      .ref(`users/${ambMed.userId}`)
      .once('value')
      .then(snapshotUser => {
        ambMed.user = snapshotUser.val();
        updateRequestAmb(ambMed);
      });
    return ambMed;
  });

export const getSolicitudesAmb = async saveNewRequest => {
  const responses = await refRequest.once('value');
  const ambulancesMed = responses.val();

  const allResponse = ambulancesMed
    ? await Promise.all(
        Object.keys(ambulancesMed).map(item =>
          getDataUser(ambulancesMed[item].userId).then(snapshotUser => {
            ambulancesMed[item].user = snapshotUser;
            ambulancesMed[item].id = item;
            return ambulancesMed[item];
          })
        )
      )
    : [];
  getnewSolicitud(saveNewRequest);
  return allResponse;
};

export const setStateSolicitudesAmb = (id, state) => {
  return firebaseDb.ref(`req_ambulances/${id}`).update({
    status: state
  });
  /* if(this.props.data.estado===1){
    firebase.database().ref('solicitudAmbulancia/' + this.props.data.id).update({ estado: 2 })
  } */
};
