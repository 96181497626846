import * as React from 'react';

function CloseIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15.666 15.667" {...props}>
      <g data-name="Grupo 1064" fill="none" stroke="#b7b7b7" strokeLinecap="round" strokeWidth={3}>
        <path data-name="L\xEDnea 464" d="M2.121 2.122l11.424 11.424" />
        <path data-name="L\xEDnea 465" d="M13.545 2.121L2.121 13.545" />
      </g>
    </svg>
  )
}

export default CloseIcon;
