export function getDistance(lat1, lon1, lat2, lon2) {
  const rad = x => (x * Math.PI) / 180;
  const earthRadio = 6378.137;
  const dLat = rad(lat2 - lat1);
  const dLong = rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(lat1)) *
      Math.cos(rad(lat2)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = earthRadio * c;
  d *= 1000;
  return d.toFixed(3);
}

export const MY_MAPTYPE_ID = 'netux_style';

export const INPUT_STYLE = {
  boxSizing: `border-box`,
  MozBoxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `240px`,
  height: `32px`,
  marginTop: `10px`,
  padding: `0 12px`,
  borderRadius: `1px`,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: `14px`,
  outline: `none`,
  textOverflow: `ellipses`
};
export const mapOptions = {
  // mapTypeControlOptions: {
  //   mapTypeIds: [MY_MAPTYPE_ID]
  // },
  // mapTypeId: MY_MAPTYPE_ID,
  scrollwheel: false,
  disableDefaultUI: true,
  zoomControl: true
};
export const styledMapOptions = { name: 'Netux' };
