import React from 'react';
import PropTypes from 'prop-types';
import apiClient from 'config/apiClient';
import { withAlert } from 'react-alert';

import DeleteOutline from '@material-ui/icons/DeleteOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import FileCopy from '@material-ui/icons/FileCopy';
import format from 'date-fns/format';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NavPills from 'components/NavPills/NavPills';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { normalizeNumber } from 'utils/ambulances';
import { setStateSolicitudesAmb } from '../../models/ambMed';
import AmbulanceAttendForm from './AmbulanceAttendForm';
import AmbulanceDuplicateForm from './AmbulanceDuplicateForm';
import AmbulanceDelete from './AmbulanceDelete';
import NxSpinner from '../NxSpinner/NxSpinner';

import { urlApi } from '../../config/app';

// import { cardTitle } from 'assets/jss/material-dashboard-pro-react';

const styles = {
  paper: {
    padding: 20
  },
  center: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  gridMargin: {
    margin: '10px 0'
  }
};

function getEmergencyType(type) {
  if (type === 'ACCIDENTE_TRANSITO') return 'Accidente de transito';
  if (type === 'PERSONA_LESIONADA') return 'Persona lesionada en la calle';
  if (type === 'EMERGENCIA_HOGAR') return 'Emergencia en el Hogar';
  return 'Otro';
}

const AmbulanceRequest = props => {
  // toDo: buscar translate automatico de ser posible
  const { ambulance, classes, onDeleteModal } = props;
  const [state, setState] = React.useState({
    status: 'close',
    secadConsecutiveNumber: '',
    secadCallNumber: '',
    secadCallIncomeDate: new Date(),
    formId: null,
    loading: false
  });

  const [buttonState, setButtonState] = React.useState(false);

  function handleToggle(newState) {
    setState({
      ...state,
      status: newState
    });
  }

  function handleChange(event) {
    let { value } = event.target;
    const { name } = event.target;
    const valueArr = value.split('');
    const regExp = /^[0-9]*$/g;
    value = valueArr.filter(word => word.match(regExp)).join('');
    setState({
      ...state,
      [name]: value
    });
  }

  function handleDateChange(date) {
    setState({
      ...state,
      secadCallIncomeDate: date
    });
  }

  function handleSelect(eventId) {
    setState({
      ...state,
      formId: eventId
    });
  }

  function cellPhoneNumberValidation(cellPhoneNumber) {
    const number = normalizeNumber(cellPhoneNumber);
    const subStringInit = number.length - 10;
    const cellPhoneNumberValidate = number.substring(subStringInit);
    return cellPhoneNumberValidate;
  }

  function submitToFirebase() {
    setState({ loading: true });
    const { alert } = props;
    if (state.status === 'delete') {
      setStateSolicitudesAmb(ambulance.id, 'delete');
      setState({
        loading: false,
        status: 'close'
      });
      onDeleteModal();
      return;
    }

    if (state.status === 'attend') {
      setButtonState(true);
      const body = {
        arrivedBy: 'AMB_MED',
        callerName: ambulance.user.fullName,
        lat: ambulance.latitude,
        lng: ambulance.longitude,
        address: ambulance.address,
        callerPhone: cellPhoneNumberValidation(ambulance.userPhoneNumber),
        healthArrivalDate: ambulance.timestamp,
        observations: ambulance.additionalInfo
      };
      apiClient.SEM.post(`${urlApi}/event`, body)
        .then(() => {
          setStateSolicitudesAmb(ambulance.id, 'attend');
          setState({
            loading: false,
            status: 'close'
          });
          setButtonState(false);
          onDeleteModal();
        })
        .catch(error => {
          console.error(error);
          setButtonState(false);
          setState({
            loading: false,
            status: 'close'
          });
          alert.show(
            error.response
              ? error.response.data.data.Message
              : 'Hubo un error al crear el incidente, sin embargo compruebe si este fue creado',
            {
              type: 'error',
              timeout: 5000
            }
          );
        });
    }

    if (state.status === 'duplicate') {
      setButtonState(true);
      const body = {
        formId: state.formId
      };
      apiClient.SEM.post(`${urlApi}/legacy/duplicateEvent`, body)
        .then(() => {
          setStateSolicitudesAmb(ambulance.id, 'duplicate');
          setState({
            loading: false,
            status: 'close'
          });
          setButtonState(false);
          onDeleteModal();
        })
        .catch(error => {
          console.error(error);
          setButtonState(false);
          setState({
            loading: false,
            status: 'close'
          });
          alert.show(error.response.data.data.Message, {
            type: 'error',
            timeout: 5000
          });
        });
    }
  }

  // const { internment, nedocs, supportServices, urgency } = resources;
  return (
    <div className={classes.paper}>
      <NxSpinner loading={state.loading} />
      <GridContainer>
        <GridItem style={{ width: 600, margin: '10px' }}>
          <Card>
            <CardBody>
              {!ambulance.status && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => handleToggle('delete')}
                  >
                    Eliminar
                    <DeleteOutline className={classes.rightIcon} />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      handleToggle('attend');
                    }}
                  >
                    Atender
                    <CheckCircleOutline className={classes.rightIcon} />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => handleToggle('duplicate')}
                  >
                    Duplicado
                    <FileCopy className={classes.rightIcon} />
                  </Button>
                </>
              )}
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: 'Info',
                    tabContent: (
                      <div style={{ width: '100%' }}>
                        <span>
                          <p>
                            <b>Dirección:</b> {ambulance.address}
                          </p>
                          <p>
                            <b>Información adicional:</b> {ambulance.additionalInfo}
                          </p>
                          <p>
                            <b>Nombre del solicitante:</b> {(ambulance.user || {}).fullName}
                          </p>
                          <p>
                            <b>Cedula del solicitante:</b> {(ambulance.user || {}).identification}
                          </p>
                          <p>
                            <b>Numero telefónico del solicitante:</b> {(ambulance.user || {}).phoneNumber}
                          </p>
                          <p>
                            <b>Tipo de emergencia:</b> {getEmergencyType(ambulance.emergencyType)}
                          </p>
                          <p>
                            <b>Fecha y hora de la solicitud:</b> {format(ambulance.timestamp, 'MM/DD/YYYY HH:mm')}
                          </p>
                          <p>
                            <b>Estado de la solicitud:</b> {ambulance.status ? ambulance.status : 'Pendiente'}
                          </p>
                          <br />
                        </span>
                      </div>
                    )
                  },
                  {
                    tabButton: 'Imagen',
                    tabContent: ambulance.additionalImageURL ? (
                      <a href={ambulance.additionalImageURL} target="_blank" rel="noopener noreferrer">
                        <img
                          src={ambulance.additionalImageURL}
                          alt="Imagen de la Emergencia"
                          style={{ width: 'auto', height: '350px', imageOrientation: 'from-image' }}
                        />
                      </a>
                    ) : (
                      <p>
                        <b>Sin imagen </b>
                      </p>
                    )
                  }
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={state.status !== 'close'}
        onClose={() => handleToggle('close')}
      >
        <Card
          style={{
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          {state.status === 'delete' ? (
            <div>
              <AmbulanceDelete submitToFirebase={submitToFirebase} />
            </div>
          ) : null}
          {state.status === 'attend' ? (
            <div>
              <AmbulanceAttendForm
                secadConsecutiveNumber={state.secadConsecutiveNumber}
                secadCallNumber={state.secadCallNumber}
                secadCallIncomeDate={state.secadCallIncomeDate}
                submitToFirebase={submitToFirebase}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                buttonState={buttonState}
              />
            </div>
          ) : null}
          {state.status === 'duplicate' ? (
            <div>
              <AmbulanceDuplicateForm
                handleSelect={handleSelect}
                submitToFirebase={submitToFirebase}
                formId={state.formId}
                buttonState={buttonState}
              />
            </div>
          ) : null}
        </Card>
      </Modal>
    </div>
  );
};

AmbulanceRequest.propTypes = {
  ambulance: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  onDeleteModal: PropTypes.func.isRequired
  // info: PropTypes.object.isRequired,
  // resources: PropTypes.object
};

export default withAlert()(withStyles(styles)(AmbulanceRequest));
