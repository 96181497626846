import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import 'chartjs-plugin-datalabels';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  canvas: {}
}));

const options = {
  responsive: true,
  maintainAspectRatio: true,
  circumference: Math.PI,
  rotation: Math.PI,
  cutoutPercentage: 80,
  plugins: {
    datalabels: {
      display: false
    }
  },
  legend: {
    display: false
  }
};

export default function DoughnutChart({ labels, data: dataArray }) {
  const classes = useStyles();
  const data = {
    labels,
    datasets: [
      {
        data: dataArray,
        backgroundColor: ['#00F060', '#E5E5E5'],
        hoverBackgroundColor: ['#00F060', '#E5E5E5'],
        borderWidth: 0
      }
    ]
  };

  return (
    <div className={classes.canvas}>
      <Doughnut data={data} options={options} />
    </div>
  );
}

DoughnutChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
  labels: PropTypes.arrayOf(PropTypes.string)
};
