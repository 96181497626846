import io from 'socket.io-client';
import { urlwsChat } from './app';

const socketChatConnection = io(urlwsChat, {
  path: process.env.REACT_APP_ENV === 'local' ? '' : '/chat',
  autoConnect: false,
  forceNew: true
});

export default socketChatConnection;
