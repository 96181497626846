import React from 'react';
import PropTypes from 'prop-types';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { strCase } from 'utils/covid';

const getColor = column => {
  if (['hospCovid', 'emergencyCovid', 'uciCovid', 'uceCovid', 'covidTotal'].includes(column)) {
    return '#FF0000';
  }
  if (['hospAvailable', 'emergencyAvailable', 'uciAvailable', 'uceAvailable'].includes(column)) {
    return '#00F060';
  }
  if (['hospIra', 'emergencyIra', 'uciIra', 'uceIra', 'iraTotal'].includes(column)) {
    return '#38BCD7';
  }
  return '#828282';
};

const renderCell = (columnName, value) => {
  if (columnName === 'ips') {
    return <p style={{ color: getColor(columnName), whiteSpace: 'initial', fontWeight: 'bold' }}> {strCase(value)} </p>;
  }
  return (
    <p
      style={
        value === 'NA'
          ? { color: getColor('NA'), whiteSpace: 'initial', fontWeight: 'bold' }
          : { color: getColor(columnName), whiteSpace: 'initial', fontWeight: 'bold' }
      }
    >
      {value === 'NA' ? '--' : value}
    </p>
  );
};

export const Cell = props => {
  const { column, classes, value } = props;
  return <VirtualTable.Cell className={classes.cell}> {renderCell(column.name, value)} </VirtualTable.Cell>;
};

Cell.propTypes = {
  column: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])
};
