import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';

// Core components
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// Components
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import NxTable from 'nxComponents/CovidIps/NxTable';
import CovidTotalTable from 'nxComponents/CovidTotal/CovidTotal';
import CovidInfectedTable from 'nxComponents/CovidInfected/CovidIpsInfected';
import NxSpinner from 'nxComponents/NxSpinner/NxSpinner';
import NxNavigation from 'nxComponents/NxNavigation/NxNavigation';
import NxBtnNavigation from 'nxComponents/NxBtnNavigation/NxBtnNavigation';

// Others
import apiClient from 'config/apiClient';
import { urlApi } from 'config/app';
import { useInterval } from 'customHooks/useInterval';
import AuthContainer from 'containers-state/auth';
import Netux from 'assets/img/Netux.png';
import alcaldia from 'assets/img/alcaldia.png';
import { styles, navigationItems, totalInfo, ipsTotalInfo, totalByIps } from 'utils/covid';
import resourcesConfig from './ipsTableConfig';
import resourcesAMConfig from './ipsTableAMConfig';
import totalConfig from './totalTableConfig';
import totalAMConfig from './totalTableAMConfig';
import ipsInfectedConfig from './ipsInfectedConfig';
import ipsInfectedAMConfig from './ipsInfectedAMConfig';

const getAllResources = async () => {
  try {
    const response = await apiClient.SEM.get(`${urlApi}/ips/covid/resources/summary/all/`);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCovidResources = async () => {
  const dataResources = await getAllResources();
  return { dataResources };
};

function Covid(props) {
  const [resources, setResources] = useState({ ips: [] });
  const [areaResources, setAreaResources] = useState({ ips: [] });
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState(0);
  const [ipsTable, setIpsTable] = useState(0);
  const { classes, history, roles } = props;

  const getResources = async () => {
    const { dataResources } = await getCovidResources();
    let listResources = {};
    let listAreaResources = {};
    if (dataResources.ok) {
      listResources = totalByIps(dataResources.data.med);
      listAreaResources = totalByIps(dataResources.data.metropolitan);
    } else {
      console.error(dataResources);
    }
    setResources(listResources);
    setAreaResources(listAreaResources);
  };

  useEffect(() => {
    window.Intercom('update', {
      hide_default_launcher: true
    });
    const loadInitialData = async () => {
      setLoading(true);
      await getResources();
      setLoading(false);
    };
    loadInitialData();
  }, []);

  useInterval(() => {
    getResources();
  }, 90000);

  if (!roles.includes('CRUE')) {
    history.push('/');
  }

  return (
    <>
      <NxSpinner loading={loading} />
      <div className={classes.covid}>
        <Hidden smDown>
          <GridItem xs={12} sm={3}>
            <img className={classes.netuxImg} src={Netux} alt="logo-Netux" />
          </GridItem>
        </Hidden>
        <GridItem xs={12} sm={9} style={{ marginRight: '25vw', textAlign: 'right' }}>
          <NxNavigation items={navigationItems(history)} value={0} />
        </GridItem>
        <Card className={classes.card}>
          <CardBody className={classes.cardBody}>
            <img className={classes.alcaldiaLogo} alt="Logo-alcaldía" src={alcaldia} />
          </CardBody>
        </Card>
      </div>
      <NxBtnNavigation
        value={table}
        onChange={(event, newValue) => setTable(newValue)}
        items={['Recursos ciudad', 'Recursos por IPS']}
      />
      <GridContainer className={classes.container} justify="center">
        {table === 0 && (
          <>
            <GridContainer className={classes.tableMargin}>
              <GridItem xs={12}>
                {resources.ips && resources.ips.length > 0 ? (
                  <CovidTotalTable dataTable={totalInfo(resources.total)} config={totalConfig} />
                ) : (
                  <h4> {!loading ? 'No se han cargado los datos o no hay datos disponibles' : ''} </h4>
                )}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} className={classes.tableMargin}>
                {areaResources.ips && areaResources.ips.length > 0 ? (
                  <CovidTotalTable dataTable={totalInfo(areaResources.total)} config={totalAMConfig} />
                ) : (
                  <h4> {!loading ? 'No se han cargado los datos o no hay datos disponibles' : ''} </h4>
                )}
              </GridItem>
            </GridContainer>
          </>
        )}
        {table === 1 && (
          <>
            <BottomNavigation
              value={ipsTable}
              onChange={(event, newValue) => {
                setIpsTable(newValue);
              }}
              showLabels
              className={classes.navigationSecondary}
            >
              <BottomNavigationAction
                classes={{
                  label: classes.secondaryNavigationLabel,
                  selected: classes.secondaryNavigationSelected,
                  root: classes.navigationActionRoot
                }}
                label="Total IPS"
              />
              <BottomNavigationAction
                classes={{
                  label: classes.secondaryNavigationLabel,
                  selected: classes.secondaryNavigationSelected,
                  root: classes.navigationActionRoot
                }}
                label="Infectados por IPS"
              />
            </BottomNavigation>
            {ipsTable === 0 && (
              <>
                <GridContainer className={classes.tableMargin}>
                  <GridItem xs={12}>
                    {resources.ips && resources.ips.length > 0 && (
                      <NxTable dataTable={resources.ips} config={resourcesConfig} />
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer className={classes.tableMargin}>
                  <GridItem xs={12}>
                    {areaResources.ips && areaResources.ips.length > 0 && (
                      <NxTable dataTable={areaResources.ips} config={resourcesAMConfig} />
                    )}
                  </GridItem>
                </GridContainer>
              </>
            )}
            {ipsTable === 1 && (
              <>
                <GridContainer className={classes.tableMargin}>
                  <GridItem xs={12}>
                    {resources.ips && resources.ips.length > 0 && (
                      <CovidInfectedTable dataTable={ipsTotalInfo(resources.ips)} config={ipsInfectedConfig} />
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer className={classes.tableMargin}>
                  <GridItem xs={12}>
                    {resources.ips && resources.ips.length > 0 && (
                      <CovidInfectedTable dataTable={ipsTotalInfo(areaResources.ips)} config={ipsInfectedAMConfig} />
                    )}
                  </GridItem>
                </GridContainer>
              </>
            )}
          </>
        )}
      </GridContainer>
    </>
  );
}

Covid.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  roles: PropTypes.array
};

const TableComponent = withStyles(styles)(Covid);

export default props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer => <TableComponent {...props} roles={AuthContainer.state.roles} />}
  </Subscribe>
);
