import * as React from 'react';
import PropTypes from 'prop-types';

function VirusIcon(props) {
  const { color, height, width, ...rest } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 47.737 44.903" {...rest}>
      <defs>
        <style>
          {`.virus_icon{fill:none;stroke:${color};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}`}
        </style>
      </defs>
      <g transform="translate(1.077 .996)">
        <path
          className="virus_icon"
          d="M43.96 28.389a2.249 2.249 0 00-2.121.548 1.057 1.057 0 00-.262-.106l-8.891-2.223a11.2 11.2 0 00-10.013-12.992c0-.02.006-.038.006-.057v-9.03a1.125 1.125 0 00-.042-.29 2.257 2.257 0 10-2.173 0 1.111 1.111 0 00-.043.29v9.03a.224.224 0 00.005.057 11.275 11.275 0 00-8.9 16.4 1.026 1.026 0 00-.128.057l-7.9 4.515c-.028.016-.051.037-.077.055a2.254 2.254 0 101.09 1.948.848.848 0 00.107-.045l7.9-4.515a1.106 1.106 0 00.211-.159 11.271 11.271 0 0019.388-3.083l8.915 2.228a.966.966 0 00.159.02 2.254 2.254 0 102.771-2.653z"
        />
        <circle className="virus_icon" cx={1.411} cy={1.411} r={1.411} transform="translate(22.701 27.32)" />
        <circle className="virus_icon" cx={0.846} cy={0.846} r={0.846} transform="translate(15.448 24.306)" />
        <circle className="virus_icon" cx={0.846} cy={0.846} r={0.846} transform="translate(24.695 22.372)" />
        <circle className="virus_icon" cx={1.075} cy={1.075} r={1.075} transform="translate(20.132 16.897)" />
        <g transform="translate(20.523 36.698)">
          <circle className="virus_icon" cx={1.035} cy={1.035} r={1.035} transform="translate(0 4.139)" />
          <path className="virus_icon" d="M1.035 0v4.139" />
        </g>
        <g transform="translate(30.777 13.132)">
          <circle className="virus_icon" cx={1.035} cy={1.035} r={1.035} transform="translate(2.996)" />
          <path className="virus_icon" d="M0 4.278l3.225-2.595" />
        </g>
        <g transform="translate(5.845 15.17)">
          <circle className="virus_icon" cx={1.035} cy={1.035} r={1.035} />
          <path className="virus_icon" d="M5.491 3.664L1.926 1.561" />
        </g>
      </g>
    </svg>
  );
}

VirusIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

VirusIcon.defaultProps = {
  color: '#F00000',
  height: 36,
  width: 46
};

export default VirusIcon;
