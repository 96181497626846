import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';

// @material-ui/core
import FormControl from '@material-ui/core/FormControl';

// Components
import GridItem from 'components/Grid/GridItem';
import InputLabel from '@material-ui/core/InputLabel';

const UserSafixReport = ({
  initialDate,
  finalDate,
  handleDateChange,
  valid,
  validFinalDate,
  classes
}) => {  

  return (
    <div>
      <div style={{ display: 'flex', marginTop: '15px' }}>
        <GridItem xs={12} sm={6}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel} error={!initialDate}>
            Fecha inicial *
          </InputLabel>
          <FormControl fullWidth>
            <Datetime
              viewMode="years"
              dateFormat="YYYY-MMM-DD"
              timeFormat={false}
              value={initialDate}
              isValidDate={idate => valid(idate)}
              closeOnSelect
              inputProps={{
                placeholder: 'Fecha inicial',
                required: true,
                readOnly: true
              }}
              onChange={date => handleDateChange(date, 'initialDate')}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel} error={!finalDate}>
            Fecha final *
          </InputLabel>
          <FormControl fullWidth disabled={!initialDate}>
            <Datetime
              viewMode="years"
              dateFormat="YYYY-MMM-DD"
              timeFormat={false}
              value={finalDate}
              isValidDate={date => validFinalDate(date, initialDate)}
              closeOnSelect
              inputProps={{
                placeholder: 'Fecha Final',
                required: true,
                readOnly: true,
                disabled: !initialDate
              }}
              onChange={date => handleDateChange(date, 'finalDate')}
            />
          </FormControl>
        </GridItem>
      </div>
    </div>
  );
};

UserSafixReport.propTypes = {
  initialDate: PropTypes.instanceOf(Date),
  finalDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  valid: PropTypes.func.isRequired,
  validFinalDate: PropTypes.func.isRequired,
  classes: PropTypes.object,
  roles: PropTypes.array
};

export default UserSafixReport;

// const UserSafixReportComponent = withStyles(dashboardStyle)(UserSafixReport);

// export default UserSafixReportComponent;
