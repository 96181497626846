import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const defaultOptions = {
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: true,
      align: 'end',
      anchor: 'end',
      clamp: true,
      rotation: 270,
      font: {
        size: '15'
      }
    }
  },
  legend: {
    position: 'top',
    labels: {
      usePointStyle: true
    }
  }
};

export default function BarChart({ data, options, style }) {
  const max = Math.max(...data.datasets[0].data, ...data.datasets[1].data);
  const scales = {
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMax: Math.ceil(max * 1.1)
          }
        }
      ]
    }
  };
  return (
    <div className={style}>
      <Bar data={data} options={{ ...defaultOptions, ...scales, ...options }} />
    </div>
  );
}

BarChart.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  style: PropTypes.string
};

BarChart.defaultProps = {
  data: [],
  options: {},
  style: ''
};
