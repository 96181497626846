import React from 'react';
import PropTypes from 'prop-types';

// Material-ui components
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles({
  marginCero: {
    margin: 0
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '20px',
    marginBottom: '25px'
  },
  lineContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0px'
  },
  line: {
    borderColor: '#f50057'
  },
  bodyContainer: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '50vh',
    marginBottom: '25px',
    marginTop: '0px',
    paddingTop: '0px',
    paddingBottom: '10px'
  },
  body: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '25px'
  }
});

const AmbulanceAttendForm = ({ submitToFirebase }) => {
  const classes = useStyles();
  return (
    <div>
      <CardActions className={classes.marginCero}>
        <GridContainer className={classes.container}>
          <GridItem>
            <h3>Atender incidente</h3>
          </GridItem>
        </GridContainer>
      </CardActions>
      <GridContainer className={classes.lineContainer}>
        <GridItem xs={10}>
          <hr className={classes.line} />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.bodyContainer}>
        <CardBody className={classes.body}>
          <h5>¿Está seguro que desea atender el incidente?</h5>
        </CardBody>
      </GridContainer>
      <GridContainer className={classes.lineContainer}>
        <GridItem xs={10}>
          <hr className={classes.line} />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.buttonContainer}>
        <GridItem className={classes.lineContainer}>
          <div>
            <Button color="secondary" variant="outlined" onClick={submitToFirebase}>
              Crear incidente en APHMED
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

AmbulanceAttendForm.propTypes = {
  submitToFirebase: PropTypes.func.isRequired
};

export default AmbulanceAttendForm;
