import * as React from 'react';
import PropTypes from 'prop-types';

function LocationIcon(props) {
  const { color, height, width, ...rest } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 29.89 36.416" {...rest}>
      <defs>
        <style>
          {`.covid_location{fill:none;stroke:${color};stroke-linecap:round;stroke-linejoin:round;stroke-width:2.45px}`}
        </style>
      </defs>
      <g transform="translate(-2285.538 -418.169)">
        <path
          className="covid_location"
          d="M2313.89 435.847a13.691 13.691 0 10-27.11-3.4c-.309 5.221 2.873 9.282 6.194 12.954a278.106 278.106 0 004.347 4.674q.916.969 1.838 1.933c.28.294.755 1.036 1.136 1.185.055.022.117.122.161.167 2.64-2.741 5.273-5.5 7.814-8.329a45.093 45.093 0 002.523-3.03"
        />
        <circle className="covid_location" cx={4.132} cy={4.132} r={4.132} transform="translate(2296.332 428.174)" />
      </g>
    </svg>
  );
}

LocationIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

LocationIcon.defaultProps = {
  color: '${color}',
  height: 26,
  width: 38
};

export default LocationIcon;
