import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  li: {
    backgroundColor: "#edf2fa",
    borderBottom: "1px solid #dddfe2",
    overflow: 'hidden',
    textOverflow: "ellipsis",
    /* white-space: nowrap; */
    display: "block",
    "&:hover": {
      background: "rgb(209, 221, 241)"
    }
  },
  lastLi: {

    fontSize: "1em",
    justifyContent: "center",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: "rgb(70, 86, 103)",
      color: "#fff",
    }

  },
  titleUl: {
    margin: 0,
    fontSize: "1em",
    fontWeight: 700,
    textAlign: "center"}
}));

const renderNotifications = (props)=> {
  const classes = useStyles();
  let  size = 8;
  let items = props.notifications.slice(0, size)
  const handleClick = (notification) => {
    props.history.push(`/traslados/${((notification.metadata || {}).referral || {}).id || ''}`)
    props.handleClose()
  }
  const handleCloseAll = () => {
    props.history.push('/notifications')
    props.handleClose()
  }

return (
  <>
  <div class="uiHeader uiHeaderBottomBorder jewelHeader" style={{
    backgroundClip: "padding-box",
    borderBottom: "solid 1px #dddfe2",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    padding: "8px 12px 6px",
    position: "relative",
    zIndex: 100
  }}>
    <div className="clearfix uiHeaderTop">
      {/* <div class="rfloat _ohf">
        <h3 class="accessible_elem" id="fbNotificationsJewelHeader">Notificaciones</h3>
        <div class="uiHeaderActions fsm fwn fcg">
          <a data-testid="non_react_mark_all_as_read_link" href="#" role="button" id="u_0_g" class="" tabindex="0">Marcar todas como leídas</a><span role="presentation" aria-hidden="true"> · </span><a href="https://www.facebook.com/settings?tab=notifications&amp;section=on_facebook">Configuración</a>
        </div>
      </div> */}
      <div>
        <h3 className={classes.titleUl} aria-hidden="true" >Notificaciones</h3>
      </div>
    </div>
  </div>
  <MenuList role="menu" style={{padding: 0}}>
    {items.map( notification =>
      <MenuItem classes={{ root: classes.li }}
      onClick={() => handleClick(notification)} /* onClick={props.handleClose} */ > { notification.shortTitle } </MenuItem>)
    }
    <MenuItem classes={{root: classes.lastLi}} onClick={handleCloseAll} >Ver todas las notificaciones</MenuItem>

  </MenuList>
  </>
)
}

export default withRouter(renderNotifications)
