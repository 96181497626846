import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ListIcon from '@material-ui/icons/List';
import PlaceIcon from '@material-ui/icons/Place';
import ChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/PieChart';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  actionLabel: {
    color: '#858585',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1em'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.4em'
    }
  },
  actionRoot: {
    maxWidth: '100%',
    borderRight: '1px solid gray'
  },
  selected: {
    color: 'white'
  },
  icon: {
    marginRight: 10
  }
}));

export default function NxNavigation({ items, value }) {
  const classes = useStyles();
  return (
    <BottomNavigation showLabels className={classes.root} value={value}>
      {items.map(({ label, icon: Icon, onClick }, index) => (
        <BottomNavigationAction
          key={label}
          classes={{
            root: classes.actionRoot,
            wrapper: classes.wrapper,
            label: classes.actionLabel,
            selected: classes.selected
          }}
          label={label}
          icon={<Icon className={classes.icon} color={value === index ? 'white' : '#858585'} />}
          onClick={onClick}
        />
      ))}
    </BottomNavigation>
  );
}

NxNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      item: PropTypes.element,
      onClick: PropTypes.func
    })
  ),
  value: PropTypes.number
};

NxNavigation.defaultProps = {
  items: [
    { label: 'Recursos disponibles', icon: ListIcon },
    { label: 'Casos activos IPS', icon: PlaceIcon },
    { label: 'Indicadores', icon: ChartIcon },
    { label: 'Dasboard', icon: DashboardIcon }
  ]
};
