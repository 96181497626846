// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import apiClient from 'config/apiClient';
import NumberInput from '../NumberInput/NumberInput';
import NxSpinner from '../NxSpinner/NxSpinner';

// Others
import { urlApi } from '../../config/app';
// import { ApiGw } from '../../containers-state/auth';

import { redbloodCell, plasma, keysToValidateBlood } from '../../variables/resources';

// style for this view
const styles = {
  gridWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  formControlFlex: {
    flexBasis: '100px',
    margin: '0 15px 0 0',
    width: '100px',
    '@media (min-width: 1300px)': {
      flexGrow: '1'
    }
  },
  ...validationFormsStyle
};

const initialState = {
  redbloodCellsOp: '',
  redbloodCellsOn: '',
  redbloodCellsAp: '',
  redbloodCellsAn: '',
  redbloodCellsBp: '',
  redbloodCellsBn: '',
  redbloodCellsABp: '',
  redbloodCellsABn: '',
  plasmaOp: '',
  plasmaOn: '',
  plasmaAp: '',
  plasmaAn: '',
  plasmaBp: '',
  plasmaBn: '',
  plasmaABp: '',
  plasmaABn: '',
  observations: ''
};

export class BloodBankForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      loading: false,
      hasCapacity: false
    };
  }

  componentDidMount() {
    const { alert } = this.props;
    this.setState({ loading: true });
    apiClient.SEM.get(`${urlApi}/ips/resources/capacity`)
      .then(({ data }) => {
        const hasBloodBank = !!data.data.services.bloodBank.enable;
        this.setState({
          hasBloodBank,
          hasCapacity: true
        });
        // alert.show('Los últimos recursos reportados se han cargado correctamente', {
        //   type: 'info',
        //   timeout: 4000
        // });
      })
      .catch(error => {
        console.error(error);
        alert.show('Ocurrió un error al cargar los últimos recursos reportados.', {
          type: 'error',
          timeout: 5000
        });
      })
      .finally(() => this.setState({ loading: false }));
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleChangeObservations = (name, value) => {
    if (value.length <= 600) {
      this.setState({
        [name]: value
      });
    }
  };

  saveResources = () => {
    this.setState({ loading: true });
    const data = { ...this.state };
    const { alert } = this.props;
    apiClient.SEM.post(`${urlApi}/ips/resources/bloodBank`, data)
      .then(() => {
        this.setState(initialState, () => {
          alert.show('Los recursos se han guardado exitosamente', {
            type: 'info',
            timeout: 0
          });
        });
      })
      .catch(error => {
        const message = error.response.data.data
          ? error.response.data.data.errors[0].message
          : error.response.statusText;
        alert.show(message, {
          type: 'error',
          timeout: 0
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { classes = {} } = this.props;
    const stateClone = { ...this.state };
    const disableButton = keysToValidateBlood.some(
      key => `${stateClone[key]}` === 'undefined' || `${stateClone[key]}` === ''
    );
    let message;
    if (!stateClone.hasCapacity) {
      message =
        'Antes de reportar los recursos asociados al banco de sangre, se debe reportar la capacidad total de la entidad';
    } else if (stateClone.hasCapacity && !stateClone.hasBloodBank) {
      message =
        'Se ha reportado que la entidad no cuenta con el servicio de banco de sangre. Activa el servicio de banco de sangre para poder llenar este formulario';
    }
    return (
      <GridContainer>
        <NxSpinner loading={stateClone.loading} />
        {stateClone.hasBloodBank ? (
          <React.Fragment>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <h4>Rellene los siguientes campos con el número de unidades disponibles</h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Globulos rojos</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridItem xs={12} className={classes.gridWrap}>
                    {redbloodCell.map((value, key) => (
                      <NumberInput
                        key={key}
                        handleChange={this.handleChange}
                        name={value.name}
                        value={stateClone[value.name]}
                        placeHolder={value.label}
                        styles={classes.formControlFlex}
                        validation={{
                          type: 'min-value',
                          number: 0
                        }}
                      />
                    ))}
                  </GridItem>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Plasma</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridItem xs={12} className={classes.gridWrap}>
                    {plasma.map((value, key) => (
                      <NumberInput
                        key={key}
                        handleChange={this.handleChange}
                        name={value.name}
                        value={stateClone[value.name]}
                        placeHolder={value.label}
                        styles={classes.formControlFlex}
                        validation={{
                          type: 'min-value',
                          number: 0
                        }}
                      />
                    ))}
                  </GridItem>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Observaciones</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridItem xs={12} className={classes.gridWrap}>
                    <CustomInput
                      labelText="Observaciones generales"
                      id="observations"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: ({ target }) => this.handleChangeObservations(target.name, target.value),
                        type: 'text',
                        name: 'observations' || '',
                        multiline: true,
                        rowsMax: 4,
                        value: stateClone.observations
                      }}
                    />
                  </GridItem>
                  <div style={{ textAlign: 'right' }}>
                    <i>Máximo 600 caracteres.</i>
                  </div>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem container justify="center">
              <div>
                <Button color="info" onClick={this.saveResources} disabled={disableButton}>
                  Reportar recursos disponibles
                </Button>
              </div>
            </GridItem>
          </React.Fragment>
        ) : (
          <h3>{message}</h3>
        )}
      </GridContainer>
    );
  }
}

BloodBankForm.propTypes = {
  classes: PropTypes.object,
  alert: PropTypes.object
};

BloodBankForm.defaultProps = {
  classes: {}
};

export default withAlert()(withStyles(styles)(BloodBankForm));
