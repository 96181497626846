import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Core components
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

// Components
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import PieChart from 'nxComponents/Charts/PieChart';
import HorizontalChart from 'nxComponents/Charts/HorizontalChart';
import { backgroundColors } from 'nxComponents/Charts/options';

// Others
import { primaryColor, warningColor } from 'assets/jss/material-dashboard-pro-react';
import {
  getTrasportStatistics,
  getRejectedStatistics,
  getDurationStatistics,
  getRecvStatistics,
  getReqStatistics
} from 'api/charts';

const styles = () => ({
  title: {
    borderBottom: `1px solid ${primaryColor}`,
    width: '90%',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingBottom: 10
  },
  grid: {
    margin: '20px 0'
  },
  color: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    paddingRigth: 5
  },
  green: {
    background: backgroundColors[0]
  },
  yellow: {
    background: backgroundColors[1]
  },
  orange: {
    background: backgroundColors[2]
  },
  flex: {
    display: 'flex'
  },
  flexDiv: {
    display: 'flex',
    width: '90%',
    justifyContent: 'space-between'
  }
});

function Charts(props) {
  const [state, setState] = useState();
  const { classes } = props;

  useEffect(() => {
    const statistics = {};
    async function getStatistics() {
      statistics.transport = await getTrasportStatistics();
      statistics.rejected = await getRejectedStatistics();
      statistics.duration = await getDurationStatistics();
      statistics.ipsRecv = await getRecvStatistics();
      statistics.ipsReq = await getReqStatistics();
      setState(statistics);
    }

    getStatistics();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">
            <h3>Indicadores</h3>
          </CardHeader>
          <CardBody>
            {state ? (
              <>
                <GridContainer className={classes.grid} justify="center">
                  <h4 className={classes.title}>IPS que mas reciben traslados</h4>
                  <GridItem xs={12}>
                    {state.ipsRecv && (
                      <HorizontalChart info={state.ipsRecv} color={primaryColor} keyName="ipsRecv.name" />
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer className={classes.grid} justify="center">
                  <h4 className={classes.title}>IPS que mas solicitan traslados</h4>
                  <GridItem xs={12}>
                    {state.ipsReq && <HorizontalChart info={state.ipsReq} color={warningColor} keyName="ipsReq.name" />}
                  </GridItem>
                </GridContainer>
                <Divider />
                <GridContainer className={classes.grid}>
                  <GridItem xs={12} sm={6}>
                    {state.transport && (
                      <PieChart
                        title="Traslados trasportados por el CRUE"
                        info={state.transport}
                        showLegend
                        keyName="ambAssignedBy"
                      />
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    {state.rejected && (
                      <PieChart title="Traslados cancelados" info={state.rejected} keyName="rejectedReason" />
                    )}
                  </GridItem>
                </GridContainer>
                <div>
                  {state.duration && (
                    <GridContainer className={classes.grid} justify="center">
                      <h4 className={classes.title}>Porcentaje de traslados por rango de tiempo</h4>
                      <GridItem xs={12} sm={6} style={{ margin: 'auto' }}>
                        <PieChart title="Hoy" info={state.duration.today} keyName="name" />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <PieChart title="Última semana" info={state.duration.lastWeek} keyName="name" />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <PieChart title="Último mes" info={state.duration.lastMonth} keyName="name" />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <PieChart title="Últimos 6 meses" info={state.duration.lastSixMonth} keyName="name" />
                      </GridItem>
                      <div className={classes.flexDiv}>
                        <div className={classes.flex}>
                          <div className={`${classes.yellow} ${classes.color}`} />
                          <p>Esperado (menos de 60 minutos)</p>
                        </div>
                        <div className={classes.flex}>
                          <div className={`${classes.green} ${classes.color}`} />
                          <p>Aceptable (entre 60 y 90 minutos)</p>
                        </div>
                        <div className={classes.flex}>
                          <div className={`${classes.orange} ${classes.color}`} />
                          <p>Retrasado (más de 90 minutos)</p>
                        </div>
                      </div>
                    </GridContainer>
                  )}
                </div>
              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress size={55} style={{ color: '#05BF95' }} />
              </div>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

Charts.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Charts);
