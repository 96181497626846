import React from 'react';
import PropTypes from 'prop-types';

// @material ui icons
import Info from '@material-ui/icons/Info';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Close from '@material-ui/icons/Close';

// Utilities
import Mp3file from './beep.mp3';

const alertStyle = {
  info: {
    backgroundColor: '#2BC5A3',
    color: 'white',
    padding: '10px',
    marginTop: '2em',
    marginRight: '2em',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
    fontFamily: 'Arial',
    width: '500px',
    boxSizing: 'border-box'
  },
  error: {
    backgroundColor: '#FF2514',
    color: 'white',
    padding: '10px',
    marginTop: '3.7em',
    marginRight: '2.5em',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
    fontFamily: 'Arial',
    width: '500px',
    boxSizing: 'border-box'
  },
  success: {
    backgroundColor: '#8DDB00',
    color: 'white',
    padding: '10px',
    marginTop: '3.7em',
    marginRight: '2.5em',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
    fontFamily: 'Arial',
    width: '500px',
    boxSizing: 'border-box'
  }
};

const buttonStyle = {
  info: {
    border: 'none',
    backgroundColor: '#2BC5A3',
    cursor: 'pointer',
    color: '#FFFFFF'
  },
  error: {
    border: 'none',
    backgroundColor: '#FF2514',
    cursor: 'pointer',
    color: '#FFFFFF'
  },
  success: {
    border: 'none',
    backgroundColor: '#8DDB00',
    cursor: 'pointer',
    color: '#FFFFFF'
  }
};

const iconStyle = {
  color: '#FFFFFF',
  fontSize: 'xx-large',
  marginRight: '0.2em'
};

class AlertTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { options, message, close } = this.props;
    return (
      <div style={alertStyle[options.type]}>
        {options.type === 'info' && <Info style={iconStyle} />}
        {options.type === 'success' && <CheckCircle style={iconStyle} />}
        {options.type === 'error' && <ErrorOutline style={iconStyle} />}
        {message}
        <audio src={Mp3file} hidden controls autoPlay>
          <track kind="captions" />
        </audio>
        <button style={buttonStyle[options.type]} onClick={close} type="button">
          <Close />
        </button>
      </div>
    );
  }
}

AlertTemplate.propTypes = {
  options: PropTypes.object,
  message: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default AlertTemplate;
