import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import Datetime from 'react-datetime';
import moment from 'moment';
import queryString from 'query-string';
import intersection from 'lodash/intersection';
import { useAlert } from 'react-alert';

import BackgroundCovid from 'assets/img/BackgroundCovid.png';

// Core components
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Divider from '@material-ui/core/Divider';

// Components
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import NxSpinner from 'nxComponents/NxSpinner/NxSpinner';
import MixedChart from 'nxComponents/Charts/MixedChart';
import NxNavigation from 'nxComponents/NxNavigation/NxNavigation';
import NxBtnNavigation from 'nxComponents/NxBtnNavigation/NxBtnNavigation';

// Others
import { useInterval } from 'customHooks/useInterval';
import { useIsFirstRender } from 'customHooks/useIsFirstRender';
import AuthContainer from 'containers-state/auth';
import Netux from 'assets/img/Netux.png';
import {
  buildName,
  getAllResources,
  getResourcesAsync,
  backgroundColors,
  allowedKeys,
  navigationItems
} from 'utils/covid';
import alcaldia from 'assets/img/alcaldia.png';

function valid(finalDate, initialDate) {
  const maxTime = moment(initialDate).add(30, 'days');
  const today = moment();
  return finalDate.isBetween(initialDate, maxTime) && finalDate.isBefore(today);
}

const styles = theme => ({
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dateLabel: {
    fontWeight: 500,
    alignItems: 'center',
    minWidth: 120,
    paddingRight: 5,
    fontSize: '1rem',
    margin: 0,
    color: '#a2a2a2'
  },
  netuxImg: {
    width: 140,
    [theme.breakpoints.up('xl')]: {
      width: 240
    }
  },
  fab: {
    marginTop: -26,
    marginLeft: 30,
    position: 'absolute',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'black'
    },
    [theme.breakpoints.up('xl')]: {
      height: 80,
      width: 80,
      marginTop: -40
    }
  },
  card: {
    width: '20vw',
    height: '25vh',
    position: 'absolute',
    top: -30,
    borderRadius: '0 0 0 60px',
    right: 0
  },
  cardBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  alcaldiaLogo: {
    maxWidth: '13vw',
    height: 'auto'
  },
  covid: {
    fontFamily: "'Lato', sans-serif",
    width: '100vw',
    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.81)),url(${BackgroundCovid}) no-repeat center top`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 30px',
    minHeight: '19vh'
  },
  container: {
    padding: '0 20px 0 20px',
    background: 'white',
    height: '100%'
  },
  formControl: {
    maxHeight: '35vh',
    overflowY: 'scroll',
    paddingLeft: 15
  },
  color: {
    width: '2rem',
    height: '0.5em',
    borderRadius: '9px',
    marginRight: 9
  },
  checkboxLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  checkboxText: {
    color: '#3F3F41',
    margin: 0
  },
  subtitle: {
    color: '#38BCD7',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  dateInput: {
    color: 'white !important',
    textAlign: 'center',
    fontSize: '0.9rem !important'
  }
});

const covidCharts = [
  { service: 'uci', key: 'availables', name: 'uci-availables' },
  { service: 'uci', key: 'covid', name: 'uci-covid' }
];

const availablesCharts = [
  { service: 'generalHospitalization', key: 'availables', name: 'generalHospitalization-availables' },
  { service: 'generalHospitalization', key: 'covid', name: 'generalHospitalization-covid' }
];

const getLabels = info => {
  return info.emergency.map(data => `${data.day}/${data.month}`);
};

const initialCharts = [
  { service: 'uci', key: 'total', name: 'uci-total' },
  { service: 'uci', key: 'availables', name: 'uci-availables' }
];

const initialChecks = {
  'uci-total': true,
  'uci-availables': true
};

const initialDates = {
  initialDate: moment()
    .subtract(15, 'days')
    .valueOf(),
  endDate: moment().valueOf()
};

function CovidCharts(props) {
  const [resources, setResources] = useState();
  const [selectedCharts, setSelectedCharts] = useState(initialCharts);
  const [dates, setDates] = useState(initialDates);
  const [checks, setChecks] = useState(initialChecks);
  const [table, setTable] = useState(0);
  const [labels, setlabels] = useState();
  const [loading, setLoading] = useState(true);
  const isFirstRender = useIsFirstRender();
  const alert = useAlert();
  const { classes, history, roles, location } = props;
  const qs = queryString.parse(location.search);

  useEffect(() => {
    window.Intercom('update', {
      hide_default_launcher: true
    });
  }, []);

  useEffect(
    () => {
      if (location.search) {
        const charts = intersection(qs.charts.split(','), allowedKeys);
        setSelectedCharts(
          charts.map(name => ({
            service: name.split('-')[0],
            key: name.split('-')[1],
            name
          }))
        );
        const queryChecks = {};
        charts.forEach(key => {
          queryChecks[key] = true;
        });
        setChecks(queryChecks);
      }
    },
    [location.search]
  );

  const getQueryString = useCallback(
    () => {
      const area = table === 0 ? 'med' : 'metropolitan';
      const initialDate = moment(dates.initialDate).unix();
      const endDate = moment(dates.endDate).unix();
      return queryString.stringify({ initialDate, endDate, area });
    },
    [dates.initialDate, dates.endDate, table]
  );

  const getResources = async () => {
    setLoading(true);
    const query = getQueryString();
    const listResources = await getAllResources(`/ips/covid/resources/indicators/all?${query}`);
    if (listResources.ok) {
      setResources(listResources.data);
      const chartLabels = getLabels(listResources.data);
      setlabels(chartLabels);
    } else {
      alert.error('Error al obtener la información');
    }
    setLoading(false);
  };

  useEffect(
    () => {
      getResources();
    },
    [table]
  );

  useInterval(() => {
    const query = getQueryString();
    getResourcesAsync(`/ips/covid/resources/indicators/all?${query}`, setResources);
  }, 90000);

  useEffect(
    () => {
      if (dates.initialDate && dates.endDate && !isFirstRender) {
        getResources();
      }
    },
    [dates.initialDate, dates.endDate]
  );

  const saveSelectedCharts = values => {
    const keys = [];
    Object.keys(values).forEach(key => {
      if (values[key]) {
        keys.push({
          service: key.split('-')[0],
          key: key.split('-')[1],
          name: key
        });
      }
    });
    setSelectedCharts(keys);
  };

  const handleCheck = event => {
    const newChecks = { ...checks, [event.target.name]: event.target.checked };
    setChecks(newChecks);
    saveSelectedCharts(newChecks);
  };

  const handleChangeDate = (date, name) => {
    setDates({ ...dates, [name]: date.valueOf() });
  };

  const handleChangeInitialDate = (date, name) => {
    setDates({ ...dates, [name]: date.valueOf(), endDate: undefined });
  };

  if (!roles.includes('CRUE')) {
    history.push('/');
  }

  return (
    <>
      <NxSpinner loading={loading} />
      <div className={classes.covid}>
        <Hidden smDown>
          <GridItem xs={12} sm={3}>
            <img className={classes.netuxImg} src={Netux} alt="logo-Netux" />
          </GridItem>
        </Hidden>
        <GridItem xs={12} sm={9} style={{ marginRight: '25vw', textAlign: 'right' }}>
          <NxNavigation items={navigationItems(history)} value={2} />
          <div className={classes.flex}>
            <div className={classes.flex}>
              <p className={classes.dateLabel}>Fecha inicial:</p>
              <FormControl fullWidth>
                <Datetime
                  viewMode="years"
                  dateFormat="DD-MM-YYYY"
                  timeFormat={false}
                  value={parseInt(dates.initialDate, 10)}
                  isValidDate={date => date.isBefore(moment())}
                  closeOnSelect
                  inputProps={{
                    // placeholder: 'Ingresa la fecha',
                    required: true,
                    readOnly: true,
                    className: `form-control ${classes.dateInput}`
                  }}
                  onChange={date => handleChangeInitialDate(date, 'initialDate')}
                />
              </FormControl>
            </div>
            <div className={classes.flex}>
              <p className={classes.dateLabel}>Fecha final:</p>
              <FormControl fullWidth>
                <Datetime
                  viewMode="years"
                  dateFormat="DD-MM-YYYY"
                  timeFormat={false}
                  value={parseInt(dates.endDate, 10)}
                  isValidDate={date => valid(date, dates.initialDate)}
                  closeOnSelect
                  inputProps={{
                    // placeholder: 'Ingresa la fecha',
                    required: true,
                    readOnly: true,
                    className: `form-control ${classes.dateInput}`
                  }}
                  onChange={date => handleChangeDate(date, 'endDate')}
                />
              </FormControl>
            </div>
          </div>
        </GridItem>
        <Card className={classes.card}>
          <CardBody className={classes.cardBody}>
            <img className={classes.alcaldiaLogo} alt="Logo-alcaldía" src={alcaldia} />
          </CardBody>
        </Card>
      </div>
      {resources ? (
        <div className={classes.container}>
          <NxBtnNavigation
            value={table}
            onChange={(event, newValue) => setTable(newValue)}
            items={['Medellín', 'Área metropolitana']}
          />
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {['total', 'availables', 'covid', 'ira'].map(field => {
                    return ['emergency', 'uce', 'uci', 'generalHospitalization'].map(service => (
                      <FormControlLabel
                        key={`${service}-${field}`}
                        control={
                          <Checkbox
                            style={{ padding: 3 }}
                            icon={<CircleUnchecked style={{ color: '#757575' }} />}
                            checkedIcon={<CircleChecked style={{ color: '#5a5a5a' }} />}
                            checked={checks[`${service}-${field}`] || false}
                            onChange={handleCheck}
                            name={`${service}-${field}`}
                          />
                        }
                        label={
                          <div className={classes.checkboxLabel}>
                            <div
                              className={classes.color}
                              style={{ background: backgroundColors[`${service}-${field}`] }}
                            />
                            <p className={classes.checkboxText}>{buildName(`${service}-${field}`)}</p>
                          </div>
                        }
                      />
                    ));
                  })}
                  {['covid', 'covidMen', 'covidWomen', 'ira', 'iraMen', 'iraWomen'].map(field => (
                    <FormControlLabel
                      key={`total-${field}`}
                      control={
                        <Checkbox
                          style={{ padding: 3 }}
                          icon={<CircleUnchecked style={{ color: '#757575' }} />}
                          checkedIcon={<CircleChecked style={{ color: '#5a5a5a' }} />}
                          checked={checks[`total-${field}`] || false}
                          onChange={handleCheck}
                          name={`total-${field}`}
                        />
                      }
                      label={
                        <div className={classes.checkboxLabel}>
                          <div className={classes.color} style={{ background: backgroundColors[`total-${field}`] }} />
                          <p className={classes.checkboxText}>{buildName(`total-${field}`)}</p>
                        </div>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <MixedChart labels={labels} selectedCharts={selectedCharts} resources={resources} />
            </GridItem>
          </GridContainer>
          <Divider />
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <h4 className={classes.subtitle}>Covid UCI</h4>
              <MixedChart labels={labels} selectedCharts={covidCharts} resources={resources} />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <h4 className={classes.subtitle}>Covid hospitalización</h4>
              <MixedChart labels={labels} selectedCharts={availablesCharts} resources={resources} />
            </GridItem>
          </GridContainer>
        </div>
      ) : (
        <h4>No ha datos disponibles</h4>
      )}
    </>
  );
}

CovidCharts.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  roles: PropTypes.array,
  location: PropTypes.object
};

const TableComponent = withStyles(styles)(CovidCharts);

export default props => (
  <Subscribe to={[AuthContainer]}>
    {AuthContainer => <TableComponent {...props} roles={AuthContainer.state.roles} />}
  </Subscribe>
);
