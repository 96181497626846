import { Container } from 'unstated';
import React from 'react';
import socketCHAT from 'config/wsChat';
import apiClient from 'config/apiClient';
import { urlApi } from 'config/app';
import format from 'date-fns/format';
import queryString from 'query-string';

const reactStringReplace = require('react-string-replace');

// const Identicon = require('identicon.js');
// const loremIpsum = require('lorem-ipsum');

// const getRandomColor = () => {
//   const letters = '0123456789ABCDEF';
//   let color = '#';
//   for (let i = 0; i < 6; i += 1) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// };

// const photo = size =>
//   new Identicon(String(Math.random()) + String(Math.random()), {
//     margin: 0,
//     size: size || 20
//   }).toString();

function getRandomColor(username, dictionary) {
  if (dictionary[username]) return dictionary[username];
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  dictionary[username] = color;
  return color;
}

// ('{ ambulanceId=12 & ipsReqId=23 }');
//  urlParams.get('myParam');
function replaceText(text) {
  // const query = text.replace(/\s/g, '').split('&');
  const query = text.replace(/\s/g, '');
  return (
    <a href={`/dashboard?${query}`} target="_blank" rel="noopener noreferrer">
      (Click para ver ruta)
    </a>
  );
}

function replaceText2(text) {
  const qs = JSON.parse(text.substring(text.indexOf('{'), text.indexOf('}') + 1));
  return (
    <a
      href={`/downloadChatFile?referralId=${qs['referralId']}&filename=${qs['fileName']}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      (Click para descargar el archivo)
    </a>
  );
}

// /{([^}]+)}/g
// /\[+([^\][]+)]+/g
function searchExpression(string, entityRoles) {
  if (string.includes('_diagnosis_')) {
    return reactStringReplace(string, /{([^}]+)}/g, (match, i) => replaceText2(string));
  }
  if (Object.values(entityRoles).indexOf('CRUE') > -1) {
    return reactStringReplace(string, /{([^}]+)}/g, (match, i) => replaceText(match));
  }
  return reactStringReplace(string, /{([^}]+)}/g, (match, i) => '');
}

class ChatContainer extends Container {
  constructor(props) {
    super(props);
    this.dictionaryColor = {};
    this.state = {
      messageList: [],
      chatIntegrants: []
    };

    socketCHAT.on('message', data => {
      const { msg, username, msgType, referralId } = data;
      if (parseInt(referralId, 10) === parseInt(this.state.referralId, 10)) {
        if (msgType === 'INFO') {
          console.log('nuevo usuario conectado');
          this.getUsersByChat(referralId);
          return;
        }
        if (msgType === 'ADM') this.transfer.getTransferId(parseInt(referralId, 10));
        this.addMessageReceived(referralId, msg, msgType, username);
      }
    });
  }

  initialize = referralId => {
    this.setState({
      referralId: parseInt(referralId, 10),
      messageList: []
    });

    this.getMessagesForTransfer(referralId);
    this.getUsersByChat(referralId);
  };

  bindAuth(auth) {
    this.auth = auth;
  }

  bindTransfer(transfer) {
    this.transfer = transfer;
  }

  getMessagesForTransfer = async transferId => {
    try {
      const response = await apiClient.SEM.get(`${urlApi}/chatMessage/${transferId}`);
      this.loadMessages(response.data.data);
    } catch (error) {
      throw error;
    }
  };

  getUsersByChat = async transferId => {
    try {
      const response = await apiClient.SEM.get(`${urlApi}/referral/${transferId}/entities`);
      this.loadUsersInChat(response.data.data);
    } catch (error) {
      throw error;
      // Promise.reject(new Error(`Ocurrio un error obteniendo los usuarios del chat`));
      // throw new Error(`Ocurrio un error obteniendo los usuarios del chat`);
    }
  };

  loadUsersInChat = (users = {}) => {
    this.setState({
      chatIntegrants: users.entities || []
    });
  };

  loadMessages = listMessages => {
    const entityRoles = this.auth.state.roles;
    const chatListMessages = listMessages.map(message => ({
      position: message.username === this.auth.state.userInfo.username ? 'right' : 'left',
      forwarded: false,
      type: message.msgType === 'MSG' ? 'text' : 'system', // photo, file, system, location, spotify, text
      theme: message.username === this.auth.state.userInfo.username ? 'green' : 'white',
      title: message.username,
      titleColor:
        message.username === this.auth.state.userInfo.username
          ? '#000'
          : getRandomColor(message.username, this.dictionaryColor),
      text: searchExpression(message.msg, entityRoles),
      date: new Date(message.updatedAt),
      dateString: format(new Date(message.updatedAt), 'HH:mm')
    }));
    this.setState({ messageList: chatListMessages });
  };

  random = (type, { msg, username, msgType }) => {
    const entityRoles = this.auth.state.roles;
    switch (type) {
      default:
        return {
          position: username === this.auth.state.userInfo.username ? 'right' : 'left',
          forwarded: false,
          type: msgType === 'MSG' ? 'text' : 'system', // photo, file, system, location, spotify, text
          theme: username === this.auth.state.userInfo.username ? 'green' : 'white',
          title: username || '',
          titleColor:
            username === this.auth.state.userInfo.username ? '#000' : getRandomColor(username, this.dictionaryColor),
          text: searchExpression(msg, entityRoles) || '',
          date: new Date(),
          dateString: format(new Date(), 'HH:mm')
        };
    }
  };

  addMessageReceived = (referralId, msg, msgType, username) => {
    // const { state } = this.auth;
    // if (username !== state.userInfo.username) {
    const { messageList } = this.state;
    const newMsg = { referralId, msgType, username, msg };

    messageList.push(this.random('message', newMsg));
    this.setState({ messageList });
    // }
  };

  addMessage = (transferId, msg, msgType = 'MSG') => {
    const { state } = this.auth;
    // const { messageList } = this.state;
    const newMsg = {
      referralId: transferId,
      msgType,
      username: state.userInfo.username,
      msg:
        `${(state.entity || {}).name.toUpperCase() || 'usuario'} dice:
      ${msg}` || ''
    };
    socketCHAT.emit('message', newMsg);
    // messageList.push(this.random('message', newMsg));

    // this.setState({ messageList });
  };
}
// export default withRouter(ChatContainer);
export default ChatContainer;
