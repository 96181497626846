import React from 'react';

// Components
import GridContainer from 'components/Grid/GridContainer';

// Core components
import ReportsCOT from '../../nxComponents/Reports/Reports';

const Reports = () => {
  return (
    <GridContainer>
      <ReportsCOT />
    </GridContainer>
  );
};

export default Reports;
