import React from 'react';
import PropTypes from 'prop-types';

import { Pie } from 'react-chartjs-2';
import { chartOptions, backgroundColors } from './options';

export default function PieChart({ info, keyName, showLegend, title }) {
  const information = info.information || info.data;
  const isEmpty = information.every(row => (row.count || row.quantity) == 0);
  const data = {
    labels: information.map(row => row[keyName]),
    datasets: [
      {
        data: information.map(row => row.count || row.quantity),
        backgroundColor: backgroundColors
      }
    ]
  };

  const options = {
    ...chartOptions(information),
    legend: {
      display: showLegend
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {isEmpty ? (
        <h4>No hay información de trasalados</h4>
      ) : (
        <>
          <Pie data={data} options={options} />
        </>
      )}
      <h4 style={{}}>{title}</h4>
    </div>
  );
}

PieChart.propTypes = {
  info: PropTypes.object.isRequired,
  keyName: PropTypes.string.isRequired,
  showLegend: PropTypes.bool,
  title: PropTypes.string
};

PieChart.defaultProps = {
  showLegend: false
};
