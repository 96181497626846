import { emphasize } from '@material-ui/core/styles/colorManipulator';

const nxSelectStyle = theme => ({
  root: {
    flexGrow: 1,
    margin: '14px 0',
    paddingTop: 10
  },
  input: {
    display: 'flex',
    padding: 0,
    height: '100%'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(4)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08)
  },
  noOptionsMessage: {
    textAlign: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: '0.93rem'
  },
  placeholderError: {
    position: 'absolute',
    left: 2,
    fontSize: '0.93rem',
    color: 'red'
  },
  paper: {
    position: 'absolute',
    zIndex: 4,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  helpText: {
    fontSize: '0.9em',
    margin: 0
  }
});

export default nxSelectStyle;
