export const typeDocuments = [
  { value: 'CC', name: 'Cédula de ciudadanía' },
  { value: 'CE', name: 'Cédula de extranjería' },
  { value: 'RC', name: 'Registro civil' },
  { value: 'TI', name: 'Tarjeta de identidad' },
  { value: 'PA', name: 'Pasaporte' },
  { value: 'MS', name: 'Menor sin identificación' },
  { value: 'AS', name: 'Adulto sin identificación' },
  { value: 'CN', name: 'Certificado nacido vivo' },
  { value: 'PEP', name: 'Permiso especial de permanencia' },
  { value: 'PT', name: 'Permiso de protección temporal' }
];
export const typeGenders = [{ value: 'F', name: 'Femenino' }, { value: 'M', name: 'Masculino' }];
