import React from 'react';
import PropTypes from 'prop-types';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { strCase } from 'utils/covid';
import CovidCellSex from './CovidCellSex';


const getColor = column => {
  switch (column) {
    case 'covid':
      return '#F00000';
    case 'ira':
      return '#005C9A';
    case 'titles':
      return '#3F3F41';
    default:
      return '#828282';
  }
};

const renderCell = (columnName, value, classes) => {
  if (columnName === 'total') {
    return (
      <>
        <GridContainer>
          <GridItem xs={1} />
          <GridItem xs={4}>
            <p className={classes.total} style={{ color: getColor('titles') }}>
              Covid
            </p>
          </GridItem>
          <GridItem xs={5}>
            <p className={classes.covid}> {value.covid} </p>
          </GridItem>
          <GridItem xs={2}>
            <Divider orientation="vertical" variant="middle" flexItem />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={1} />
          <GridItem xs={4}>
            <p className={classes.total} style={{ color: getColor('titles') }}>
              IRA
            </p>
          </GridItem>
          <GridItem xs={5}>
            <p className={classes.ira}> {value.ira} </p>
          </GridItem>
          <GridItem xs={2}>
            <Divider orientation="vertical" variant="middle" flexItem />
          </GridItem>
        </GridContainer>
      </>
    );
  }
  if (columnName === 'name') {
    return (
      <GridContainer>
        <GridItem xs={10}>
          <p className={classes.total}> {strCase(value)} </p>
        </GridItem>
        <GridItem xs={2}>
          <Divider orientation="vertical" variant="middle" flexItem />
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <>
      <GridContainer>
        <GridItem xs={3}>
          <Chip
            key="covidLabel"
            label="Covid"
            className={classes.totalChip}
            style={{ color: getColor('titles'), backgroundColor: '#FFF' }}
          />
        </GridItem>
        <GridItem xs={6}>
          <CovidCellSex value={value} classes={classes} type="covid" />
        </GridItem>
        <GridItem xs={3}>
          <Chip
            key="totalCovidNumber"
            label={value.covid === 'NA' ? '--' : `= ${value.covid}`}
            className={classes.totalChip}
            style={{ color: value.covid === 'NA' ? getColor('NA') : getColor('ira'), backgroundColor: '#FFF' }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={3}>
          <Chip
            key="covidName"
            label="IRA"
            className={classes.totalChip}
            style={{ color: getColor('titles'), backgroundColor: '#FFF' }}
          />
        </GridItem>
        <GridItem xs={6}>
          <CovidCellSex value={value} classes={classes} type="ira" />
        </GridItem>
        <GridItem xs={3}>
          <Chip
            key="totalIraNumber"
            label={value.ira === 'NA' ? '--' : `= ${value.ira}`}
            className={classes.totalChip}
            style={{ color: value.ira === 'NA' ? getColor('NA') : getColor('ira'), backgroundColor: '#FFF' }}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};

export const Cell = props => {
  const { column, classes, value } = props;
  return (
    <VirtualTable.Cell className={classes.cell} style={{ padding: '8px 5px', margin: 0 }}>
      {renderCell(column.name, value, classes)}
    </VirtualTable.Cell>
  );
};

Cell.propTypes = {
  column: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
