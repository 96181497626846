// @flow
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/core components
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CustomInput from 'components/CustomInput/CustomInput';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';

// Others
import { nonSurgicalSupportFields, noAvailableReasons } from 'variables/resources';
import { scheduleOptions } from 'utils/resources';
import NxSelect from '../../NxSelect/NxSelect';

// Assets
import styles from '../iosStyleSwitch';

const NonSurgicalSupport = props => {
  const { classes, state, handleChange, isOccupation, capacity } = props;
  const switchLabel = isOccupation ? 'Disponible' : 'Ofertado';
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitle}>Recurso humano - Especialidades no quirúrgicas de apoyo</h4>
            </CardText>
          </CardHeader>
          <CardBody className={classes.cardBodyJustify}>
            <GridContainer>
              {nonSurgicalSupportFields.map(resource => {
                if (isOccupation) {
                  if (capacity[resource.name]) {
                    return (
                      <GridItem key={resource.id} xs={12} sm={6} md={4} lg={3}>
                        <FormControl component="fieldset">
                          <FormLabel>{resource.label}</FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  classes={{
                                    switchBase: classes.iOSSwitchBase,
                                    bar: classes.iOSBar,
                                    icon: classes.iOSIcon,
                                    // iconChecked: classes.iOSIconChecked,
                                    checked: classes.iOSChecked
                                  }}
                                  checked={Boolean(state[resource.name])}
                                  onClick={({ target }) =>
                                    handleChange(target.name, target.checked, 'nonSurgicalSupport')
                                  }
                                  name={resource.name}
                                  color="primary"
                                />
                              }
                              label={state[resource.name] ? `${switchLabel}` : `No ${switchLabel}`}
                            />
                          </FormGroup>
                        </FormControl>
                      </GridItem>
                    );
                  }
                } else {
                  return (
                    <GridItem key={resource.id} xs={12} sm={6} md={4} lg={3}>
                      <FormControl component="fieldset">
                        <FormLabel>{resource.label}</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                classes={{
                                  switchBase: classes.iOSSwitchBase,
                                  bar: classes.iOSBar,
                                  icon: classes.iOSIcon,
                                  // iconChecked: classes.iOSIconChecked,
                                  checked: classes.iOSChecked
                                }}
                                checked={Boolean(state[resource.name])}
                                onClick={({ target }) =>
                                  handleChange(target.name, target.checked, 'nonSurgicalSupport')
                                }
                                name={resource.name}
                                color="primary"
                              />
                            }
                            label={state[resource.name] ? `${switchLabel}` : `No ${switchLabel}`}
                          />
                        </FormGroup>
                      </FormControl>
                    </GridItem>
                  );
                }
                return null;
              })}

              <GridContainer style={{ padding: '0 12px' }}>
                {isOccupation
                  ? nonSurgicalSupportFields.map(resource => {
                      if (capacity[resource.name] && !state[resource.name]) {
                        return (
                          <GridItem key={resource.id} xs={12} sm={6}>
                            <FormControl fullWidth className={classes.selectFormControl}>
                              <InputLabel error={!state[resource.reasonName]} htmlFor="documentType">
                                {resource.placeholder}
                              </InputLabel>
                              <Select
                                value={state[resource.reasonName] || ''}
                                onChange={({ target }) => handleChange(target.name, target.value, 'nonSurgicalSupport')}
                                inputProps={{
                                  name: resource.reasonName,
                                  id: resource.reasonName
                                }}
                              >
                                {noAvailableReasons.map(reason => (
                                  <MenuItem key={reason.id} value={reason.name}>
                                    {reason.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {state[resource.reasonName] === 'Otro' && (
                              <CustomInput
                                error={!state[`${resource.name}OtherReason`]}
                                labelText="¿Cuál razón?"
                                id={`${resource.name}OtherReason`}
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  onChange: ({ target }) =>
                                    handleChange(target.name, target.value, 'nonSurgicalSupport'),
                                  type: 'text',
                                  name: `${resource.name}OtherReason`,
                                  multiline: true,
                                  rowsMax: 2,
                                  value: state[`${resource.name}OtherReason`]
                                }}
                              />
                            )}
                          </GridItem>
                        );
                      }
                      return null;
                    })
                  : nonSurgicalSupportFields.map(resource => {
                      if (state[resource.name]) {
                        return (
                          <GridItem key={resource.id} xs={12} sm={6} lg={4}>
                            <NxSelect
                              required
                              options={scheduleOptions}
                              name={resource.scheduleName}
                              placeholder={`Disponibilidad de ${resource.label}`}
                              handleChange={e => handleChange(resource.scheduleName, e, 'nonSurgicalSupport')}
                              value={state[resource.scheduleName] || null}
                              id={resource.scheduleName}
                              isMulti
                            />
                          </GridItem>
                        );
                      }
                      return null;
                    })}
              </GridContainer>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

NonSurgicalSupport.propTypes = {
  capacity: PropTypes.object,
  classes: PropTypes.object,
  state: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isOccupation: PropTypes.bool
};

NonSurgicalSupport.defaultProps = {
  capacity: {},
  classes: {},
  state: {},
  isOccupation: false
};

export default withStyles(styles)(NonSurgicalSupport);
