// @flow

import React from 'react';
import Datetime from 'react-datetime';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import apiClient from 'config/apiClient';
import { Subscribe } from 'unstated';
import TransferContainer from 'containers-state/transferState';
import AuthContainer from 'containers-state/auth';

// @material-ui/core components
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Styles
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

// material ui icons
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';

// core components
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CustomInput from 'components/CustomInput/CustomInput';
import FormControl from '@material-ui/core/FormControl';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ImageUpload from 'components/CustomUpload/ImageUpload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import NumberInput from '../NumberInput/NumberInput';
import NxSelectAsync from '../NxSelect/NxSelectAsync';
import Address from '../Address/Address';
import NxSpinner from '../NxSpinner/NxSpinner';

// Others
import { changeValidator } from '../../utils/Validator';
import { typeDocuments, typeGenders } from '../../variables/person';
import { urlApi } from '../../config/app';
import { cieCall, neighborhoodCall, departmentCall, municipalityCall } from '../../utils/Transfer';
import { keysToValidate } from '../../variables/goldenCode';

let timeOutId;

function apiCall(inputValue, field, param) {
  switch (field) {
    case 'cie-10':
      return cieCall(inputValue);
    case 'department':
      return departmentCall(inputValue);
    case 'municipality':
      return municipalityCall(inputValue, param);
    case 'neighborhood':
      return neighborhoodCall(inputValue, param);
    default:
      break;
  }
  return null;
}

function getOptions(inputValue, field, param) {
  if (timeOutId) {
    clearInterval(timeOutId);
  }
  if (inputValue.length > 2) {
    return new Promise(resolve => {
      timeOutId = setTimeout(() => {
        resolve(apiCall(inputValue, field, param));
      }, 400);
    });
  }
  return null;
}

export class AmbulanceRequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.getAllEps();
    this.getAllRegimeTypes();
    this.getAddressLetters();
    this.getNetworks();
  }

  componentDidMount() {
    const { person } = this.props;
    this.setState({ ...person });
    if (person.epsCode) {
      this.getInsuranceRegime(person.epsCode);
    }
  }

  handleChangeAsync = (inputValue, type, stateNameEqualTo, maxValue) => {
    const value = inputValue[0] ? inputValue[0].value : '';
    const event = {
      target: {
        name: 'cie-10',
        value
      }
    };
    const error = changeValidator(event, type, stateNameEqualTo, maxValue);
    if (value === '') {
      this.setState({
        'cie-10': undefined,
        'cie-10State': undefined
      });
    } else {
      this.setState({
        'cie-10': inputValue,
        'cie-10State': error
      });
    }
  };

  handleNeighborhoodChange = inputValue => {
    this.setState({ neighborhood: inputValue });
  };

  handleDepartmentChange = inputValue => {
    this.setState({
      department: inputValue,
      municipality: null,
      neighborhood: null
    });
  };

  handleMunicipalityChange = inputValue => {
    this.setState({
      municipality: inputValue,
      neighborhood: null
    });
  };

  getNetworks = () => {
    apiClient.SEM.get(`${urlApi}/network`)
      .then(({ data }) => {
        this.setState({
          networks: data.data.rows
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getAllEps = () => {
    apiClient.SEM.get(`${urlApi}/eps`)
      .then(({ data }) => {
        const { rows } = data.data;
        const index = rows.findIndex(row => row.id === 2);
        rows.splice(index, 1);
        this.setState({
          epsList: rows
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getAllRegimeTypes = () => {
    apiClient.SEM.get(`${urlApi}/regimeType`)
      .then(({ data }) => {
        this.setState({
          regimeTypeList: data.data.rows
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getInsuranceRegime = epsCode => {
    apiClient.SEM.get(`${urlApi}/insuranceRegime/code/${epsCode}`)
      .then(({ data }) => {
        this.setState({
          eps: data.data.eps.id,
          regimeType: data.data.regimeType.id,
          insuranceRegimeId: data.data.id
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  getInsuranceRegimesByEps = epsId => {
    let regimeTypeList = [];
    apiClient.SEM.get(`${urlApi}/insuranceRegime/eps/${epsId}`)
      .then(({ data }) => {
        regimeTypeList = data.data;
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.setState({
          regimeTypeList
        });
      });
  };

  getAddressLetters = () => {
    apiClient.SEM.get(`${urlApi}/addressLetter`)
      .then(response => {
        this.setState({ addressLetters: response.data.data.rows });
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleChange = (event, type, stateNameEqualTo, maxValue) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    const error = changeValidator(event, type, stateNameEqualTo, maxValue);
    switch (type) {
      case 'checkbox':
        this.setState({
          [inputName]: event.target.checked
        });
        break;
      default:
        this.setState({
          [inputName]: inputValue,
          [`${inputName}State`]: error
        });
        break;
    }
    if (inputName === 'eps') {
      this.getInsuranceRegimesByEps(inputValue);
    }
  };

  observationsHandleChange = ({ target }) => {
    if (target.value.length < 511) {
      this.setState({
        [target.name]: target.value
      });
    }
  };

  handleChangeNumber = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  epsHandleChange = ({ target }) => {
    this.getInsuranceRegimesByEps(target.value);
    this.setState({
      [target.name]: target.value,
      regimeType: null
    });
  };

  handleInsuranceRegimeChange = ({ target }) => {
    const { regimeTypeList } = this.state;
    const insuranceRegime = regimeTypeList.find(regime => regime.id === target.value);
    this.setState({
      regimeType: target.value,
      insuranceRegimeId: insuranceRegime.id
    });
  };

  valid = date => date.isBefore(new Date());

  changebirthday = date => {
    if (typeof date !== 'string') {
      this.setState({
        birthdate: date.format('YYYY-MM-DD'),
        birthdateState: 'success'
      });
    }
  };

  onChangeFile1 = file => {
    this.setState({ annex9: file });
  };

  buildAddress = () => {
    const {
      addressRoad,
      letterNumber,
      addressLetter,
      orientation,
      addressRoad2,
      street,
      addressLetter2,
      orientation2,
      number
    } = this.state;
    if (addressRoad && letterNumber && addressRoad2 && street && number) {
      return `${addressRoad} ${letterNumber}${addressLetter || ''}${orientation ||
        ''} # ${addressRoad2} ${street}${addressLetter2 || ''}${orientation2 || ''}-${number}`;
    }
    return undefined;
  };

  buildDiagnoses = () => {
    const stateClone = { ...this.state };
    return stateClone['cie-10'] ? stateClone['cie-10'].map(code => code.id) : undefined;
  };

  requestData = () => {
    const stateClone = { ...this.state };
    return {
      person: {
        firstName: stateClone.firstName,
        middleName: stateClone.middleName,
        lastName: stateClone.lastName,
        secondLastName: stateClone.secondLastName,
        fullName: `${stateClone.firstName} ${stateClone.middleName} ${stateClone.lastName} ${stateClone.lastName}`,
        documentType: stateClone.documentType,
        document: stateClone.document ? `${stateClone.document}`: undefined,
        birthdate: stateClone.birthdate ? new Date(stateClone.birthdate) : undefined,
        sex: stateClone.sex,
        address: this.buildAddress(),
        cellphone: stateClone.phone,
        neighborhoodId: stateClone.neighborhood ? stateClone.neighborhood.value : undefined,
        insuranceRegimeId: stateClone.insuranceRegimeId,
      },
      goldenCode: {
        eventDiagnoses: this.buildDiagnoses(),
        observations: stateClone.observations,
        careForDiagnosis: stateClone.careForDiagnosis
      }
    };
  };

  isAddressInvalid = () =>  {
    const {addressRoad, letterNumber, addressRoad2, number} = this.state;
    const values = [addressRoad, letterNumber, letterNumber, addressRoad2, number]
    return values.some(value => !value)
  }

  requestAmbulance = () => {
    this.setState({ loading: true });
    const { alert, transferData, history } = this.props;
    const { annex9 } = this.state;
    const data = this.requestData();
    const dataString = JSON.stringify(data);
    const formData = new FormData();
    formData.append('form', dataString);
    formData.append('transfer', annex9);
    transferData
      .requestAmbulance(formData)
      .then(response => {
        history.push(`/`);
        alert.show('Se ha realizado la solicitud de la ambulancia exitosamente');
      })
      .catch(() => {
        alert.show('no se pudo enviar la solicitud de traslado', {
          type: 'error',
          timeout: 5000
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  areKeysPresent = () => {
    const stateClone = { ...this.state };
    const keys = keysToValidate.filter(key => key !== 'cie-10' || key !== 'annex9');
    return keys.some(key => !stateClone[key]);
  };

  render() {
    const { classes = {}, person, roles } = this.props;
    const stateClone = { ...this.state };
    const { networks = [] } = this.state;
    const disabled = this.areKeysPresent() || this.isAddressInvalid();

    return (
      <GridContainer>
        <NxSpinner loading={stateClone.loading} />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Solicitud de traslado</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <CustomInput
                      labelText="Primer nombre*"
                      formControlProps={{ fullWidth: true }}
                      error={!stateClone.firstName}
                      id="firstName"
                      inputProps={{
                        onChange: event => this.handleChange(event, 'length', 1),
                        autoComplete: 'off',
                        name: 'firstName',
                        value: stateClone.firstName || '',
                        type: 'text',
                        disabled: !!person.firstName,
                        endAdornment:
                          stateClone.firstNameState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <CustomInput
                      labelText="Segundo nombre"
                      formControlProps={{ fullWidth: true }}
                      id="middleName"
                      inputProps={{
                        onChange: event => this.handleChange(event),
                        autoComplete: 'off',
                        name: 'middleName',
                        value: stateClone.middleName || '',
                        type: 'text',
                        disabled: !!person.middleName,
                        endAdornment:
                          stateClone.middleNameState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <CustomInput
                      labelText="Primer apellido*"
                      formControlProps={{ fullWidth: true }}
                      error={!stateClone.lastName}
                      id="lastName"
                      inputProps={{
                        onChange: event => this.handleChange(event, 'length', 1),
                        autoComplete: 'off',
                        name: 'lastName',
                        value: stateClone.lastName || '',
                        type: 'text',
                        disabled: !!person.lastName,
                        endAdornment:
                          stateClone.lastnameState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <CustomInput
                      labelText="Segundo apellido"
                      formControlProps={{ fullWidth: true }}
                      id="secondLastName"
                      inputProps={{
                        onChange: event => this.handleChange(event),
                        autoComplete: 'off',
                        name: 'secondLastName',
                        value: stateClone.secondLastName || '',
                        type: 'text',
                        disabled: !!person.secondLastName,
                        endAdornment:
                          stateClone.secondLastNameState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <FormControl
                      disabled={!!person.documentType}
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel htmlFor="documentType" error={!stateClone.documentType}>
                        Tipo de documento*
                      </InputLabel>
                      <Select
                        value={stateClone.documentType || ''}
                        onChange={event => this.handleChange(event, 'length', 0)}
                        inputProps={{
                          name: 'documentType',
                          id: 'documentType'
                        }}
                      >
                        {typeDocuments.map((typeDocument, key) => (
                          <MenuItem key={key} value={typeDocument.value}>
                            {typeDocument.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <CustomInput
                      labelText="Número de documento*"
                      formControlProps={{ fullWidth: true }}
                      error={!stateClone.document}
                      id="document"
                      inputProps={{
                        onChange: event => this.handleChange(event, 'length', 1),
                        autoComplete: 'off',
                        name: 'document',
                        value: stateClone.document || '',
                        type: 'text',
                        disabled: !!person.document,
                        endAdornment:
                          stateClone.documentState === 'error' ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          )
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <FormControl disabled={!!person.sex} fullWidth className={classes.selectFormControl}>
                      <InputLabel htmlFor="sex" error={!stateClone.sex}>
                        Sexo*
                      </InputLabel>
                      <Select
                        value={stateClone.sex || ''}
                        onChange={event => this.handleChange(event, 'length', 0)}
                        inputProps={{
                          name: 'sex',
                          id: 'sex'
                        }}
                      >
                        {typeGenders.map((typeGender, key) => (
                          <MenuItem key={key} value={typeGender.value}>
                            {typeGender.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                      error={!stateClone.birthdate}
                    >
                      Fecha de nacimiento *
                    </InputLabel>
                    <FormControl required className={classes.selectFormControl} fullWidth>
                      <Datetime
                        viewMode="years"
                        dateFormat="YYYY-MM-DD"
                        onChange={this.changebirthday}
                        value={stateClone.birthdate ? format(stateClone.birthdate, 'DD-MM-YYYY') : ''}
                        isValidDate={this.valid}
                        closeOnSelect
                        inputProps={{
                          placeholder: 'Elija hora y fecha de nacimiento',
                          disabled: !!person.birthdate,
                          required: true,
                          readOnly: true
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  {stateClone.addressLetters && !person.address && (
                    <Address
                      required
                      state={stateClone}
                      handleChange={this.handleChange}
                      handleChangeNumber={this.handleChangeNumber}
                    />
                  )}

                  {person.address && (
                    <GridItem xs={12} sm={6} md={4} lg={3}>
                      <CustomInput
                        labelText="Dirección"
                        formControlProps={{ fullWidth: true }}
                        id="address"
                        inputProps={{
                          onChange: event => this.handleChange(event),
                          autoComplete: 'off',
                          disabled,
                          multiline: true,
                          rowsMax: 4,
                          name: 'address',
                          value: person.address || '',
                          type: 'number'
                        }}
                      />
                    </GridItem>
                  )}

                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <NxSelectAsync
                      name="department"
                      placeholder="Busca el departamento"
                      handleLoadOptions={e => getOptions(e, 'department')}
                      handleChange={this.handleDepartmentChange}
                      value={stateClone.department}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <NxSelectAsync
                      name="municipality"
                      isDisabled={!stateClone.department}
                      placeholder="Busca el municipio"
                      handleLoadOptions={e => getOptions(e, 'municipality', stateClone.department.value)}
                      handleChange={this.handleMunicipalityChange}
                      value={stateClone.municipality}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <NxSelectAsync
                      name="neighborhood"
                      isDisabled={!stateClone.municipality}
                      placeholder="Busca el barrio"
                      handleLoadOptions={e => getOptions(e, 'neighborhood', stateClone.municipality.value)}
                      handleChange={this.handleNeighborhoodChange}
                      value={stateClone.neighborhood}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={4} lg={3}>
                    <NumberInput
                      required
                      disabled={!!person.phone}
                      handleChange={this.handleChangeNumber}
                      name="phone"
                      value={stateClone.phone}
                      placeHolder="Teléfono o celular*"
                      validation={{
                        type: 'otherPhone'
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6}>
                    <FormControl required fullWidth className={classes.selectFormControl}>
                      <InputLabel htmlFor="eps" error={!stateClone.eps}>
                        Entidad aseguradora
                      </InputLabel>
                      <Select
                        fullWidth
                        value={stateClone.eps || ''}
                        onChange={event => this.epsHandleChange(event)}
                        inputProps={{
                          name: 'eps',
                          id: 'eps'
                        }}
                      >
                        {stateClone.epsList &&
                          stateClone.epsList.map(eps => (
                            <MenuItem key={eps.id} value={eps.id}>
                              {eps.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={6}>
                    <FormControl
                      required
                      fullWidth
                      className={classes.selectFormControl}
                      disabled={!stateClone.eps}
                    >
                      <InputLabel htmlFor="regimeType" error={!stateClone.regimeType}>
                        Régimen de seguridad social
                      </InputLabel>
                      <Select
                        fullWidth
                        value={stateClone.regimeType || ''}
                        onChange={event => this.handleInsuranceRegimeChange(event)}
                        inputProps={{
                          name: 'regimeType',
                          id: 'regimeType'
                        }}
                      >
                        {stateClone.regimeTypeList &&
                          stateClone.regimeTypeList.map(regime => (
                            <MenuItem key={regime.id} value={regime.id}>
                              {regime.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12}>
                    <CustomInput
                      labelText="Cuidados según diagnóstico*"
                      error={!stateClone.careForDiagnosis}
                      formControlProps={{ fullWidth: true }}
                      id="careForDiagnosis"
                      inputProps={{
                        onChange: event => this.observationsHandleChange(event),
                        multiline: true,
                        rowsMax: 4,
                        autoComplete: 'off',
                        name: 'careForDiagnosis',
                        value: stateClone.careForDiagnosis || '',
                        type: 'text'
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <NxSelectAsync
                      name="select-cie10"
                      placeholder="Busca los códigos CIE-10 y selecciónalos en orden de importancia"
                      handleLoadOptions={e => getOptions(e, 'cie-10')}
                      handleChange={e => this.handleChangeAsync(e, 'length', 1)}
                      isMulti
                      value={stateClone['cie-10']}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText="Observaciones generales"
                      formControlProps={{ fullWidth: true }}
                      id="observations"
                      inputProps={{
                        onChange: event => this.observationsHandleChange(event),
                        autoComplete: 'off',
                        multiline: true,
                        rowsMax: 4,
                        name: 'observations',
                        value: stateClone.observations || '',
                        type: 'text'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} style={{ textAlign: 'end' }}>
                    <p
                      style={{
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        color: '#dc3545'
                      }}
                    >
                      Los campos marcados con * son obligatorios
                    </p>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Adjuntar Anexo 9</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center'
                  }}
                >
                  <ImageUpload
                    styles={{ flexBasis: '100px' }}
                    title="Adjuntar Anexo 9"
                    onChangeFile={this.onChangeFile1}
                    addButtonProps={{ color: 'info' }}
                    changeButtonProps={{ color: 'info' }}
                    removeButtonProps={{ color: 'danger' }}
                  />
                </GridItem>
                <GridItem xs={12} style={{ textAlign: 'end' }}>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      color: '#dc3545'
                    }}
                  >
                    El anexo 9 debe estar en formato PDF
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem container justify="center">
          <div>
            <Button color="info" onClick={this.requestAmbulance} disabled={disabled}
            >
              Solicitar Ambulancia
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

AmbulanceRequestForm.propTypes = {
  alert: PropTypes.object,
  classes: PropTypes.object,
  person: PropTypes.object.isRequired,
  transferData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired
};

AmbulanceRequestForm.defaultProps = {
  classes: {}
};

const TransferRequestComponent = withAlert()(withStyles(validationFormsStyle)(AmbulanceRequestForm));

export default props => (
  <Subscribe to={[AuthContainer, TransferContainer]}>
    {(authData, transferData) => (
      <TransferRequestComponent {...props} transferData={transferData} roles={authData.state.roles} />
    )}
  </Subscribe>
);
