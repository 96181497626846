import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/messaging';

let messagingFb;

function getMessagingFirebase() {
  if (!messagingFb && firebase.messaging.isSupported()) {
    messagingFb = firebase.app('firebasePush').messaging();
    messagingFb.usePublicVapidKey(
      'BNeUn4WnucYFi38Pg_0qmP9Hnr6q944yEJmL4ftD5a6R_pHrzFmtrXfTtP_ivbbcak_6Csdbujww1HH3p-ZTgck'
    );
  }
  return messagingFb;
}

const devConfigAmbMed = {
  apiKey: "AIzaSyB0RfFHQLevof0--V-uX5ENgQS215zvrsQ",
  authDomain: "amb-med.firebaseapp.com",
  databaseURL: "https://amb-med.firebaseio.com",
  projectId: "amb-med",
  storageBucket: "amb-med.appspot.com",
  messagingSenderId: "675765182932",
  appId: "1:675765182932:web:f4b3562568aa5750"
};

/* const devConfigAmbMed = {
  apiKey: 'AIzaSyB4t0J8ruvasi4Q6y_kxV3kTUWo_Zd62Qk',
  authDomain: 'amb-med.firebaseapp.com',
  databaseURL: 'https://amb-med.firebaseio.com',
  projectId: 'amb-med',
  storageBucket: 'amb-med.appspot.com',
  messagingSenderId: '675765182932',
  appId: '1:675765182932:web:f4b3562568aa5750'
}; */

const configFCM = {
  apiKey: 'AIzaSyA96NmJBYj8iBk0VVOdO8qXT5NPcmBoyQA',
  // apiKey: 'AIzaSyDekzZ9YxRTwU5xYgZCXL2kTDpWIIQfstc', // clave vieja
  // authDomain: "push-notification-232118.firebaseapp.com",
  // databaseURL: "https://push-notification-232118.firebaseio.com",
  projectId: 'sem-core',
  // storageBucket: "push-notification-232118.appspot.com",
  messagingSenderId: '751696161757'
};

if (!firebase.apps.length) {
  firebase.initializeApp(devConfigAmbMed, 'ambMed');
  firebase.initializeApp(configFCM, 'firebasePush');
}

export const firebaseDb = firebase.app('ambMed').database();
export const messaging = getMessagingFirebase();
