import React from 'react';
import PropTypes from 'prop-types';

// Others
import { intensivistFields } from 'variables/resources';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import NumberInput from '../../NumberInput/NumberInput';

const Intensivist = props => {
  const { state, handleChange } = props;
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="warning" text>
            <CardText color="warning">
              <h4>Intensivistas</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {intensivistFields.map(resource => {
                return (
                  <GridItem style={{ display: 'flex' }} key={resource.id} xs={12} sm={6} lg={4}>
                    <NumberInput
                      key={resource.id}
                      required
                      placeHolder={resource.qtyLabelOccupation}
                      handleChange={handleChange}
                      name={resource.qtyName}
                      value={state[resource.qtyName]}
                      validation={{
                        type: 'min-value',
                        number: 0
                      }}
                    />
                  </GridItem>
                );
              })}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

Intensivist.propTypes = {
  state: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default Intensivist;
