import React from 'react';
import PropTypes from 'prop-types';
import apiClient from 'config/apiClient';
import { buildShortAvailabitity } from 'utils/resources';
import { urlApi } from '../config/app';

const defaultState = { elements: [] };
const WrapperFloatContext = React.createContext(defaultState);

class WrapperFloatProvider extends React.Component {
  constructor(props) {
    super(props);
    const { roles } = this.props;
    this.state = defaultState;
    if (roles.includes('CRUE')) {
      this.getAllIpsResources();
    }
  }

  getAllIpsResources = () => {
    apiClient.SEM.get(`${urlApi}/ips/resources/summary/all`)
      .then(({ data }) => {
        this.setState({ ipsResources: data.data });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  addWrapperFloat = element => {
    if (element.type === 'ips') {
      const { ipsResources } = this.state;
      const apiResource = ipsResources.find(ipsResource => ipsResource.hospitalaryCapacity.ipsId === element.id);
      const resource = apiResource ? buildShortAvailabitity(apiResource) : {};
      const newElement = {
        info: element,
        resources: resource,
        name: element.name,
        type: element.type
      };
      this.setState(state => ({
        elements: state.elements.concat(newElement)
      }));
    } else if (element.type === 'commune') {
      delete element.coordinates;
      this.setState(state => ({
        elements: state.elements.concat(element)
      }));
    } else if (element.type === 'ambulance') {
      this.setState(state => ({
        elements: state.elements.concat(element)
      }));
    } else if (element.type === 'reqAmb') {
      this.setState(state => ({
        elements: state.elements.concat(element)
      }));
    }
  };

  onDeleteModal = id => {
    this.setState(state => ({
      elements: state.elements.filter(element => element.id !== id)
    }));
  };

  render() {
    const { children } = this.props;
    return (
      <WrapperFloatContext.Provider
        value={{
          ...this.state,
          onDeleteModal: this.onDeleteModal,
          addWrapperFloat: this.addWrapperFloat
        }}
      >
        {children}
      </WrapperFloatContext.Provider>
    );
  }
}

WrapperFloatProvider.propTypes = {
  children: PropTypes.element,
  roles: PropTypes.array
};

WrapperFloatProvider.defaultProps = {
  roles: []
};

const WrapperFloatConsumer = WrapperFloatContext.Consumer;

export { WrapperFloatProvider, WrapperFloatConsumer };
