export const columns = [
  {
    name: 'name',
    title: 'Area Metropolitana',
    getCellValue: row => {
      if (!row) return undefined;
      return `${row.name || '--'}`;
    }
  },
  {
    name: 'total',
    title: 'Totales',
    getCellValue: row => {
      if (!row) return undefined;
      return row.total || 0;
    }
  },
  {
    name: 'covid',
    title: 'COVID-19',
    getCellValue: row => {
      if (!row) return undefined;
      return {
        total: row.covid || 0,
        manWithCovid: row.manWithCovid || 0,
        womanWithCovid: row.womanWithCovid || 0
      };
    }
  },
  {
    name: 'ira',
    title: 'IRA',
    getCellValue: row => {
      if (!row) return undefined;
      return {
        total: row.ira || 0,
        manWithIra: row.manWithIra || 0,
        womanWithIra: row.womanWithIra || 0
      };
    }
  },
  {
    name: 'availables',
    title: 'Disponibles',
    getCellValue: row => {
      if (!row) return undefined;
      return row.availables || 0;
    }
  },
  {
    name: 'publics',
    title: 'Públicas',
    getCellValue: row => {
      if (!row) return undefined;
      return row.publics || 0;
    }
  },
  {
    name: 'privates',
    title: 'Privadas',
    getCellValue: row => {
      if (!row) return undefined;
      return row.privates || 0;
    }
  }
];

export const columnExtensions = [
  {
    columnName: 'name',
    width: 350,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'total',
    width: 150,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'covid',
    width: 300,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'ira',
    width: 300,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'availables',
    width: 200,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'publics',
    width: 200,
    align: 'center',
    wordWrapEnabled: true
  },
  {
    columnName: 'privates',
    width: 200,
    align: 'center',
    wordWrapEnabled: true
  }
];

export const columnBands = [
  {
    title: 'Urgencias',
    children: [{ columnName: 'emergencyTotal' }, { columnName: 'emergencyCovid' }, { columnName: 'emergencyAvailable' }]
  },
  {
    title: 'Hospitalización',
    children: [{ columnName: 'hospTotal' }, { columnName: 'hospCovid' }, { columnName: 'hospAvailable' }]
  },
  {
    title: 'UCI',
    children: [{ columnName: 'uciTotal' }, { columnName: 'uciCovid' }, { columnName: 'uciAvailable' }]
  },
  {
    title: 'UCE',
    children: [{ columnName: 'uceTotal' }, { columnName: 'uceCovid' }, { columnName: 'uceAvailable' }]
  }
];

export const data = [];
export default {
  columns,
  columnExtensions,
  columnBands,
  data
};
