import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ManIconWhiteBackgroun from 'Icons/ManIconWhiteBackgroun';
import WomanIconWhiteBackground from 'Icons/WomanIconWhiteBackground';

const dataSelect = (value, type, sexType) => {
  let renderValue = '';
  if (type === 'covid') {
    renderValue = sexType === 'male' ? value.manWithCovid : value.womanWithCovid;
    return renderValue === 'NA' ? '--' : renderValue;
  }
  renderValue = sexType === 'male' ? value.manWithIra : value.womanWithIra;
  return renderValue === 'NA' ? '--' : renderValue;
};

const CovidCellSex = props => {
  const { value, classes, type } = props;
  return (
    <GridContainer>
      <GridItem xs={6}>
        <Chip
          key="maleNumber"
          icon={<ManIconWhiteBackgroun className={classes.icon} />}
          label={dataSelect(value, type, 'male') || 0}
          className={classes.totalChip}
        />
      </GridItem>
      <GridItem xs={6}>
        <Chip
          key="femaleNumber"
          icon={<WomanIconWhiteBackground className={classes.icon} />}
          label={dataSelect(value, type, 'female') || 0}
          className={classes.totalChip}
        />
      </GridItem>
    </GridContainer>
  );
};

CovidCellSex.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default CovidCellSex;
