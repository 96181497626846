// @flow

import React, { Fragment } from 'react';
// import Datetime from 'react-datetime';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import apiClient from 'config/apiClient';
import { urlApi } from 'config/app';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/core/Icon';

// Styles
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import NxSpinner from '../NxSpinner/NxSpinner';
import BloodBankList from '../BloodBank/BloodBankList';

import { buildResourceDetails } from '../../utils/resources';
import {
  diagnosticAids,
  humanResourcesFields,
  urgencyFields,
  internmentFields,
  nonSurgicalSupportFields,
  supportServiceFields,
  translateService,
  reasonsTranslate
} from '../../variables/resources';

const styles = theme => ({
  ...validationFormsStyle,
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  icons: {
    verticalAlign: 'middle',
    fontSize: '30px',
    color: 'green'
  },
  successIcon: {
    verticalAlign: 'middle',
    fontSize: '30px',
    color: 'green'
  },
  errorIcon: {
    verticalAlign: 'middle',
    fontSize: '30px',
    color: '#f44336'
  },
  warningIcon: {
    verticalAlign: 'middle',
    fontSize: '30px',
    color: '#ffa726'
  },
  gridDl_dt: {
    borderTop: '2px solid #ccc',
    padding: '0.5em 0 0.5em 0',
    fontWeight: 600
  },

  gridDl_dd: {
    margin: 0,
    padding: '0.5em 0 0.5em 0'
  },
  gridDl_dt_dd: {
    margin: 0,
    padding: '0.5em 0 0.5em 0'
  },

  '@media (min-width: 550px)': {
    gridDl: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      gridTemplateRows: 'auto'
    },
    gridDl_dt: {
      gridColumnStart: 1
    },
    gridDl_dd: {
      gridColumnStart: 2,
      margin: 0
    },
    gridDl_dt_dd: {
      gridColumnStart: 2,
      margin: 0,
      borderTop: '2px solid #ccc'
    }
  },
  ...buttonStyle
});

const otherOccupationUrgencyFields = [
  { id: 1, name: 'bedsOutOfServiceUrg', label: 'Camillas fuera de servicio', msg: 'camilla(s)' },
  {
    id: 2,
    name: 'patientsPendingHospitalization',
    label: 'Pacientes con orden de hospitalización',
    msg: ' paciente(s)'
  },
  {
    id: 5,
    name: 'maxTimeToBeAttended',
    label: 'Tiempo máximo en sala de espera antes de pasar a urgencias',
    msg: 'horas'
  },
  {
    id: 6,
    name: 'maxTimeAtEmergencyToHospitalization',
    label: 'Tiempo del paciente más antiguo en urgencias',
    msg: 'horas'
  }
];

export class ResourceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      epsList: []
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.getAllEps();
    apiClient.SEM.get(`${urlApi}/ips/${match.params.ipsId}/resources`)
      .then(response => {
        try {
          const resources = buildResourceDetails(response.data.data);
          this.setState({ resources });
        } catch (error) {
          console.error('Error al cargar los recursos: ', error);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getAllEps = () => {
    apiClient.SEM.get(`${urlApi}/eps`)
      .then(({ data }) => {
        this.setState({
          epsList: data.data.rows
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  renderIcon = hasResource => {
    const { classes = {} } = this.props;
    let iconStyle;
    let icon;
    let message;
    switch (hasResource) {
      case undefined:
        icon = 'cancel';
        iconStyle = 'errorIcon';
        message = 'No ofertado';
        break;
      case true:
        icon = 'check_circle';
        iconStyle = 'successIcon';
        message = 'Disponible';
        break;
      default:
        icon = 'remove_circle';
        iconStyle = 'warningIcon';
        message = 'No disponible';
        break;
    }
    return (
      <Fragment>
        <Icon className={classes[iconStyle]}>{icon}</Icon>
        {message}
      </Fragment>
    );
  };

  render() {
    const { classes = {} } = this.props;
    const { loading, resources = {}, epsList } = this.state;
    const {
      humanResource,
      internment,
      nonSurgicalSupport,
      supportServices,
      urgency = {},
      bloodResources,
      bedsOutOfService
    } = resources;
    const { patientsReferredToOtherHospital, patientsWaitingReferralTransport } = urgency;

    return (
      <div className={classes.root}>
        <NxSpinner loading={loading} />
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Información básica</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <dl className={classes.gridDl}>
                  <dt className={classes.gridDl_dt}>IPS:</dt>
                  <dd className={classes.gridDl_dt_dd}>{resources.ipsName}</dd>

                  <dt className={classes.gridDl_dt}>Usuario que reporta recursos:</dt>
                  <dd className={classes.gridDl_dt_dd}>{resources.createdBy}</dd>

                  <dt className={classes.gridDl_dt}>Fecha y hora que se realiza el reporte de recursos:</dt>
                  <dd className={classes.gridDl_dt_dd}>{format(new Date(resources.createdAt), 'DD-MM-YYYY')}</dd>
                  <dd className={classes.gridDl_dt_dd}>{format(new Date(resources.createdAt), 'HH:mm a')}</dd>
                  {(supportServices || {}).bloodBank !== undefined && (
                    <React.Fragment>
                      <dt className={classes.gridDl_dt}>Usuario que reporta banco de sangre:</dt>
                      <dd className={classes.gridDl_dt_dd}>{(bloodResources || {}).createdBy || 'Sin información'}</dd>

                      <dt className={classes.gridDl_dt}>Fecha y hora que se realiza el reporte de banco de sangre:</dt>
                      <dd className={classes.gridDl_dt_dd}>
                        {(bloodResources || {}).createdAt
                          ? format(new Date(bloodResources.createdAt), 'DD-MM-YYYY')
                          : 'Sin Información'}
                      </dd>
                      <dd className={classes.gridDl_dt_dd}>
                        {(bloodResources || {}).createdAt
                          ? format(new Date(bloodResources.createdAt), 'HH:mm a')
                          : 'Sin Información'}
                      </dd>
                    </React.Fragment>
                  )}
                </dl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Recurso humano</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                {humanResource &&
                  humanResourcesFields.map(field => (
                    <dl key={field.id} className={classes.gridDl}>
                      <dt className={classes.gridDl_dt}>{field.label}:</dt>
                      <dd className={classes.gridDl_dt_dd}>
                        {this.renderIcon(humanResource[field.name])}
                        {!humanResource[field.name] &&
                          humanResource[field.reasonName] &&
                          ` - Razón: ${humanResource[field.reasonName]}`}
                      </dd>
                    </dl>
                  ))}
              </CardBody>
            </Card>
          </GridItem>
          {internment && (
            <GridItem xs={12} sm={6}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Internación</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  {internment &&
                    internmentFields.map(field => {
                      if (internment[field.qtyName] || internment[field.qtyName] === 0) {
                        return (
                          <dl key={field.id} className={classes.gridDl}>
                            <dt className={classes.gridDl_dt}>{field.qtyLabel}:</dt>
                            <dd className={classes.gridDl_dt_dd}>{internment[field.qtyName]} unidades ocupadas.</dd>
                            <dd className={classes.gridDl_dt_dd}>
                              Ocupación: {internment[`${field.qtyPercentageName}`]}%
                            </dd>
                          </dl>
                        );
                      }
                      return (
                        <dl key={field.id} className={classes.gridDl}>
                          <dt className={classes.gridDl_dt}>{field.qtyLabel}:</dt>
                          <dd className={classes.gridDl_dt_dd}>No se cuenta con este servicio</dd>
                        </dl>
                      );
                    })}

                  <dl className={classes.gridDl}>
                    <dt className={classes.gridDl_dt}>Camas fuera de servicio:</dt>
                    <dd className={classes.gridDl_dt_dd}>{internment.bedsOutOfServiceInt} camillas</dd>
                  </dl>
                </CardBody>
              </Card>
            </GridItem>
          )}
          {Object.keys(urgency).length > 0 && (
            <GridItem xs={12} sm={6}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Urgencias</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  {urgencyFields.map(field => {
                    if (urgency[field.qtyName] || urgency[field.qtyName] === 0) {
                      return (
                        <dl key={field.id} className={classes.gridDl}>
                          <dt className={classes.gridDl_dt}>{field.qtyLabel}:</dt>
                          <dd className={classes.gridDl_dt_dd}>{urgency[field.qtyName]} unidades ocupadas.</dd>
                          <dd className={classes.gridDl_dt_dd}>Ocupación: {urgency[`${field.qtyPercentageName}`]}%</dd>
                        </dl>
                      );
                    }
                    return (
                      <dl key={field.id} className={classes.gridDl}>
                        <dt className={classes.gridDl_dt}>{field.qtyLabel}:</dt>
                        <dd className={classes.gridDl_dt_dd}>No se cuenta con este servicio</dd>
                      </dl>
                    );
                  })}
                </CardBody>
              </Card>
            </GridItem>
          )}
          {Object.keys(urgency).length > 0 && (
            <GridItem xs={12} sm={6}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Información adicional Urgencias</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  {patientsReferredToOtherHospital && (
                    <dl className={classes.gridDl}>
                      <dt className={classes.gridDl_dt}>Pacientes con orden de remisión:</dt>
                      {patientsReferredToOtherHospital.qty && epsList.length > 0 ? (
                        patientsReferredToOtherHospital.byEps.map(patientByEps => {
                          const entity = epsList.find(eps => eps.id === patientByEps.id);
                          return (
                            <React.Fragment>
                              <dd className={classes.gridDl_dt_dd}>{`${patientByEps.qty} pacientes de ${
                                (entity || {}).name
                              }`}</dd>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <React.Fragment>
                          <dd className={classes.gridDl_dt_dd}>No tiene pacientes con orden de remisión</dd>
                        </React.Fragment>
                      )}
                    </dl>
                  )}
                  {patientsWaitingReferralTransport && (
                    <dl className={classes.gridDl}>
                      <dt className={classes.gridDl_dt}>Pacientes esperando traslado:</dt>
                      {patientsWaitingReferralTransport.qty ? (
                        patientsWaitingReferralTransport.byEps.map(patientByEps => {
                          const entity = epsList.find(eps => eps.id === patientByEps.id);
                          return (
                            <React.Fragment>
                              <dd className={classes.gridDl_dt_dd}>{`${patientByEps.qty} pacientes de ${
                                (entity || {}).name
                              }`}</dd>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <React.Fragment>
                          <dd className={classes.gridDl_dt_dd}>No tiene pacientes esperando traslado</dd>
                        </React.Fragment>
                      )}
                    </dl>
                  )}
                  {otherOccupationUrgencyFields.map(field => {
                    if (urgency[field.name]) {
                      if (['maxTimeToBeAttended', 'maxTimeAtEmergencyToHospitalization'].includes(field.name)) {
                        return (
                          <dl key={field.id} className={classes.gridDl}>
                            <dt className={classes.gridDl_dt}>{field.label}:</dt>
                            <dd className={classes.gridDl_dt_dd}>
                              {urgency[field.name] > 24
                                ? `${urgency[field.name]} ${field.msg}(${(urgency[field.name] / 24).toFixed(2)} días)`
                                : `${urgency[field.name]} ${field.msg}`}
                              .
                            </dd>
                          </dl>
                        );
                      }
                      return (
                        <dl key={field.id} className={classes.gridDl}>
                          <dt className={classes.gridDl_dt}>{field.label}:</dt>
                          <dd className={classes.gridDl_dt_dd}>{`${urgency[field.name]} ${field.msg}`}.</dd>
                        </dl>
                      );
                    }
                    return null;
                  })}
                </CardBody>
              </Card>
            </GridItem>
          )}
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Especialidades no quirúrgicas</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                {nonSurgicalSupport &&
                  nonSurgicalSupportFields.map(field => (
                    <dl key={field.id} className={classes.gridDl}>
                      <dt className={classes.gridDl_dt}>{field.label}:</dt>
                      <dd className={classes.gridDl_dt_dd}>
                        {this.renderIcon(nonSurgicalSupport[field.name])}
                        {!nonSurgicalSupport[field.name] &&
                          nonSurgicalSupport[field.reasonName] &&
                          ` - Razón: ${nonSurgicalSupport[field.reasonName]}`}
                      </dd>
                    </dl>
                  ))}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Ayudas diagnósticas</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                {supportServices &&
                  diagnosticAids.map(field => (
                    <dl key={field.id} className={classes.gridDl}>
                      <dt className={classes.gridDl_dt}>{field.checkLabel}:</dt>
                      <dd className={classes.gridDl_dt_dd}>
                        {this.renderIcon(supportServices[field.checkName])}
                        {!supportServices[field.checkName] &&
                          supportServices[field.reasonName] &&
                          ` - Razón: ${supportServices[field.reasonName]}`}
                      </dd>
                    </dl>
                  ))}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Servicios de apoyo</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                {supportServices &&
                  supportServiceFields.map(field => (
                    <dl key={field.id} className={classes.gridDl}>
                      <dt className={classes.gridDl_dt}>{field.checkLabel}:</dt>
                      <dd className={classes.gridDl_dt_dd}>
                        {this.renderIcon(supportServices[field.checkName])}
                        {!supportServices[field.checkName] &&
                          supportServices[field.reasonName] &&
                          ` - Razón: ${supportServices[field.reasonName]}`}
                      </dd>
                    </dl>
                  ))}
              </CardBody>
            </Card>
          </GridItem>
          {(supportServices || {}).bloodBank !== undefined && bloodResources && (
            <GridItem xs={12} sm={12}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Banco de sangre</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <BloodBankList bloodResources={bloodResources} />
                </CardBody>
              </Card>
            </GridItem>
          )}
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Fuera de servicio</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                {bedsOutOfService ? (
                  Object.keys(bedsOutOfService).map(serviceKey => (
                    <dl key={serviceKey} className={classes.gridDl}>
                      <dt className={classes.gridDl_dt}>{translateService(serviceKey)}:</dt>
                      {bedsOutOfService[serviceKey].map(service => (
                        <dd className={classes.gridDl_dt_dd}>
                          {`${service.quantity} unidades por ${reasonsTranslate[service.reason].toLowerCase()}`}
                        </dd>
                      ))}
                    </dl>
                  ))
                ) : (
                  <h5>No hay camas fuera de servicio</h5>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ResourceDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withAlert()(withStyles(styles)(ResourceDetails));
