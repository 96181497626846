import React, { Component } from 'react';
import IpsResources from 'nxComponents/Resources/IpsResources';
import { WrapperFloatConsumer } from 'context-api/WrapperFloatContext';
import PropTypes from 'prop-types';
import WrapperFloat from './WrapperFloat';
import CommuneInfo from '../Commune/CommuneInfo';
import AmbulanceInfo from '../Ambulances/AmbulancesInfo';
import AmbulanceRequest from '../Ambulances/AmbulanceRequest';

class ListWrapperFloat extends Component {
  render() {
    const { elements, onDeleteModal } = this.props;
    return elements.map(item => (
      <WrapperFloat key={item.id} onDeleteModal={() => onDeleteModal(item.id)} title={item.name}>
        {item.type === 'ips' && <IpsResources {...item} />}
        {item.type === 'commune' && <CommuneInfo commune={item} />}
        {item.type === 'ambulance' && <AmbulanceInfo ambulance={item} onDeleteModal={() => onDeleteModal(item.id)} />}
        {item.type === 'reqAmb' && <AmbulanceRequest ambulance={item} onDeleteModal={() => onDeleteModal(item.id)} />}
      </WrapperFloat>
    ));
  }
}
ListWrapperFloat.propTypes = {
  elements: PropTypes.array,
  onDeleteModal: PropTypes.func,
  onDisabledWrapper: PropTypes.func,
  disabledWrapper: PropTypes.bool
};

export default props => (
  <WrapperFloatConsumer>
    {({ elements, onDeleteModal }) => <ListWrapperFloat {...props} elements={elements} onDeleteModal={onDeleteModal} />}
  </WrapperFloatConsumer>
);
