export const eventType2ES = eventType => {
  switch (eventType) {
    case 'TRANSFER':
      return 'en un traslado';
    case 'MATCH':
      return 'en un partido';
    case 'CONCERT':
      return 'en concierto';
    case 'QUEUE':
      return 'en cola';
    case 'OTHER':
      return 'en otro evento';
    default:
      return 'disponible';
  }
};

export const normalizeNumber = number => {
  if (number.includes('+', 0) && number.length > 10) {
    const leftoverNumbers = number.length - 10;
    const numb = number.split('');
    const tel = numb.splice(leftoverNumbers);
    return tel.join('');
  }
  return number;
};
