import { Container } from 'unstated';
import format from 'date-fns/format';
import apiClient from 'config/apiClient';
import { urlApi } from 'config/app';

function validateDatetime(selectedColumn, referralData) {
  if (
    selectedColumn === 'createdAt' &&
    (referralData.arrivalTime !== undefined || referralData.availableTime !== undefined)
  ) {
    return 'No es posible agregar una hora de inicio de traslado porque ya existe una fecha de llegada y/o de finalización del traslado';
  }
  if (selectedColumn === 'arrivalTime' && referralData.createdAt === undefined) {
    return 'Debe ingresar primero la hora de inicio de traslado';
  }
  if (
    selectedColumn === 'availableTime' &&
    (referralData.arrivalTime === undefined || referralData.createdAt === undefined)
  ) {
    return 'Debe ingresar primero la hora de inicio y llegada del traslado';
  }
  return false;
}

class TransferContainer extends Container {
  constructor(props) {
    super(props);
    this.state = {
      transfers: [],
      currentTransfer: null,
      ipsAgreements: []
    };
  }

  getTransfers = async querystring => {
    try {
      const response = await apiClient.SEM.get(`${urlApi}/referral${querystring}`);
      this.setState({
        transfers: response.data.data.rows
      });
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  getTransferId = async transferId => {
    try {
      const response = await apiClient.SEM.get(`${urlApi}/referral/${transferId}`);
      this.setState({
        currentTransfer: response.data.data
      });
      // this.getIPSbyNetworkId(response.data.data.networkId);
      return response;
    } catch (error) {
      this.setState({
        currentTransfer: null
      });
      throw error;
    }
  };

  saveAsignedIPS = async (IPSid, acceptanceCode) => {
    const { currentTransfer } = this.state;
    try {
      const response = await apiClient.SEM.patch(`${urlApi}/referral/${currentTransfer.id}/ipsrecv/${IPSid}`, {
        acceptanceCode
      });

      this.setState({
        currentTransfer: response.data.data
      });
      return `Se ha asignado la IPS correctamente`;
    } catch (error) {
      throw error;
    }
  };

  getIPSbyNetworkId = async transferId => {
    try {
      const response = await apiClient.SEM.get(`${urlApi}/ips/referral/${transferId}`);
      this.setState({
        ipsAgreements: response.data.data
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  setCurrentTransfer = value => {
    this.setState({
      currentTransfer: value
    });
  };

  putHour(name, referralData, alert) {
    if (referralData[name] === undefined) {
      // si no se ha agregado hora todavia se permite actualizar
      const error = validateDatetime(name, referralData);
      if (!error) {
        const updateSelectedRow = {
          ...referralData,
          [name]: format(new Date(), 'MM/dd/yyyy') // ToDo: esto debe generarse es el servidor
        };
        // updateHour contiene la data para enviar al Socket.io
        // let updatedHour = {
        //   id: referralData.id,
        //   [name]: format(new Date(), 'MM/dd/yyyy')
        // };
        const { transfers } = this.state;
        const newRows = transfers.map(row => {
          if (row.id === updateSelectedRow.id) {
            return updateSelectedRow;
          }
          return row;
        });
        this.setState({ transfers: newRows });
      } else {
        alert.show(error, {
          type: 'error',
          timeout: 5000
        });
      }
    }
  }

  uploadDiagnosesFile = async file => {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const { currentTransfer } = this.state;
    const formData = new FormData();
    formData.append('diagnosis', file);
    try {
      const response = await apiClient.SEM.patch(
        `${urlApi}/referral/${currentTransfer.id}/upload/diagnosis`,
        formData,
        options
      );
      this.setState({
        currentTransfer: {
          ...currentTransfer,
          diagnosesResources: response.data.data.diagnosesResources,
          referralState: response.data.data.referralState
        }
      });
      // this.getIPSbyNetworkId(response.data.data.networkId);
      return `Se ha adjuntado el archivo correctamente`;
    } catch (error) {
      throw error;
    }
  };

  saveTransfer = async formData => {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    try {
      const response = await apiClient.SEM.post(`${urlApi}/referral`, formData, options);
      this.setState({
        currentTransfer: response.data.data,
        referralState: response.data.data.referralState
      });
      // this.getIPSbyNetworkId(response.data.data.networkId);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  requestAmbulance = async formData => {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    try {
      const response = await apiClient.SEM.post(`${urlApi}/referral/goldenCode`, formData, options);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  rejectTrasfer = async (id, data) => {
    try {
      const response = await apiClient.SEM.patch(`${urlApi}/referral/${id}/reject`, data);
      this.setState(prevState => ({
        currentTransfer: {
          ...prevState.currentTransfer,
          referralState: response.data.data.referralState,
          rejectedReason: response.data.data.rejectedReason
        }
      }));
      return response;
    } catch (error) {
      throw error;
    }
  };

  getAnnex9 = async fileName => {
    try {
      const response = await apiClient.SEM.get(`${urlApi}/file?image=${fileName}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  assignAmbulance = async ({ ambulanceId, referralId }) => {
    try {
      const response = await apiClient.SEM.post(`${urlApi}/transport/referral/${referralId}`, { ambulanceId });
      this.setState(({ currentTransfer = {} }) => ({
        currentTransfer: {
          ...currentTransfer,
          referralState: response.data.data.referralState,
          ambAssignedBy: response.data.data.ambAssignedBy,
          ambulance: response.data.data,
          attentionHours: {
            ...currentTransfer.attentionHours,
            ambulanceDispatchDate: response.data.data.updatedAt
          }
        }
      }));
      return response;
    } catch (error) {
      throw error;
    }
  };

  assignPrivateAmbulance = async (referralId, data) => {
    try {
      const response = await apiClient.SEM.patch(`${urlApi}/referral/${referralId}/assign/ambulance`, data);
      this.setState(prevState => ({
        currentTransfer: {
          ...prevState.currentTransfer,
          referralState: response.data.data.referralState
        }
      }));
      return response;
    } catch (error) {
      throw error;
    }
  };

  assignPrivateEntity = async (referralId, data) => {
    try {
      const response = await apiClient.SEM.patch(`${urlApi}/referral/${referralId}/assign/ambulanceEntity`, data);
      this.setState(prevState => ({
        currentTransfer: {
          ...prevState.currentTransfer,
          referralState: response.data.data.referralState,
          ambulancesEntityId: response.data.data.ambulancesEntityId
        }
      }));
      return response;
    } catch (error) {
      throw error;
    }
  };

  cancelAmbulance = async(referralId) => {
    try {
      const response = await apiClient.SEM.patch(`${urlApi}/referral/${referralId}/undoDispatch`, {"undoDispatch": true});
      this.getTransferId(referralId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  addAllPrivateEntities = async referralId => {
    try {
      const response = await apiClient.SEM.patch(`${urlApi}/referral/${referralId}/allowEntities`);
      this.setState(prevState => ({
        currentTransfer: {
          ...prevState.currentTransfer,
          referralState: response.data.data.referralState,
          allowAllAmbulancesEntities: response.data.data.allowAllAmbulancesEntities
        }
      }));
      return response;
    } catch (error) {
      throw error;
    }
  };

  entitieOfferAmbulance = async (referralId, ambId) => {
    const id = { ambulanceId: ambId };
    try {
      const response = await apiClient.SEM.patch(`${urlApi}/referral/${referralId}/offerAmbulance/`, id);
      return response;
    } catch (error) {
      throw error;
    }
  };

  saveHours = hours => {
    const { currentTransfer } = this.state;
    this.setState({
      currentTransfer: {
        ...currentTransfer,
        attentionHours: hours
      }
    });
  };
}
// export default withRouter(TransferContainer);
export default TransferContainer;
