import apiClient from 'config/apiClient';
import { urlApi } from 'config/app';

export const getTrasportStatistics = () => {
  return apiClient.SEM.get(`${urlApi}/referral/statistics/transport`).then(response => response.data.data);
};

export const getRejectedStatistics = () => {
  return apiClient.SEM.get(`${urlApi}/referral/statistics/canceled`).then(response => response.data.data);
};

export const getDurationStatistics = () => {
  return apiClient.SEM.get(`${urlApi}/referral/statistics/duration`).then(response => response.data.data);
};

export const getRecvStatistics = () => {
  return apiClient.SEM.get(`${urlApi}/referral/statistics/ipsRecv`).then(response => response.data.data);
};

export const getReqStatistics = () => {
  return apiClient.SEM.get(`${urlApi}/referral/statistics/ipsReq`).then(response => response.data.data);
};
