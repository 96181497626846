import ListIcon from 'Icons/List';
import LocationIcon from 'Icons/Location';
import DashboardIcon from 'Icons/Dashboard';
import ChartIcon from 'Icons/Chart';
import apiClient from 'config/apiClient';
import { urlApi } from 'config/app';
import BackgroundCovid from 'assets/img/BackgroundCovid.png';

export const styles = theme => ({
  title: {
    color: '#38BCD7',
    margin: '10px 0',
    fontFamily: "'Lato', sans-serif",
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3.2em'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '4.2em'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '6.2em'
    }
  },
  bannerText: {
    color: '#C8C8C8',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2em'
    }
  },
  netuxImg: {
    width: 140,
    [theme.breakpoints.up('xl')]: {
      width: 240
    }
  },
  fab: {
    marginTop: -26,
    marginLeft: 30,
    position: 'absolute',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'black'
    },
    [theme.breakpoints.up('xl')]: {
      height: 80,
      width: 80,
      marginTop: -40
    }
  },
  navigationLabel: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.7em !important'
    }
  },
  secondaryNavigationLabel: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2em !important'
    }
  },
  navigationSelected: {
    color: '#38BCD7',
    fontWeight: 'bold',
    fontSize: '1.1em !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.3em !important'
    }
  },
  secondaryNavigationSelected: {
    color: '#38BCD7',
    fontWeight: 'bold',
    fontSize: '1em !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1em !important'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2em !important'
    }
  },
  navigationRoot: {
    minWidth: 370,
    maxWidth: 500,
    margin: '0 auto',
    borderRadius: '0 0 40px 40px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    [theme.breakpoints.down('sm')]: {
      minWidth: 370,
      maxWidth: 500
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 470,
      maxWidth: 700,
      minHeight: 60
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 670,
      maxWidth: 900,
      minHeight: 80
    }
  },
  navigationSecondary: {
    minWidth: 370,
    maxWidth: 500,
    margin: 'auto auto 1vh auto !important',
    borderRadius: '30px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    [theme.breakpoints.down('sm')]: {
      minWidth: 370,
      maxWidth: 500
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 470,
      maxWidth: 700,
      minHeight: 30
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 670,
      maxWidth: 900,
      minHeight: 44
    }
  },
  navigationActionRoot: {
    maxWidth: 'none'
  },
  subtitle: {
    color: '#38BCD7',
    fontWeight: 'bold',
    margin: '10px 0',
    fontFamily: "'Lato', sans-serif"
  },
  container: {
    fontFamily: "'Lato', sans-serif",
    padding: '10px 30px'
  },
  card: {
    width: '20vw',
    height: '25vh',
    position: 'absolute',
    top: -30,
    borderRadius: '0 0 0 60px',
    right: 0
  },
  cardBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  alcaldiaLogo: {
    maxWidth: '13vw',
    height: 'auto'
  },
  covid: {
    fontFamily: "'Lato', sans-serif",
    width: '100vw',
    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.81)),url(${BackgroundCovid}) no-repeat center top`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5vh'
  },
  footerText: {
    maxWidth: 500,
    margin: '10px auto',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em',
      maxWidth: 800
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2em',
      maxWidth: 1000,
      lineHeight: '35px'
    }
  },
  footerTextBold: {
    fontWeight: 'bold',
    minWidth: 500,
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em',
      maxWidth: 800
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '2em',
      maxWidth: 1000,
      lineHeight: '35px'
    }
  },
  tableMargin: {
    marginBottom: '20px'
  },
  // Dashboard
  dashboardContainer: {
    padding: '7vh 30px 0 30px',
    background: 'white',
    [theme.breakpoints.up('xl')]: {
      paddingTop: '10vh'
    }
  },
  halfPieTitle: {
    textAlign: 'center',
    fontWeight: 500
  },
  halfPieNumber: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    top: '-6vh',
    fontSize: '3.5rem',
    fontWeight: 'bold'
  },
  halfPielabels: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    top: '-2vh',
    fontSize: '1.2rem'
  },
  button: {
    textTransform: 'capitalize',
    color: '#949494',
    fontWeight: '400'
  },
  canvasDoughnut: {
    height: '12vh'
  },
  canvasBar: {
    height: '37vh'
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: 2
  },
  flexC: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  legendLabel: {
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9em'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.4em'
    },
    margin: 0,
    padding: '0 5px 0 0'
  }
});

export const totalInfo = total => {
  return [
    {
      name: 'UCI Adultos',
      total: total.hospitalization.uci.total,
      covid: total.hospitalization.uci.covid,
      ira: total.hospitalization.uci.ira,
      manWithCovid: total.hospitalization.uci.manWithCovid,
      manWithIra: total.hospitalization.uci.manWithIra,
      womanWithCovid: total.hospitalization.uci.womanWithCovid,
      womanWithIra: total.hospitalization.uci.womanWithIra,
      availables: total.hospitalization.uci.availables,
      publics: total.hospitalization.uci.publics,
      privates: total.hospitalization.uci.privates
    },
    {
      name: 'UCE Adultos',
      total: total.hospitalization.uce.total,
      covid: total.hospitalization.uce.covid,
      ira: total.hospitalization.uce.ira,
      manWithCovid: total.hospitalization.uce.manWithCovid,
      manWithIra: total.hospitalization.uce.manWithIra,
      womanWithCovid: total.hospitalization.uce.womanWithCovid,
      womanWithIra: total.hospitalization.uce.womanWithIra,
      availables: total.hospitalization.uce.availables,
      publics: total.hospitalization.uce.publics,
      privates: total.hospitalization.uce.privates
    },
    {
      name: 'Hospitalización',
      total: total.hospitalization.general.total,
      covid: total.hospitalization.general.covid,
      ira: total.hospitalization.general.ira,
      manWithCovid: total.hospitalization.general.manWithCovid,
      manWithIra: total.hospitalization.general.manWithIra,
      womanWithCovid: total.hospitalization.general.womanWithCovid,
      womanWithIra: total.hospitalization.general.womanWithIra,
      availables: total.hospitalization.general.availables,
      publics: total.hospitalization.general.publics,
      privates: total.hospitalization.general.privates
    },
    {
      name: 'Urgencias',
      total: total.emergency.general.total,
      covid: total.emergency.general.covid,
      ira: total.emergency.general.ira,
      manWithCovid: total.emergency.general.manWithCovid,
      manWithIra: total.emergency.general.manWithIra,
      womanWithCovid: total.emergency.general.womanWithCovid,
      womanWithIra: total.emergency.general.womanWithIra,
      availables: total.emergency.general.availables,
      publics: total.emergency.general.publics,
      privates: total.emergency.general.privates
    }
  ];
};

export const ipsTotalInfo = total => {
  const infectedTotalInfo = [];
  total.forEach(ips => {
    const ipsData = {
      name: ips.ips,
      emergency: ips.emergency.general,
      uce: ips.hospitalization.uce,
      uci: ips.hospitalization.uci,
      hospitalization: ips.hospitalization.general,
      total: ips.total
    };
    infectedTotalInfo.push(ipsData);
  });
  return infectedTotalInfo;
};

export const totalByIps = (data = { ips: {} }) => {
  const dataCopy = { ...data };
  if (data.ips) {
    data.ips.map((ips, index) => {
      const { general: emergencyGeneral } = ips.emergency;
      const { general, uce, uci } = ips.hospitalization;
      const covid =
        (emergencyGeneral.covid === 'NA' ? 0 : emergencyGeneral.covid) +
        (general.covid === 'NA' ? 0 : general.covid) +
        (uce.covid === 'NA' ? 0 : uce.covid) +
        (uci.covid === 'NA' ? 0 : uci.covid);
      const ira =
        (emergencyGeneral.ira === 'NA' ? 0 : emergencyGeneral.ira) +
        (general.ira === 'NA' ? 0 : general.ira) +
        (uce.ira === 'NA' ? 0 : uce.ira) +
        (uci.ira === 'NA' ? 0 : uci.ira);
      dataCopy.ips[index].total = {
        covid,
        ira
      };
      return null;
    });
  }
  return dataCopy;
};

export const backgroundColors = {
  'emergency-total': '#E4FF1C',
  'uce-total': '#E8DA1A',
  'uci-total': '#FFDC29',
  'generalHospitalization-total': '#E8B11A',
  'emergency-availables': '#0CF8B0',
  'uce-availables': '#0BD997',
  'uci-availables': '#00F060',
  'generalHospitalization-availables': '#0BD923',
  'emergency-covid': '#FA5846',
  'uci-covid': '#DB413D',
  'uce-covid': '#F2516E',
  'generalHospitalization-covid': '#DB3D71',
  'emergency-ira': '#4B4CF2',
  'uce-ira': '#4E7DFC',
  'uci-ira': '#52A1E5',
  'generalHospitalization-ira': '#4ED9FC',
  'total-covid': '#B8A477',
  'total-covidMen': '#234B75',
  'total-covidWomen': '#FFB8C4',
  'total-ira': '#8D00B0',
  'total-iraMen': '#487945',
  'total-iraWomen': '#E107FF'
};

export const allowedKeys = [
  'emergency-total',
  'uce-total',
  'uci-total',
  'generalHospitalization-total',
  'emergency-availables',
  'uce-availables',
  'uci-availables',
  'generalHospitalization-availables',
  'emergency-covid',
  'uci-covid',
  'uce-covid',
  'generalHospitalization-covid',
  'emergency-ira',
  'uce-ira',
  'uci-ira',
  'generalHospitalization-ira',
  'total-covid',
  'total-covidMen',
  'total-covidWomen',
  'total-ira',
  'total-iraMen',
  'total-iraWomen'
];

export const buildName = value => {
  switch (value) {
    case 'emergency-total':
      return 'Camillas totales(Urg)';
    case 'emergency-availables':
      return 'Camillas disponibles(Urg)';
    case 'emergency-usages':
      return 'Camillas en uso(Urg)';
    case 'emergency-covid':
      return 'Pacientes COVID(Urg)';
    case 'emergency-ira':
      return 'Pacientes con IRA(Urg)';
    case 'uce-total':
      return 'Totales UCE';
    case 'uce-availables':
      return 'Disponibles UCE';
    case 'uce-usages':
      return 'En uso UCE';
    case 'uce-covid':
      return 'Pacientes COVID UCE';
    case 'uce-ira':
      return 'Pacientes IRA UCE';
    case 'uci-total':
      return 'Totales UCI';
    case 'uci-availables':
      return 'Disponibles UCI';
    case 'uci-usages':
      return 'En uso UCI';
    case 'uci-covid':
      return 'Pacientes COVID UCI';
    case 'uci-ira':
      return 'Pacientes IRA UCI';
    case 'generalHospitalization-total':
      return 'Camas totales(Hosp)';
    case 'generalHospitalization-availables':
      return 'Camas disponibles(Hosp)';
    case 'generalHospitalization-usages':
      return 'Camas en uso(Hosp)';
    case 'generalHospitalization-covid':
      return 'Pacientes COVID(Hosp)';
    case 'generalHospitalization-ira':
      return 'Pacientes IRA(Hosp)';
    case 'total-covid':
      return 'Totales Covid';
    case 'total-covidMen':
      return 'Hombres Covid';
    case 'total-covidWomen':
      return 'Mujeres Covid';
    case 'total-ira':
      return 'Totales IRA';
    case 'total-iraMen':
      return 'Hombres IRA';
    case 'total-iraWomen':
      return 'Mujeres IRA';
    default:
      return '--';
  }
};

export const navigationItems = history => [
  { label: 'Recursos disponibles', icon: ListIcon, onClick: () => history.push('/covid-resources') },
  { label: 'Casos activos IPS', icon: LocationIcon, onClick: () => history.push('/covid-map') },
  { label: 'Indicadores', icon: ChartIcon, onClick: () => history.push('/covid-charts') },
  { label: 'Dasboard', icon: DashboardIcon, onClick: () => history.push('/covid-dashboard') }
];

export const getAllResources = async path => {
  try {
    const response = await apiClient.SEM.get(`${urlApi}${path}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getResourcesAsync = async (path, cb) => {
  const listResources = await getAllResources(path);
  if (listResources.ok) {
    cb(listResources.data);
  }
};

export const getData = ({ resources, service, name }) => {
  const { total } = resources[service][name];
  const { availables } = resources[service][name];
  return [availables, total - availables];
};

export const getPieData = (data, key) => {
  const array = Object.keys(data).map(service => {
    return data[service][key];
  });
  return [...array];
};

export const customLegend = [
  { label: 'UCI IRA', color: '#004573' },
  { label: 'UCE IRA', color: '#005C9A' },
  { label: 'Hosp IRA', color: '#337DAE' },
  { label: 'Urg IRA', color: '#77A6C5' },
  { label: 'UCI Covid', color: '#EF084F' },
  { label: 'UCE Covid', color: '#F05000' },
  { label: 'Hosp Covid', color: '#FEABD4' },
  { label: 'Urg Covid', color: '#FE3FFF' }
];

export const dataServices = data => ({
  labels: ['UCI', 'UCE', 'Hospitalicación', 'Urgencias'],
  datasets: [
    {
      label: 'ira',
      data: getPieData(data.byServices, 'ira'),
      backgroundColor: ['#004573', '#005C9A', '#337DAE', '#77A6C5'],
      borderWidth: 4
    },
    {
      label: 'covid',
      data: getPieData(data.byServices, 'covid'),
      backgroundColor: ['#EF084F', '#F05000', '#FEABD4', '#FE3FFF'],
      borderWidth: 4
    }
  ]
});

export const dataCovidBySex = (data, key) => ({
  labels: ['Hombres', 'Mujeres'],
  datasets: [
    {
      data: [data.byGenders[key].male, data.byGenders[key].female],
      backgroundColor: ['#52A1E5', '#EE6E85'],
      hoverBackgroundColor: ['#52A1E5', '#EE6E85'],
      borderWidth: 4
    }
  ]
});

export const barData = data => ({
  labels: data.byAges.map(info => info.label),
  datasets: [
    {
      label: 'Covid',
      backgroundColor: '#F00000',
      borderColor: '#F00000',
      data: data.byAges.map(info => info.covid)
    },
    {
      label: 'IRA',
      backgroundColor: '#005C9A',
      borderColor: '#005C9A',
      data: data.byAges.map(info => info.ira)
    }
  ]
});

export const optionsCovidBySex = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: 'right',
    labels: {
      fontSize: 14,
      boxWidth: 13,
      usePointStyle: true
    }
  }
};

export const strCase = str => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};
