import React from 'react';
import PropTypes from 'prop-types';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import PatientByPathology from '../PatientByPathology';
import CounterChips from './CounterChips';
import PatientChip from './PatientChip';

import { comorbidities } from '../../../variables/ocupation';

const CovidForm = props => {
  const {
    state,
    handleChange,
    comorbiditiesHandleChange,
    addPatient,
    openModal,
    formName,
    label,
    handleMunicipalityChange,
    handleDepartmentChange,
    originDepartmentId,
    originCity,
    originDepartment,
    servicesList
  } = props;
  const modalKey = label === 'Covid' ? 'covid' : 'ira';
  const sexCount = state.patientsInfo.reduce((count, patient) => {
    count[patient.sex] = !patient.outcome ? (count[patient.sex] || 0) + 1 : count[patient.sex] || 0;
    return count;
  }, {});

  const selectComorbodities = patient => {
    if (
      patient.comorbidities &&
      patient.comorbidities.comorbiditiesTypes &&
      patient.comorbidities.comorbiditiesTypes !== null
    ) {
      const comorbSelecteedKeys = Object.keys(patient.comorbidities.comorbiditiesTypes).map(key =>
        patient.comorbidities.comorbiditiesTypes[key].enable === true ? `${key}` : null
      );
      const comorbSelection = comorbidities.filter(comorbiditie =>
        comorbSelecteedKeys.some(selec => comorbiditie.id === selec)
      );
      const selectedComorbidities = [];
      comorbSelection.forEach(s => selectedComorbidities.push(`${s.name}, `));
      return selectedComorbidities;
    }
    return ' No registra';
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="warning" text>
            <CardText color="warning">
              <h4>{label}</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <CounterChips sexCount={sexCount} />
            <GridContainer>
              <GridItem xs={12}>
                <PatientByPathology
                  placeholder={`Pacientes con ${label}`}
                  municipalityList={state.municipalityList}
                  handleChange={handleChange}
                  comorbiditiesHandleChange={comorbiditiesHandleChange}
                  formName={formName}
                  value={state.patientInfo}
                  addPatient={addPatient}
                  handleMunicipalityChange={handleMunicipalityChange}
                  handleDepartmentChange={handleDepartmentChange}
                  servicesList={servicesList}
                  originDepartmentId={originDepartmentId}
                  originCity={originCity}
                  originDepartment={originDepartment}
                  epsList={state.epsList}
                />
                {state.patientsInfo.length > 0 &&
                  state.patientsInfo.map((patient, key) => {
                    const selectedService = servicesList.find(service => patient.serviceType === service.id);
                    const selectedMunicipality = state.municipalityList
                      ? state.municipalityList.find(municipality => patient.originCityId === municipality.id)
                      : '';
                    const selectedEps = patient.epsId
                      ? state.epsList.find(eps => patient.epsId === eps.id)
                      : { name: '' };
                    const selectedComorbidities = selectComorbodities(patient);
                    return patient.outcome && patient.outcome !== null ? null : (
                      <PatientChip
                        patient={patient}
                        id={key}
                        selectedEps={selectedEps}
                        selectedService={selectedService}
                        selectedMunicipality={selectedMunicipality}
                        openModal={openModal}
                        modalKey={modalKey}
                        comorbidities={selectedComorbidities}
                      />
                    );
                  })}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

CovidForm.propTypes = {
  state: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  comorbiditiesHandleChange: PropTypes.func.isRequired,
  addPatient: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  servicesList: PropTypes.array,
  handleMunicipalityChange: PropTypes.func,
  handleDepartmentChange: PropTypes.func,
  originDepartmentId: PropTypes.number,
  originCity: PropTypes.object,
  originDepartment: PropTypes.object
};

CovidForm.defaultProps = {
  state: {
    patientInfo: [],
    municipalityList: [],
    servicesList: [],
    originCity: {},
    originDepartment: {}
  }
};

export default CovidForm;
