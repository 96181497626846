import React, { useEffect, useState } from 'react';
import { withAlert } from 'react-alert';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';

// Components
import AgreementsInfo from 'nxComponents/Agreements/AgreementsInfo';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NxSpinner from '../../nxComponents/NxSpinner/NxSpinner';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from './Table';

// Others
import apiClient from 'config/apiClient';
import { urlApi } from '../../config/app';
import AuthContainer from 'containers-state/auth';

function Agreements(props) {
  const { alert, roles, entityId, history, location } = props;
  const urlParams = new URLSearchParams(location.search);
  const epsIdUrl = urlParams.get('epsId');
  const [agreements, setAgreements] = useState();
  const [epsList, setEpsList] = useState([]);
  const [selectedEps, setEps] = useState(entityId | epsIdUrl);
  const [loading, setLoading] = useState(true);
  const isEps = roles.includes('EPS_REFERRAL_MANAGER');
  const isCrue = roles.includes('CRUE_REFERRAL_MANAGER');

  const getAllEps = () => {
    apiClient.SEM.get(`${urlApi}/eps`)
      .then(({ data }) => {
        setEpsList(data.data.rows);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const getAgreements = () => {
    const path = isEps || isCrue ? 'agreement/byUser/epsCrueOnly' : 'agreement/byRole';
    apiClient.SEM.get(`${urlApi}/${path}/${selectedEps}`)
      .then(({ data }) => {
        const response = data.data.ips.reduce(
          (results, item) => [
            ...results,
            ...item.names.map(ips => ({ ...ips, network: item.network, networkId: item.networkId }))
          ],
          []
        );
        setAgreements(response);
      })
      .catch(error => {
        console.error('getAgreements', error);
        alert.show('Ocurrió un problema al cargar los acuerdos, inténtalo más tarde', {
          type: 'error',
          timeout: 5000
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if(isCrue){
      getAllEps();
    }
  }, []);

  const handleChange = (event) => {
    setEps(event.target.value);
    history.replace({
      search: `?epsId=${event.target.value}`
    });
  };

  useEffect(() => {
    if(selectedEps) {
      getAgreements();
    } else {
      setLoading(false)
    }
  }, [selectedEps]);

  if (loading) {
    return <NxSpinner loading={loading} />;
  }

  return (
    <>
      {isEps || isCrue ? (
        <>
          <GridContainer justify="center">
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary">
                <h3>Lista de acuerdos</h3>
              </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12}>
                      {isCrue && (
                        <FormGroup>
                          <GridItem xs={12} md={6}>
                            <FormLabel style={{ paddingTop: '20px' }}>Seleccione una eps:</FormLabel>
                            <GridItem>
                              <FormControl fullWidth>
                                <Select
                                  value={selectedEps || ''}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'addressRoad',
                                    id: 'addressRoad'
                                  }}
                                >
                                  <MenuItem value="" disabled>Selecciona un opción</MenuItem>
                                  {epsList.map(eps => (
                                    <MenuItem key={`${eps.nit}-${eps.id}`} value={eps.id}>
                                      {eps.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                          </GridItem>
                        </FormGroup>
                      )}
                      {selectedEps > 0 && (
                        <GridItem xs={12}>
                          <Table agreements={agreements} entityId={entityId} getAgreements={getAgreements} selectedEps={selectedEps}/>
                        </GridItem>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      ) : (
        <GridContainer justify="center">
          {agreements ? <AgreementsInfo agreements={agreements} /> : <h3>No se encontraron acuerdos</h3>}
        </GridContainer>
      )}
    </>
  );
}

Agreements.propTypes = {
  alert: PropTypes.object.isRequired
};

const AgreementComponent = withAlert()(Agreements);

export default props => (
  <Subscribe to={[AuthContainer]}>
    {({ state: { roles: userRoles, userInfo } }) => (
      <AgreementComponent {...props} roles={userRoles} entityId={userInfo.attributes.entityId ? userInfo.attributes.entityId[0]: undefined} />
    )}
  </Subscribe>
);
