import React from 'react';
import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui';

export const BandCellBase = ({ children, tableRow, tableColumn, column, ...restProps }) => {
  const background = '#fff';
  // if (column.title === 'Urgencias Adultos') background = 'blue';

  return (
    <TableBandHeader.Cell {...restProps} column={column} style={{ textAlign: 'center', background }}>
      <strong>{children}</strong>
    </TableBandHeader.Cell>
  );
};

// const BandCell = withStyles(cellStyles, { name: 'BandCell' })(BandCellBase);
