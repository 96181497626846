import React from 'react';
import PropTypes from 'prop-types';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';

const headerCellStyles = theme => ({
  text: {
    verticalAlign: 'middle',
    // height: 100,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.4em'
    }
  }
});

export const Header = ({ classes, className, ...restProps }) => {
  return <TableHeaderRow.Cell {...restProps} className={`${classes.text} ${className}`} />;
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export const HeaderCellBase = withStyles(headerCellStyles, { name: 'HeaderCellBase' })(Header);
