import React from 'react';
import './MessageBox.css';

import { FaForward } from 'react-icons/fa';
import { IoMdDoneAll } from 'react-icons/io';
import { MdAccessTime, MdCheck } from 'react-icons/md';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import PhotoMessage from '../PhotoMessage/PhotoMessage';
import FileMessage from '../FileMessage/FileMessage';
import SystemMessage from '../SystemMessage/SystemMessage';
import LocationMessage from '../LocationMessage/LocationMessage';
import SpotifyMessage from '../SpotifyMessage/SpotifyMessage';

import Avatar from '../Avatar/Avatar';

// import FaForward from 'react-icons/lib/fa/mail-forward';
// import FaReply from 'react-icons/lib/fa/mail-reply';

// import IoDoneAll from 'react-icons/lib/io/android-done-all';
// import MdIosTime from 'react-icons/lib/md/access-time';
// import MdCheck from 'react-icons/lib/md/check';


const classNames = require('classnames');

function MessageBox(props) {
  const positionCls = classNames('rce-mbox', { 'rce-mbox-right': props.position === 'right' });
  const thatAbsoluteTime = props.type !== 'text' && props.type !== 'file' && !(props.type === 'location' && props.text);

  const dateText = props.date && !isNaN(props.date) && (props.dateString || distanceInWordsToNow(props.date));

  return (
    <div className={classNames('rce-container-mbox', props.className)} onClick={props.onClick}>
      {props.renderAddCmp instanceof Function && props.renderAddCmp()}
      {props.type === 'system' ? (
        <SystemMessage text={props.text} />
      ) : (
        <div
          className={classNames(
            positionCls,
            { 'rce-mbox--clear-padding': thatAbsoluteTime },
            { 'rce-mbox--clear-notch': !props.notch }
          )}
        >
          <div className="rce-mbox-body" onContextMenu={props.onContextMenu}>
            {props.forwarded === true && (
              <div
                className={classNames(
                  'rce-mbox-forward',
                  { 'rce-mbox-forward-right': props.position === 'left' },
                  { 'rce-mbox-forward-left': props.position === 'right' }
                )}
                onClick={props.onForwardClick}
              >
                <FaForward />
              </div>
            )}

            {(props.title || props.avatar) && (
              <div
                style={props.titleColor && { color: props.titleColor }}
                onClick={props.onTitleClick}
                className={classNames('rce-mbox-title', {
                  'rce-mbox-title--clear': props.type === 'text'
                })}
              >
                {props.avatar && <Avatar src={props.avatar} />}
                {props.title && <span>{props.title}</span>}
              </div>
            )}

            {props.type === 'text' && <div className="rce-mbox-text">{props.text}</div>}

            {props.type === 'location' && (
              <LocationMessage
                onOpen={props.onOpen}
                data={props.data}
                target={props.target}
                href={props.href}
                apiKey={props.apiKey}
                src={props.src}
                zoom={props.zoom}
                markerColor={props.markerColor}
                text={props.text}
              />
            )}

            {props.type === 'photo' && (
              <PhotoMessage
                onOpen={props.onOpen}
                onDownload={props.onDownload}
                onLoad={props.onLoad}
                data={props.data}
                width={props.width}
                height={props.height}
                text={props.text}
              />
            )}

            {props.type === 'file' && (
              <FileMessage onOpen={props.onOpen} onDownload={props.onDownload} data={props.data} text={props.text} />
            )}

            {props.type === 'spotify' && (
              <SpotifyMessage
                width={props.width}
                height={props.height}
                theme={props.theme}
                view={props.view}
                data={props.data}
                uri={props.uri || props.text}
              />
            )}

            <div
              className={classNames(
                'rce-mbox-time',
                { 'rce-mbox-time-block': thatAbsoluteTime },
                { 'non-copiable': !props.copiableDate }
              )}
              data-text={props.copiableDate ? undefined : dateText}
            >
              {props.copiableDate &&
                props.date &&
                !isNaN(props.date) &&
                (props.dateString || distanceInWordsToNow(props.date))}
              {props.status && (
                <span className="rce-mbox-status">
                  {props.status === 'waiting' && <MdAccessTime />}

                  {props.status === 'sent' && <MdCheck />}

                  {props.status === 'received' && <IoMdDoneAll />}

                  {props.status === 'read' && <IoMdDoneAll color="#4FC3F7" />}
                </span>
              )}
            </div>
          </div>

          {props.notch &&
            (props.position === 'right' ? (
              <svg className="rce-mbox-right-notch" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M0 0v20L20 0" />
              </svg>
            ) : (
              <div>
                <svg className="rce-mbox-left-notch" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <defs>
                    <filter id="filter1" x="0" y="0">
                      <feOffset result="offOut" in="SourceAlpha" dx="-2" dy="-5" />
                      <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3" />
                      <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                  </defs>
                  <path d="M20 0v20L0 0" filter="url(#filter1)" />
                </svg>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

MessageBox.defaultProps = {
  position: 'left',
  type: 'text',
  text: '',
  title: null,
  titleColor: null,
  onTitleClick: null,
  onForwardClick: null,
  date: new Date(),
  data: {},
  onClick: null,
  onOpen: null,
  onDownload: null,
  onLoad: null,
  forwarded: false,
  status: null,
  dateString: null,
  notch: true,
  avatar: null,
  renderAddCmp: null,
  copiableDate: false,
  onContextMenu: null
};

export default MessageBox;
