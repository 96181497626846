import io from 'socket.io-client';
import { urlwsRT } from './app';

const socketConnection = io(urlwsRT, {
  path: process.env.REACT_APP_ENV === 'local' ? '' : '/rt',
  autoConnect: false,
  forceNew: true
});

export default socketConnection;
// import io from 'socket.io-client';

// let client;
// export const initws = mesg => {
//   client = new WebSocket('ws://localhost:4237');
//   return client;
// };
// export const getws = () => client;

// export const Notification = getws();

// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/database';
// import 'firebase/storage'

// const prodConfig  = {
//   apiKey: "AIzaSyDvl0QFqzhu4HM6R20aX3RYuEeNl7ks7Bc",
//   authDomain: "paula-project.firebaseapp.com",
//   databaseURL: "https://paula-project.firebaseio.com",
//   projectId: "paula-project",
//   storageBucket: "paula-project.appspot.com",
//   messagingSenderId: "1021784868753"
// };

// const devConfig = {
//   apiKey: "AIzaSyDvl0QFqzhu4HM6R20aX3RYuEeNl7ks7Bc",
//   authDomain: "paula-project.firebaseapp.com",
//   databaseURL: "https://paula-project.firebaseio.com",
//   projectId: "paula-project",
//   storageBucket: "paula-project.appspot.com",
//   messagingSenderId: "1021784868753"
// };

// const config = process.env.NODE_ENV === 'production'
//   ? prodConfig
//   : devConfig;

// if (!firebase.apps.length) {
//   firebase.initializeApp(config);
// }
// const storage = firebase.storage()
// const db = firebase.database();
// const auth = firebase.auth();

// export {
//   db,
//   auth,
//   storage
// };
