import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiClient from 'config/apiClient';

// @material-ui/core
import { makeStyles } from '@material-ui/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// Assets
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import { capitalizeString, selectEpsToShow } from 'utils/attentionNetworks';

// Components
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import EpsCard from './EpsCard';
import AgreementCard from './AgreementCard';

import { urlApi } from '../../config/app';

const useStyles = makeStyles(() => ({
  list: {
    position: 'relative',
    width: '100%'
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

const AttentionNetwork = props => {
  const classesMs = useStyles();
  const { classes } = props;
  const [state, setState] = useState({
    expandedId: null,
    selectedEps: []
  });
  const [epsNetworks, setEpsNetworks] = useState([]);

  useEffect(() => {
    apiClient.SEM.get(`${urlApi}/agreement/all`)
      .then(({ data }) => {
        const epsList = selectEpsToShow(data.data);
        setEpsNetworks(epsList);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleExpandClick = id => {
    if (id === state.expandedId) {
      return setState({
        expandedId: null,
        selectedEps: []
      });
    }
    const selectedEps = epsNetworks.find(eps => eps.id === id);
    const { networks } = selectedEps;
    const networksCopy = [...networks];
    const ambulances = {
      name: 'AMBULANCIAS',
      data: selectedEps.ambulances
    };
    networksCopy.push(ambulances);
    return setState({
      expandedId: id,
      selectedEps: networksCopy
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">
            <h3 className={classes.cardTitleWhite}>Redes de atención</h3>
          </CardHeader>
          <CardBody>
            <div style={{ display: 'flex' }} />
          </CardBody>
        </Card>
        <GridContainer>
          <GridItem xs={4}>
            <List className={classesMs.list}>
              {epsNetworks.length > 0 &&
                epsNetworks.map(eps => {
                  return (
                    <ListItem key={`eps${eps.id}`} className={classesMs.listItem}>
                      <EpsCard
                        handleExpandClick={handleExpandClick}
                        expanded={state.expandedId === eps.id}
                        name={capitalizeString(eps.name)}
                        id={eps.id}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </GridItem>

          <GridItem xs={8}>
            <List className={classesMs.listItem}>
              {state.selectedEps.length > 0 &&
                state.selectedEps.map((net, index) => {
                  return (
                    <ListItem key={`agreements${index}`}>
                      <AgreementCard agreements={net.data} name={net.name} />
                    </ListItem>
                  );
                })}
            </List>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

AttentionNetwork.propTypes = {
  classes: PropTypes.object.isRequired
};

const AttentionNetworkComponent = withStyles(dashboardStyle)(AttentionNetwork);

export default AttentionNetworkComponent;
