import apiClient from '../config/apiClient';
import { urlApi } from '../config/app';

export async function cieCall(inputValue) {
  const response = await apiClient.SEM.get(`${urlApi}/cie10Diagnosis?filter=${inputValue}`);
  const codes = [];
  response.data.data.rows.forEach(element => {
    codes.push({
      id: element.id,
      value: element.code,
      label: element.fullName
    });
  });
  return codes;
}

export async function departmentCall(inputValue) {
  const response = await apiClient.SEM.get(
    `${urlApi}/department?filter=${inputValue}` // corregir URL
  );
  const codes = [];
  response.data.data.rows.forEach(element => {
    codes.push({
      value: element.id,
      label: element.name
    });
  });
  return codes;
}

export async function municipalityCall(inputValue, departmentId) {
  const response = await apiClient.SEM.get(`${urlApi}/municipality/department/${departmentId}?filter=${inputValue}`);
  const codes = [];
  response.data.data.rows.forEach(element => {
    codes.push({
      value: element.id,
      label: element.name
    });
  });
  return codes;
}

export async function neighborhoodCall(inputValue, municipalityId) {
  const response = await apiClient.SEM.get(
    `${urlApi}/neighborhood/municipality/${municipalityId}?filter=${inputValue}`
  );
  const codes = [];
  response.data.data.rows.forEach(element => {
    codes.push({
      value: element.id,
      label: element.name
    });
  });
  return codes;
}

export const isIpsRecipient = (entityId, transferData) => {
  return parseInt(entityId, 10) === (transferData || {}).ipsRecvId;
};

export const isIpsApplicant = (entityId, transferData) => {
  return parseInt(entityId, 10) === (transferData || {}).ipsReqId;
};

export const isTransportEntity = (entityId, transferData) => {
  return parseInt(entityId, 10) === (transferData || {}).ambulancesEntityId;
};
