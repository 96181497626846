import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';

// @material-ui/core
import FormControl from '@material-ui/core/FormControl';

// Components
import GridItem from 'components/Grid/GridItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const TransferReport = ({
  initialDate,
  finalDate,
  ipsList,
  ipsReceives,
  ipsRequesting,
  ipsForIps,
  epsList,
  eps,
  triage,
  attentionNetwork,
  ambulanceEntity,
  attentionNetworkList,
  ambulanceEntityList,
  handleDateChange,
  handleIpsChange,
  roles,
  valid,
  handleChange,
  validFinalDate,
  classes
}) => {
  const showAvailableList = (ipsL, rol) => {
    if (rol.includes('CRUE_REFERRAL_MANAGER') || rol.includes('TRANSPORT') || rol.includes('EPS')) {
      return ipsL.map(a => (
        <MenuItem key={a.id} value={a.id}>
          {a.name}
        </MenuItem>
      ));
    }
    return null;
  };

  function showAvailableData(data) {
    return data.map(n => (
      <MenuItem key={n.id} value={n.id}>
        {n.name}
      </MenuItem>
    ));
  }

  return (
    <div>
      <div style={{ display: 'flex', marginTop: '15px' }}>
        <GridItem xs={12} sm={12} md={6}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel} error={!initialDate}>
            Fecha inicial *
          </InputLabel>
          <FormControl fullWidth>
            <Datetime
              viewMode="years"
              dateFormat="YYYY-MMM-DD"
              timeFormat={false}
              value={initialDate}
              isValidDate={date => valid(date)}
              closeOnSelect
              inputProps={{
                placeholder: 'Fecha inicial',
                required: true
              }}
              onChange={date => handleDateChange(date, 'initialDate')}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel} error={!finalDate}>
            Fecha final *
          </InputLabel>
          <FormControl fullWidth disabled={!initialDate}>
            <Datetime
              viewMode="years"
              dateFormat="YYYY-MMM-DD"
              timeFormat={false}
              value={finalDate}
              isValidDate={date => validFinalDate(date, initialDate)}
              closeOnSelect
              inputProps={{
                placeholder: 'Fecha Final',
                required: true,
                disabled: !initialDate
              }}
              onChange={date => handleDateChange(date, 'finalDate')}
            />
          </FormControl>
        </GridItem>
      </div>
      {roles.includes('IPS') ? (
        <div style={{ display: 'flex' }}>
          <GridItem xs={12} sm={6}>
            <FormControl
              disabled={!ipsList}
              fullWidth
              className={classes.selectFormControl}
              style={{ marginTop: '10px' }}
            >
              <InputLabel htmlFor="simple-select" className={classes.selectLabel} error={!ipsForIps}>
                Tipo de traslado
              </InputLabel>
              <Select
                classes={{ select: classes.select }}
                value={ipsForIps || ''}
                onChange={event => handleIpsChange(event)}
                fullWidth
                inputProps={{
                  name: 'ipsForIps',
                  id: 'ipsForIps'
                }}
              >
                <MenuItem value={1}>Traslados que he solicitado</MenuItem>
                <MenuItem value={2}>Traslados que he recibido</MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          <GridItem xs={12} sm={6}>
            <FormControl
              disabled={!ipsList}
              fullWidth
              className={classes.selectFormControl}
              style={{ marginTop: '10px' }}
            >
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                IPS que recibe
              </InputLabel>
              <Select
                classes={{ select: classes.select }}
                value={ipsReceives || ''}
                onChange={event => handleChange(event)}
                fullWidth
                inputProps={{
                  name: 'ipsReceives',
                  id: 'ipsReceives'
                }}
              >
                {showAvailableList(ipsList, roles)}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <FormControl
              disabled={!ipsList}
              fullWidth
              className={classes.selectFormControl}
              style={{ marginTop: '10px' }}
            >
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                IPS que solicita
              </InputLabel>
              <Select
                classes={{ select: classes.select }}
                value={ipsRequesting || ''}
                onChange={event => handleChange(event)}
                fullWidth
                inputProps={{
                  name: 'ipsRequesting',
                  id: 'ipsRequesting'
                }}
              >
                {showAvailableList(ipsList, roles)}
              </Select>
            </FormControl>
          </GridItem>
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <GridItem xs={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl} style={{ marginTop: '10px' }}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Triage
            </InputLabel>
            <Select
              classes={{ select: classes.select }}
              value={triage || ''}
              onChange={event => handleChange(event)}
              fullWidth
              inputProps={{
                name: 'triage',
                id: 'triaje'
              }}
            >
              <MenuItem value={1}>Triage nivel 1</MenuItem>
              <MenuItem value={2}>Triage nivel 2</MenuItem>
              <MenuItem value={3}>Triage nivel 1 y 2</MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormControl
            disabled={!attentionNetworkList}
            fullWidth
            className={classes.selectFormControl}
            style={{ marginTop: '10px' }}
          >
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Red de atención
            </InputLabel>
            <Select
              classes={{ select: classes.select }}
              value={attentionNetwork || ''}
              onChange={handleChange}
              fullWidth
              inputProps={{
                name: 'attentionNetwork',
                id: 'attentionNetwork'
              }}
            >
              {showAvailableData(attentionNetworkList)}
            </Select>
          </FormControl>
        </GridItem>
      </div>
      <div style={{ display: 'flex' }}>
        <GridItem xs={12} sm={6}>
          <FormControl
            disabled={!ambulanceEntityList}
            fullWidth
            className={classes.selectFormControl}
            style={{ marginTop: '10px' }}
          >
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Entidad prestadora del servicio de ambulancia
            </InputLabel>
            <Select
              disabled={roles.includes('TRANSPORT')}
              classes={{ select: classes.select }}
              value={ambulanceEntity || ''}
              onChange={handleChange}
              fullWidth
              inputProps={{
                name: 'ambulanceEntity',
                id: 'ambulanceEntity'
              }}
            >
              {showAvailableData(ambulanceEntityList)}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControl
            disabled={!epsList}
            fullWidth
            className={classes.selectFormControl}
            style={{ marginTop: '10px' }}
          >
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              EPS
            </InputLabel>
            <Select
              disabled={roles.includes('EPS')}
              classes={{ select: classes.select }}
              value={eps || ''}
              onChange={handleChange}
              fullWidth
              inputProps={{
                name: 'eps',
                id: 'eps'
              }}
            >
              {showAvailableData(epsList)}
            </Select>
          </FormControl>
        </GridItem>
      </div>
    </div>
  );
};

TransferReport.propTypes = {
  initialDate: PropTypes.instanceOf(Date),
  finalDate: PropTypes.instanceOf(Date),
  ipsList: PropTypes.array.isRequired,
  attentionNetworkList: PropTypes.array.isRequired,
  ambulanceEntityList: PropTypes.array.isRequired,
  ipsReceives: PropTypes.number,
  ipsRequesting: PropTypes.number,
  ipsForIps: PropTypes.number,
  epsList: PropTypes.array.isRequired,
  eps: PropTypes.number,
  triage: PropTypes.number,
  attentionNetwork: PropTypes.number,
  ambulanceEntity: PropTypes.number,
  handleDateChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleIpsChange: PropTypes.func.isRequired,
  valid: PropTypes.func.isRequired,
  validFinalDate: PropTypes.func.isRequired,
  classes: PropTypes.object,
  roles: PropTypes.array,
  userInfo: PropTypes.object
};

export default TransferReport;
