// style for this view
const styles = {
  iOSSwitchBase: {
    '&$iOSChecked': {
      color: 'white',
      '& + $iOSBar': {
        // backgroundColor: '#52d869'
        backgroundColor: '#05BF95'
      }
    }
  },
  iOSChecked: {
    transform: 'translateX(15px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none'
    }
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: 'solid 1px',
    borderColor: '#969696',
    backgroundColor: '#e2e2e2',
    opacity: 1
  },
  iOSIcon: {
    width: 24,
    height: 24
  },
  cardBodyJustify: {
    display: 'flex',
    justifyContentCenter: 'space-between'
  }
};

export default styles;
