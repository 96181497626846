import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useBreakpoints from 'customHooks/useBreakpoints';
// @material-ui/core
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';
// Components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Agreement from 'Icons/Agreement';
import BulletPoint from 'Icons/BulletPoint';

import { capitalizeString } from 'utils/attentionNetworks';

const useStyles = makeStyles(theme => ({
  card: {
    height: 'auto',
    zIndex: '1',
    width: '100%',
    borderRadius: '13px',
    display: 'flex',
    margin: '20px 0 auto 0'
  },
  cardContent: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    height: '75px',
    width: '75px',
    fill: '#858585 !important',
    margin: '17px auto 17px auto',
    [theme.breakpoints.up('md')]: {
      height: '40px',
      width: '40px',
      margin: '40px auto 40px auto'
    },
    [theme.breakpoints.up('lg')]: {
      height: '60px',
      width: '60px',
      margin: '30px auto 30px auto'
    },
    [theme.breakpoints.up('xl')]: {
      height: '60px',
      width: '60px',
      margin: '30px auto 30px auto'
    }
  },
  visibility: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  gridList: {
    height: 100
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  listSubheader: {
    padding: 0
  },
  cardHeader: {
    position: 'relative',
    zIndex: '3 !important',
    marginTop: '-20px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFF',
    background: 'transparent',
    marginBottom: '0',
    borderRadius: '13px 50px 13px 0',
    color: '#21CCA2',
    boxShadow: '0px 3px 6px #a6a6a6',
    [theme.breakpoints.up('xs')]: {
      // maxWidth: '17vw',
      padding: '1px 15px 1px 15px'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '17vw',
      whiteSpace: 'nowrap',
      padding: '1px 30px 1px 30px',
      minWidth: 'max-content !important'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '17vw',
      whiteSpace: 'nowrap',
      padding: '1px 86px 1px 43px',
      minWidth: 'max-content !important'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '20vw',
      whiteSpace: 'nowrap',
      padding: '1px 86px 1px 43px',
      minWidth: 'max-content !important'
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '25vw',
      whiteSpace: 'nowrap',
      padding: '1px 86px 1px 43px',
      minWidth: 'max-content !important'
    }
  },
  networkTitle: {
    fontWeight: 'bold'
  },
  bulletPoint: {
    marginRight: '10px'
  }
}));

const AgreementsCard = ({ agreements, name }) => {
  const classes = useStyles();
  const point = useBreakpoints();

  return (
    <Card className={classes.card}>
      <div className={classes.cardHeader}>
        <h4 className={classes.networkTitle}> {capitalizeString(name)} </h4>
      </div>
      <CardBody className={classes.cardContent}>
        <GridContainer>
          <GridItem md={2} className={classes.visibility}>
            <Agreement className={classes.icon} />
          </GridItem>
          <GridItem xs={12} md={10}>
            <GridContainer>
              <GridItem xs={12} className="list">
                <GridList cols={point === 'xs' ? 1 : 2} cellHeight="auto">
                  <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                    <ListSubheader color="inherit" component="div" className={classes.listSubheader}>
                      Convenio con:
                    </ListSubheader>
                  </GridListTile>
                  {agreements.length > 0 ? (
                    agreements.map(agreement => (
                      <Typography component="p" variant="body2" key={`agreement${agreement.id}`}>
                        <BulletPoint className={classes.bulletPoint} />
                        {agreement.ips && capitalizeString(agreement.ips.name)}
                        {agreement.ambulancesEntity && capitalizeString(agreement.ambulancesEntity.name)}
                      </Typography>
                    ))
                  ) : (
                    <Typography component="p" variant="body1">
                      No cuenta con convenios
                    </Typography>
                  )}
                </GridList>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

AgreementsCard.propTypes = {
  agreements: PropTypes.array,
  name: PropTypes.string
};

AgreementsCard.defaultProps = {
  agreements: [],
  name: ''
};

export default AgreementsCard;
