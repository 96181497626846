// @flow

import React, { Fragment } from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import apiClient from 'config/apiClient';
import TransferContainer from 'containers-state/transferState';
import AuthContainer from 'containers-state/auth';
import { urlApi } from 'config/app';
import { Subscribe } from 'unstated';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import DialogActions from '@material-ui/core/DialogActions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

// material ui icons

// Styles
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

// core components
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Tooltip from '@material-ui/core/Tooltip';
import CardText from 'components/Card/CardText';
// import GridContainer from 'components/Grid/GridContainer';
// import GridItem from 'components/Grid/GridItem';
import NxTimeline from 'nxComponents/NxTimeline/NxTimeline';
import NxSpinner from '../NxSpinner/NxSpinner';
import { typeDocuments } from '../../variables/person';
import { referralReason as transferReason } from '../../variables/transfer';

// Others

const styles = theme => ({
  ...validationFormsStyle,
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  titleInfo: {
    margin: 0,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  gridDl_dt: {
    borderTop: '2px solid #ccc',
    padding: '0.5em 0 0.5em 0',
    fontWeight: 600
  },
  gridDl_dd: {
    margin: 0,
    padding: '0.5em 0 0.5em 0'
  },
  gridDl_dt_dd: {
    margin: 0,
    padding: '0.5em 0 0.5em 0'
  },
  '@media (min-width: 550px)': {
    gridDl: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      gridTemplateRows: 'auto'
    },
    gridDl_dt: {
      gridColumnStart: 1
    },
    gridDl_dd: {
      gridColumnStart: 2,
      margin: 0
    },
    gridDl_dt_dd: {
      gridColumnStart: 2, // ToDo: buscar el plugin extends de JSS
      margin: 0,
      borderTop: '2px solid #ccc'
    }
  },
  ...buttonStyle
});

const capitalize = s => {
  if (typeof s !== 'string') return s;
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export class ViewDataTransfer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      openAccordion: false
    };
  }

  componentDidMount() {
    this.getTransfer();
  }

  componentWillUnmount() {
    const { transferData } = this.props;
    transferData.setCurrentTransfer(null);
  }

  handleSpinner = boolean => this.setState({ loading: boolean });

  getTransfer = () => {
    const { transferData, match } = this.props;
    const trasferId = match.params.transferId;
    transferData
      .getTransferId(trasferId)
      .then(() => {
        this.getFiles(trasferId);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getFiles = trasferId => {
    const { alert } = this.props;
    apiClient.SEM.get(`${urlApi}/file/referral/${trasferId}`)
      .then(({ data }) => {
        this.setState({
          attach9Url: data.data.attach9Url,
          diagnosesUrls: data.data.diagnosesUrls
        });
      })
      .catch(() => {
        alert.show('No se encontró información del traslado.', {
          type: 'error'
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleCloseAccordion = () => {
    const { openAccordion } = this.state;
    if (openAccordion) {
      const container = document.querySelector('#scrollComponent');
      container.scrollTo(0, 0);
      this.setState({ openAccordion: false });
    }
  };

  handleChangeAccordion = () => {
    this.setState(prevState => {
      return { openAccordion: !prevState.openAccordion };
    });
  };

  valid = date => date.isBefore(new Date());

  render() {
    const { classes = {}, transferData, roles } = this.props;
    if (!transferData.state.currentTransfer) {
      return <NxSpinner loading />;
    }
    const {
      patient,
      network,
      referralReason,
      observations,
      ambulanceType,
      createdAt
    } = transferData.state.currentTransfer;
    const { attach9Url, diagnosesUrls, loading, openAccordion } = this.state;
    const sex = patient && patient.person.sex === 'M' ? 'Masculino' : 'Femenino';
    return (
      <div className={classes.root}>
        <NxSpinner loading={loading} />
        <NxTimeline
          transferInfo={transferData.state.currentTransfer}
          saveHours={transferData.saveHours}
          handleSpinner={this.handleSpinner}
        />
        <ClickAwayListener onClickAway={this.handleCloseAccordion}>
          <ExpansionPanel
            expanded={openAccordion}
            style={{ background: '#cee6d585' }}
            onChange={this.handleChangeAccordion}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                Información de la solicitud
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Card style={{ boxShadow: '3px 4px 5px 3px rgba(0, 0, 0, 0.34)' }}>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Solicitud de traslado</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <dl className={classes.gridDl}>
                    <dt className={classes.gridDl_dt}>Fecha de la solicitud:</dt>
                    <dd className={classes.gridDl_dt_dd}>{format(createdAt, 'DD-MM-YYYY  hh:mm a')}</dd>

                    <dt className={classes.gridDl_dt}>Nombre Completo:</dt>
                    <dd className={classes.gridDl_dt_dd}>
                      {patient
                        ? `${patient.person.firstName || ''} ${patient.person.middleName || ''} ${
                            patient.person.lastName
                          } ${patient.person.secondLastName || ''}`
                        : ''}
                    </dd>

                    <dt className={classes.gridDl_dt}>Tipo de documento:</dt>
                    <dd className={classes.gridDl_dt_dd}>
                      {patient && typeDocuments.find(type => type.value === patient.person.documentType)
                        ? typeDocuments.find(type => type.value === patient.person.documentType).name
                        : ''}
                    </dd>

                    <dt className={classes.gridDl_dt}>Número de documento:</dt>
                    <dd className={classes.gridDl_dt_dd}>{patient ? `${patient.person.document}` : ''}</dd>

                    <dt className={classes.gridDl_dt}>Sexo:</dt>
                    <dd className={classes.gridDl_dt_dd}>{sex}</dd>
                    {/* <dd className={classes.gridDl_dd}>
                        Elika J. Etemad / fantasai (Invited Expert)
                      </dd> */}

                    <dt className={classes.gridDl_dt}>Fecha de nacimiento:</dt>
                    <dd className={classes.gridDl_dt_dd}>
                      {patient ? format(new Date(patient.person.birthdate), 'DD-MM-YYYY') : ''}
                    </dd>

                    <dt className={classes.gridDl_dt}>Dirección:</dt>
                    <dd className={classes.gridDl_dt_dd}>
                      {patient ? `${patient.person.address || 'Sin información'}` : ''}
                    </dd>

                    <dt className={classes.gridDl_dt}>Teléfono o celular:</dt>
                    <dd className={classes.gridDl_dt_dd}>
                      {patient ? `${patient.person.phone || 'Sin información'}` : ''}
                    </dd>

                    <dt className={classes.gridDl_dt}>EPS:</dt>
                    <dd className={classes.gridDl_dt_dd}>
                      {patient ? capitalize(`${patient.insuranceRegime.eps.name}`) : ''}
                    </dd>

                    <dt className={classes.gridDl_dt}>Régimen de seguridad social</dt>
                    <dd className={classes.gridDl_dt_dd}>
                      {patient ? capitalize(`${patient.insuranceRegime.regimeType.name}`) : ''}
                    </dd>

                    <dt className={classes.gridDl_dt}>Red de atención a la que solicita traslado:</dt>
                    <dd className={classes.gridDl_dt_dd}>{network ? `${network.name}` : ''}</dd>

                    <dt className={classes.gridDl_dt}>Triage:</dt>
                    <dd className={classes.gridDl_dt_dd}>{patient ? `${patient.triage}` : ''}</dd>

                    {ambulanceType && (
                      <Fragment>
                        <dt className={classes.gridDl_dt}>Tipo de ambulancia:</dt>
                        <dd className={classes.gridDl_dt_dd}>{ambulanceType || ''}</dd>
                      </Fragment>
                    )}

                    <dt className={classes.gridDl_dt}>Motivo del traslado:</dt>
                    <dd className={classes.gridDl_dt_dd}>
                      {transferReason.find(reason => reason.value === referralReason)
                        ? transferReason.find(reason => reason.value === referralReason).name
                        : ''}
                    </dd>

                    {/* <dt className={classes.gridDl_dt}>¿Es habitante de calle?:</dt>
                    <dd className={classes.gridDl_dt_dd}>
                      {patient ? `${patient.isInhabitantOfStreet ? 'Si' : 'No'}` : ''}
                      </dd> */}
                    {sex === 'Femenino' && (
                      <Fragment>
                        <dt className={classes.gridDl_dt}>¿Esta en embarazo?:</dt>
                        <dd className={classes.gridDl_dt_dd}>{patient ? `${patient.isPregnant ? 'Si' : 'No'}` : ''}</dd>
                      </Fragment>
                    )}
                    <dt className={classes.gridDl_dt}>Diagnosticos:</dt>
                    {patient &&
                      patient.diagnoses.map((diagnose, index) => (
                        <dd className={classes.gridDl_dt_dd} key={index}>
                          {capitalize(diagnose.cie10.name)}
                        </dd>
                      ))}

                    <dt className={classes.gridDl_dt}>Observaciones generales:</dt>
                    <dd className={classes.gridDl_dt_dd}>{observations || 'Sin información'}</dd>
                  </dl>
                  {!roles.includes('TRANSPORT_REFERRAL_MANAGER') && (
                    <DialogActions
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {diagnosesUrls &&
                        transferData.state.currentTransfer.diagnosesResources &&
                        diagnosesUrls.map((url, index) => (
                          <Tooltip key={index} title={transferData.state.currentTransfer.diagnosesResources[index]}>
                            <a className={[classes.button, classes.primary]} href={url} target="_new">
                              {`Descargar archivo ${index + 1}`}
                            </a>
                          </Tooltip>
                        ))}
                      <a className={[classes.button, classes.primary]} href={attach9Url} target="_new">
                        Descargar anexo 9
                      </a>
                    </DialogActions>
                  )}
                </CardBody>
              </Card>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </ClickAwayListener>
      </div>
    );
  }
}

ViewDataTransfer.propTypes = {
  classes: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  transferData: PropTypes.object,
  match: PropTypes.object
};
// export default withAlert(withStyles(styles)(ViewDataTransfer));
const ViewDataTransferStyle = withAlert()(withStyles(styles)(ViewDataTransfer));
export default props => (
  <Subscribe to={[AuthContainer, TransferContainer]}>
    {({ state: { roles: userRoles } }, { state, getTransferId, setCurrentTransfer, saveHours }) => (
      <ViewDataTransferStyle
        {...props}
        transferData={{ getTransferId, state, setCurrentTransfer, saveHours }}
        roles={userRoles}
      />
    )}
  </Subscribe>
);
