import React, {useEffect, useState} from 'react'
import { withRouter } from 'react-router-dom';
import { Subscribe } from 'unstated';
import AuthContainer from 'containers-state/auth';

import {makeStyles} from '@material-ui/core/styles'
import {useAlert} from 'react-alert'
import apiClient from 'config/apiClient';
import FormControl from '@material-ui/core/FormControl';

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NxSpinner from 'nxComponents/NxSpinner/NxSpinner';

import useHandleForm from 'utils/customHooks/useForm'

// Others
import { urlApi } from 'config/app';

const useStyles = makeStyles(theme => ({
  errorText: {
    color: '#f44336 !important',
    fontSize: '0.9rem',
  },
}))


function AgreementForm(props) {
  const { entityId, history, match, roles, location } = props;
  const urlParams = new URLSearchParams(location.search);
  const epsIdUrl = urlParams.get('epsId');
  const initialState = { epsId: props.entityId || epsIdUrl };
  const [isLoading, setLoading] = useState(true);
  const isCrue = roles.includes('CRUE_REFERRAL_MANAGER');
  const agreementId = Number(match.params.agreementId);
  const classes = useStyles();
  const alert = useAlert();
  
  const editAgreement = (body, agreementId) => {
    return apiClient.SEM.patch(`${urlApi}/agreement/${agreementId}`, body)
  }

  const createAgreement = (body) => {
    return apiClient.SEM.post(`${urlApi}/agreement`, body);
  }
  
  const run = async () => {
    let msg;
    try {
      if (agreementId) {
        await editAgreement({
          epsId: inputs.epsId, 
          ipsId: inputs.ipsId, 
          networkId: inputs.networkId
        }, agreementId)
        msg = 'Se ha editado el acuerdo exitosamente';
      } else {
         await createAgreement({ epsId: inputs.epsId, ipsId: inputs.ipsId, networkId: inputs.networkId });
         msg = 'Se ha creado el acuerdo exitosamente';
      }
      history.goBack()
      alert.show(msg);
    } catch (error) {
      console.error(error)
      alert.show(`Hubo un error al ${agreementId ? "editar" : "crear"} el acuerdo`, {type: 'error'});
    }

  }

  const {inputs, handleInputChange, handleSubmit, setInputs} = useHandleForm(run, undefined, initialState)

  useEffect(() => {
    Promise.all([getIPS(), getNetworks(), getCurrentAgrement(), getAllEps()])
      .then(([ipsList, networks, agreement, epsList]) => {
        setInputs({ ...inputs, ipsList, networks, ...agreement, epsList });
      })
      .finally(() => setLoading(false));
  }, [])

  const getCurrentAgrement = () => {
    if (agreementId) {
      return apiClient.SEM.get(`${urlApi}/agreement/${agreementId}`)
        .then(response => {
          const picked = (({ epsId, ipsId, networkId, id }) => ({ epsId, ipsId, networkId, id }))(response.data.data);
          return picked
        })
        .catch(error => {
          alert.show('Ocurrió un problema al cargar los acuerdos, inténtalo más tarde', {
            type: 'error',
            timeout: 5000
          });
        });
    }
  }

  const getAllEps = () => {
    return apiClient.SEM.get(`${urlApi}/eps`)
      .then(response => {
        const epsList = response.data.data.rows.map(({name, id}) => ({name, id}))
        return epsList
      })
      .catch(error => {
        alert.show('Ocurrió un problema al cargar las EPS, inténtalo más tarde', {
          type: 'error',
          timeout: 5000
        });
      });
  };

  const getIPS = () => {
    return apiClient.SEM.get(`${urlApi}/ips`)
      .then(response => {
        const ipsList = response.data.data.rows.map(({name, id}) => ({name, id}))
        return ipsList
      })
      .catch(error => {
        alert.show('Ocurrió un problema al cargar las IPS, inténtalo más tarde', {
          type: 'error',
          timeout: 5000
        });
      });
  }
  const getNetworks = () => {
    return apiClient.SEM.get(`${urlApi}/network`)
      .then(response => {
        const networks = response.data.data.rows.map(({name, id}) => ({name, id}))
        return networks
      })
      .catch(error => {
        alert.show('Ocurrió un problema al cargar las IPS, inténtalo más tarde', {
          type: 'error',
          timeout: 5000
        });
      });
  }

  return (
    <>
      {isLoading && <NxSpinner loading={isLoading} />}
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={10}>
            <Button round color="white" type={'submit'} onClick={() => history.goBack()}>
              atrás
            </Button>
            <Card className={classes.cardSignup}>
              <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>{agreementId ? 'Editar' : 'Crear'} acuerdo</h3>
            </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12}>
                    <form className={classes.form} onSubmit={handleSubmit}>
                      {inputs.epsList && (
                        <GridItem xs={12} hidden={!isCrue}>
                          <FormControl
                            fullWidth
                            disabled={agreementId}
                            style={{ marginTop: '10px' }}
                          >
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                              EPS
                            </InputLabel>
                            <Select
                              value={inputs.epsId || ''}
                              onChange={handleInputChange}
                              fullWidth
                              inputProps={{
                                name: 'epsId',
                                id: 'ips'
                              }}
                            >
                              {inputs.epsList.map(eps => (
                                  <MenuItem key={eps.id} value={eps.id}>
                                    {eps.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      )}
                      {inputs.ipsList && (
                        <GridItem xs={12}>
                          <FormControl
                            fullWidth
                            disabled={!!agreementId}
                            style={{ marginTop: '10px' }}
                          >
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                              IPS
                            </InputLabel>
                            <Select
                              value={inputs.ipsId || ''}
                              onChange={handleInputChange}
                              fullWidth
                              inputProps={{
                                name: 'ipsId',
                                id: 'ips'
                              }}
                            >
                              {inputs.ipsList.map(ips => (
                                <MenuItem key={ips.id} value={ips.id}>
                                  {ips.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      )}
                      {inputs.networks && (
                        <GridItem xs={12}>
                          <FormControl
                            disabled={!inputs.networks}
                            fullWidth
                            // className={classes.selectFormControl}
                            style={{ marginTop: '10px' }}
                          >
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                              Red
                            </InputLabel>
                            <Select
                              value={inputs.networkId || ''}
                              onChange={handleInputChange}
                              fullWidth
                              inputProps={{
                                name: 'networkId',
                                id: 'ips'
                              }}
                            >
                              {inputs.networks.map(n => (
                                <MenuItem key={n.id} value={n.id}>
                                  {n.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      )}

                      <div className={classes.textCenter}>
                        <Button round color="primary" type={'submit'} onClick={handleSubmit}>
                          {agreementId ? 'Editar' : 'Guardar'}
                        </Button>
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </>
  )
}

const AgreementFormComponent = withRouter(AgreementForm);

export default props => (
  <Subscribe to={[AuthContainer]}>
    {({ state: { userInfo, roles: userRoles } }) => (
      <AgreementFormComponent
        {...props}
        roles={userRoles}
        entityId={userInfo.attributes.entityId ? userInfo.attributes.entityId[0] : undefined}
      />
    )}
  </Subscribe>
);