import React from 'react';
import PropTypes from 'prop-types';
import apiClient from 'config/apiClient';
import { urlApi } from 'config/app';
import { eventType2ES } from 'utils/ambulances';
import { distanceInWordsToNow } from 'date-fns';

// @material-ui/core components
import Divider from '@material-ui/core/Divider';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const esLocale = require('date-fns/locale/es');

/**
//  * @param {Object} ambulance
 */

class AmbulancesInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      availability: {},
      loading: false
    };
  }

  componentDidMount() {
    const { ambulance } = this.props;
    if (ambulance.ambulancesEntityId !== 1) {
      this.getavailability(ambulance.id);
    }
  }

  getavailability = ambulanceId => {
    this.setState({ loading: true });
    apiClient.SEM.get(`${urlApi}/ambulance/${ambulanceId}/availability`)
      .then(({ data }) => {
        this.setState({ availability: data.data });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  renderMessage = () => {
    const { availability } = this.state;
    const { ambulance } = this.props;
    const eventType = eventType2ES(availability.eventType);
    const timeAgo = distanceInWordsToNow(availability.createdAt, { locale: esLocale });

    if (ambulance.ambulancesEntityId === 1) {
      return null;
    }
    return (
      <div>
        <Divider />
        <h5>{`La ambulancia se encuentra ${eventType} hace ${timeAgo}.`}</h5>
        {availability.ips && (
          <h5>
            <strong>Ips:</strong> {availability.ips.name.toLowerCase()}.
          </h5>
        )}
        {availability.comment && (
          <h5>
            <strong>Comentarios:</strong> {availability.comment}
          </h5>
        )}
      </div>
    );
  };

  render() {
    const { ambulance } = this.props;
    const { loading } = this.state;
    let ambulanceName;
    let plate;
    if (ambulance.ambulanceEntityId === 1) {
      const name = ambulance.name.split(' ');
      ambulanceName = `${name[1]} ${name[2]}`;
      plate = `${name[0]}`;
    } else {
      ambulanceName = ambulance.name;
      plate = ambulance.licensePlate;
    }
    return (
      <GridContainer>
        <GridItem style={{ margin: '0 10px' }}>
          <Card>
            <CardBody>
              <h5>
                <strong>Nombre:</strong> {ambulanceName}
              </h5>
              {ambulance.aph1Name && (
                <h5>
                  <strong>APH</strong> 1: {ambulance.aph1Name}
                </h5>
              )}
              {ambulance.aph2Name && (
                <h5>
                  <strong>APH 2:</strong> {ambulance.aph2Name}
                </h5>
              )}
              {ambulance.phoneNumber && (
                <h5>
                  <strong>Teléfono:</strong> {ambulance.phoneNumber}
                </h5>
              )}
              {ambulance.cellphone && (
                <h5>
                  <strong>Celular:</strong> {ambulance.cellphone}
                </h5>
              )}
              <h5>
                <strong>Placa:</strong> {plate}
              </h5>
              {ambulance.pendingSupplies && <h5>Suministros pendientes: {ambulance.pendingSupplies}</h5>}
              {loading ? <h5>Cargando información de disponibilidad...</h5> : this.renderMessage()}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

AmbulancesInfo.propTypes = {
  ambulance: PropTypes.object.isRequired
};

export default AmbulancesInfo;
