import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  paper: {
    padding: 20
  }
};

/**
//  * @param {Object} ambulance
//  * @param {Object} classes
 */

export const CommuneInfo = props => {
  const { commune, classes } = props;
  return (
    <div className={classes.paper}>
      <h4>Comuna: {commune.commune}</h4>
      <h4>Nombre: {commune.name}</h4>
      <h4>Sector: {commune.section}</h4>
    </div>
  );
};

CommuneInfo.propTypes = {
  commune: PropTypes.object.isRequired,
  classes: PropTypes.object
};

CommuneInfo.defaultProps = {
  classes: {}
};

export default withStyles(styles)(CommuneInfo);
